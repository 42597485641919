import makeStyles from "@mui/styles/makeStyles";
import { Bold, SemiBold } from "../../../utils";

export const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.dashboard.primary,
        height: `calc(100vh - 128px)`,
        overflow: "overlay",
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": {
            display: "none",
        },
    },
    btn: {
        // border: "1px solid #CED3DD",
        boxShadow: "0px 3px 30px #5C86CB2E",
        padding: "12px",
        borderRadius: "4px",
        color: theme.typography.color.primary,
        backgroundColor: theme.palette.background.paper,
        marginBottom: "4px"
    },
    next: {
        boxShadow: "none",
        padding: "8px",
        textTransform: "capitalize",
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
        }
    },
    btn1: {
        boxShadow: "none",
        padding: "8px",
        textTransform: "capitalize",
        border: "1px solid #E4E8EE",
        backgroundColor: theme.palette.background.paper,
        color: theme.typography.color.primary,
    },
    commencebtn: {
        // boxShadow: "none",
        padding: "8px",
        textTransform: "capitalize",
        backgroundColor: theme.palette.primary.main,
        color: "#FFFFFF",
        fontSize: "14px",
        fontFamily:"NunitoSans-Bold",
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
            color: "#FFFFFF",
        }

    },
    formRoot: {
        height: `calc(100vh - 128px)`,
        overflow: "auto",
        position: "relative"
    },
    btnRoot: {
        padding: "12px",
        backgroundColor: theme.palette.background.paper,
    },
    fab: {
        position: "absolute",
        bottom: "20px",
        right: "10px"
    },
    signRoot: {

        height: "400px",
        borderRadius: "8px",
        [theme.breakpoints.up('sm')]: {
            width: "32.5rem",
        },
        [theme.breakpoints.down('sm')]: {
            width: "20.5rem",
        },
    },
    clearBtn: {
        position: "absolute",
        bottom: "20px",
        textTransform: "capitalize"
    },
    label: {
        color: theme.typography.color.Tertiary,
        fontSize: "12px",
        paddingBottom: "8px",
        fontFamily: SemiBold
    },
    image: {
        height: "400px",
        borderRadius: "8px",
        width: "100%"
    },
    itemHeading: {

        fontSize: "14px",
        fontFamily: Bold
    },
    reallocatecard: {
        padding: "10px",
        display: "flex",
        justifyContent: "space-between",
        border: "1px solid #E4E8EE",
        marginBottom: "15px",
        borderRadius: theme.palette.borderRadius
    },
    title: {
        fontSize: "14px",
        fontFamily:"NunitoSans-Bold",
        color: theme.typography.color.primary
    },
    description: {
        fontSize: "12px",
        fontFamily: "NunitoSans-Regular",
        color: theme.typography.color.Tertiary,
        marginTop: "2px"
    },
    cmptitle: {
        fontSize: "16px",
        fontFamily:"NunitoSans-Bold",
        color: theme.typography.color.primary,
        marginBottom: "2px"
    },
    cmpdescription: {
        fontSize: "14px",
        fontFamily: "NunitoSans-Regular",
        color: theme.typography.color.Tertiary,
    },
    startbtn: {
        boxShadow: "none",
        // padding: "8px",
        textTransform: "capitalize",
        backgroundColor: theme.palette.primary.main,
        color: "#FFFFFF",
        fontSize: "13px",
        // fontFamily:"NunitoSans-Bold",
        borderRadius: theme.palette.borderRadius,
        whiteSpace: "nowrap",
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
            color: "#FFFFFF",
        }

    },
    closebtn: {
        boxShadow: "none",
        // padding: "8px",
        textTransform: "capitalize",
        border: "1px solid #E4E8EE",
        backgroundColor: "#FFFFFF",
        color: theme.typography.color.primary,
        borderRadius: theme.palette.borderRadius,
        fontSize: "13px",
        // fontFamily:"NunitoSans-Bold",

    },
    manualbtn: {
        boxShadow: "none",
        // padding: "8px",
        textTransform: "capitalize",
        border: `1px solid ${theme.palette.primary.main}`,
        backgroundColor: "#FFFFFF",
        color: theme.palette.primary.main,
        borderRadius: theme.palette.borderRadius,
        fontSize: "13px",
        // fontFamily:"NunitoSans-Bold",
        whiteSpace: "nowrap"
    },
    completeDetailsCard: {
        backgroundColor: "#FFFFFF",
        borderRadius: theme.palette.borderRadius,
        padding: "15px"
    },
    projectedCard: {
        backgroundColor: "#F5F7FA",
        padding: "10px",
        borderRadius: theme.palette.borderRadius,
        display: "flex",
        justifyContent: "space-between"
    },
    hourtext: {
        color: theme.typography.color.secondary,
        fontSize: "12px",
        fontFamily:"NunitoSans-Bold",
    },
    datetext: {
        color: theme.typography.color.secondary,
        fontSize: "12px",
        fontFamily: "NunitoSans-Regular",
    },
    actualhourtext: {
        color: theme.palette.warning.main,
        fontSize: "12px",
        fontFamily:"NunitoSans-Bold",

    },
    otherhourtext:{
        color: theme.palette.warning.main,
        fontSize: "12px",
        fontFamily:"NunitoSans-Bold",
        padding: "7px",
        backgroundColor: "#FFE7E7",
        borderRadius: theme.palette.borderRadius
    },
    mainsection: {
        backgroundColor: "#FFFFFF",
        boxShadow: "0px 3px 30px #4250682E",
        borderRadius: theme.palette.borderRadius,
        padding: "15px"
    },
    otherHoursCard:{
        display: "flex",
        justifyContent: "end",
    },
    sandClockCard: {
        display: "flex",
        justifyContent: "space-between",
        padding: "12px 8px 8px 8px",
        backgroundColor: "#FFF6F6",
        borderRadius: theme.palette.borderRadius
    },
    sandClockCardother: {
        display: "flex",
        justifyContent: "space-between",
        padding: "20px 8px 8px 8px",
        backgroundColor: "#FFF6F6",
        borderRadius: theme.palette.borderRadius
    },
    updatehourCard: {
        borderRadius: theme.palette.borderRadius,
        border: "1px solid #E4E8EE",
        padding: "15px"
    },
    previewCard: {
        padding: '15px'
    },
    utilityCard: {
        display: "flex",
        justifyContent: "space-between"
    },
    headerTitle: {
        fontSize: "10px",
        fontFamily:"NunitoSans-Bold",
        color: theme.typography.color.Tertiary,
        textTransform: "uppercase"
    },
    videobox: {
        width: "180px"
    },
    starCard: {
        padding: "8px",
        borderRadius: theme.palette.borderRadius,
        backgroundColor: "#FFF9EB",
        width: "200px"
    },
    completeInspection: {
        backgroundColor: "#F6F6F6"
    },
    homebtn: {
        boxShadow: "none",
        // padding: "12px",
        textTransform: "capitalize",
        border: `1px solid ${theme.palette.primary.main}`,
        backgroundColor: "#FFFFFF",
        color: theme.palette.primary.main,
        borderRadius: theme.palette.borderRadius,
        fontSize: "13px",
        // fontFamily:"NunitoSans-Bold",
        whiteSpace: "nowrap"
    },
    submitbtn: {
        boxShadow: "none",
        // padding: "8px",
        textTransform: "capitalize",
        backgroundColor: theme.palette.primary.main,
        color: "#FFFFFF",
        fontSize: "13px",
        // fontFamily:"NunitoSans-Bold",
        borderRadius: theme.palette.borderRadius,
        whiteSpace: "nowrap",
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
            color: "#FFFFFF",
        }

    },
    utilityTitle: {
        fontSize: "12px",
        fontFamily: Bold,
        color: theme.typography.color.Tertiary,
        textTransform: "uppercase"
    },
    // itemList:{
    //     height:"300px",
    //     overflow:"scroll"
    // }
    propertyImage: {
        height: 75,
        width: 75,
        marginTop: 4,
        borderRadius: 4,
        position: "relative"
    },
    content: {
        width: "100%",
        marginLeft: "12px",

    },
    status_success: {
        fontSize: 10,
        padding: 4,
        borderRadius: 4,
        fontFamily: `${Bold}`,
    },
    status_pending: {
        fontSize: 8,
        color: theme.palette.warning.main,
        backgroundColor: theme.palette.warning.light,
        padding: 4,
        borderRadius: 4,
        fontFamily: `${Bold}`,
    },
    propertyId: {
        fontSize: 14,
        fontFamily: `${Bold}`,
        color: theme.typography.color.primary
    },
    propertyName: {
        fontSize: 12,
        fontFamily: `${SemiBold}`,
        color: theme.typography.color.primary,
        textAlign: "left"

    },
    unitName: {
        fontSize: 10,
        fontFamily: `${SemiBold}`,
        color: theme.typography.color.primary,
        marginTop: "4px"
    },
    dateAndType: {
        fontSize: 12,
        color: theme.typography.color.Tertiary,
        paddingLeft: 8
    },
    dot: {
        height: "6px",
        width: "6px",
        borderRadius: "50%",
        backgroundColor: "#CED3DD",
        margin: "6px",
    },

    unit_category: {
        position: "absolute",
        backgroundColor: "#071741",
        color: "white",
        fontFamily: SemiBold,
        fontSize: "10px",
        bottom: "0px",
        padding: "4px",
        width: "100%",
        textAlign: "center"
    },
    completeTitle: {
        fontFamily:"NunitoSans-Bold",
        fontSize: "16px",
        color: theme.typography.color.primary
    },
    instext: {
        fontSize: "14px",
        color: "#78B1FE",
        fontFamily: "NunitoSans-SemiBold",
    },
    arrowIcon: {
        color: "#78B1FE",
    },
    insBox:{
        backgroundColor:"#F1F7FF",
        borderRadius:theme.palette.borderRadius,
        padding:"10px"
    },
    rewardGIF:{
        width:"150px",
        height:"150px"
    },
    seeMoreLessTextStyle: {
        fontSize: "12px",
        fontFamily: "NunitoSans-SemiBold",
        color: theme.palette.primary.main,
        textDecoration: "none",
        whiteSpace:"nowrap"
      },
      pdfViewer:{
        height:"600px",
        overflow:"scroll"
      }
}));