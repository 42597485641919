import makeStyles from "@mui/styles/makeStyles";
import { Bold, Regular, SemiBold } from "../../utils";

export const useStyles = makeStyles((theme) => ({

    productId: {
        fontFamily: Bold,
        fontSize: "16px"
    },
    divider: {
        margin: "8px 0px",
        borderColor: "#E4E8EE"
    },
    heading: {
        fontSize: "14px",
        fontFamily: SemiBold,

    },
    value: {
        fontSize: "12px",
        fontFamily: Regular,
        color: theme.typography.color.secondary,
        marginTop: "8px"
    },
    value1: {
        fontSize: "12px",
        fontFamily: SemiBold,
        color: "white",
        marginTop: "8px",
        display: "inline",
        borderRadius: "4px",
        padding: "2px 8px"
    }
}))