import { makeStyles } from "@mui/styles";
import { Bold, SemiBold } from "../../utils";

export const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        borderRadius: 12,
        boxShadow: "0px 3px 30px #5C86CB2E"
    },
    count: {
        fontSize: 26,
        fontFamily: `${Bold}`,
        marginBottom: "12px"
    },
    status: {
        fontSize: 12,
        fontFamily: `${SemiBold}`
    }
}))