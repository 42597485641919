import { Box } from "@mui/material";
import React from "react";
import { FormGenerator } from "../../../components";
import { config } from "../../../config";
import { AlertContext } from "../../../contexts";
import { NetworkCall } from '../../../networkcall';
import { AlertProps, NetWorkCallMethods } from "../../../utils";
import { addFormState } from "../../../utils/inspections";
export const AddForm = ({ inspection_item, agreement_inspection_id, reload = () => false, onClose = () => false, inventory_id = "" }) => {
    const [data, setData] = React.useState({ ...addFormState });
    const alert = React.useContext(AlertContext);
    const updateState = (key, value) => {
        let error = data.error;
        error[key] = "";
        setData({ ...data, [key]: value, error });
    };
    //on submit
    const onSubmit = () => {
        const payload = {
            agreement_inspection_id: agreement_inspection_id,
            inspection_item: inspection_item,
            serial_number: data?.product_id,
            name: data?.title,
            inventory_id: inventory_id
        }
        NetworkCall(
            `${config.api_url}/move-in-out/add_items`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((res) => {
            reload()
            onClose()
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.success,
                msg: "Activity Successfully Updated",
            });


        }).catch((error) => {
            console.log(error)
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: "Some thing went wrong",
            });
        });


    }
    const form = [
        {
            size: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12
            },
            isActive: true,
            component: "text",
            label: "Title",
            placeholder: "Enter Title",
            value: data?.title,
            onChange: (value) => updateState("title", value.target.value),
            error: data?.error?.title,
            isRequired: true,
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12
            },
            isActive: true,
            component: "text",
            label: "Product ID",
            placeholder: "Enter Product ID",
            value: data?.product_id,
            onChange: (value) => updateState("product_id", value.target.value),
            error: data?.error?.product_id,
            isRequired: true,
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12
            },
            isActive: true,
            component: "button",
            label: "Add Item",
            onClick: () => onSubmit(),
            disabled: (data?.title?.length === 0 && data?.title?.length === 0)
        },
    ]
    return (
        <Box>
            <Box >
                <FormGenerator components={form} />
                <Box height="12px" />
            </Box>
        </Box>
    )
}