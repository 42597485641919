import makeStyles from "@mui/styles/makeStyles";
import { Bold, ExtraBold } from "../../utils";

export const profileImageStyles = makeStyles((theme) => ({
  imageSet: {
    padding: "30px 15px 15px 15px",
  },

  avatarImg1: {
    height: "128px",
    width: "128px",
    border: "2px solid" +theme.palette?.primary?.main,
  },

  avatarImg2: {
    height: "100%",
    width: "100%",
    border: "2px solid" + theme.palette.border.tertiary,
  },

  profilePicture: {
    height: "128px",
    width: "128px",
  },

  editButton: {
    padding: "4px 7px 4px 7px",
    marginTop: "-30px",
    marginLeft: "45px",
    minWidth: "35px",
    height: "24px",
    backgroundColor: theme.palette?.primary?.main,
    border: "2px solid" + theme.palette.border.secondary1,
    borderRadius: "8px",
    "&:hover": {
      cursor: "pointer !important",
    },
  },

  editText: {
    fontSize: "12px",
    color: theme.typography.color.tertiary1,
  },

  box1: {
    padding: "4px 8px 4px 8px",
    border: "1px solid #27AFCF",
    borderRadius: "8px",
    marginRight: "8px",
  },

  personRole: {
    display: "flex",
    marginTop: "12px"
  },

  box2: {
    padding: "4px 8px 4px 8px",
    border: "1px solid #6A68CC",
    borderRadius: "8px",
  },

  imageText: {
    alignItems: "center",
  },

  name: {
    fontSize: "24px",
    fontFamily: `${ExtraBold}`,
    color: theme.typography.color.primary,
  },

  role: {
    fontSize: "10px",
    fontFamily: `${Bold}`,
    color: theme.typography.color.main1,
  },

  type: {
    fontSize: "10px",
    fontFamily: `${Bold}`,
    color: "#27AFCF",
  },

  background: {
    backgroundColor: "#F6F6F6",
  },

  top: {
    padding: "16px",
  },

  cardBox1: {
    backgroundColor: theme.palette.background.tertiary1,
    borderRadius: "8px",
    padding: "12px",
    minHeight: "100px"
  },

  contactNum: {
    "& p": {
      fontSize: "14px",
      color: theme.typography.color.secondary,
      fontWeight: 600,
    },
  },

  contactMail: {
    "& p": {
      fontSize: "14px",
      color: theme.typography.color.secondary,
      fontWeight: 600,
      wordBreak: "break-all",
    },
  },

  bottom: {
    padding: "0px 16px 16px 16px ",
  },

  bigCardBox: {
    backgroundColor: theme.palette.background.tertiary1,
    borderRadius: "8px",
    padding: "12px",
  },

  mapIcon: {
    marginBottom: "5px",
  },

  address: {
    "& p": {
      fontSize: "14px",
      color: theme.typography.color.secondary,
      fontWeight: 600,
    },
  },

  address1: {
    paddingBottom: "12.5px",

  },

  address2: {
    paddingTop: "12.5px",
  },

  lastBottom: {
    padding: "0px 16px 16px 16px ",
    cursor: "pointer"
  },

  logOut: {
    backgroundColor: theme.palette.background.tertiary1,
    borderRadius: "8px",
    padding: "12px",
  },

  logOutSection: {
    display: "flex",
  },

  logOutSvg: {
    display: "flex",
    flex: 1,
    alignItems: "center",
  },

  logOutIcon: {
    marginRight: "12px",
  },

  logOutText: {
    fontSize: "14px",
    color: theme.typography.color.primary,
    fontWeight: 600,
  },

  screen: {

    border: "1px solid #F5F7FA",
    background: theme.palette.background.tertiary1,
  },

  titleText: {
    "& .makeStyles-root-48": {
      backgroundColor: "#5078E1 !important",
    },
  },
}));
