import { Avatar, Box, Grid, Typography } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { calendar_days } from "../../../utils";
import { useStyles } from "./style";
import { Tag } from "./tag";

export const MontlyCalendar = ({ state = [], events = [], onSingleDate = () => false }) => {

    const classes = useStyles()
    const [prefillCount, setPrefillCount] = useState(0)


    const calculatePrefillCount = () => {
        const firstDay = state[0];
        const formattedFirstDay = moment(new Date(firstDay)).format("ddd")
        const item = calendar_days.filter(day => day.label === formattedFirstDay)[0]
        setPrefillCount(item.order - 1);
    }

    useEffect(() => {
        calculatePrefillCount()
        // eslint-disable-next-line
    }, [state])

    return (

        <Grid container className={classes.calDates}>
            {
                calendar_days.map(day => {
                    return (
                        <Grid item xs={1.7} className={classes.caldays}>
                            <span className={moment(new Date()).format("ddd") === day.label ? classes.highlight_day : ''} > {day.label} </span>
                        </Grid>
                    )
                })
            }
            {
                [...new Array(prefillCount).fill(null), ...state]?.map(e => {
                    return (
                        <Grid item xs={1.7} className={classes.dateRoot}>
                            {e &&
                                <Box>
                                    <Box display="flex" justifyContent="center">
                                        {moment(e).format("DD MMM YY") === moment(new Date()).format("DD MMM YY") ?
                                            <Avatar className={classes.toDay}>
                                                {moment(e).format("DD")}
                                            </Avatar>
                                            :
                                            <Typography className={classes.day}>
                                                {moment(e).format("DD")}
                                            </Typography>

                                        }
                                    </Box>

                                    {
                                        events?.map((val) => {
                                            return (
                                                <Box marginTop="4px">

                                                    {moment(e).format("DD MMM YYYY") === moment(val?.execution).format("DD MMM YYYY") ?
                                                        <Box onClick={() => onSingleDate(val?.execution)}>
                                                            <Tag status={val?.status} property={val?.property_name} />
                                                        </Box>
                                                        :
                                                        ""
                                                    }

                                                </Box>
                                            )
                                        })
                                    }

                                </Box>
                            }
                        </Grid>
                    )
                })
            }

        </Grid>

    )
}