import { Box, Grid, Typography } from "@mui/material";
import { useStyles } from "./styles";
export const SelectedCard = ({ value, onChange = () => false, options = [], label = "", isError = false, errorMessage = "", disable = false }) => {
    const classes = useStyles();

    const onChanges = (val) => {
        if (disable) {

        } else {
            onChange(val)
        }
    }
    return (
        <Grid container spacing={1} >
            <Grid item xs={12}>
                <Typography className={classes.label}>{label}</Typography>
            </Grid>
            {
                options?.map((item) => {
                    return (
                        <Grid item xs={4} marginTop="-10px">
                            <Box
                                onClick={() => onChanges(item.value)}
                                sx={{ border: value === item.value ? "1px solid #B20606" : "0px" }}
                                className={classes.card}

                            >
                                <center>
                                    {value === item.value ? item?.selected_icon : item?.icon}

                                    <Typography
                                        className={classes.cardValue}
                                        sx={{
                                            color: value === item.value ? "#B20606" : "#98A0AC",
                                        }}>
                                        {item?.label}
                                    </Typography>
                                </center>
                            </Box>
                        </Grid>
                    )
                })
            }

            {isError &&
                <Grid item xs={12}>
                    <Typography variant={"caption"} color={"error"}>
                        {errorMessage}
                    </Typography>
                </Grid>
            }

        </Grid>

    )
}