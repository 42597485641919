import { Avatar, Box, Divider, Stack, Typography } from "@mui/material";
import { Calander, InspectionProperty } from "../../assets";
import { returnColors } from "../../utils/inspections";
import { useStyles } from "./styles";

export const TrackCard = ({ value = {} }) => {
    const classes = useStyles();
    const status = returnColors(value?.status)
    return (
        <Box display="flex" className={classes.root} >
            <Box>
                <Avatar
                    sx={{ backgroundColor: status?.bg }} className={classes.avatar}>
                    {status?.image}
                </Avatar>
            </Box>
            <Box flexGrow={1} marginLeft="12px">
                <Stack direction="row" justifyContent="space-between" marginBottom="4px">
                    <Typography className={classes.title}>{value?.title}</Typography>
                    <Typography className={classes.status}
                        sx={{ backgroundColor: status?.bg, color: status?.color }}
                    >{value?.status}</Typography>
                </Stack>
                <Typography className={classes.name}>{value?.name}</Typography>
                <Stack marginTop="8px" direction={"row"} divider={<Divider orientation="vertical" flexItem />} spacing={1}>

                    <Stack direction="row" spacing={1} alignItems="center">
                        <Calander />
                        <Typography className={classes.sub}>{value?.subTitle1}</Typography>
                    </Stack>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <InspectionProperty />
                        <Typography className={classes.sub}>{value?.subTitle2}</Typography>
                    </Stack>
                </Stack>
            </Box>
        </Box>

    )
}