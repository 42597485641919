import makeStyles from "@mui/styles/makeStyles";
import { SemiBold } from "../../utils";
export const useStyles = makeStyles((theme) => ({
    dialog: {
        width: "100%",
        maxWidth: "auto",
        padding: "0 !important",
        "& .MuiPaper-root": {
            width: "100% !important",
            borderRadius: "4px",

        },
        "& .MuiDialogContent-root": {
            padding: "0px !important",
            position: 'relative'
        },
        '& .MuiDialog-container': {
            padding: (props) => props?.padding ?? '',
        }
    },
    header: {
        border: "1px solid #E4E8EE",
        fontSize: "16px",
        fontFamily: SemiBold,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "8px"
    },
    drawer: {
        "& .MuiDrawer-paper": {
            borderTopLeftRadius: "16px",
            borderTopRightRadius: "16px",
        },
        "& .MuiContainer-root": {
            padding: "0px",
        },
    },
    component: {
        overflow: "auto"
    }
}));