import * as React from "react"

export const Profile = (props) => (
    props?.select ?
        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
            <g data-name="Group 7068">
                <g data-name="Group 18">
                    <path data-name="Rectangle 31" fill="none" d="M0 0h24v24H0z" />
                    <path
                        data-name="icons8-user (2)"
                        d="M12 2a5 5 0 1 0 5 5 5.011 5.011 0 0 0-5-5ZM5.989 14A2 2 0 0 0 4 15.989v.761a4.66 4.66 0 0 0 2.653 4.031A11.254 11.254 0 0 0 12 22a11.254 11.254 0 0 0 5.347-1.219 4.833 4.833 0 0 0 2.534-3.281H20v-1.511A2 2 0 0 0 18.011 14Z"
                        fill="#B20606"
                        stroke="#fff"
                        strokeWidth={0.2}
                    />
                </g>
            </g>
        </svg>
        :
        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
            <g data-name="Group 7068">
                <g data-name="Group 18">
                    <path data-name="Rectangle 31" fill="none" d="M0 0h24v24H0z" />
                    <path
                        data-name="icons8-user (2)"
                        d="M12 2a5 5 0 1 0 5 5 5.011 5.011 0 0 0-5-5Zm0 1.5A3.5 3.5 0 1 1 8.5 7 3.489 3.489 0 0 1 12 3.5ZM5.989 14A2 2 0 0 0 4 15.989v.761a4.66 4.66 0 0 0 2.653 4.031A11.254 11.254 0 0 0 12 22a11.254 11.254 0 0 0 5.347-1.219 4.833 4.833 0 0 0 2.534-3.281H20v-1.511A2 2 0 0 0 18.011 14Zm0 1.5h12.022a.479.479 0 0 1 .49.489v.761a3.047 3.047 0 0 1-1.878 2.719A9.967 9.967 0 0 1 12 20.5a9.967 9.967 0 0 1-4.622-1.031A3.047 3.047 0 0 1 5.5 16.75v-.761a.478.478 0 0 1 .489-.489Z"
                        fill="#98a0ac"
                        stroke="#fff"
                        strokeWidth={0.2}
                    />
                </g>
            </g>
        </svg>
)
