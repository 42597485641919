import { Avatar, Box, Divider, Stack, Typography } from "@mui/material";
import moment from "moment";
import React from "react";
import { Calander, InspectionProperty } from "../../assets";
import { RemoveZUTCDateTime } from "../../utils";
import { useStyles } from "./style";

export const InspectionCard = ({ value = {}, onClick = () => false, preview = false }) => {
  const classes = useStyles();
  const getColor = (status) => {
    switch (status) {
      case "Assigned": {
        return {
          bgColor: "#FFE6C9",
          color: "#E29336"
        }
      }
      case "Completed": {
        return {
          bgColor: "#5AC782",
          color: "white"

        }
      }
      case "Yet To Verify": {
        return {
          bgColor: "#E4EDFF",
          color: "#5078E1"
        }
      }
      case "Rejected": {
        return {
          bgColor: "#e57373",
          color: "#fff"

        }
      }
      default: return {
        bgColor: "#FFE6C9",
        color: "#E29336"
      }
    }
  }
  return (
    <Box className={classes.root} onClick={() => onClick(value)}>
      <Stack p={1} className={classes.container} direction={"row"} alignItems="center">
        <Avatar className={classes.propertyImage}>
          <img
            src={value?.logo}
            alt=""
            className={classes.image}
          />
          {
            (value?.is_parking_component || value?.is_parking_unit) &&
            <Typography noWrap className={classes.unit_category}>Parking</Typography>
          }
        </Avatar>

        <Box className={classes.content}>

          <Stack direction={"row"} justifyContent={"space-between"}>
            <Typography className={classes.propertyId} noWrap>{value?.reference_id}</Typography>
            {
              value?.status ?
                <Typography
                  sx={{
                    backgroundColor: getColor(value?.status)?.bgColor,
                    color: getColor(value?.status)?.color ?? "auto",
                  }}
                  className={
                    classes.status_success
                  }>
                  {value?.status === "Assigned" ? (value?.current_state?.length > 0 && value?.current_state !== "pending" ? value?.current_state : "Assigned") : value?.status}
                </Typography> : "  "
            }
          </Stack>
          <Stack >
            <Typography className={classes.propertyName}>
              {value?.property_name}
            </Typography>
            <Typography sx={{ marginTop: "2px" }} className={classes.unitName}>
              {value?.unit_name}
            </Typography>
          </Stack>

          {/* <Typography className={classes.unitName}>
            {value?.unit_no}
          </Typography> */}

          <Stack
            direction={"row"}
            spacing={2}
            marginTop="6px"
            divider={<Divider orientation="vertical" flexItem />}
          >
            <Typography sx={{ display: "flex", alignItems: "center" }}>
              <Calander />
              <span className={classes.dateAndType}> {preview ? moment(value?.execution).format("DD-MM-YYYY") : moment(RemoveZUTCDateTime(value?.execution)).format("DD MMM YY hh:mm A")} </span>
            </Typography>
            <Typography sx={{ display: "flex", alignItems: "center" }}>
              <InspectionProperty />
              <span className={classes.dateAndType}> {value?.unit_type ?? value?.type} </span>
            </Typography>
          </Stack>

        </Box>
      </Stack>

    </Box>
  );
};
