import { Container } from "@mui/material";
import React from "react";
import ParkingDetails from "./parkingDetails";
class ParkingDetailsParent extends React.Component {
  render() {
    return (
      <Container maxWidth="sm" sx={{ padding: "0px" }}>
        <ParkingDetails />
      </Container>
    )
  }
}
export default ParkingDetailsParent;
