export const initialState = {
    userProfileID: "",
    contactID: "",
    firstName: "",
    profileImageID: "",
    profileImage: "",
    userType: "Tenant",
    clientRoleNo: "",
    primaryContact: "",
    alternativeMobile: "",
    primaryEmailID: "",
    alternativeEmailID: "",
    address: "",
    addressLineOne: "",
    addressLineTwo: "",
    addressLineThree: "",
    district: "",
    city: "",
    state: "",
    zipCode: "",
    country: "",
    latitude: "",
    longitude: "",
    error: {
        primaryContact: "",
        alternativeMobileNo: "",
    },

}

export const returnEditPayload = (data) => {
    const result = {
        userProfileID: data?.id,
        contactID: data?.contact_id,
        firstName: `${data?.first_name ?? ""} ${data?.last_name ?? ""}`,
        profileImage: data?.image_url?.length > 0 ? {
            src: data?.image_url
        } : "",
        clientRoleNo: "",
        primaryContact: {
            mobile: data?.mobile_no,
            mobile_code: data?.mobile_no_country_code
        },
        alternativeMobile: data?.alternative_mobile_no ? {
            mobile: data?.alternative_mobile_no,
            mobile_code: data?.alternative_mobile_no_country_code
        } : "",
        primaryEmailID: data?.email_id,
        alternativeEmailID: data?.alternative_email_id,
        address: (data?.contact?.street_1 !== null &&
            data?.contact?.street_1 !== ""
            ? data?.contact?.street_1
            : "") +
            (data?.contact?.street_2 !== null &&
                data?.contact?.street_2 !== ""
                ? ", " + data?.contact?.street_2
                : "") +
            (data?.contact?.street_3 !== null &&
                data?.contact?.street_3 !== ""
                ? ", " + data?.contact?.street_3
                : "") +
            (data?.contact?.district !== null &&
                data?.contact?.district !== ""
                ? ", " + data?.contact?.district
                : "") +
            (data?.contact?.city !== null &&
                data?.contact?.city !== ""
                ? ", " + data?.contact?.city
                : "") +
            (data?.contact?.state !== null &&
                data?.contact?.state !== ""
                ? ", " + data?.contact?.state
                : "") +
            (data?.contact?.country !== null &&
                data?.contact?.country !== ""
                ? ", " + data?.contact?.country
                : "") +
            (data?.contact?.zipcode !== null &&
                data?.contact?.zipcode !== ""
                ? ", " + data?.contact?.zipcode
                : ""),
        addressLineOne: data?.contact?.street_1 ?? "",
        addressLineTwo: data?.contact?.street_2 ?? "",
        addressLineThree: data?.contact?.street_3 ?? "",
        district: data?.contact?.district ?? "",
        city: data?.contact?.city ?? "",
        state: data?.contact?.state ?? "",
        zipCode: data?.contact?.zipcode ?? "",
        country: data?.contact?.country ?? "",
        latitude: data?.contact?.latitude ?? "",
        longitude: data?.contact?.longitude ?? "",
        error: {
            primaryContact: "",
            alternativeMobile: "",
        },
    }
    return result
}

export const initialAddressState = {
    userProfileID: "",
    contactID: "",
    firstName: "",
    lastName: "",
    profileImageID: "",
    profileImage: "",
    userType: "Tenant",
    clientRoleNo: "",
    primaryMobileNo: "",
    alternativeMobileNo: "",
    primaryEmailID: "",
    alternativeEmailID: "",
    address: "",
    addressLineOne: "",
    addressLineTwo: "",
    addressLineThree: "",
    district: { label: "", value: "" },
    city: "",
    state: "",
    zipCode: "",
    country: "",
    latitude: "",
    longitude: "",
    error: {

    }
}

export const returnAddressEditPayload = (data) => {
    const result = {
        userProfileID: data?.id,
        contactID: data?.contact_id,
        addressLineOne: data?.contact?.street_1 ?? "",
        addressLineTwo: data?.contact?.street_2 ?? "",
        addressLineThree: data?.contact?.street_3 ?? "",
        latitude: data?.contact?.latitude ?? "",
        longitude: data?.contact?.longitude ?? "",
        zipCode: data?.contact?.zipcode ?? "",
        country: data?.contact?.country ?? "",
        state: data?.contact?.state ?? "",
        city: data?.contact?.city ?? "",

    }
    return result
}