import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { withNamespaces } from "react-i18next";
import { CartItemCard } from "../../../components";
import { useStyles } from "./styles";
const Step2 = ({
    list = [],
    changeInspactionItems = () => false,
    addInspectionItem = () => false,
    changeStep = () => false,
    onDeleteAll = () => false,
    onPrevious = () => false
}) => {
    const classes = useStyles()
    const handleStep = () =>{
        let result = list?.filter(i => i?.isActive).map((val) => {
            return {
                id: val?.id,
                inventory_id: val?.inventory_id,
                item:val?.item
            }
        })
        changeStep(result)
    }
    return (
        <Box >
            <Stack justifyContent="space-between" direction="row" p={1} alignItems="center">
                <Typography className={classes.itemHead}>GENERAL ITEMS</Typography>
                <Typography onClick={onDeleteAll} className={classes.delete}>Delete All</Typography>
            </Stack>
            <Box className={classes.itemRoot}>
                {
                    list?.filter((val) => val?.isActive).map((val) => {
                        return (
                            <CartItemCard
                                list={val}
                                increaseQty={() => addInspectionItem(val?.id, true)}
                                decreaseQty={() => addInspectionItem(val?.id, false)}
                                removeitem={() => changeInspactionItems(val?.id, false)}
                            />
                        )
                    })
                }
            </Box>
            <Grid className={classes.btnRoot} container spacing={2}>
                <Grid item xs={6}>
                    <Button onClick={onPrevious} className={classes.btnOutlined} fullWidth variant="outlined">Previous</Button>
                </Grid>
                <Grid item xs={6}>
                    <Button onClick={handleStep} className={classes.btn} fullWidth variant="contained">Add</Button>
                </Grid>
            </Grid>
        </Box>
    )
}
export default withNamespaces("inspections")(Step2);