import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';
import React from 'react';
import CameraIcon from '../../assets/cameraIcon';
import { config } from '../../config';
import { AlertContext } from '../../contexts';
import { NetworkCall } from '../../networkcall';
import { AlertProps, NetWorkCallMethods } from '../../utils';


const ImageWrapper = styled('div')({
    width: 141,
    height: 141,
    borderRadius: 71,
    backgroundColor: "#F2F4F7",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative"
});

const CustomInput = styled('input')({
    position: 'absolute',
    opacity: 0,
    inset: 0,
    zIndex: 1,
    cursor: 'pointer'
})

const CustomAvatar = styled(Avatar)({
    width: 'inherit',
    height: 'inherit',
    borderRadius: 'inherit',
})

const UploadButton = styled(IconButton)(({ theme }) => ({
    borderColor: "#E4E8EE",
    position: "absolute",
    bottom: "0px",
    right: "10px",
    marginTop: theme.spacing(2),
    color: "#071741",
    background: "#FFFFFF",
    fontSize: 12,
    fontFamily: "NunitoSans-SemiBold",
    cursor: "pointer",
    '&:hover': {
        background: "#FFFFFF"
    },
    borderRadius: theme.palette.borderRadius,
}))

export const LogoUpload = (props) => {

    const {
        accept = "image/*",
        fileUploadUrl = true,
        handleChange = null,
        data = {},
        placeholderLogo = "",
        isReadonly = false,
        isError = false,
        errorMessage = "",
    } = props;

    const alert = React.useContext(AlertContext);

    const [loading, setLoading] = React.useState(false);

    const handleUpload = async (data) => {
        //only allow if file selected
        if (data?.length) {
            if (fileUploadUrl) {
                executeImageData(data)
            }
            else {
                handleChange && handleChange(data)
            }

        }
    }

    const executeImageData = async (data) => {

        setLoading(true);

        const formData = new FormData();
        formData.append("files", data?.[0]);
        formData.append("tenantId", `${config.tenantId}`);

        await NetworkCall(
            `${config.api_url}/file-upload`,
            NetWorkCallMethods.post,
            formData,
            null,
            true,
            false
        )
            .then((response) => {
                handleChange && handleChange({ src: response.data.fileUrls[0].url });
                setLoading(false);
            })
            .catch((err) => {
                handleChange(null);
                setLoading(false);
                alert.setSnack({
                    open: true,
                    horizontal: AlertProps.horizontal.center,
                    vertical: AlertProps.vertical.top,
                    msg: `Unable to upload`,
                    severity: AlertProps.severity.error,
                });
            });
    }


    return (
        <div>
            <Stack
                alignItems={"center"}

            >

                {
                    loading ? (
                        <Skeleton width={141} height={141} variant={"circular"} />
                    ) : (
                        <ImageWrapper>
                            {
                                data?.src ? (
                                    <CustomAvatar src={data?.src} />
                                ) : (
                                    <>
                                        {placeholderLogo ? placeholderLogo :
                                            <PersonOutlineIcon sx={{ fontSize: "100px", color: "#E4E8EE" }} />

                                        }
                                    </>
                                )
                            }
                            <UploadButton
                                variant={"outlined"}
                                disabled={loading}
                            >
                                <CameraIcon />
                                <CustomInput
                                    type="file"
                                    onChange={(e) => handleUpload(e.target.files)}
                                    accept={accept}
                                />
                            </UploadButton>
                        </ImageWrapper>
                    )
                }

                {
                    !isReadonly &&
                    <>
                        {isError && (
                            <Typography variant={"caption"} color={"error"}>
                                {errorMessage}
                            </Typography>
                        )}
                    </>
                }
            </Stack>
        </div>
    )
}