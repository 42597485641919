import { Box, Button, Grid } from "@mui/material";
import React, { useState } from "react";
import { withNamespaces } from "react-i18next";
import { useLocation } from "react-router-dom";
import { DialogDrawer, TextBox } from "../../../components";
import { useStyles } from "../styles";
import ReAllocateCard from "./pause&reallocate";


const Description = ({ t, setStep = () => false, updateState = () => false, data = {}, disable = false }) => {
    const classes = useStyles();
    const { state } = useLocation();
    const [showReallocateDialog, setShowReallocateDialog] = useState(false)
    const [location, setLocation] = React.useState({
        lat: "",
        lng: ""
    })

    //initial load
    React.useEffect(() => {
        getCurrentLocation()

        // eslint-disable-next-line
    }, [])
    const getCurrentLocation = () => {
        let location = null;
        if (window.navigator && window.navigator.geolocation) {
            location = window.navigator.geolocation
        }
        if (location) {
            location.getCurrentPosition(function (position) {
                setLocation({ lat: position.coords.latitude, lng: position.coords.longitude })
            })
        }
    }

    //on click card 
    const onClick = (val) => {
        setStep(6, null)
    }


    return (
        <Box>
            <Box>
                <Box className={classes.root}>

                    <Box className={classes.list}>
                        <TextBox
                            isReadonly={disable}
                            multiline
                            rows={5}
                            value={data?.description}
                            onChange={(val) => updateState("description", val.target.value)}
                            label={t("inspectorNotes")}
                            placeholder={`${t("enter")} ${t("notes")}`}
                        />

                    </Box>
                </Box>
                {disable ?
                    <Box className={classes.btnRoot}>
                        <Button
                            fullWidth
                            className={classes.btn}
                            variant="contained"
                            disabled={data?.description?.length>0?false:true}
                            onClick={onClick}
                            sx={{ backgroundColor: "#B20606" }}
                        >
                            {t("next")}
                        </Button>

                    </Box>
                    :
                    <Grid className={classes.btnRoot} container spacing={1}>
                        <Grid item xs={6}>
                            <Button onClick={() => setShowReallocateDialog(true)} fullWidth className={classes.closebtn}>{t("pause/reallocate")}</Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button onClick={onClick} fullWidth variant="contained" className={classes.startbtn}>{t("preview&signature")}</Button>
                        </Grid>
                    </Grid>
                }
            </Box>
            {/* {Pause/Reallocate} */}
            <DialogDrawer
                header="Pause/Reallocate"
                component={<ReAllocateCard location={location} setStep={setStep} state={state} setShowReallocateDialog={setShowReallocateDialog} />}
                open={showReallocateDialog}
                padding={0}
                onClose={() => setShowReallocateDialog(false)}
            />
        </Box>
    );
};

export default withNamespaces("inspections")(Description);