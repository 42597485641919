
import Close from "@mui/icons-material/Close";
import { Box, Dialog, DialogContent, DialogTitle, Drawer, Hidden, IconButton, Stack } from "@mui/material";
import * as React from "react";
import DeleteIcon from "../../assets/delete";
import { useStyles } from "./styles";
export const DialogDrawer = (props) => {
    const classes = useStyles();
    const requestType = ["move-in", "move-out", "service", "site_visit", "general", "unit_readiness", "unit_handover"]
    return (
        <div>
            <Hidden smDown>
                <Dialog
                    open={props?.open}
                    onClose={props?.onClose}
                    aria-labelledby="alert-dialog-title"
                    className={classes.dialog}
                    maxWidth={props?.width ?? "sm"}
                    fullWidth
                    aria-describedby="alert-dialog-description"
                >
                    {
                        !props?.isnotTitle &&
                        <DialogTitle className={classes.header}>
                            <span>{props?.header}</span>{" "}
                            {requestType.includes(props?.type) &&
                                <IconButton>
                                    <DeleteIcon />
                                </IconButton>
                            }
                            <IconButton onClick={props?.onClose}>
                                <Close />
                            </IconButton>

                        </DialogTitle>
                    }

                    <DialogContent style={{ padding: "0 !important" }}>
                        <Box height={props?.height ?? "auto"} overflow="auto" p={props?.padding ?? 2} className={classes.component}>
                            {props?.component}
                        </Box>
                    </DialogContent>
                </Dialog>
            </Hidden>
            <Hidden smUp>
                <Drawer
                    anchor="bottom"
                    className={classes.drawer}
                    open={props?.open}
                    onClose={props?.onClose}
                >
                    {
                        !props?.isnotTitle &&
                        <DialogTitle className={classes.header}>
                            <span>{props?.header}</span>{" "}
                            <Stack direction="row" spacing={0.5} alignItems="center">
                                {requestType.includes(props?.type) &&
                                    <IconButton onClick={props?.onDelete}>
                                        <DeleteIcon />
                                    </IconButton>
                                }
                                <IconButton onClick={props?.onClose}>
                                    <Close />
                                </IconButton>
                            </Stack>
                        </DialogTitle>
                    }

                    <Box height={props?.height ?? "auto"} overflow="auto" p={props?.padding ?? 2} className={classes.component}>
                        {props?.component}
                    </Box>
                </Drawer>
            </Hidden>
        </div>
    );
}
