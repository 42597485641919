import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { Pie, PieChart, ResponsiveContainer } from "recharts";

const useStyles = makeStyles((theme, props) => ({
  root: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    "& svg": {
      borderRadius: "50%",
      width: "100%",
      minWidth: "120px !important",
      height: "120px !important",
      position: "relative",
      [theme.breakpoints.up("sm")]: {
        width: "100% !important",
        margin: "0 auto",
      },
      "& .recharts-wrapper": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "120px !important",
      },
    },

  },
  total: {
    color: "#637381",
    fontSize: "15px",
    marginTop: "-6px"
  },
  des: {
    fontWeight: 600,
    color: "#252F3A",
    fontSize: "25px",
  },
  center: {
    textAlign: "center",
    position: "absolute",
    left: "0px",
    right: "0px",
    top: "36%",
    [theme.breakpoints.down("sm")]: {
      left: "0px",
      right: "0px",
      top: "36%",
    },
  },
  chart: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));
export const PieCharts = (props) => {
  const classes = useStyles(props);

  return (
    <div style={{ position: "relative" }} className={classes.root}>
      <ResponsiveContainer
        width={props?.width}
        height={200}
        style={{ margin: "0 auto" }}
      >
        <PieChart width={props?.width} height={178} className={classes.chart}>
          <Pie
            data={props?.data}
            cx="50%"
            cy="50%"
            outerRadius={100}
            innerRadius={75}
            dataKey="value"
            paddingAngle={4}           
            isAnimationActive={false}
          />
          {/* <Tooltip/> */}
        </PieChart>
      </ResponsiveContainer>
      <div className={classes.center}>
        <Typography className={classes.des}>
          {props?.total}
        </Typography>
        <Typography className={classes.total}>Total</Typography>
      </div>
    </div>
  );
};
