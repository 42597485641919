import { Canceled, Completed, YetToUpload } from "../assets"


export const trackerData = [
    {
        title: "PID74432",
        name: "Casagrand 3245",
        subTitle1: "12-02-2022",
        subTitle2: "Apartments",
        status: "Yet To Update"
    },
    {
        title: "PID74432",
        name: "Casagrand 3245",
        subTitle1: "12-02-2022",
        subTitle2: "Apartments",
        status: "Completed"
    },
    {
        title: "PID74432",
        name: "Casagrand 3245",
        subTitle1: "12-02-2022",
        subTitle2: "Apartments",
        status: "Failed"
    }
]

export const returnColors = (type) => {
    switch (type) {
        case "Yet To Update":
            let result = {
                image: <YetToUpload />,
                bg: "#FFE6C9",
                color: "#E29336"
            }
            return result

        case "Completed":
            let result1 = {
                image: <Completed />,
                bg: "#DFF0EA",
                color: "#35A27D"
            }
            return result1
        case "Failed":
            let result2 = {
                image: <Canceled />,
                bg: "#F2F4F7",
                color: "#4E5A6B"
            }
            return result2


        default:
            return result
    }
}

export const returnmoveInOutStepper = (t, type) => {
    let result = {
        1: {
            text: `${t("move_in")} ${t("details")}`,
            process: 50
        },
        2: {
            text: `${t("move_in")} ${t("notes")}`,
            process: 50
        }
    }
    let result2 = {
        1: {
            text: `${t("move_out")} ${t("details")}`,
            process: 50
        },
        2: {
            text: `${t("move_out")} ${t("notes")}`,
            process: 50
        }
    }

    return type === "Move In" ? result : result2
}

export const Months = [
    {
        label: 'January',
        value: 0
    },
    {
        label: 'February',
        value: 1
    },
    {
        label: 'March',
        value: 2
    },
    {
        label: 'April',
        value: 3
    },
    {
        label: 'May',
        value: 4
    },
    {
        label: 'June',
        value: 5
    },
    {
        label: 'July',
        value: 6
    },
    {
        label: 'August',
        value: 7
    },
    {
        label: 'September',
        value: 8
    },
    {
        label: 'October',
        value: 9
    },
    {
        label: 'November',
        value: 10
    },
    {
        label: 'December',
        value: 11
    },

]
export const calenderType = [
    {
        label: "Month",
        value: "Month"
    },
    {
        label: "Day",
        value: "Day"
    }
]


export const moveInItem = [
    [
        {
            title: "Light-01",
            product_id: "PD-ID-25847"
        },
        {
            title: "Light-02",
            product_id: "PD-ID-25847"
        },
        {
            title: "Light-03",
            product_id: "PD-ID-25847"
        }
    ]
]

export const initialState = {
    product_id: "",
    description: "",
    assets: [],
    activity_id: "",
    error: {
        product_id: "",
        description: "",
        images: "",
    }
}

export const itemList = [
    {
        title: "Light-01",
        product_id: "PD-ID-25847"
    },
    {
        title: "Light-02",
        product_id: "PD-ID-25847"
    },
    {
        title: "Light-03",
        product_id: "PD-ID-25847"
    }
]

export const addFormState = {
    title: "",
    product_id: "",
    error: {
        title: "",
        product_id: "",
    }
}

export const itemData = [
    {
        "id": "b63499c7-e52b-4b93-880a-d9bae4ca45c9",
        "Name": "wires 1",
        "image": "/images/Group 98362.svg",
        "count": 0,
        "is_active": false,
        "name": "wires 1"
    },
    {
        "id": "81a39ede-9efa-4d08-ab64-2729ab917dd1",
        "Name": "Speaker ",
        "image": "/images/Group 98362.svg",
        "count": 0,
        "is_active": false,
        "name": "Speaker "
    },
    {
        "id": "5816d8b4-6336-418c-966b-dcb944048b78",
        "Name": "Keyboard",
        "image": "/images/Group 98362.svg",
        "count": 0,
        "is_active": false,
        "name": "Keyboard"
    },
    {
        "id": "2e51813c-af4e-4ada-9f42-cea1f64d1fda",
        "Name": "PC",
        "image": "/images/Group 98362.svg",
        "count": 0,
        "is_active": false,
        "name": "PC"
    },
    {
        "id": "5fb80242-f6a9-4cf3-a307-b0f2c5b3c729",
        "Name": "Laptop",
        "image": "/images/Group 98362.svg",
        "count": 0,
        "is_active": false,
        "name": "Laptop"
    }
]

export const returnActivityEdit = (data, product_id, activity_id) => {
    let result = {
        product_id: product_id,
        description: data?.description ?? "",
        assets: data?.inspection_activity_assets?.map((val) => {
            return {
                url: val?.url,
                is_active: val?.is_active,
                id: val?.id ?? undefined,
                file_meta: val?.file_meta
            }
        }),
        activity_id: activity_id,
        error: {
            product_id: "",
            description: "",
            images: "",
            electricity: "",
        }
    }

    return result
}

export const validateArrayContaines = (array) => {
  
    let result = array.flatMap((val) => {
        return [
            val?.activity_feedback?.length>0,
        ]
    }).every((i) => i)
    return result
}
export const validateTwoArrayContaines = (array) => {
    let oneArray = array?.map((val) => {
        return [...val?.list]
    }).flat()
    return validateArrayContaines(oneArray.flat())
}

//generate Items

export const generateItems = (count, data) => {
    if (!parseInt(count)) return false;
    const fill = new Array(parseInt(count)).fill(data);
    return fill;
};


export const returnUniqueArray = (datas, key) => {
    const arrayUniqueByKey = [
        ...new Map(datas.map((item) => [item[key], item])).values(),
    ];
    return arrayUniqueByKey;
};