import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { Box } from "@mui/material";
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import styled from '@mui/material/styles/styled';
import React from 'react';
import { config } from '../../config';
import { AlertContext } from '../../contexts';
import { NetworkCall } from '../../networkcall';
import { AlertProps, NetWorkCallMethods } from '../../utils';

const CustomInput = styled('input')({
    position: 'absolute',
    opacity: 0,
    inset: 0,
    zIndex: 1,
    cursor: 'pointer'
})

const UploadButton = styled(Button)(({ theme }) => ({
    padding: "20px",
    color: "#404E61",
    background: "#fff",
    textTransform: "capitalize",
    fontSize: 12,
    fontFamily: "NunitoSans-SemiBold",
    cursor: "pointer",
    '&:hover': {
        background: "#E4EFFF"
    },
    borderRadius: theme.palette.borderRadius,
    border: "1px dashed #E4E8EE"

}))

export const UploadBtn = (props) => {

    const {
        accept = "image/*",
        buttonName = "Upload Image",
        fileUploadUrl = true,
        handleChange = null,
        isReadonly = false,
    } = props;


    const alert = React.useContext(AlertContext);

    const [loading, setLoading] = React.useState(false);

    const handleUpload = async (data) => {
        //only allow if file selected
        if (data?.length) {
            if (fileUploadUrl) {
                executeImageData(data)
            }
            else {
                handleChange && handleChange(data)
            }

        }
    }

    const executeImageData = async (data) => {
        setLoading(true);

        const formData = new FormData();
        formData.append("files", data?.[0]);
        formData.append("tenantId", `${config.tenantId}`);

        await NetworkCall(
            `${config.api_url}/file-upload`,
            NetWorkCallMethods.post,
            formData,
            null,
            true,
            false
        )
            .then((response) => {
                handleChange && handleChange({
                    url: response.data.fileUrls[0].url,
                    is_active: true,
                    file_meta: {
                        name: data?.[0]?.name,
                        size: data?.[0]?.size,
                        type: data[0]?.type
                    }


                });
                setLoading(false);
            })
            .catch((err) => {
                handleChange(null);
                setLoading(false);
                alert.setSnack({
                    open: true,
                    horizontal: AlertProps.horizontal.center,
                    vertical: AlertProps.vertical.top,
                    msg: `Unable to upload`,
                    severity: AlertProps.severity.error,
                });
            });
    }

    return (
        <div>
            <Stack
                alignItems={"center"}
            >
                {
                    !isReadonly &&
                    <>
                        {buttonName &&
                            <UploadButton
                                fullWidth
                                variant={"outlined"}
                                disabled={loading}
                                startIcon={<FileUploadOutlinedIcon />}
                            >
                                <Box>


                                    {buttonName}
                                </Box>
                                <CustomInput
                                    type="file"
                                    onChange={(e) => handleUpload(e.target.files)}
                                    accept={accept}
                                />
                            </UploadButton>
                        }
                    </>
                }

            </Stack>
        </div>
    )
}