import { Grid } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import { useTheme } from "@mui/styles";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import useWindowDimensions from "../../utils/useWindowDimensions";
const useStyles = makeStyles((theme) => ({
    second: {
        backgroundColor: "white",
        borderTopRightRadius: "15px",
        borderTopLeftRadius: "15px",
        padding: "12px",
    },
}));
export const DashboardSimmer = (props) => {
    const size = useWindowDimensions();
    const classes = useStyles();
    const theme = useTheme()

    return (
        <div style={{ height: size.height }}>

            <Grid item style={{ padding: "12px" }}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={4}>
                        <Skeleton
                            variant="rectangular"
                            height={50}
                            style={{ borderRadius: "12px" }}
                        />
                        <Skeleton
                            variant="rectangular"
                            height={50}
                            style={{ borderRadius: theme.palette.borderRadius, marginTop: "10px" }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Skeleton
                            variant="rectangular"
                            height={50}
                            style={{ borderRadius: "12px" }}
                        />
                        <Skeleton
                            variant="rectangular"
                            height={50}
                            style={{ borderRadius: theme.palette.borderRadius, marginTop: "10px" }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Skeleton
                            variant="rectangular"
                            height={50}
                            style={{ borderRadius: "12px" }}
                        />
                        <Skeleton
                            variant="rectangular"
                            height={50}
                            style={{ borderRadius: theme.palette.borderRadius, marginTop: "10px" }}
                        />
                    </Grid>
                </Grid>
                <br />
                <Skeleton
                    variant="rectangular"
                    height={100}
                    style={{ borderRadius: "12px" }}
                />
            </Grid>
            <div className={classes.second}>
                <br />
                <Skeleton
                    variant="rectangular"
                    height={100}
                    style={{ borderRadius: "12px" }}
                />
                <br />
                <Grid container spacing={1} alignItems="center">
                    <Grid item xs={6}>
                        <Skeleton
                            variant="rectangular"
                            height={100}
                            style={{ borderRadius: "12px" }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Skeleton
                            variant="rectangular"
                            height={100}
                            style={{ borderRadius: "12px" }}
                        />
                    </Grid>
                </Grid>
                <br />
                <Skeleton
                    variant="rectangular"
                    height={100}
                    style={{ borderRadius: "12px" }}
                />
                <br />
                <Skeleton
                    variant="rectangular"
                    height={100}
                    style={{ borderRadius: "12px" }}
                />
            </div>
        </div>
    );
};
