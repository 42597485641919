import * as React from "react"

export const Search = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} {...props}>
        <g data-name="Group 3813">
            <g data-name="Group 7106" transform="translate(-326 -103)">
                <rect
                    data-name="Rectangle 44485"
                    width={32}
                    height={32}
                    rx={4}
                    transform="translate(326 103)"
                    fill="#fff"
                />
                <path
                    d="M340.639 112a5.639 5.639 0 1 0 3.391 10.131l3.642 3.641a.778.778 0 1 0 1.1-1.1l-3.641-3.642a5.626 5.626 0 0 0-4.492-9.03Zm0 1.556a4.084 4.084 0 0 1 2.974 6.882.778.778 0 0 0-.174.174 4.084 4.084 0 1 1-2.8-7.056Z"
                    fill="#071741"
                />
            </g>
        </g>
    </svg>
)

