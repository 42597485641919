import { useApolloClient } from "@apollo/client"
import { KeyboardArrowDown } from "@mui/icons-material"
import { Box, Stack, Typography } from "@mui/material"
import React from "react"
import { useLocation } from "react-router"
import { EllipseIcon } from "../../../assets/ellipseIcon"
import { UtilityIcon } from "../../../assets/utilityIcon"
import { DialogDrawer } from "../../../components"
import { config } from "../../../config"
import { GETUNIT_BASED_ON_PROPERTYID } from "../../../graphql/queries"
import { NetworkCall } from "../../../networkcall"
import { NetWorkCallMethods } from "../../../utils"
import { useStyles } from "../style"

export const Step1 = ({ next = () => false, prev = () => false }) => {
    const [isUnit, setIsUnit] = React.useState(false)
    const [selectedUnit, setSelectedUnit] = React.useState({})
    const { state } = useLocation()
    const client = useApolloClient()
    const [unitList, setUnitList] = React.useState([])
    const [utilityList, setUtilityList] = React.useState([])
    const classes = useStyles()
    const getUnitList = () => {
        client.query({
            query: GETUNIT_BASED_ON_PROPERTYID,
            fetchPolicy: 'network-only',
            variables: {
                propertyId: state?.property_id,
            }
        }).then((response) => {
            setUnitList(response?.data?.unit)
        }).catch((err) => {
            console.log(err)
        })
    }
    const getUtilityList = (unitId = []) => {
        const payload = {
            unit_ids: [unitId]
        }
        NetworkCall(
            `${config.api_url}/unit_meter_reading/`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((res) => {
            setUtilityList(res?.data?.data)
        }).catch((error) => {
            console.log(error)
        });
    }
    const handleSelectedUnit = (val) => {
        setSelectedUnit(val)
        setIsUnit(false)
        getUtilityList(val?.id)
    }
    React.useEffect(() => {
        getUnitList()
        //eslint-disable-next-line
    }, [])
    return (
        <Box>
            <Box m={1.5} display="flex" justifyContent={"space-between"} className={classes.searchdropdown} onClick={() => setIsUnit(true)}>
                <Stack direction="row" alignItems={"center"} spacing={0.5}>
                    <Typography className={classes.readingdate}>{selectedUnit?.name ? "Unit" : ""}</Typography>
                    <Typography className={classes.propertyName}>{selectedUnit?.name ? selectedUnit?.name : "Choose Unit"}</Typography>
                </Stack>
                <KeyboardArrowDown />
            </Box>
            {utilityList?.length > 0 ? utilityList?.map((val) => {
                return (
                    <Box onClick={() => next(val)} p={1}>
                        <Box display="flex" justifyContent={"space-between"} className={classes.propertyMainblock}>
                            <Stack direction={"row"} spacing={2} alignItems="center">
                                <Box className={classes.utilitybg}><UtilityIcon color={"#B20606"} /></Box>
                                <Box>
                                    <Typography className={classes.propertyName}>{val?.utility_name}</Typography>
                                    <Typography className={classes.readingdate}>{val?.reading_count}</Typography>
                                </Box>
                            </Stack>
                        </Box>
                    </Box>
                )
            }) : selectedUnit?.id?.length > 0 && <Box mt={4}><Typography className={classes.nodata}>{"No data found"}</Typography></Box>}

            <DialogDrawer
                header={"Unit"}
                component={
                    <Box height="350px" overflow={"scroll"}>
                        {unitList?.map((x) => {
                            return (
                                <Box p={2}>
                                    <Stack direction={"row"} spacing={1.5} alignItems="center" onClick={() => handleSelectedUnit(x)}>
                                        {selectedUnit?.id === x?.id ?
                                            <EllipseIcon color="#B20606" />
                                            :
                                            <EllipseIcon />
                                        }
                                        <Typography className={classes.unitName}>{x.name}</Typography>
                                    </Stack>
                                </Box>
                            )
                        })}
                    </Box>
                }
                open={isUnit}
                padding={0}
                onClose={() => setIsUnit(false)}
            />
        </Box>
    )
}