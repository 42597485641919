
import {
      Button,
      Container,
      Grid,
      Typography
} from "@mui/material";
import axios from "axios";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { updateDeviceToken } from "../../graphql/mutations";
import { NetworkCall } from "../../networkcall";
import { Routes } from "../../router/routes";
import { AlertProps, LocalStorageKeys, NetWorkCallMethods, roles } from '../../utils';
import useWindowDimensions from "../../utils/useWindowDimensions";
import { CompanyList } from './component/companyselect';
import { useStyles } from "./style";

export const CompanySelect = () => {
      const { state } = useLocation();
      // history
      const history = useHistory()
      const alert = React.useContext(AlertContext);
      // state
      const [client, setClient] = React.useState(state?.[0] ?? "")
      const [disable, setDisable] = React.useState(false)
      // classes
      const classes = useStyles();
      // useDimension
      const size = useWindowDimensions();
      // add client
      const companySelect = (client) => {
            setClient(client)
      }
      const setDeviceToken = (val, client) => {
            if (localStorage.getItem(LocalStorageKeys.deviceToken)) {
                  const variables = {
                        query: updateDeviceToken,
                        variables: {
                              insert: [
                                    {
                                          user_profile_id: val,
                                          token: localStorage.getItem(LocalStorageKeys.deviceToken),
                                          is_active: true,
                                          client: client,
                                          app_build: config.app_x_build
                                    },
                              ],
                        },
                  };

                  NetworkCall(
                        `${config.graphql_url}`,
                        NetWorkCallMethods.post,
                        variables,
                        null,
                        true,
                        false
                  )
                        .then((response) => { })
                        .catch((error) => {
                              console.log(error)
                        });
            }
      };
      //get client
      const getClientId = (roleID) => {
            axios.get(`${config.auth_api_url}/auth/select_role?role_id=${roleID}`,
                  { headers: { Authorization: `Bearer ${localStorage.getItem(LocalStorageKeys.authToken)}` } }
            ).then((res) => {
                  localStorage.setItem(LocalStorageKeys.authToken, res.data.token)
                  localStorage.setItem(LocalStorageKeys.client, JSON.stringify(res?.data?.selected_client))
                  setDisable(false)
                  setDeviceToken(res?.data?.userProfile?.[0]?.id, res?.data?.selected_client?.id)
                  history.push({
                        pathname: Routes.dashboard,
                        state: true
                  })
            }).catch((err) => {
                  alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: "Some thing went wrong",
                  });
                  setDisable(false)

            });
      }
      //submit 
      const submit = () => {
            setDisable(true)
            axios.get(`${config.auth_api_url}/auth/select_client?client_id=${client?.id}`,
                  { headers: { Authorization: `Bearer ${localStorage.getItem(LocalStorageKeys.authToken)}` } }
            ).then((res) => {

                  const rolesName = res?.data?.userProfile?.[0]?.roles.map((val) => { return val.name });
                  const isInspector = (name) => name === roles.inspector;
                  if (rolesName.some(isInspector)) {
                        //set datas in local 
                        let role = res?.data?.userProfile?.[0]?.roles?.filter((val) => val.name === roles.inspector);
                        localStorage.setItem(LocalStorageKeys.authToken, res.data.token)
                        localStorage.setItem(LocalStorageKeys.accessType, JSON.stringify(role))
                        //localStorage.setItem(LocalStorageKeys.userProfileId, res?.data?.userProfile?.[0]?.id)
                        //get client
                        getClientId(role?.[0]?.role_id)

                  } else {
                        alert.setSnack({
                              ...alert,
                              open: true,
                              severity: AlertProps.severity.error,
                              msg: "Sorry You Don't Have Access",
                        });
                        localStorage.clear()
                        history.push(Routes.login)
                  }


            }).catch((err) => {
                  alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: "Some thing went wrong",
                  });

                  setDisable(false)
            });
      }
      return (
            <div>
                  {/* body */}
                  <Container maxWidth="sm" style={{ padding: 0 }}>
                        <Grid container>
                              <Grid item className={classes.content} style={{ height: size.height - 150 }}>
                                    <Grid item xs={12} className={classes.backgroundImage} />
                                    <Grid >
                                          {/* header */}
                                          <Grid item xs={12}>
                                                <Typography className={classes.text} padding="16px">Select Client</Typography>
                                          </Grid>
                                          {/* company list */}
                                          <Grid>
                                                <div style={{ height: size?.height - 400, overflow: "auto", padding: "16px" }} className={classes.list}>
                                                      {
                                                            state?.map((value) => {
                                                                  return (
                                                                        <CompanyList
                                                                              data={value}
                                                                              companySelect={companySelect}
                                                                              seleted={client?.id} />
                                                                  )
                                                            })
                                                      }
                                                </div>
                                          </Grid>

                                    </Grid>
                              </Grid>
                              {/* button */}
                              <Grid item xs={12} className={classes.paddingCompany}>
                                    <Button fullWidth variant="contained" className={classes.btn} onClick={submit} disabled={disable} >
                                          Continue
                                    </Button>
                                    <Typography className={classes.goBackStyle} onClick={() => {
                                          history.push(Routes.login)
                                          localStorage.removeItem(LocalStorageKeys.authToken)
                                    }}>
                                          Go Back
                                    </Typography>
                              </Grid>
                        </Grid>
                  </Container>
            </div>
      );
};
