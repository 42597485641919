import { Avatar, Box, Button, Divider, Grid, Stack, Typography } from "@mui/material"
// import { Stack } from "@mui/system"
import React, { useState } from "react"
import { withNamespaces } from "react-i18next"
import ShowMoreText from "react-show-more-text"
import CheckIcon from "../../assets/checkIcon"
import { config } from "../../config"
import { NetworkCall } from "../../networkcall"
import { NetWorkCallMethods } from "../../utils"
import { DialogDrawer } from "../dialogDrawer"
import { InspectionCard } from "../inspectionCard"
import { LoderSimmer } from "../simmers/card"
import { WorkingHourTrackCard } from "../workinghourTrackCard/trackCard"
import ReAllocateCard from "./pause&reallocate"
import { useStyles } from "./styles"

const Preview = ({ t, next = () => false, state = {}, setStep = () => false, previous = () => false, disable = false, details = {}, setDetails = () => false, onPreviewClick = () => false, isdescription = false }) => {
    const classes = useStyles()
    const [showReallocateDialog, setShowReallocateDialog] = useState(false)
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    const [location, setLocation] = React.useState({
        lat: "",
        lng: ""
    })
    const getInspectionPreviewDetails = async () => {
        const payload = {
            "agreement_inspection_id": state?.id,
            "unit_id": state?.unit_id,
            "agreement_id": state?.agreement_id,
            "resource_id": state?.resource_id,
            "type_in": state?.type
        }
        await NetworkCall(
            `${config.api_url}/move-in-out/get_preview_details`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((res) => {
            setData(res?.data?.data)
            setDetails({ ...details, property_unit_details: res?.data?.data?.property_unit_details })
            setLoading(false)
        }).catch((error) => {
            console.log(error)
            setLoading(false)

        });

    }
    React.useEffect(() => {
        getInspectionPreviewDetails()
        getCurrentLocation()
        // eslint-disable-next-line
    }, [])

    const getCurrentLocation = () => {
        let location = null;
        if (window.navigator && window.navigator.geolocation) {
            location = window.navigator.geolocation
        }
        if (location) {
            location.getCurrentPosition(function (position) {
                setLocation({ lat: position.coords.latitude, lng: position.coords.longitude })
            })
        }
    }

    return (
        <Box>
            {loading ?
                <LoderSimmer count={10} />
                :
                <>
                    <Box className={classes.root}>
                        <Box className={classes.previewCard}>
                            <InspectionCard value={data?.property_unit_details} preview={true} />
                            <Box mt={2} className={classes.mainsection}>
                                <WorkingHourTrackCard title={t("workinghourTrack")} data={data?.track} t={t} />
                            </Box>
                            {data?.inspection_items?.length > 0 &&
                                <>
                                    <Box mt={2}>
                                        <Typography className={classes.headerTitle}>{`INSPECTION ITEMS (${data?.inspection_items?.length})`}</Typography>
                                    </Box>
                                    <Box className={classes.mainsection} mt={2}>
                                        {data?.inspection_items?.filter(i => i?.title !== "null")?.map((item) => {
                                            return (
                                                <Box className={classes.itemList}>
                                                    <Box mb={1}>
                                                        <Typography className={classes.headerTitle}>{`${item?.title} (${item?.list?.length})`}</Typography>
                                                    </Box>
                                                    {item?.list?.map((x, i) => {
                                                        return (
                                                            <>
                                                                <Box className={classes.utilityCard}>
                                                                    <Stack direction={'row'} spacing={2}>
                                                                        <Avatar src={x.url} variant="square" />
                                                                        <Box>
                                                                            <Typography className={classes.title}>{x.name}</Typography>
                                                                            <ShowMoreText
                                                                                lines={2}
                                                                                more="Show More"
                                                                                less="Show Less"
                                                                                className={classes.description}
                                                                                anchorClass={classes.seeMoreLessTextStyle}
                                                                                expanded={false}
                                                                                truncatedEndingComponent={"... "}
                                                                            >
                                                                                <Typography className={classes.description}>{x.description}</Typography>
                                                                            </ShowMoreText>
                                                                        </Box>
                                                                    </Stack>
                                                                    <Box mt={1}><CheckIcon /></Box>
                                                                </Box>
                                                                <Box mt={2} mb={2}>
                                                                    {item?.list?.length - 1 !== i && <Divider />}
                                                                </Box>
                                                            </>
                                                        )
                                                    })}


                                                </Box>
                                            )
                                        })}
                                    </Box>
                                </>
                            }
                            {data?.utilities?.length > 0 &&
                                <>
                                    <Box mt={2}>
                                        <Typography className={classes.headerTitle}>{`UTILITY GROUP (${data?.utilities?.length})`}</Typography>
                                    </Box>
                                    <Box className={classes.mainsection} mt={2}>
                                        {data?.utilities?.map((item, i) => {
                                            return (
                                                <Box className={classes.itemList}>
                                                    <Box mb={1}>
                                                        {/* <Typography className={classes.headerTitle}>{`${item?.title} (${item?.list?.length})`}</Typography> */}

                                                        <>
                                                            <Box className={classes.utilityCard}>
                                                                <Stack direction={'row'} spacing={2}>
                                                                    <Avatar src={item?.assets?.[0]} variant="square" />
                                                                    <Box>
                                                                        <Typography className={classes.title}>{item.utility_name}</Typography>
                                                                        <Typography className={classes.description}>Reading:{item?.present_reading}</Typography>
                                                                    </Box>
                                                                </Stack>
                                                                <Box mt={1}><CheckIcon /></Box>
                                                            </Box>
                                                            <Box mt={2} mb={2}>
                                                                {data?.utilities?.length - 1 !== i && <Divider />}
                                                            </Box>
                                                        </>


                                                    </Box>

                                                </Box>
                                            )
                                        })}
                                    </Box>
                                </>
                            }
                        </Box>

                    </Box>
                    <Grid className={classes.btnRoot} container spacing={1}>
                        {disable !== true &&
                            <Grid item xs={6}>
                                <Button onClick={() => disable === true ? previous() : setShowReallocateDialog(true)} fullWidth className={classes.btn1}>{disable === true ? t("previous") : t("pause/reallocate")}</Button>
                            </Grid>
                        }
                        <Grid item xs={disable === true ? 12 : 6}>
                            <Button  onClick={() => onPreviewClick()} fullWidth variant="contained" className={classes.next}>{disable === true ? (state?.status === "Completed" ? t("next") : t("done")) : t("getSignature")}</Button>
                        </Grid>
                    </Grid>
                    {/* {Pause/Reallocate} */}
                    <DialogDrawer
                        header="Pause/Reallocate"
                        component={<ReAllocateCard location={location} setStep={setStep} state={state} setShowReallocateDialog={setShowReallocateDialog} />}
                        open={showReallocateDialog}
                        padding={0}
                        onClose={() => setShowReallocateDialog(false)}
                    />
                </>
            }
        </Box>
    )
}
export default withNamespaces("inspections")(Preview);
