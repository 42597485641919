import React from "react"

export const TimeIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
        <g id="icons8-tenses" transform="translate(-1 -10)">
            <path id="Path_97580" data-name="Path 97580" d="M33,26A16,16,0,1,1,17,10,16,16,0,0,1,33,26" transform="translate(0 0)" fill="#6dafb3" />
            <path id="Path_97584" data-name="Path 97584" d="M16.571,13A12.571,12.571,0,1,0,29.143,25.571,12.572,12.572,0,0,0,16.571,13Zm0,13.714a1.143,1.143,0,1,1,1.143-1.143A1.143,1.143,0,0,1,16.571,26.714Z" transform="translate(0.429 0.429)" fill="#fff" />
            <g id="Group_104470" data-name="Group 104470" transform="translate(14.714 15.714)">
                <path id="Path_97585" data-name="Path 97585" d="M16.286,23.893H14V15h2.286Zm3.354,7.865,1.976-1.976-4.5-4.495-1.976,1.975Z" transform="translate(-12.857 -15)" />
                <path id="Path_97586" data-name="Path 97586" d="M15.286,22a2.286,2.286,0,1,0,2.286,2.286A2.285,2.285,0,0,0,15.286,22Zm0,3.429a1.143,1.143,0,1,1,1.143-1.143A1.143,1.143,0,0,1,15.286,25.429Z" transform="translate(-13 -14)" />
            </g>
        </g>
    </svg>
)