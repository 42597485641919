import { Box, Divider, Grid, Typography } from "@mui/material";
import React from "react";
import { withNamespaces } from "react-i18next";
import { config } from "../../config";
import { GET_ACTIVITY_BY_ID } from "../../graphql/queries";
import { NetworkCall } from "../../networkcall";
import { NetWorkCallMethods } from "../../utils";
import { AssetView } from "../assetView";
import { LoadingSection } from "../LoadingSections";
import ShowMoreText from "react-show-more-text";
import { useStyles } from "./styles";
const Details = ({ in_activity, t, serial_number = "" }) => {
    const classes = useStyles()
    const [data, setData] = React.useState({ loading: true });
    //get details
    const getActivityDetails = () => {
        const payload = {
            query: GET_ACTIVITY_BY_ID,
            variables: {
                id: in_activity
            },
        };
        NetworkCall(
            `${config.graphql_url}`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                setData({ ...response?.data?.data?.inspection_activity[0], loading: false })
            })
            .catch((error) => {
                console.log(error)
            });

    }
    React.useEffect(() => {
        if (in_activity) {
            getActivityDetails();
        } else {
            setData({ ...data, loading: false })
        }
        // eslint-disable-next-line
    }, [])
    return (
        <Box height="600px" overflow="auto" p={1.5}>
            {
                data?.loading ?
                    <LoadingSection top="20vh" />
                    :
                    <Box>
                        {
                            data?.inspection_activity_assets?.length > 0 && <AssetView asset={data?.inspection_activity_assets} />
                        }

                        <Typography className={classes.productId}>{serial_number}</Typography>
                        <Divider className={classes.divider} />
                        <Typography className={classes.heading}>{t("description")}</Typography>
                        <ShowMoreText
                            lines={2}
                            more="Show More"
                            less="Show Less"
                            className={classes.value}
                            anchorClass={classes.seeMoreLessTextStyle}
                            expanded={false}
                            truncatedEndingComponent={"... "}
                        >
                            <Typography className={classes.value}
                            >
                                {data?.description}
                            </Typography>
                        </ShowMoreText>
                        <Divider className={classes.divider} />
                        <Grid container>
                            {data?.inspection_activity_feedback?.map((x) => {
                                return (
                                    <Grid item xs={6}>
                                        <Typography className={classes.heading}>{x?.inspection_feedback_category?.[0]?.name}</Typography>
                                        <Typography className={classes.value1}
                                            sx={{ backgroundColor: x?.inspection_feedback_sub_category?.color }}
                                        >
                                            {x?.inspection_feedback_sub_category?.name}
                                        </Typography>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </Box>
            }

        </Box>
    )
}
export default withNamespaces("inspections")(Details);