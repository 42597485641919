import { useApolloClient } from "@apollo/client";
import { Box, Button, Grid } from "@mui/material";
import React, { useState } from "react";
import { withNamespaces } from "react-i18next";
import { useLocation } from "react-router-dom";
import { Bicycle3, FourWheel, TwoWheel } from "../../../assets";
import { DialogDrawer, SingleUploadComponent, TextBox } from "../../../components";
import { AlertContext } from "../../../contexts";
import { PARKING_SLOT_MASTER, PARKING_SLOT_MASTER_BY_UNIT, UPDATE_PARKING_SLOT_MASTER, UPDATE_PARKING_SLOT_MASTER_BY_UNIT } from "../../../graphql/mutations";
import { AlertProps } from "../../../utils";
import { SelectedCard } from "../components";
import { useStyles } from "../styles";
import ReAllocateCard from "./pause&reallocate";

const initialState = {
    type: "",
    no: "",
    image: "",
    error: {
        type: "",
        no: "",
        image: "",
    }
}

const VehicleDetails = ({ t, setStep = () => false, datas = {}, disable = false, detail = {}, agreement_unit_id = {} }) => {

    const alert = React.useContext(AlertContext);
    const classes = useStyles();
    const client = useApolloClient();
    const [data, setData] = React.useState({ ...initialState })
    const { state } = useLocation();


    const VehicleTypeList = [
        {
            label: "Four Wheeler",
            value: "FOUR-WHEELER",
            icon: (<FourWheel />),
            selected_icon: (<FourWheel color="#B20606" />)
        },
        {
            label: "Two Wheeler",
            value: "TWO-WHEELER",
            icon: (<TwoWheel />),
            selected_icon: (<TwoWheel color="#B20606" />)
        },
        {
            label: "Bi Cycle",
            value: "BI-CYCLE",
            icon: (<Bicycle3 />),
            selected_icon: (<Bicycle3 color="#B20606" />)
        },
    ]
    const [showReallocateDialog, setShowReallocateDialog] = useState(false)
    const [location, setLocation] = React.useState({
        lat: "",
        lng: ""
    })


    //initial load
    React.useEffect(() => {
        getCurrentLocation()

        // eslint-disable-next-line
    }, [])
    const getCurrentLocation = () => {
        let location = null;
        if (window.navigator && window.navigator.geolocation) {
            location = window.navigator.geolocation
        }
        if (location) {
            location.getCurrentPosition(function (position) {
                setLocation({ lat: position.coords.latitude, lng: position.coords.longitude })
            })
        }
    }

    //get parking slot
    const parkingSlot = () => {
        const parking = {
            agreement_id: state?.agreement_id,
            parking_id: datas?.parking?.id,
        }
        const unit = {
            agreement_id: state?.agreement_id,
            agreement_unit_id: datas?.agreement_unit,
        }

        client.query({
            query: state?.is_parking_component ? PARKING_SLOT_MASTER_BY_UNIT : PARKING_SLOT_MASTER,
            fetchPolicy: 'network-only',
            variables: state?.is_parking_component ? unit : parking
        }).then(rs => {
            let details = rs?.data?.parking_slot?.[0];
            if (details?.number_plate && details?.vehicle_image && details?.vehicle_type) {
                setData({
                    type: details?.vehicle_type,
                    no: details?.number_plate,
                    image: {
                        url: details?.vehicle_image
                    },
                    agreement_unit_id: agreement_unit_id,
                    error: {
                        type: "",
                        no: "",
                        image: "",
                    }
                })
            }

        }).catch(er => {
            console.log(er)
        })
    }
    //load options
    React.useEffect(() => {
        console.log(disable)
        parkingSlot();
        // eslint-disable-next-line
    }, [])
    const updateState = (key, value) => {
        let error = data.error;
        error[key] = "";
        setData({ ...data, [key]: value, error });
    };
    //validate form
    const isFormValid = () => {
        let isValid = true;
        let error = data.error;
        if (data.type.length === 0) {
            isValid = false;
            error.type = `${t("vehicle")} ${t("is_required")}`;
        }
        if (data.no.length === 0) {
            isValid = false;
            error.no = `${t("vehicle_number")} ${t("is_required")}`;
        }
        if (data.image.length === 0) {
            isValid = false;
            error.image = `${t("image")} ${t("is_required")}`;
        }

        setData({ ...data, error });
        return isValid;
    };

    //update parking slot
    const updateParkingSlot = () => {
        const parking = {
            agreement_id: state?.agreement_id,
            parking_id: datas?.parking?.id,
            payload: {
                number_plate: data?.no,
                vehicle_type: data?.type,
                vehicle_image: data?.image?.url,
                agreement_unit_id: detail?.agreement_unit_id?.agreement_unit
            }
        }
        const unit = {
            agreement_id: state?.agreement_id,
            agreement_unit_id: datas?.agreement_unit,
            payload: {
                number_plate: data?.no,
                vehicle_type: data?.type,
                vehicle_image: data?.image?.url,
                parking_id: detail?.slot?.id
            }
        }

        client.mutate({
            mutation: state?.is_parking_component ? UPDATE_PARKING_SLOT_MASTER_BY_UNIT : UPDATE_PARKING_SLOT_MASTER,
            variables: state?.is_parking_component ? unit : parking
        }).then(rs => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.success,
                msg: "Parking Updated",
            });
            //history.push(Routes.inspections)

        }).catch(er => {
            console.log(er)
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: "Some thing went wrong",
            });
        })
    }

    //submit
    const onSubmit = () => {
        if (isFormValid()) {
            if (!disable) {
                updateParkingSlot()
            }
            setStep(5, null)
        } else {
            return false
        }
    }


    return (
        <Box>
            <Box className={classes.root} p={1}>
                <SelectedCard
                    onChange={(value) => updateState("type", value)}
                    value={data?.type}
                    disable={disable}
                    options={VehicleTypeList}
                    label={`${t("select")} ${t("vehicle")}`}
                    isError={data?.error?.type?.length > 0}
                    errorMessage={data?.error?.type}
                />
                <Box height="10px" />
                <TextBox
                    color="white"
                    placeholder={`${t("enter")} ${t("vehicle_number")}`}
                    label={t("vehicle_number")}
                    onChange={(e) => updateState("no", e.target.value)}
                    value={data?.no}
                    isError={data?.error?.no?.length > 0}
                    errorMessage={data?.error?.no}
                    isReadonly={disable}
                />
                <Box height="10px" />
                <SingleUploadComponent
                    logo_title={t("uploadImage")}
                    handleChange={(value) => updateState("image", value)}
                    value={data?.image}
                    isError={data?.error?.image?.length > 0}
                    errorMessage={data?.error?.image}
                    disable={disable}
                />
            </Box>
            {disable ?
                <Box className={classes.btnRoot}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Button
                                fullWidth
                                className={classes.btn}
                                sx={{ backgroundColor: "#B20606" }}
                                variant="contained"
                                onClick={onSubmit}
                            >
                                {t("okay")}
                            </Button>
                        </Grid>

                    </Grid>

                </Box>
                :
                <Grid className={classes.btnRoot} container spacing={1}>
                    <Grid item xs={6}>
                        <Button onClick={() => setShowReallocateDialog(true)} fullWidth className={classes.closebtn}>{t("pause/reallocate")}</Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button onClick={onSubmit} fullWidth variant="contained" className={classes.startbtn}>{t("next")}</Button>
                    </Grid>
                </Grid>
            }
            {/* {Pause/Reallocate} */}
            <DialogDrawer
                header="Pause/Reallocate"
                component={<ReAllocateCard location={location} setStep={setStep} state={state} setShowReallocateDialog={setShowReallocateDialog} />}
                open={showReallocateDialog}
                padding={0}
                onClose={() => setShowReallocateDialog(false)}
            />
        </Box>
    );
};

export default withNamespaces("inspections")(VehicleDetails);