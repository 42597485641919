import * as React from "react"

export const Completed = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={25.6} height={32} {...props}>
        <path
            d="M10 0a3.608 3.608 0 0 0-3.559 3.2H3.6A3.6 3.6 0 0 0 0 6.8v21.6A3.6 3.6 0 0 0 3.6 32H22a3.6 3.6 0 0 0 3.6-3.6V6.8A3.6 3.6 0 0 0 22 3.2h-2.841A3.608 3.608 0 0 0 15.6 0Zm0 2.4h5.6a1.2 1.2 0 1 1 0 2.4H10a1.2 1.2 0 1 1 0-2.4Zm2.8 8a7.2 7.2 0 1 1-7.2 7.2 7.2 7.2 0 0 1 7.2-7.2Zm4 4a.8.8 0 0 0-.566.234L11.6 19.269l-2.234-2.234a.8.8 0 1 0-1.131 1.131l2.8 2.8a.8.8 0 0 0 1.131 0l5.2-5.2A.8.8 0 0 0 16.8 14.4Z"
            fill="#35a27d"
        />
    </svg>
)

