import React from "react";

export const BigCheckIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
  <g id="Group_107133" data-name="Group 107133" transform="translate(-234 -137)">
    <g id="Group_107130" data-name="Group 107130" transform="translate(-17 4)">
      <g id="Group_107131" data-name="Group 107131" transform="translate(0 4)">
        <g id="Rectangle_55631" data-name="Rectangle 55631" transform="translate(251 129)" fill="#fff" stroke="#707070" stroke-width="1" opacity="0">
          <rect width="24" height="24" stroke="none"/>
          <rect x="0.5" y="0.5" width="23" height="23" fill="none"/>
        </g>
        <path id="icons8-verified-badge_1_" data-name="icons8-verified-badge (1)" d="M21.429,13.471a2.119,2.119,0,0,0-.593-3.4l-.884-.417a.725.725,0,0,1-.409-.718l.085-.982a2.121,2.121,0,0,0-.735-1.8,2.073,2.073,0,0,0-1.878-.421l-.942.256a.714.714,0,0,1-.77-.284L14.746,4.9a2.068,2.068,0,0,0-3.412,0l-.559.808a.713.713,0,0,1-.77.284l-.941-.256a2.069,2.069,0,0,0-1.878.421,2.121,2.121,0,0,0-.735,1.8l.085.982a.727.727,0,0,1-.41.718l-.884.416a2.119,2.119,0,0,0-.593,3.4l.689.7a.73.73,0,0,1,.142.816l-.412.894a2.13,2.13,0,0,0,.084,1.945,2.082,2.082,0,0,0,1.623,1.045l.971.086a.718.718,0,0,1,.627.533l.252.952a2.1,2.1,0,0,0,1.3,1.435,2.064,2.064,0,0,0,1.908-.254l.8-.566a.71.71,0,0,1,.819,0l.8.566a2.063,2.063,0,0,0,1.907.254,2.1,2.1,0,0,0,1.3-1.435l.252-.952a.717.717,0,0,1,.627-.533l.97-.086a2.082,2.082,0,0,0,1.623-1.045,2.131,2.131,0,0,0,.083-1.945l-.412-.894a.73.73,0,0,1,.142-.816Zm-4.607-1.827L12.261,16.26a.679.679,0,0,1-.968,0L9.012,13.952a.7.7,0,0,1,0-.979.678.678,0,0,1,.968,0l1.8,1.818,4.078-4.126a.678.678,0,0,1,.968,0A.7.7,0,0,1,16.822,11.644Z" transform="translate(249.96 128)" fill="#5ac782"/>
      </g>
    </g>
  </g>
</svg>

)