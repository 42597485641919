import React from "react"

export const CompassIcon = () => (
    <svg id="Group_108209" data-name="Group 108209" xmlns="http://www.w3.org/2000/svg"  width="22" height="17.169" viewBox="0 0 22 17.169">
        <defs>
            <clipPath id="clip-path">
                <rect id="Rectangle_53017" data-name="Rectangle 53017" width="22" height="17.169" fill="#b20606" />
            </clipPath>
        </defs>
        <g id="Group_96525" data-name="Group 96525" clip-path="url(#clip-path)">
            <path id="Path_9105" data-name="Path 9105" d="M0,10.3c.056-.4.1-.809.17-1.208a10.826,10.826,0,0,1,4.324-6.95A10.672,10.672,0,0,1,11,0,11,11,0,0,1,21.789,8.892,10.851,10.851,0,0,1,20.577,16.4a.658.658,0,0,1-1.023.282q-.818-.472-1.635-.945a.648.648,0,1,1,.636-1.12c.379.215.756.435,1.133.653a8.648,8.648,0,0,0,.965-3.632c-.453,0-.909,0-1.366,0a.632.632,0,0,1-.646-.676.647.647,0,0,1,.677-.609c.443-.007.885,0,1.359,0a9.527,9.527,0,0,0-.969-3.634l-.933.536c-.1.057-.2.118-.3.169a.646.646,0,0,1-.86-.239.635.635,0,0,1,.229-.878c.391-.237.79-.46,1.184-.688a8.634,8.634,0,0,0-2.644-2.661c-.228.394-.453.792-.687,1.183a.639.639,0,0,1-.716.31.61.61,0,0,1-.475-.568.98.98,0,0,1,.118-.445c.2-.384.43-.756.646-1.132a8.884,8.884,0,0,0-3.63-.969c0,.454,0,.91,0,1.367a.643.643,0,1,1-1.286,0c-.007-.387,0-.773,0-1.16,0-.07,0-.14,0-.207A8.877,8.877,0,0,0,6.723,2.3c.217.375.443.747.646,1.13a.99.99,0,0,1,.119.445.61.61,0,0,1-.472.57.632.632,0,0,1-.706-.289c-.191-.31-.368-.63-.551-.945L5.6,2.938A9.731,9.731,0,0,0,2.937,5.6c.4.232.789.454,1.175.682A.644.644,0,1,1,3.483,7.4c-.2-.109-.4-.228-.595-.343l-.6-.344a9.529,9.529,0,0,0-.976,3.636h.37c.337,0,.673-.005,1.009,0a.641.641,0,0,1,.66.646.634.634,0,0,1-.664.639c-.379.006-.759,0-1.138,0H1.318a9.6,9.6,0,0,0,.969,3.642c.4-.233.8-.464,1.192-.685a.644.644,0,0,1,.92.254.623.623,0,0,1-.231.828q-.91.547-1.839,1.062a.623.623,0,0,1-.856-.248A10.831,10.831,0,0,1,.13,12.671c-.05-.331-.087-.664-.13-1V10.3" transform="translate(0 0)" fill="#b20606" />
            <path id="Path_9106" data-name="Path 9106" d="M111.005,108.942c-.06.241-.107.486-.186.721a.208.208,0,0,0,.052.261c.193.181.378.371.563.561a.646.646,0,1,1-.908.913c-.189-.185-.379-.37-.561-.563a.2.2,0,0,0-.26-.055,2.021,2.021,0,0,1-2.633-2.618.232.232,0,0,0-.071-.292q-1.439-1.431-2.872-2.869a.645.645,0,0,1-.187-.666.6.6,0,0,1,.477-.446.675.675,0,0,1,.655.232c.935.938,1.874,1.872,2.807,2.813a.282.282,0,0,0,.362.086,2.051,2.051,0,0,1,2.763,1.922m-1.324-.037a.732.732,0,1,0-1.464.018.732.732,0,0,0,1.464-.018" transform="translate(-97.95 -97.911)" fill="#b20606" />
            <path id="Path_9107" data-name="Path 9107" d="M147.124,276.623c.687,0,1.374-.009,2.06,0a.632.632,0,0,1,.546.953.62.62,0,0,1-.566.329q-2.061.006-4.121,0a.642.642,0,1,1,.019-1.284c.687-.005,1.374,0,2.061,0Z" transform="translate(-136.105 -260.742)" fill="#b20606" />
        </g>
    </svg>

)