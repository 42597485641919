import jwt_decode from "jwt-decode";
/**
 * Object with role as key and value, which is used for
 * comparison of role in different place.
 */
export const UserRoles = {
  role: "role",
};

/**
 * Object which has the proper name of all the role
 * used in the application.
 */
export let UserRolesName = {
  role: "Role",
};

/**
 * Object which has the different themes used in
 * the application.
 */
export let Themes = {
  default: "default",
  dark: "dark",
};

/**
 * Object which has the different props for the Alert Component (/src/component/alert)
 * which is used via AlertContext (/src/contexts) and provided at /src/App.alert.js.
 */
export let AlertProps = {
  vertical: {
    top: "top",
    bottom: "bottom",
  },
  horizontal: {
    left: "left",
    right: "right",
    center: "center",
  },
  severity: {
    success: "success",
    error: "error",
    warning: "warning",
    info: "info",
  },
};

/**
 * Object which has the different props for the Drawer Component (/src/App.drawer.js)
 * which is used via DrawerContext (/src/contexts) and provided at /src/App.drawer.js.
 */
export const DrawerProps = {
  direction: {
    top: "top",
    bottom: "bottom",
    left: "left",
    right: "right",
  },
  variant: {
    permanent: "permanent",
    persistent: "persistent",
    temporary: "temporary",
  },
};

/**
 * Object has the key and value pair of all the keys which
 * are used to store some values in the local storage.
 */
export let LocalStorageKeys = {
  authToken: "authToken",
  version: "version",
  deviceToken: "device_token",
  accessType: "accessType",
  userProfileId: "userProfileId",
  client: "client"
};

/**
 * Object has the key and value pair of all the HTTP method
 * used for an network call.
 */
export let NetWorkCallMethods = {
  get: "GET",
  post: "POST",
  put: "PUT",
  delete: "DELETE",
  update: "UPDATE",
};
export const calculateTime = (date1, date2) => {
  var oneDay = 24 * 60 * 60 * 1000;
  var date1InMillis = date1.getTime();
  var date2InMillis = date2.getTime();
  var days = Math.round((date2InMillis - date1InMillis) / oneDay);
  return days;
};

export const TabContant = {
  Tabs: [
    {
      mounth: "Jan",
      value: 0,
    },
    {
      mounth: "Feb",
      value: 1,
    },
    {
      mounth: "Mar",
      value: 2,
    },
    {
      mounth: "Apr",
      value: 3,
    },
    {
      mounth: "May",
      value: 4,
    },
    {
      mounth: "Jun",
      value: 5,
    },
    {
      mounth: "July",
      value: 6,
    },
    {
      mounth: "Aug",
      value: 7,
    },
    {
      mounth: "Sep",
      value: 8,
    },
    {
      mounth: "Oct",
      value: 9,
    },
    {
      mounth: "Nov",
      value: 10,
    },
    {
      mounth: "Dec",
      value: 11,
    },
  ],

  TabYearly: [
    {
      label: "2022",
      value: 2022,
    },
    {
      label: "2021",
      value: 2021,
    },
  ],
};

export let roles = {
  tenant: "Tenant",
  inspector:"Inspector"
}

export const getClient = (token) => jwt_decode(token)?.selected_client

export let requsetStatus = {
  open: "Open",
  assigned: "Assigned",
  closed: "Closed",
  maintenance: "Maintenance",
  general: "General"
}

export let parkingEnum = {
  parking_unit: "Parking Unit",
}

export let quotationActionCard = [
  {
    id: 1,
    img: "/images/icons8-paid-bill.svg",
    title: "Accept quote",
    color: "#35A27D",
    value: "Interested"
  },
  // {
  //   id: 2,
  //   img: "/images/icons8-leave-house (1).svg",
  //   title: "Request for visit",
  //   color: "#27AFCF",
  //   value: "Interested"
  // },
  {
    id: 4,
    title: "Not Interested",
    value: "Not Interested"
  }
];

export let quotationEnum = {
  interested: "Interested",
  notinterested: "Not Interested"

}

export let agreementEnum = {
  termination: "Termination",
  pending: "Pending",
  renwal: "Renewal"

}

//fonts function
export const Regular =
  "NunitoSans-Regular";
export const Bold =
 "NunitoSans-Bold";
export const SemiBold =
  "NunitoSans-SemiBold";
export const ExtraBold =
  "NunitoSans-ExtraBold";