import { Box, Typography } from "@mui/material";
import { useStyles } from "./style";

export const Tag = ({ status, property }) => {
    const classes = useStyles();

    const statusColor = () => {
        switch (status) {
            case "Pending":
                return "#FF9340"
            case "Approved":
                return "#8FAEFF"
            case "Rejected":
                return "red"
            case "Completed":
                return "green"
            default:
                return "#FF9340"
        }
    }
    return (
        <Box className={classes.tagRoot} p={1} sx={{ backgroundColor: statusColor(status), overflow: "hidden" }}>
            <Typography className={classes.propertyName} noWrap>{property}</Typography>
        </Box>
    )
}