


import gql from "graphql-tag";

export const GETPROFILEDETAILS = `
query Profile($userID: String, $roleID: String) {
  user_profiles(where: { id: { eq: $userID } }, is_active: true) {
    id
    first_name
    mobile_no_country_code
    last_name
    contact_id
    contactByID {
      id
      street_1
      street_2
      street_3
      district
      country
      state
      zipcode
      latitude
      longitude
      state
    }
    image_url
    email_id
    mobile_no
    mobile_no_country_code
    alternative_mobile_no
    alternative_mobile_no_country_code
    alternative_email_id
  }
  role: client_user_roles(
    where: {
      user_profile_id: { eq: $userID }
      roles_id: { eq: $roleID }
      is_active: { eq: true }
    }
  ) {
    id
    user_profile_id
    client_role_no
    is_active
  }
}

`;
export const GETCONTACTDETAILS = `
query($contactId: ID) {
  contact(where: { id: { eq: $contactId } }) {
    id
    street_1
    street_2
    street_3
    district
    city
    country
    state
    zipcode
    latitude
    longitude
  }
}
`;
export const GET_ACTIVITY_BY_ID = `
query {
  inspection_activity(where: { id: $id }) {
    present_reading
    description
    inspection_item_mappingByID {
      id
      inspection_itemByID {
        id
        item
      }
    }
    inspection_activity_assets {
      url
      id
      file_meta
      is_active
    }
    inspection_activity_feedback {
      id
      inspection_feedback_sub_category {
        id
        name
        color
      }
      inspection_feedback_category {
        name
      }
    }
  }
}

`
export const getAgreementInspection = `
query {
  agreement_inspection(where: { id: { eq: $id } }) {
    id
    description
    signature
    status
    asset_url
  }
   agreement(where: { id: $agreement_id }) {
    id
    agreement_units(where: { unit: {
    unit_category_master:{
      id:8
    }
    } }) {
      id
      agreement_unit_users{
        contact{
          first_name
          mobile_no
          mobile_no_country_code
        }
      }
      unit {
        unit_no
        unit_type{
          name
        }
        parking_slot(where: { agreement_id: $agreement_id }) {
          id
          parking_id
          parkingByID{
            slot_no:parking_no
            id
          }
        }
      }
    }
  }
}


`
export const GET_ANNOUNCEMENT_BY_ID = `
query($id: ID) {
  notifications(where: { id: { eq: $id } }) {
    id
    title
    description
    notifications_type
    content_type
    triggered_by
    created_at
    category
    created_by {
      id
      first_name
      last_name
      get_assets_url_id
    }
  }
}`;
export const GET_AGREEMENT_PARKING_UNIT = gql`
query {
  agreement(where: { id: $id }) {
    id
    agreement_units(where: { unit: { unit_category_id:  8 } }) {
      agreement_unit_users {
        contact {
          first_name
          mobile_no
          mobile_no_country_code
          email_id
        }
      }
      id
      unit_id
      unit {
        id
        logo
        unit_no
        parking_masterByID {
          id
          parking_no
          location
          parking_slot(where: { agreement_id: $id }) {
            id
            parking_id
          }
        }
        unit_type {
          name
        }
      }
    }
  }
  unit(where:{id:$unit_id}){
     parking_masterByID {
          id
          parking_no
          location
        }
  }
}

`
export const GET_PARKING_SLOT = gql`
query {
  agreement(where: { id: $id }) {
    id
    agreement_units(where: { unit: {id:$unit_id} }) {
      id
      agreement_unit_users{
        contact{
          first_name
          mobile_no
          mobile_no_country_code
        }
      }
      unit {
        unit_no
        unit_type{
          name
        }
        parking_slot(where: { agreement_id: $id }) {
          id
          parking_id
         parkingByID{
            parking_no
            location
            id
          }
        }
      }
    }
  }
}

`
export const GETSINGLEMAINTENANCEREQUESTBYGENERALREQUESTNO = `
query($maintenanceRequestNo: ID, $referenceId: String) {
  maintenance_request(
    where: { id: { eq: $maintenanceRequestNo } }
  ) {
    id
    maintenance_request_no
    maintenance_category_master {
      id
      type:name
    }
    maintenance_sub_category_master {
      id
     type:name
    }
    subject
    description
    problem_since
    raised_on
    maintenance_request_units {
      id
      unit {
        name
      }
    }
    preferred_time
    contact_name
    contact_mobile
    contact_alternative_mobile
    maintenance_status(
      order_by: { updated_at: desc }
    )  {
      id
      created_at
      remarks
       closed_on
       type
      maintenance_status_master {
        id
        type:name
      }
      notes
    }
  }
  assets:maintenance_request_assets(where: { maintenance_request_id: { eq: $referenceId } }) {
    id
    priority
    url
  }
}
`;

export const GETUNIT_BASED_ON_PROPERTYID = gql`
query {
  unit(
    where: {
      is_active: { eq: true }
      property:{id:$propertyId}
    }
  ) {
    id
    logo
    name
  }
}
`
export const GET_UTILITY_READINGS_BYID = gql`
query {
  unit_utilties_readings(where: { id: $utilities_id }) {
    utilities_master {
      id
      utility_name
    }
    id
    present_meter_readings
    previous_meter_readings
    unit_utilties_readings_assets{
      url
      id
      file_meta
      is_active
    }
  }
}
`
