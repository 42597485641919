import { Avatar, Box, Button, Container, Divider, Grid, Stack, Typography } from "@mui/material"
import moment from "moment"
import React, { useCallback, useState } from "react"
import { withNamespaces } from "react-i18next"
import ImageViewer from "react-simple-image-viewer"
import { DialogDrawer } from "../../../components"
import { LoderSimmer } from "../../../components/simmers/card"
import { config } from "../../../config"
import { AlertContext } from "../../../contexts"
import { GETSINGLEMAINTENANCEREQUESTBYGENERALREQUESTNO } from "../../../graphql/queries"
import { NetworkCall } from "../../../networkcall"
import { AlertProps, NetWorkCallMethods } from "../../../utils"
import ReAllocateCard from "./pause&reallocate"
import { useStyles } from "./styles"

const ViewMaintenanceRequest = ({ t, next = () => false, state = {}, setStep = () => false, previous = () => false, disable = false, details = {}, setDetails = () => false }) => {
    const classes = useStyles()
    const [loading, setLoading] = useState(true)
    const [showReallocateDialog, setShowReallocateDialog] = useState(false)
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const [currentImage, setCurrentImage] = useState(0);
    const [viewAsset, setViewAsset] = React.useState([]);
    const [request, setRequest] = useState({})
    const alert = React.useContext(AlertContext)
    const [location, setLocation] = React.useState({
        lat: "",
        lng: ""
    })
    const getServiceDetails = () => {
        const payload = {
            query: GETSINGLEMAINTENANCEREQUESTBYGENERALREQUESTNO,
            variables: {
                generalRequestNo: state?.maintenance_id,
                maintenanceRequestNo: state?.maintenance_id,
                referenceId: state?.maintenance_id,
            },
        };
        NetworkCall(
            `${config.graphql_url}`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                setRequest(response.data.data);
                const assets = response.data.data?.assets.map((x) => x.url)
                setViewAsset(assets)
                setLoading(false);
            })
            .catch((error) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("SomethingWentwrong"),
                });
            });
    }

    React.useEffect(() => {
        getCurrentLocation()
        getServiceDetails()
        // eslint-disable-next-line
    }, [])

    // open image viwer
    const openImageViewer = useCallback((index) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
    }, []);
    // close image viwer
    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };
    const getCurrentLocation = () => {
        let location = null;
        if (window.navigator && window.navigator.geolocation) {
            location = window.navigator.geolocation
        }
        if (location) {
            location.getCurrentPosition(function (position) {
                setLocation({ lat: position.coords.latitude, lng: position.coords.longitude })
            })
        }
    }

    return (
        <Container maxWidth="sm" sx={{ padding: 0 }}>
            {loading ?
                <LoderSimmer count={10} />
                :
                <Box>
                    <Box className={classes.root}>
                        <Box className={classes.reqCard} m={1.5}>
                            <span className={classes.requsettop}>
                                {request?.maintenance_request[0]?.maintenance_category_master.type}
                            </span>{" "}
                            <span className={classes.requsettop}>
                                {request?.maintenance_request[0]?.maintenance_sub_category_master.type}
                            </span>
                            <Typography className={classes.requsettitle}>
                                {request?.maintenance_request[0]?.subject}
                            </Typography>
                            <Typography className={classes.requsetsub}>
                                {request?.maintenance_request[0]?.description}
                            </Typography>
                            <Box
                                display="flex"
                                // justifyContent="space-between"
                                alignItems="center"
                                marginTop="10px"
                                marginBottom="14px"
                            >
                                <Box>
                                    <Typography className={classes.requsetsubs}>
                                        {t("ProblemSince")}
                                    </Typography>
                                    <Typography className={classes.requsetsubs2}>
                                        {" "}
                                        <img src="/images/icons8_calendar.svg" alt="img" />{" "}
                                        <Box width={'4px'} />
                                        <span>
                                            {moment(
                                                request?.maintenance_request[0]?.problem_since
                                            )
                                                .tz(moment.tz.guess())
                                                .format("DD MMM YY")}
                                        </span>
                                    </Typography>
                                </Box>
                                <Box width="13px" />
                                <Box>
                                    <Typography className={classes.requsetsubs}>
                                        {t("Unit")}
                                    </Typography>
                                    <Typography className={classes.requsetsubs2}>
                                        {" "}
                                        <img src="/images/build.svg" alt="img" />{" "}
                                        <Box width={'4px'} />
                                        <span>
                                            {" "}
                                            {request?.maintenance_request[0]?.maintenance_request_units?.length > 1
                                                ? request?.maintenance_request[0]?.maintenance_request_units.length + " Units"
                                                : request?.maintenance_request[0]?.maintenance_request_units[0]?.unit.name}
                                        </span>
                                    </Typography>
                                </Box>
                                <Box width="13px" />
                                <Box>
                                    <Typography className={classes.requsetsubs}>
                                        {t("RaisedOn")}
                                    </Typography>
                                    <Typography className={classes.requsetsubs2}>
                                        {" "}
                                        <img src="/images/icons8_calendar.svg" alt="img" />{" "}
                                        <Box width={'4px'} />
                                        <span>
                                            {moment(request?.maintenance_request[0]?.raised_on)
                                                .tz(moment.tz.guess())
                                                .format("DD MMM YY")}
                                        </span>
                                    </Typography>
                                </Box>
                            </Box>
                            <Box mt={1} mb={1}>
                                <Divider />
                            </Box>
                            <Box className={classes.divider} />
                            {request?.assets != null && request?.assets.length > 0 ? (
                                <>
                                    <Grid
                                        container
                                        style={{ marginBottom: "10px", marginTop: "10px" }}
                                    >
                                        <Grid
                                            item
                                            xs={12}
                                            style={{ marginBottom: "8px", marginTop: "8px" }}
                                        >
                                            <Typography className={classes.requsetsubs}>
                                                {t("Images")}
                                            </Typography>
                                        </Grid>
                                        <Box display="flex" alignItems="center">
                                            {
                                                request?.assets?.map((src, index) => {
                                                    return (
                                                        <Box onClick={() => openImageViewer()}>
                                                            {
                                                                src?.file_meta?.file_type === "mp4" ?
                                                                    <video
                                                                        className={classes.imageStyle}

                                                                    >
                                                                        <source src={src?.url} type="video/mp4" />
                                                                    </video> :
                                                                    <img
                                                                        src={src.url}
                                                                        width="300"
                                                                        key={index}
                                                                        className={classes.imageStyle}
                                                                        alt=""
                                                                    />
                                                            }</Box>
                                                    )
                                                })
                                            }
                                        </Box>
                                    </Grid>
                                    <Box mt={1} mb={1}>
                                        <Divider />
                                    </Box>
                                </>
                            ) : (
                                <></>
                            )}
                            <>
                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    marginTop="10px"
                                    marginBottom="14px"
                                >
                                    <Box>
                                        <Typography className={classes.requsetsubs}>
                                            Preferred Visit Date And Time
                                        </Typography>
                                        <Typography className={classes.requsetsubs2}>
                                            {" "}
                                            <img
                                                src="/images/icons8_calendar.svg"
                                                alt="img"
                                            /> <Box width={'4px'} />{" "}
                                            <span>
                                                {moment(
                                                    request?.maintenance_request[0]?.preferred_time
                                                )
                                                    .tz(moment.tz.guess())
                                                    .format("DD MMM YY")}
                                            </span>
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box mt={1} mb={1}>
                                    <Divider />
                                </Box>

                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    marginTop="10px"
                                    marginBottom="14px"
                                >
                                    <Box>
                                        <Typography className={classes.requsetsubs}>
                                            {t("ContactDetails")}
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    marginTop="10px"
                                    marginBottom="14px"
                                >
                                    <Box>
                                        <Avatar className={classes.avatar} variant="square">
                                            <img src="/images/icons8_call.svg" alt="phone" />
                                        </Avatar>
                                    </Box>
                                    <Box flexGrow={1} marginLeft="8px">
                                        <Typography className={classes.requsetsubs}>
                                            {request?.maintenance_request[0]?.contact_name}
                                        </Typography>
                                        <Stack
                                            direction="row"
                                            divider={
                                                <Divider orientation="vertical" flexItem />
                                            }
                                            spacing={1}
                                        >
                                            <Typography className={classes.requsetsubs2}>
                                                {request?.maintenance_request[0]?.contact_mobile !== null &&
                                                    request?.maintenance_request[0]?.contact_mobile !== ""
                                                    ? "+91 " +
                                                    request?.maintenance_request[0]?.contact_mobile
                                                    : ""}
                                            </Typography>
                                            <Typography className={classes.requsetsubs3}>
                                                {request?.maintenance_request[0]?.contact_alternative_mobile !== null &&
                                                    request?.maintenance_request[0]?.contact_alternative_mobile !== ""
                                                    ? "+91 " +
                                                    request?.maintenance_request[0]?.contact_alternative_mobile
                                                    : ""}
                                            </Typography>
                                        </Stack>
                                    </Box>
                                </Box>
                                <Box mt={1} mb={1}>
                                    <Divider />
                                </Box>
                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    marginTop="10px"
                                    marginBottom="14px"
                                >
                                    <Box>
                                        <Typography className={classes.requsetsubs}>
                                            {t("Track")}
                                        </Typography>
                                    </Box>
                                </Box>
                                <Grid container direction={"column"} spacing={1}>
                                    <div className={classes.createnewsectionlog1}>
                                        {request?.maintenance_request?.[0]?.maintenance_status.map((item, index, array) => {
                                            return (
                                                <Box className={classes.treeBox}>
                                                    <Box className={classes.border} style={{ borderLeft: (index !== request?.maintenance_request?.[0]?.maintenance_status.length - 1) && `2px dashed #5078E1` }}>
                                                        <Avatar
                                                            variant="circular"
                                                            className={classes.avatars1}
                                                        >
                                                            <Avatar
                                                                variant="circular"
                                                                style={{
                                                                    height: "100%",
                                                                    width: "100%",
                                                                    backgroundColor: "#5078E1",
                                                                }}
                                                            >
                                                                <img
                                                                    src={
                                                                        item?.type === "Open"
                                                                            ? "images/clockWhite.svg"
                                                                            : "/images/tick.svg"
                                                                    }
                                                                    alt="document"
                                                                />
                                                            </Avatar>
                                                        </Avatar>
                                                        <Typography
                                                            className={classes.tracktitle}
                                                        >
                                                            {item?.type}
                                                        </Typography>
                                                        <Box height={'4px'} />
                                                        {
                                                            item?.notes?.length > 0 ? <>
                                                                {
                                                                    item?.notes?.map((x) => {
                                                                        return (
                                                                            <>
                                                                                <Typography
                                                                                    className={classes.notes}
                                                                                >
                                                                                    Notes: {x?.notes ?? "-"}
                                                                                </Typography>
                                                                                <Box height={'4px'} />
                                                                                <Typography
                                                                                    className={classes.dateTime}
                                                                                >
                                                                                    {moment(x?.created_at)
                                                                                        .tz(moment.tz.guess())
                                                                                        .format("YYYY-MM-DD hh:mm A")}
                                                                                </Typography>
                                                                                <Box height={'15px'} />
                                                                            </>
                                                                        )
                                                                    })
                                                                }
                                                            </> :
                                                                <>
                                                                    <Box height={'4px'} />
                                                                    <Typography
                                                                        className={classes.dateTime}
                                                                    >
                                                                        {moment(item?.created_at)
                                                                            .tz(moment.tz.guess())
                                                                            .format("YYYY-MM-DD hh:mm A")}
                                                                    </Typography></>
                                                        }

                                                    </Box>
                                                </Box>
                                            )
                                        })}
                                    </div>
                                </Grid>
                            </>

                        </Box>
                    </Box>
                    <Grid className={classes.btnRoot} container spacing={1}>
                        {disable !== true &&
                            <Grid item xs={6}>
                                <Button onClick={() => disable === true ? previous() : setShowReallocateDialog(true)} fullWidth className={classes.btn1}>{disable === true ? t("previous") : t("pause/reallocate")}</Button>
                            </Grid>
                        }
                        <Grid item xs={disable === true ? 12 : 6}>
                            <Button onClick={() => next()} fullWidth variant="contained" className={classes.next}>{t("next")}</Button>
                        </Grid>
                    </Grid>
                    {/* {Pause/Reallocate} */}
                    <DialogDrawer
                        header="Pause/Reallocate"
                        component={<ReAllocateCard location={location} setStep={setStep} state={state} setShowReallocateDialog={setShowReallocateDialog} />}
                        open={showReallocateDialog}
                        padding={0}
                        onClose={() => setShowReallocateDialog(false)}
                    />

                </Box>
            }
            {isViewerOpen && (
                <ImageViewer
                    src={viewAsset}
                    currentIndex={currentImage}
                    onClose={closeImageViewer}
                    disableScroll={false}
                    backgroundStyle={{
                        backgroundColor: "rgba(0,0,0,0.9)",
                    }}
                    closeOnClickOutside={true}
                />
            )}

        </Container>
    )
}
export default withNamespaces("inspections")(ViewMaintenanceRequest)