import { Box, Grid, Typography } from "@mui/material";
import styled from '@mui/material/styles/styled';
import React from "react";
import { AssetCard } from "./assetCard";
import { UploadBtn } from "./uploadBtn";


const CustomTypography = styled(Typography, {
    shouldForwardProp: prop => prop !== "fontFamily" && prop !== "fontSize" && prop !== "color"
})(({ fontFamily, fontSize, color }) => ({
    fontFamily,
    fontSize,
    color
}))

export const SingleUploadComponent = ({
    logo_title,
    isrequired,
    isError,
    errorMessage,
    handleChange = () => false,
    value = {},
    disable = false
}) => {


    //upload image
    const handleUpload = (value) => {
        handleChange(value)
    }
    //delete asset
    const onDelete = (i) => {
        handleChange("")
    }

    ///initial load

    return (
        <Grid container spacing={1} alignItems="flex-end">

            <Grid item xs={12}>
                {logo_title &&
                    <CustomTypography
                        fontFamily={"tenant-bold"}
                        fontSize={12}
                        margin="12px 0px 12px 0px"
                        color={"#4E5A6B"}
                    >
                        {logo_title}
                        {isrequired && (
                            <Typography variant="caption" style={{ color: "red", marginLeft: 4 }}>
                                *
                            </Typography>
                        )}
                    </CustomTypography>
                }
                {
                    value?.url ?
                        <Box>
                            <AssetCard x={value} disable={disable} onDelete={onDelete} />
                        </Box>
                        :
                        <Box>
                            {
                                !disable &&
                                < UploadBtn
                                    handleChange={handleUpload}

                                />
                            }

                        </Box>

                }

            </Grid>


            <Grid item xs={12}>
                {isError && (
                    <Typography variant={"caption"} color={"error"}>
                        {errorMessage}
                    </Typography>
                )}
            </Grid>

        </Grid >
    )
}