import { config } from "../../config";
import { NetworkCall } from "../../networkcall";
import { NetWorkCallMethods } from "../../utils";

export const getDetails = async (offset, type, search = "", status = [], property = []) => {
    const result = [];

    const payload = {
        offset: offset,
        type: type,
        search: search,
        status: status,
        property: property
    }
    await NetworkCall(
        `${config.api_url}/move-in-out/get_all`,
        NetWorkCallMethods.post,
        payload,
        null,
        true,
        false
    ).then((res) => {
        result.push(res?.data)
    }).catch((error) => {
        console.log(error)
    });

    return result?.[0]

}