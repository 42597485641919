import * as React from "react"

export const Calander = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={15} height={15} {...props}>
        <path
            data-name="icons8-calendar (5)"
            d="M3.028.5A2.537 2.537 0 0 0 .5 3.028v8.944A2.537 2.537 0 0 0 3.028 14.5h8.944a2.537 2.537 0 0 0 2.528-2.528V3.028A2.537 2.537 0 0 0 11.972.5Zm0 1.167h8.944a1.353 1.353 0 0 1 1.361 1.361v.583H1.667v-.583a1.353 1.353 0 0 1 1.361-1.361ZM1.667 4.778h11.666v7.194a1.353 1.353 0 0 1-1.361 1.361H3.028a1.353 1.353 0 0 1-1.361-1.361Zm2.528 1.556a.972.972 0 1 0 .972.972.972.972 0 0 0-.973-.973Zm3.306 0a.972.972 0 1 0 .972.972.972.972 0 0 0-.973-.973Zm3.306 0a.972.972 0 1 0 .972.972.972.972 0 0 0-.973-.973Zm-6.611 3.5a.972.972 0 1 0 .972.972.972.972 0 0 0-.974-.973Zm3.306 0a.972.972 0 1 0 .972.972.972.972 0 0 0-.974-.973Z"
            fill="#4E5A6B"
            stroke="rgba(0,0,0,0)"
        />
    </svg>
)


