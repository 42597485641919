import { Badge, Box, Dialog, Drawer, Grid, Hidden, IconButton, Stack, Typography } from "@mui/material";
import React from "react";
import { withNamespaces } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { useHistory, useLocation } from "react-router-dom";
import { CustomTabs, Filter, InspectionCard } from "../../components";
import { LoderSimmer } from "../../components/simmers/card";
import { config } from "../../config";
import { NetworkCall } from "../../networkcall";
import { Routes } from "../../router/routes";
import { enumCatagory, enumSelect, NetWorkCallMethods, RequestType } from "../../utils";
import { getDetails } from "./apiCalls";
import { useStyles } from "./styles";
import jwtDecode from "jwt-decode";
const Inspections = ({ t }) => {
  const { state } = useLocation()
  const classes = useStyles();
  const history = useHistory();
  const search = useLocation().search
  const type = new URLSearchParams(search).get("type");
  const jwt = new URLSearchParams(search).get("inspection_data");
  const inspection_data = jwt && jwtDecode(jwt)
  const [offset, setOffset] = React.useState(0)
  const [drawer, setDrawer] = React.useState(false);
  const [selectedFilter, setSelectedFilter] = React.useState({
    status: ["Assigned", "Yet To Verify"]
  });
  const [allProperties, setAllProperties] = React.useState([])
  const [statusList, setStatusList] = React.useState([])
  const [data, setData] = React.useState({
    data: [],
    loading: true,
    type: "",
    count: 0,
    openSearch: false,
    search: ""
  })
  const [TabList, setTabList] = React.useState([])
  const filterData = [
    {
      id: "1",
      title: "Status",
      key: "status",
      filterType: "CHECKBOX",
      values: statusList
    },

    {
      id: "2",
      title: "Properties",
      key: "properties",
      filterType: "CHECKBOX",
      values: allProperties,
    },
  ];
  const draweropen = () => {
    setDrawer(true);
  };
  const drawerclose = () => {
    setDrawer(false);
  };
  const getEnum = async (propertyOptions) => {
    const statusOptions = await enumSelect([enumCatagory?.agreement_inspection_status_type_new])
    setStatusList(statusOptions.agreement_inspection_status_type_new?.filter(i => i?.value !== "Yet to assign"))
  }
  const getInspectionOrdersCount = async () => {
    const payload = {}
    await NetworkCall(
      `${config.api_url}/inspector/inspection_job_count`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    ).then((res) => {
      const filteredData = res?.data?.data?.count?.filter((val) => { return val.count > 0 })
      const result = filteredData?.map((x, i) => {
        return {
          title: RequestType(x.request_type),
          value: i + 1,
          type: x.request_type
        }
      })
      setTabList(result)
    }).catch((error) => {
      console.log(error)
    });
  }
  const getAllProperties = async () => {
    const payload = {}
    await NetworkCall(
      `${config.api_url}/move-in-out/get_resource_property`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    ).then((res) => {
      if (res.data?.data?.length > 0) {
        let propertyOptions = res?.data?.data.map((x) => {
          return (
            {
              label: x.name,
              value: x.id
            }
          )
        })
        setAllProperties(propertyOptions)
        getEnum(propertyOptions)
      }
      else {
        setAllProperties([])
      }
    }).catch((error) => {
      console.log(error)
    });
  }
  const switchRoutes = (val) => {
    switch (val) {
      case "move-in":
        return Routes?.moveIn
      case "move-out":
        return Routes?.moveOut
      case "unit_handover":
        return Routes?.unitHandover
      case "service":
        return Routes?.serviceInspection
      case "site_visit":
        return Routes?.siteVisit
      case "unit_readiness":
        return Routes?.unitReadiness
      case "general":
        return Routes?.generalInspection
      default:
        return Routes?.inspections
    }
  }
  //on Click
  const onClick = (val) => {
    if (val?.is_parking_component || val?.is_parking_unit) {
      history.push({
        pathname: Routes.parkingDetails,
        state: {
          id: val?.id,
          type: val?.request_type,
          agreement_id: val?.agreement_id,
          unit_id: val?.unit_id,
          is_parking_component: val?.is_parking_component,
          is_parking_unit: val?.is_parking_unit,
          resource_id: val?.resource_id,
          reference_id: val?.reference_id,
          status: val?.status,
          property_unit_details: val

        },
      })
    }
    else {
      history.push({
        pathname: switchRoutes(val.request_type),
        state: {
          id: val?.id,
          type: val?.request_type,
          agreement_id: val?.agreement_id,
          reference_id: val?.reference_id,
          resource_id: val?.resource_id,
          unit_id: val?.unit_id,
          property_id: val?.property_id,
          status: val?.status,
          property_unit_details: val,
          maintenance_id: val?.maintenance_id
        },
      })
    }
  }
  //load data
  const loadDetails = async (offset, type, reload = false, search = "", status = [], property = []) => {
    const result = await getDetails(offset, type, search, status, property);
    setData({
      ...data,
      data: reload ? result?.data : data?.data.concat(result?.data),
      loading: false,
      type: type,
      count: result?.count,
      search: search
    })
  }
  //initial load
  React.useEffect(() => {
    getInspectionOrdersCount()
    if (state?.type) {
      loadDetails(0, state?.type?.label)
    } else if (jwt) {
      loadDetails(0, inspection_data?.request_type)
    } else if (type) {
      loadDetails(0, type)
    }
    else {
      loadDetails(0, "move-in")
    }
    getAllProperties()
    // eslint-disable-next-line
  }, [])

  //onchange tabs
  const handleChange = (value) => {
    loadDetails(0, value, true)
    setData({
      ...data,
      loading: true,
      count: 0,
      openSearch: false
    })
  }

  //onscroll
  const fetchMoreData = () => {
    setOffset(offset + 10);
    loadDetails(offset + 10, data?.type);
  };
  //filter 
  const applyFilter = (value) => {
    setSelectedFilter(value)
    loadDetails(0, data?.type, true, "", value?.status, value?.properties)
    setDrawer(false)
  }
  const activeTabFromState = (val) => {
    return val
  }
  return (
    <Box>
      <Box className={classes.root}>
        {/*tab */}
        <Box display="flex" justifyContent={"space-between"}>
          <CustomTabs handleChange={handleChange} list={TabList} activeTab={inspection_data?.request_type ?? activeTabFromState(state?.type?.value)} />
          <Box>
          </Box>
        </Box>
        {/*title */}
        <Stack p={0.5} spacing={1} alignItems="center" direction="row" justifyContent="space-between">
          <Typography className={classes.heading}>{t("inspection")}{`(${data?.count ?? 0})`}</Typography>
          {/* Filter*/}
          <Box m={1} onClick={draweropen}>
            <IconButton className={classes.filter}>
              <Badge variant="dot" color="primary">
                <img src="/images/icons8-funnel.svg" alt="filter" />
              </Badge>
            </IconButton>
          </Box>
        </Stack>
        {/*list */}
        <Box className={classes.listRoot} mt={0.5}>
          {
            data?.loading ?
              <LoderSimmer count={10} />
              :
              <>
                {data?.data?.length > 0 ?
                  <InfiniteScroll
                    dataLength={data?.data?.length ?? ""}
                    next={fetchMoreData}
                    hasMore={true}
                    height={`calc(100vh - 230px)`}
                    className={classes.scroll}
                  >
                    <Box p={1} marginTop="-20px">
                      {
                        data?.data?.map((val) => {
                          return (
                            <Box id={val?.id} marginTop="10px">
                              <InspectionCard value={val} onClick={onClick} />
                            </Box>
                          )
                        })
                      }
                    </Box>
                  </InfiniteScroll>
                  :
                  <Box mt={2}>
                    <Typography textAlign={"center"}>{t("noDataFound")}</Typography>
                  </Box>
                }
              </>
          }

        </Box>
      </Box>
      <Hidden smDown>
        <Dialog
          open={drawer}
          onClose={drawerclose}
          fullWidth={true}
          maxWidth="sm"
          className={classes.dialog}
        >
          <Grid container>
            <Grid item xs={12}>
              <Filter
                filterData={filterData}
                onGoBack={drawerclose}
                selectedList={filterData[0]}
                filters={{
                  status: selectedFilter?.status,
                  properties: selectedFilter?.properties,
                }}
                onApply={applyFilter}
                onClose={drawerclose}
                arrow={true}
              />
            </Grid>
          </Grid>
        </Dialog>
      </Hidden>
      <Hidden smUp>
        <Drawer
          className={classes.filterDrawer}
          open={drawer}
          onClose={drawerclose}
        >
          <Grid container>
            <Grid item xs={12}>
              <Filter
                filterData={filterData}
                onGoBack={drawerclose}
                selectedList={filterData[0]}
                filters={{
                  status: selectedFilter?.status,
                  properties: selectedFilter?.properties,
                }}
                onApply={applyFilter}
                onClose={drawerclose}
                arrow={true}
              />
            </Grid>
          </Grid>
        </Drawer>
      </Hidden>
    </Box>
  );
};

export default withNamespaces("inspections")(Inspections);