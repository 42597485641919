import { makeStyles } from "@mui/styles";
import { Bold, SemiBold } from "../../utils";

export const useStyles = makeStyles((theme) => ({
    root: {
        borderRadius: 12,

        marginLeft: "12px",
        "&:nth-child(1)": {
            marginLeft: "0px",
        },
    },
    dateCard: {
        backgroundColor: "#fff",
        borderRadius: 12,
        padding: "0px 20px",

    },
    date: {
        fontSize: 20,
        fontWeight: "bold",
        marginTop: "4px",
    },
    day: {
        fontSize: 12,
        fontFamily: `${Bold}`,
        color: theme.typography.color.Tertiary,
        marginBottom: "8px",
        //marginTop: "-1px",
    },
    status: {

        color: "#fff",
        fontSize: 10,
        padding: "4px 2px 4px 4px",
        borderRadius: 4,
        fontFamily: Bold,
        display: "inline",

    },
    propertyName: {
        fontSize: 16,
        fontFamily: `${Bold}`,
        marginTop: "4px",
        textTransform: "capitalize"
    },
    reference_id: {
        fontSize: 14,
        fontFamily: `${SemiBold}`,

    }
}))