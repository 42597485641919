import makeStyles from "@mui/styles/makeStyles";
import { Bold } from "../../utils";

export const TabStyles = makeStyles((theme) => ({
    header: {
        //backgroundColor: theme.palette.background.tertiary1,
        padding: "0px 12px",
        width:"100%"
        //boxShadow: "0px -1px 6px #00000021"
    },
    tabroot: {
        display: "flex",
        alignItems: "center",
    },
    tabItem: {
        textTransform: "capitalize",
        fontWeight: "bold",
        color: "#071741",
        padding: "8px 12px",
        minHeight: "auto",
        borderRadius: "20px",
        "& .MuiButtonBase-root-MuiTab-root": {
            borderRadius: "20px",
        },
        minWidth: "auto",
        marginRight: "8px",
        border: "1px solid #E4E8EE",
        backgroundColor: theme.palette.background.paper,
    },
    tabItemSelect: {
        textTransform: "capitalize",
        fontWeight: "bold",
        backgroundColor: theme.palette.primary.main,
        marginRight: "8px",
        padding: "8px 12px",
        borderRadius: "20px",
        minHeight: "auto",
        "& .MuiButtonBase-root-MuiTab-root": {
            minHeight: "0px",
            color: "white"
        },
        minWidth: "auto",
    },
    selectedlabel: {
        textTransform: "capitalize",
        fontFamily: `${Bold}`,
        color: "white",
        fontSize: "12px"
    },
    label: {
        textTransform: "capitalize",
        fontFamily: `${Bold}`,
        fontSize: "12px",
        color: theme.typography.color.primary,
    }
}))