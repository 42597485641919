import makeStyles from "@mui/styles/makeStyles";

export const WorkFlowCardStyles = makeStyles((theme) => ({
    mainsection: {
        padding: "15px",
        border: "1px solid #E4E8EE",
        borderRadius:theme.palette.borderRadius
    },
    notifyBox: {
        backgroundColor: "#FFF9EB",
        borderRadius:theme.palette.borderRadius
    },
    videobox:{
        width:"60px"
    },
    title:{
        fontSize:"14px",
        color:"#E09D00",
        fontFamily:"NunitoSans-Bold",

    },
    description:{
        fontSize:"12px",
        color:"#E09D00",
        fontFamily: "NunitoSans-Regular",

    },
    headerTitle: {
        fontSize: "10px",
        fontFamily:"NunitoSans-Bold",
        color: theme.typography.color.Tertiary,
        textTransform:"uppercase"
    },
    calendaricon: {
        backgroundColor: "#EEF9EE",
        borderRadius: theme.palette.borderRadius,
        width:"40px",
        height:"40px",
        paddingTop:"10px",
        textAlign:"center"
    },
    usericon: {
        backgroundColor: "#F1F7FF",
        borderRadius: theme.palette.borderRadius,
        width:"40px",
        height:"40px",
        paddingTop:"10px",
        textAlign:"center"
    },
    date: {
        fontSize: "14px",
        fontFamily:"NunitoSans-Bold",
        color: theme.typography.color.primary
    },
    type: {
        fontSize: "12px",
        fontFamily: "NunitoSans-Regular",
        color: theme.typography.color.secondary,
        marginTop:"3px"
    },
    startbtn:{
        boxShadow: "none",
        padding: "8px",
        textTransform: "capitalize",
        border: "1px solid #E4E8EE",
        backgroundColor: theme.palette.primary.main,
        color: "#FFFFFF",
        fontSize:"14px",
        fontFamily:"NunitoSans-Bold",
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
        }
    },
    closebtn:{
        boxShadow: "none",
        padding: "8px",
        textTransform: "capitalize",
        border: "1px solid #E4E8EE",
        backgroundColor:"#FFFFFF",
        color:theme.typography.color.primary,
        fontSize:"14px",
        fontFamily:"NunitoSans-Bold",

    },
    notifygif:{
        width:"60px",
        height:"60px"
    }
}))