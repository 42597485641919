import { useApolloClient } from "@apollo/client"
import { Box, Stack, Typography } from "@mui/material"
import React from "react"
import { CompassIcon } from "../../../assets/compassIcon"
import { DialogDrawer, UtilityForm } from "../../../components"
import { GET_UTILITY_READINGS_BYID } from "../../../graphql/queries"
import { useStyles } from "../style"
export const Step2 = ({ utilityReading = [], onSubmit = () => false, open = [], setOpen = () => false }) => {
    const classes = useStyles()
    const client = useApolloClient()
    const GetUtilityReadings = (val) => {
        client.query({
            query: GET_UTILITY_READINGS_BYID,
            fetchPolicy: 'network-only',
            variables: {
                utilities_id: val?.id,
            }
        }).then((response) => {
            const result = response?.data?.unit_utilties_readings?.map((val) => {
                return {
                    ...val,
                    utility_name: val?.utilities_master?.[0]?.utility_name,
                    assets: val?.unit_utilties_readings_assets?.map((x) => {
                        return {
                            url: x?.url,
                            is_active: x?.is_active,
                            id: x?.id ?? undefined,
                            file_meta: x?.file_meta
                        }
                    }),
                }
            })
            setOpen({ ...open, data: result?.[0], bool: true })
        }).catch((err) => {
            console.log(err)
        })
    }
    return (
        <Box>
            {utilityReading?.length > 0 ? utilityReading?.map((val) => {
                return (
                    <Box
                        mt={2}
                        onClick={() => GetUtilityReadings(val)}
                    >
                        <Box m={1} p={1} display="flex" justifyContent={"space-between"} className={classes.propertyMainblock}>
                            <Stack direction={"row"} spacing={2} alignItems="center">
                                <Box className={classes.utilitybg}><CompassIcon color={"#B20606"} /></Box>
                                <Box>
                                    <Typography className={classes.propertyName}>{val?.present_meter_readings}</Typography>
                                </Box>
                            </Stack>
                        </Box>
                    </Box>
                )
            }) : <Box mt={4}><Typography className={classes.nodata}>{"No Data Found"}</Typography></Box>}

            {/*utility drawer */}
            <DialogDrawer
                header={open?.data?.utility_name}
                component={<UtilityForm details={open?.data} onUpdate={onSubmit} />}
                open={open?.bool}
                padding={0}
                onClose={() => setOpen({ ...open, bool: false })}
            />
        </Box>
    )
}