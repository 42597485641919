import * as React from "react"

export const Inspections = (props) => (

    props?.select ?
        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
            <g data-name="Group 102840">
                <g data-name="Group 7567">
                    <path
                        data-name="icons8-submit-idea (1)"
                        d="M12 4a.614.614 0 0 0-.385.131L5.788 8.726a3.059 3.059 0 0 0-1.173 2.417v7.832A1.027 1.027 0 0 0 5.641 20h12.718a1.027 1.027 0 0 0 1.026-1.026v-7.831a3.059 3.059 0 0 0-1.17-2.417L12.38 4.131A.614.614 0 0 0 12 4Zm-.371 4.923h.686a1.917 1.917 0 0 1 1.882 1.51l.359 1.685a3.489 3.489 0 1 1-5.168 0l.356-1.675a1.922 1.922 0 0 1 1.885-1.52Zm0 .821a1.113 1.113 0 0 0-1.081.868l-.4 1.9c0 .015-.007.016 0 .01a2.663 2.663 0 0 0 1.83 4.6 2.663 2.663 0 0 0 1.831-4.6l-.405-1.906a1.114 1.114 0 0 0-1.089-.872h-.687ZM7.692 11.8a.615.615 0 1 1-.615.615.616.616 0 0 1 .615-.615Zm3.862 0h.837l.209.984a1.245 1.245 0 0 0 .357.635 1.436 1.436 0 1 1-1.97 0 1.248 1.248 0 0 0 .357-.636Zm4.753 0a.615.615 0 1 1-.615.615.616.616 0 0 1 .615-.615Zm-9.436 2.047a.615.615 0 1 1-.615.615.616.616 0 0 1 .615-.615Zm10.256 0a.615.615 0 1 1-.615.615.616.616 0 0 1 .616-.615ZM7.692 15.9a.615.615 0 1 1-.615.615.616.616 0 0 1 .615-.615Zm8.615 0a.615.615 0 1 1-.615.615.616.616 0 0 1 .615-.615Z"
                        fill="none"
                    />
                    <g data-name="Group 102839">
                        <path data-name="Rectangle 49471" fill="none" d="M0 0h24v24H0z" />
                    </g>
                </g>
                <path
                    data-name="icons8-inspection (1)"
                    d="M10.25 2a2.255 2.255 0 0 0-2.225 2H6.25A2.253 2.253 0 0 0 4 6.25v13.5A2.253 2.253 0 0 0 6.25 22h11.5A2.253 2.253 0 0 0 20 19.75V6.25A2.253 2.253 0 0 0 17.75 4h-1.775a2.255 2.255 0 0 0-2.225-2Zm0 1.5h3.5a.75.75 0 0 1 0 1.5h-3.5a.75.75 0 0 1 0-1.5Zm5 7a.75.75 0 0 1 .53 1.28l-4.5 4.5a.748.748 0 0 1-1.06 0l-2-2a.75.75 0 0 1 1.06-1.06l1.469 1.47 3.97-3.97a.748.748 0 0 1 .53-.22Z"
                    fill="#B20606"
                />
            </g>
        </svg>
        :
        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
            <g data-name="Group 102840">
                <g data-name="Group 7567">
                    <path
                        data-name="icons8-submit-idea (1)"
                        d="M12 4a.614.614 0 0 0-.385.131L5.788 8.726a3.059 3.059 0 0 0-1.173 2.417v7.832A1.027 1.027 0 0 0 5.641 20h12.718a1.027 1.027 0 0 0 1.026-1.026v-7.831a3.059 3.059 0 0 0-1.17-2.417L12.38 4.131A.614.614 0 0 0 12 4Zm-.371 4.923h.686a1.917 1.917 0 0 1 1.882 1.51l.359 1.685a3.489 3.489 0 1 1-5.168 0l.356-1.675a1.922 1.922 0 0 1 1.885-1.52Zm0 .821a1.113 1.113 0 0 0-1.081.868l-.4 1.9c0 .015-.007.016 0 .01a2.663 2.663 0 0 0 1.83 4.6 2.663 2.663 0 0 0 1.831-4.6l-.405-1.906a1.114 1.114 0 0 0-1.089-.872h-.687ZM7.692 11.8a.615.615 0 1 1-.615.615.616.616 0 0 1 .615-.615Zm3.862 0h.837l.209.984a1.245 1.245 0 0 0 .357.635 1.436 1.436 0 1 1-1.97 0 1.248 1.248 0 0 0 .357-.636Zm4.753 0a.615.615 0 1 1-.615.615.616.616 0 0 1 .615-.615Zm-9.436 2.047a.615.615 0 1 1-.615.615.616.616 0 0 1 .615-.615Zm10.256 0a.615.615 0 1 1-.615.615.616.616 0 0 1 .616-.615ZM7.692 15.9a.615.615 0 1 1-.615.615.616.616 0 0 1 .615-.615Zm8.615 0a.615.615 0 1 1-.615.615.616.616 0 0 1 .615-.615Z"
                        fill="none"
                    />
                    <g data-name="Group 102839">
                        <path data-name="Rectangle 49471" fill="none" d="M0 0h24v24H0z" />
                    </g>
                </g>
                <path
                    d="M10.25 2a2.255 2.255 0 0 0-2.225 2H6.25A2.261 2.261 0 0 0 4 6.25v13.5A2.261 2.261 0 0 0 6.25 22h11.5A2.261 2.261 0 0 0 20 19.75V6.25A2.261 2.261 0 0 0 17.75 4h-1.775a2.255 2.255 0 0 0-2.225-2Zm0 1.5h3.5a.75.75 0 0 1 0 1.5h-3.5a.75.75 0 0 1 0-1.5Zm-4 2h2.135a2.255 2.255 0 0 0 1.865 1h3.5a2.255 2.255 0 0 0 1.865-1h2.135a.739.739 0 0 1 .75.75v13.5a.739.739 0 0 1-.75.75H6.25a.739.739 0 0 1-.75-.75V6.25a.739.739 0 0 1 .75-.75Zm8.985 4.993a.75.75 0 0 0-.516.227l-3.97 3.97-1.47-1.47a.75.75 0 1 0-1.06 1.061l2 2a.75.75 0 0 0 1.061 0l4.5-4.5a.75.75 0 0 0-.545-1.287Z"
                    fill="#98a0ac"
                />
            </g>
        </svg>
)
