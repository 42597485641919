import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({

    btn: {
        padding: "8px",
        boxShadow: "none",
        textTransform: "capitalize",
        backgroundColor:theme.palette.primary.main,
        color:theme.palette.primary.contrastText,
        "&:hover":{
            backgroundColor:theme.palette.primary.main,
            color:theme.palette.primary.contrastText,
        }


    }

}))