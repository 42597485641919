import makeStyles from "@mui/styles/makeStyles";
import { Bold, SemiBold } from "../../utils";

export const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.dashboard.primary,
        height: `calc(100vh - 134px)`,
        overflow: "overlay",
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": {
            display: "none",
        },
    },
    title: {
        fontFamily: Bold,
        fontSize: "16px",
        textTransform:"capitalize"

    },
    list: {
        backgroundColor: theme.palette.background.paper,
        padding: "12px",
        boxShadow: "0px 3px 30px #5C86CB2E"
    },
    btnRoot: {
        padding: "12px",
        backgroundColor: theme.palette.background.paper,
        // boxShadow: "0px -1px 6px #00000021"

    },
    btn: {
        padding: "12px",
        textTransform: "capitalize",
        boxShadow: "none"
    },
    card: {
        backgroundColor: theme.palette.background.paper,
        padding: "12px",
        margin: "12px",
        boxShadow: "0px 3px 30px #5C86CB2E",
        borderRadius: "4px"
    },
    divider: {
        border: `1px dashed  ${theme.palette.border.secondary}`,
        marginTop: "8px",
        marginBottom: "8px"
    },

    value: {
        fontSize: "14px",
        fontFamily: SemiBold
    },
    signRoot: {

        height: "400px",
        borderRadius: "8px",
        [theme.breakpoints.up('sm')]: {
            width: "32.5rem",
        },
        [theme.breakpoints.down('sm')]: {
            width: "20.5rem",
        },
    },
    clearBtn: {
        position: "absolute",
        bottom: "20px",
        textTransform: "capitalize"
    },
    label: {
        color: theme.typography.color.Tertiary,
        fontSize: "12px",
        paddingBottom: "8px",
        fontFamily: SemiBold
    },
    image: {
        height: "400px",
        borderRadius: "8px",
        width: "100%"
    },
    progressBar: {
        backgroundColor: "#E4E8EE",
        "& .MuiLinearProgress-bar1": {
            backgroundColor: "#B20606 !important" 
        }
    },
    reallocatecard:{
        padding:"10px",
        display:"flex",
        justifyContent:"space-between",
        border: "1px solid #E4E8EE",
        marginBottom:"15px",
        borderRadius:theme.palette.borderRadius
    },
    retitle: {
        fontSize: "14px",
        fontFamily:"NunitoSans-Bold",
        color: theme.typography.color.primary
    },
    description: {
        fontSize: "12px",
        fontFamily: "NunitoSans-Regular",
        color: theme.typography.color.secondary
    },
    startbtn:{
        boxShadow: "none",
        padding: "8px",
        textTransform: "capitalize",
        backgroundColor: theme.palette.primary.main,
        color: "#FFFFFF",
        fontSize:"13px",
        fontFamily:"NunitoSans-Bold",
        borderRadius:theme.palette.borderRadius,
        whiteSpace:"nowrap",
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
            color: "#FFFFFF",
        }

    },
    closebtn:{
        boxShadow: "none",
        padding: "8px",
        textTransform: "capitalize",
        border: "1px solid #E4E8EE",
        backgroundColor:"#FFFFFF",
        color:theme.typography.color.primary,
        borderRadius:theme.palette.borderRadius,
        fontSize:"13px",
        fontFamily:"NunitoSans-Bold",

    },
    headerTitle: {
        fontSize: "10px",
        fontFamily:"NunitoSans-Bold",
        color: theme.typography.color.Tertiary,
        textTransform:"uppercase"
    },
    avatarImg:{
        borderRadius:theme.palette.borderRadius
    },
    vehicleImage:{
        borderRadius:theme.palette.borderRadius
    },
    
}));