import { makeStyles } from "@mui/styles";

export const AddressCardStyles = makeStyles((theme) => ({
    mainsection: {
        backgroundColor: "#FFFFFF",
        boxShadow: "0px 3px 30px #4250682E",
        borderRadius: theme.palette.borderRadius,
        padding: "15px"
    },
    headerTitle: {
        fontSize: "10px",
        fontFamily:"NunitoSans-Bold",
        color: theme.typography.color.Tertiary,
        textTransform:"uppercase"
    },
    calendaricon: {
        backgroundColor: "#EDE4FE80",
        borderRadius: theme.palette.borderRadius,
        width:"35px",
        height:"35px",
        paddingTop:"6px",
        textAlign:"center"
    },
    date: {
        fontSize: "14px",
        fontFamily:"NunitoSans-Bold",
        color: theme.typography.color.primary
    },
    type: {
        fontSize: "12px",
        fontFamily: "NunitoSans-Regular",
        color: theme.typography.color.secondary,
        textTransform:"capitalize"
    },
    section2:{
        backgroundColor: "#F2F4F7",
        borderRadius: theme.palette.borderRadius,
        padding:"8px"
    },
    address: {
        fontSize: "12px",
        fontFamily: "NunitoSans-SemiBold",
        color: theme.typography.color.secondary,
        marginTop:"5px",
    },
    maptext:{
        fontSize:"12px",
        fontFamily:"NunitoSans-Bold",
        color:theme.palette.primary.main,
        textAlign:"center",
        // marginTop:"10px",
        cursor:"pointer"

    },
    mapIcon:{
        width:"30px",
        height:"30px"
    }
}))