import { Box, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import { withNamespaces } from "react-i18next";
import { Appartment, Doc } from "../../assets";
import { useStyles } from './style';

const DashboardCard = ({ data = {}, t }) => {
    const classes = useStyles()


    const DashboardCardData = [
        {
            count: data?.move_in_request ?? 0,
            status: `${t("open")} ${t("move_in")}`,
            image: <Doc color="#2CAB7A" />
        },
        {
            count: data?.move_out_request?? 0,
            status: `${t("open")} ${t("move_out")}`,
            image: <Appartment color="#2881e7"/>
        },
        {
            count: data?.general_request??0,
            status: `${t("open")} ${t("other_inspections")}`,
            image: <Doc color="#50cbc7"/>
        },
        {
            count: data?.pass_scanned??0,
            status: `${t("open")} ${t("pass_scanned")}`,
            image: <Appartment color="#FF2453"/>
        },
    ]
    return (
        <Grid container spacing={2} marginTop="8px">
            {
                DashboardCardData.map(data => {
                    return (
                        <Grid item xs={6}>
                            <Box className={classes.root} p={2}>
                                <Stack direction={"row"} justifyContent={"space-between"} >
                                    <Typography className={classes.count}> {data?.count} </Typography>
                                    {data?.image}
                                </Stack>
                                <Typography className={classes.status} noWrap> {data?.status} </Typography>

                            </Box>
                        </Grid>
                    )
                })
            }
        </Grid>
    )
}

export default withNamespaces("inspections")(DashboardCard);