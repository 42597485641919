import * as React from "react";

const LocationIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={13.604} height={16} {...props}>
    <path
      d="M6.8 0a6.8 6.8 0 0 0-5.17 11.216s2.894 3.3 3.924 4.285a1.807 1.807 0 0 0 2.481 0c1.174-1.12 3.925-4.286 3.925-4.286A6.8 6.8 0 0 0 6.8 0Zm0 1.2a5.6 5.6 0 0 1 4.257 9.235c-.005.006-2.82 3.226-3.845 4.2a.585.585 0 0 1-.824 0c-.856-.817-3.836-4.193-3.845-4.2A5.6 5.6 0 0 1 6.8 1.2Zm0 3.2a2.383 2.383 0 0 0-1.8.764 2.526 2.526 0 0 0 0 3.273 2.5 2.5 0 0 0 3.6 0 2.526 2.526 0 0 0 0-3.273A2.383 2.383 0 0 0 6.8 4.4Zm0 1.2a1.106 1.106 0 0 1 .9.361 1.329 1.329 0 0 1 0 1.679 1.106 1.106 0 0 1-.9.361 1.106 1.106 0 0 1-.9-.361 1.329 1.329 0 0 1 0-1.679 1.106 1.106 0 0 1 .9-.361Z"
      fill="#4e5a6b"
    />
  </svg>
);

export default LocationIcon;
