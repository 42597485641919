import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { CompanySelect } from "../screens/welcomePage/companyselectpage";
import { LoginDetails } from "../screens/welcomePage/login";
import { OtpPage } from "../screens/welcomePage/otpPage";
import { ResetPassword } from "../screens/welcomePage/resetPassword";
import { SetupAccountDetails } from "../screens/welcomePage/setupAccountDetails";
import { AddMoveInOut, Calendar, Dashboard, GeneralInspection, Inspections, MoveInOut, NotFound, Notification, ParkingDetails, ProfileAddAddress, ProfileEdit, ProfilePage, ServiceInspection, SiteVisit, Track, UnitHandover, UnitReadiness, Utility, WelcomePage } from "./../screens";
import PrivateRouter from "./privateRouter";
import { Routes } from "./routes";
import AnnouncementDetails from "../screens/announsment/announcementDetails";
import UtilityList from "../screens/utilities/steps";
import AmenityPass from "../screens/amenityPass/amenityPass";
const RouterApp = (props) => {
  return (
    <Router>
      <Switch>
        {/* Login path */}
        <Route exact path="/">
          <LoginDetails />
        </Route>

        {/* Welcome page Route */}
        <Route exact path={Routes.welcomePage}>
          <WelcomePage />
        </Route>

        {/* SetupAccount Details Route */}
        <Route exact path={Routes.setupAccount}>
          <SetupAccountDetails />
        </Route>

        {/* Login Route */}
        <Route exact path={Routes.login}>
          <LoginDetails />
        </Route>

        {/* otp Route */}
        <Route exact path={Routes.otpPage}>
          <OtpPage />
        </Route>
        {/* otp Route */}
        <Route exact path={Routes.resetpassword}>
          <ResetPassword />
        </Route>

        {/* Dashboard */}
        <PrivateRouter exact path={Routes.dashboard}>
          <Dashboard />
        </PrivateRouter>

        {/* company select page Route */}
        <Route exact path={Routes.companyselect}>
          <CompanySelect />
        </Route>

        {/* move in  page Route */}
        <PrivateRouter exact path={Routes.moveIn}>
          <MoveInOut />
        </PrivateRouter>
        {/* move in  page Route */}
        <PrivateRouter exact path={Routes.moveOut}>
          <MoveInOut />
        </PrivateRouter>

        {/* Profile Page Route */}
        <PrivateRouter exact path={Routes.profilePage}>
          <ProfilePage />
        </PrivateRouter>

        {/* Profile Edit Route */}
        <PrivateRouter exact path={Routes.profileEdit}>
          <ProfileEdit />
        </PrivateRouter>

        {/* Profile Add Address Route */}
        <PrivateRouter exact path={Routes.profileAddAddress}>
          <ProfileAddAddress />
        </PrivateRouter>

        {/* Inspections */}
        <PrivateRouter exact path={Routes.inspections}>
          <Inspections />
        </PrivateRouter>

        {/* Track */}
        <PrivateRouter exact path={Routes.track}>
          <Track />
        </PrivateRouter>

        {/* calendar Route */}
        <PrivateRouter exact path={Routes.calendar}>
          <Calendar />
        </PrivateRouter>

        {/* add move in out */}
        <PrivateRouter exact path={Routes.addMoveInOut}>
          <AddMoveInOut />
        </PrivateRouter>
        {/* notification */}
        <PrivateRouter exact path={Routes.notification}>
          <Notification />
        </PrivateRouter>
        {/* Announcement Detials*/}
        <PrivateRouter exact path={Routes.announcement_details}>
          <AnnouncementDetails />
        </PrivateRouter>

        {/*parking unit details*/}
        <PrivateRouter exact path={Routes.parkingDetails}>
          <ParkingDetails />
        </PrivateRouter>
        {/*Service Inspection*/}
        <PrivateRouter exact path={Routes.serviceInspection}>
          <ServiceInspection />
        </PrivateRouter>
        {/*Utilities*/}
        <PrivateRouter exact path={Routes.utilities}>
          <Utility />
        </PrivateRouter>
        {/*Utilities List*/}
        <PrivateRouter exact path={Routes.utilityList}>
          <UtilityList />
        </PrivateRouter>
        {/*Amenity Pass*/}
        <PrivateRouter exact path={Routes.amenityPass}>
          <AmenityPass />
        </PrivateRouter>
        {/* unit Handover */}
        <PrivateRouter exact path={Routes.unitHandover}>
          <UnitHandover />
        </PrivateRouter>
        {/* unit Readiness */}
        <PrivateRouter exact path={Routes.unitReadiness}>
          <UnitReadiness />
        </PrivateRouter>
        {/* Site Visit */}
        <PrivateRouter exact path={Routes.siteVisit}>
          <SiteVisit />
        </PrivateRouter>
        {/*General Inspection */}
        <PrivateRouter exact path={Routes.generalInspection}>
          <GeneralInspection />
        </PrivateRouter>

        {/* For unknow/non-defined path */}
        <Route exact path="*" component={NotFound} />
      </Switch>
    </Router>
  );
};

export default RouterApp;
