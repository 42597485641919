import { Avatar, Box, Grid, Stack, Typography } from "@mui/material"
import React from "react"
import { CalendarWithTimeIcon } from "../../assets/calendarWithTimeIcon"
import moment from "moment"
import { AddressCardStyles } from "./style"
import { RemoveZUTCDateTime } from "../../utils"
import { RequestType } from "../../utils"
export const AddressCard = ({ title = "", data = {}, t, onClick = () => false }) => {
    const classes = AddressCardStyles()
    return (
        <Box className={classes.mainsection}>
            <Typography className={classes.headerTitle}>{title}</Typography>
            <Stack direction={"row"} spacing={2} mt={1}>
                <Box className={classes.calendaricon}><CalendarWithTimeIcon /></Box>
                <Box>
                    <Typography className={classes.date}>{moment(RemoveZUTCDateTime(data?.inspection_details?.execution)).format("DD-MM-YYYY HH:mm a")}</Typography>
                    <Typography className={classes.type}>{RequestType(data?.inspection_details?.request_type)}&nbsp;Inspection</Typography>
                </Box>
            </Stack>

            <Grid container alignItems="center" mt={1} className={classes.section2}>
                <Grid item xs={2} md={1}>
                    <Avatar src="/images/mapIcon.png" className={classes.mapIcon} variant="rounded" />
                </Grid>
                <Grid item xs={8} md={9}>
                    <Box display="flex" width={"90%"}>
                        {data?.property_unit_details?.street_1?.length > 0 &&
                            <Typography className={classes.address} noWrap>
                                {data?.property_unit_details?.street_1},
                            </Typography>
                        }
                        {data?.property_unit_details?.street_2?.length > 0 &&
                            <Typography className={classes.address} noWrap>
                                {data?.property_unit_details?.street_2},
                            </Typography>
                        }
                        {data?.property_unit_details?.city?.length > 0 &&
                            <Typography className={classes.address} noWrap>
                                {data?.property_unit_details?.city}.
                            </Typography>
                        }
                    </Box>
                </Grid>
                <Grid item xs={2} md={2}>
                    <Typography className={classes.maptext} onClick={() => onClick(data?.property_unit_details?.latitude, data?.property_unit_details?.longitude)}>{t("map")}</Typography>
                </Grid>
            </Grid>
            {/* <Box display={"flex"} justifyContent="space-between"  mt={1} className={classes.section2}>
            <Stack direction={"row"} spacing={1.5} flexWrap="wrap" mt={1}>
                <Box><img src="/images/mapIcon.png" alt="map" className={classes.mapIcon}/></Box>
                <Box>
                    <Typography className={classes.address} noWrap>
                        {data?.property_unit_details?.street_1},
                        {data?.property_unit_details?.street_2},
                        {data?.property_unit_details?.city}.
                        </Typography>
                </Box>
            </Stack>
            <Box mt={1.5}>
                <Typography className={classes.maptext} onClick={()=>onClick(data?.property_unit_details?.latitude,data?.property_unit_details?.longitude)}>{t("map")}</Typography>
            </Box> 
            </Box>
            */}
        </Box>
    )
}