
import { Avatar, Box, Button, Divider, Grid, IconButton, Typography } from "@mui/material";
import React from "react";
import { Item } from "../../assets";
import DeleteIcon from "../../assets/delete";
import { CartItemStyles } from "./styles";
export const CartItemCard = ({ list = "", count = "", decreaseQty = () => false, increaseQty = () => false, removeitem = () => false }) => {
    const classes = CartItemStyles()

    return (
        <Box className={`${classes.main}`}>
            <Grid container padding="12px 0px" alignItems="center">
                <Grid item xs={2}>
                    <Avatar className={classes.avatar}>
                        <Item />
                    </Avatar>
                </Grid>
                <Grid item xs={4}>
                    <Typography className={`${classes.inspectionName}`}>{list?.name}</Typography>
                    <Typography className={`${classes.itemcount}`}>{list?.count} Qty</Typography>
                </Grid>
                <Grid item xs={4} justifySelf="center">
                    <center>
                        <div className={classes.cart}>
                            <Button onClick={decreaseQty}> - </Button>
                            <Typography className={classes.cartText}>{list.count}</Typography>
                            <Button onClick={increaseQty}> + </Button>
                        </div>
                    </center>
                </Grid>
                <Grid item xs={2} >
                    <IconButton sx={{ float: "right" }} onClick={removeitem}>
                        <DeleteIcon />
                    </IconButton>
                </Grid>
            </Grid>
            <Divider className={`${classes.divider}`} />
        </Box>
    )
}