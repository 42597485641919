import CircleIcon from '@mui/icons-material/Circle';
import { Box, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { withNamespaces } from "react-i18next";
import { config } from "../../config";
import { NetworkCall } from "../../networkcall";
import { getYearList, NetWorkCallMethods, returnYearMonth, returnYearMonthGraph } from '../../utils';
import { PieCharts } from "../piechart";
import { CustomSelect } from '../selectBox';
import { useStyles } from "./styles";

const DashboardChart = ({ t }) => {
    const classes = useStyles();
    const month = new Date().getMonth()
    const [monthArray, setMonthArray] = React.useState(returnYearMonth());
    const days = returnYearMonthGraph();
    const [selectedMonth, setSelectedMonth] = React.useState(monthArray?.[month])
    const [dataType, setDataType] = React.useState("Today")
    // const [value, setValue] = React.useState(days?.[month]?.order);
    const [list, setList] = React.useState({
        data: [],
        initial: {}
    })
    const [years, setYears] = React.useState([])
    const [activeYear, setActiveYear] = React.useState(new Date().getFullYear())
    React.useEffect(() => {
        const yearlist = getYearList();
        setYears(yearlist)

    }, [])
    //get chart data
    const getGrapghData = (start_date, end_date) => {
        NetworkCall(
            `${config.api_url}/inspector/requset_stats`,
            NetWorkCallMethods.post,
            {
                start_date,
                end_date
            },
            null,
            true,
            false
        ).then((res) => {
            const data = res?.data?.data;
            const result = [
                { name: t("move_in"), value: parseInt(data?.move_in_request), fill: "#EE5188" },
                { name: t("move_out"), value: parseInt(data?.move_out_request), fill: "#A9ACFF" },
                { name: t("service"), value: parseInt(data?.general_request), fill: "#25C967" },
            ]
            setList({
                data: result,
                initial: data
            })

        }).catch((error) => {
            console.log(error)
        });
    }

    //initial load
    React.useEffect(() => {
        let current_date = new Date()
        getGrapghData(current_date, current_date)
        // eslint-disable-next-line
    }, []);

    //change tab
    // const handleChange = (val) => {
    //     setValue(val?.order);
    //     getGrapghData(val?.start, val?.end)
    // };

    const onChangeYear = (val) => {
        setActiveYear(val)
        const choosenYear = returnYearMonth(val);
        getGrapghData(choosenYear?.[month]?.start, choosenYear?.[month]?.end)
        setMonthArray(choosenYear)
    }
    const onChangeMonth = (val) => {
        console.log(val)
        setSelectedMonth(val)
        getGrapghData(val?.start, val?.end)
    }
    const changeDataType = (val) => {
        if (val === "Today") {
            let current_date = new Date()
            getGrapghData(current_date, current_date)
        }
        else if (val === "Monthly") {
            getGrapghData(days?.[month]?.start, days?.[month]?.end)
        }
        setDataType(val)
    }
    return (
        <Box className={classes.root} marginTop="12px">
            {/*type with toogle type */}
            <Box className={classes.headerRoot} p={1}>
                <Stack p={1} direction={"row"} justifyContent="space-between" alignItems="center">
                    <Typography className={classes.title}>{t("inspections")}</Typography>
                </Stack>
                <Stack direction={"row"} spacing={1} alignItems="center" className={classes.toggleSwitch}>
                    <Typography className={dataType === "Today" ? classes.activeType : classes.defaultType} onClick={() => changeDataType("Today")}>Today</Typography>
                    <Typography className={dataType === "Monthly" ? classes.activeType : classes.defaultType} onClick={() => changeDataType("Monthly")}>Monthly</Typography>
                </Stack>
            </Box>
            {/*tabs */}
            {/* <TabContext value={value}>
                <TabList
                    variant="scrollable"
                    scrollButtons
                    aria-label="scrollable auto tabs example"
                    sx={{
                        [`& .${tabsClasses.scrollButtons}`]: {
                            "&.Mui-disabled": { opacity: 0.3 },
                        },
                    }}

                    className={classes.tabRoot}>
                    {days?.map((val) => <Tab onClick={() => handleChange(val)} className={classes.tab} label={val?.label} value={val?.order} />)}
                </TabList>
            </TabContext> */}
            {/*chart container */}
            {dataType === "Monthly" &&
                <Grid container spacing={1} p={1}>
                    <Grid item xs={5} md={3} lg={3}>
                        <CustomSelect
                            type="date"
                            placeholder="Select Month"
                            icon={false}
                            options={monthArray}
                            value={selectedMonth}
                            onChange={(val) => onChangeMonth(val)}
                        />

                    </Grid>
                    <Grid item xs={5} md={3} lg={3}>
                        <CustomSelect
                            type="date"
                            placeholder="Select Year"
                            options={years}
                            icon={false}
                            value={{
                                value: activeYear,
                                label: activeYear
                            }}
                            onChange={(val) => onChangeYear(val?.value)}
                        />

                    </Grid>
                </Grid>
            }
            <Grid container spacing={1} alignItems="center" p={1}>

                <Grid item xs={6}>
                    <PieCharts
                        data={list?.data}
                        width={111}
                        total={list?.initial?.total}
                        
                    />
                </Grid>
                <Grid item xs={6}>
                    {
                        list?.data?.map((item) =>
                            <Box display="flex" alignItems="center" direction="row">
                                <Box>
                                    <CircleIcon sx={{ color: item?.fill, fontSize: 12 }} />
                                </Box>
                                <Box marginLeft="12px" flexGrow={1}>
                                    <Typography className={classes.value}>{item?.name}</Typography>
                                </Box>
                                <Box marginRight="12px">
                                    <Typography className={classes.valueAmount}>{item?.value}</Typography>
                                </Box>
                            </Box>
                        )
                    }

                </Grid>
            </Grid>



        </Box>
    )

}
export default withNamespaces("dashboard")(DashboardChart);
