import makeStyles from "@mui/styles/makeStyles";
import { SemiBold } from "../../utils";

export const useStyles = makeStyles((theme) => ({
    root: {
        //backgroundColor: theme.palette.dashboard.primary,

    },
    scroll: {
        overflow: "overlay",
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": {
            display: "none",
        },
    },
    heading: {
        fontFamily: `${SemiBold}`,
        fontSize: "16px",
        color: theme.typography.color.primary,
        marginLeft: "8px"
    },
    listRoot: {
        //boxShadow: "0px 3px 30px #5C86CB2E"
    },
    filter: {
        backgroundColor: "white",
        padding: "12px",
        borderRadius: "8px",
    },
    filterDrawer: {
        "& .MuiDrawer-paper": {
            width: "-webkit-fill-available",
        },
    },
    drawer: {
        "& .MuiPaper-root-MuiDrawer-paper": {
          // "background-color": "transparent",
          // "box-shadow": "none"
          borderTopRightRadius: "4px",
          borderTopLeftRadius: "4px",
        },
        "& .MuiContainer-root": {
          padding: "0px",
        },
      },
      filterdata: {
        padding: "8px 12px",
        fontSize: "16px",
        fontFamily:"NunitoSans-Bold",
        cursor: "pointer",
      },
      filterdataChild: {
        paddingLeft: "12px",
        fontSize: "16px",
        fontFamily:"NunitoSans-Bold",
      },
    
      filterleft: {
        borderRight: "1px solid #c1c1c1",
        [theme.breakpoints.up("sm")]: {
          minHeight: "68vh",
        },
        [theme.breakpoints.down("sm")]: {
          minHeight: "75vh",
        },
      },
}));