import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "#fff",
        borderTopLeftRadius: 8,
        borderBottomLeftRadius: 8,
    },
    avatarGroup: {
        float: "left",

        "& .MuiAvatar-root": {
            height: "34px",
            width: "34px",
            fontSize: "12px",
        }
    },
    avatar: {
        height: "34px",
        width: "34px",
        fontSize: "12px",
    },
    label:{
        fontSize:12,
        fontWeight:"bold"
    }
}))