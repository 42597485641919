import { useApolloClient } from "@apollo/client"
import Close from "@mui/icons-material/Close"
import { Avatar, Box, Divider, Grid, IconButton, InputAdornment, Stack, TextField, Typography } from "@mui/material"
import { subDays } from "date-fns"
import moment from "moment"
import React from "react"
import { withNamespaces } from "react-i18next"
import { useHistory } from "react-router-dom"
import { Calander, Search } from "../../assets"
import { UtilityIcon } from "../../assets/utilityIcon"
import { CustomTabs, DialogDrawer, LoadingSection, UtilityForm } from "../../components"
import { config } from "../../config"
import { AlertContext } from "../../contexts"
import { GET_UTILITY_READINGS_BYID } from "../../graphql/queries"
import { NetworkCall } from "../../networkcall"
import { Routes } from "../../router/routes"
import { AlertProps, NetWorkCallMethods } from "../../utils"
import { useStyles } from "./style"
const Utility = ({ t }) => {
    const classes = useStyles()
    const client = useApolloClient()
    const alert = React.useContext(AlertContext)
    const history = useHistory()
    const [searchText, setSearchText] = React.useState([])
    const [loading, setLoading] = React.useState(true)
    const [open, setOpen] = React.useState({
        bool: false,
        details: {},
        openSearch: false
    })
    const [data, setData] = React.useState({
        type: "new",
        list: [],
        count: 0,
    })
    const TabList = [
        {
            title: `${t("new")}`,
            value: 1,
            type: "new"
        },
        {
            title: `${t("todays_reading")}`,
            value: 2,
            type: "today"
        },
        {
            title: `${t("yesterdays_reading")}`,
            value: 3,
            type: "yesterday"
        }
    ]
    const getPropertyList = (searchValue = "", type) => {
        const payload = {
            search: searchValue
        }
        NetworkCall(
            `${config.api_url}/inspector/get_property`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((res) => {
            setLoading(false)
            setData({ ...data, list: res?.data?.data, count: res?.data?.data?.length, type: type })
        }).catch((error) => {
            setLoading(false)
            console.log(error)
        });
    }
    const getPropertyListBasedOnDate = (selectedDate = new Date(), type) => {
        const payload = {
            "date": moment(selectedDate).format("YYYY-MM-DD")
        }
        NetworkCall(
            `${config.api_url}/unit_meter_reading/reading_stats`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((res) => {
            setLoading(false)
            setData({
                ...data, list: res?.data?.data?.reading, count: res?.data?.data?.reading?.length, type: type
            })
        }).catch((error) => {
            setLoading(false)
            console.log(error)
        });
    }
    React.useEffect(() => {
        if (data?.type === "new") {
            getPropertyList(searchText, data?.type)
        }
        //eslint-disable-next-line
    }, [])
    const handleTabChange = (value) => {
        setData({ ...data, type: value })
        if (value === "today" || value === "yesterday") {
            getPropertyListBasedOnDate(value === "today" ? new Date() : subDays(new Date(), 1), value)
        }
        else {
            getPropertyList(searchText, value)
        }
    }
    const handleSearch = (val) => {
        setSearchText(val)
        getPropertyList(val, data?.type)
    }
    const handleopenSearch = () => {
        setOpen({ ...open, openSearch: !open?.openSearch })
        setSearchText("")
        getPropertyList("", data?.type)
    }
    const goToUtilityList = (val) => {
        history.push({
            pathname: Routes.utilityList,
            state: {
                property_id: val?.id,
                property_name: val?.name
            }
        })
    }
    const GetUtilityReadings = (val) => {
        client.query({
            query: GET_UTILITY_READINGS_BYID,
            fetchPolicy: 'network-only',
            variables: {
                utilities_id: val?.id,
            }
        }).then((response) => {
            const result = response?.data?.unit_utilties_readings?.map((val) => {
                return {
                    ...val,
                    utility_name: val?.utilities_master?.[0]?.utility_name,
                    assets: val?.unit_utilties_readings_assets?.map((x) => {
                        return {
                            url: x?.url,
                            is_active: x?.is_active,
                            id: x?.id ?? undefined,
                            file_meta: x?.file_meta
                        }
                    }),
                }
            })
            setOpen({ ...open, details: result?.[0], bool: true })
        }).catch((err) => {
            console.log(err)
        })
    }
    const onSubmitData = (data) => {
        const payload = {
            data: {
                "present_meter_readings": data?.present_meter_readings,
                "assets": data?.assets,
                "id": data?.id
            }
        }
        NetworkCall(
            `${config.api_url}/unit_meter_reading/upsert`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((res) => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.success,
                msg: res?.data?.data,
            });
            setOpen({ ...open, bool: false })
            getPropertyListBasedOnDate(data?.type === "today" ? new Date() : subDays(new Date(), 1))
        }).catch((error) => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: "Something Went Wrong",
            });
        });
    }
    return (
        <Box>
            <Box className={classes.tabSection}>
                <CustomTabs handleChange={handleTabChange} list={TabList} />
            </Box>
            {/*title */}
            {loading ?
                <LoadingSection top="10vh" message={"Loading Properties"} />
                :
                <>
                    <Stack m={1} spacing={1} alignItems="center" direction="row" justifyContent="space-between">
                        <Typography className={classes.heading}>{data?.type === "new" ? t("properties") : t("updatedReadings")}{data?.type === "new" ? `(${data?.count})` : ""}</Typography>
                        {
                            open?.openSearch ?
                                <TextField
                                    size="small"
                                    sx={{ backgroundColor: "white" }}
                                    value={searchText}
                                    onChange={(e) => handleSearch(e.target.value)}
                                    InputProps={{
                                        endAdornment:
                                            < InputAdornment position="end" >
                                                <IconButton size="small" onClick={handleopenSearch}>
                                                    <Close fontSize="small" />
                                                </IconButton>
                                            </InputAdornment>

                                    }}

                                />
                                :
                                data?.type === "new" && <IconButton onClick={handleopenSearch} size="small">
                                    <Search />
                                </IconButton>

                        }

                    </Stack>
                    {data?.type === "new" &&
                        data?.list?.length > 0 ? data?.list?.map((val) => {
                            return (
                                <Box p={1} onClick={() => goToUtilityList(val)}>
                                    <Stack direction={"row"} spacing={2} alignItems="center" className={classes.propertyblock}>
                                        <Avatar variant="square" src={val?.logo} className={classes?.logoImg} />
                                        <Typography className={classes.propertyName}>{val?.name}</Typography>
                                    </Stack>
                                </Box>
                            )
                        })
                        : data?.type === "new" && <Box mt={4}><Typography className={classes.nodata}>{t("noDataFound")}</Typography></Box>

                    }
                    {(data?.type === "today" || data?.type === "yesterday") && data?.list?.length > 0 ? data?.list?.map((val) => {
                        return (
                            <Box m={1} className={classes.propertyMainblock} onClick={() => { data?.type === "today" && GetUtilityReadings(val) }}>
                                <Grid container spacing={1}>
                                    <Grid item xs={2.5}>
                                        <Box className={classes.utilitybg}><UtilityIcon color={"#B20606"} /></Box>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Box display="flex" justifyContent={"space-between"}>
                                            <Typography className={classes.propertyName}>{val?.present_meter_readings}</Typography>
                                            <Box className={classes.issueName}>
                                                <Typography className={classes.issuetitle}>{val?.utility_name}</Typography>
                                            </Box>
                                        </Box>
                                        <Stack direction="row"
                                            divider={<Divider orientation="vertical" flexItem />}
                                            spacing={1} alignItems="center" mt={1}>
                                            <Stack direction="row" spacing={1}>
                                                <Calander />
                                                <Typography className={classes.readingdate} alignItems="center">{moment(val?.date).format("DD-MMM-YY")}</Typography>
                                            </Stack>
                                            <Typography className={classes.readingdate}>{val?.property_name}</Typography>
                                        </Stack>
                                    </Grid>


                                </Grid>
                            </Box>
                        )
                    })

                        : (data?.type === "today" || data?.type === "yesterday") && <Box mt={4}><Typography className={classes.nodata}>{t("noDataFound")}</Typography></Box>

                    }
                </>
            }
            {/*utility drawer */}
            <DialogDrawer
                header={open?.data?.utility_name}
                component={<UtilityForm details={open?.details} onUpdate={onSubmitData} />}
                open={open?.bool}
                padding={0}
                onClose={() => setOpen({ ...open, bool: false })}
            />
        </Box>
    )
}
export default withNamespaces("inspections")(Utility)