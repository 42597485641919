import React from "react";
import { withBottombar } from "../../HOCs";
import { CustomCalendar } from "./customCalendar";


class CalendarParent extends React.Component {
  render() {
    return <CustomCalendar />
  }
}
const props = {
  titleBar: true,
  title: "Calendar",
  goBack: true
}
export default withBottombar(CalendarParent, props);
