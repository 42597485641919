import makeStyles from "@mui/styles/makeStyles";
import { Bold, Regular } from "../../utils";

export const ItemListStyles = makeStyles((theme) => ({
    ItemCard: {
        backgroundColor: theme.palette.background.tertiary1,
        padding: "15px 15px 0px 15px",
    },
    cardlist: {
        display: "flex",
        justifyContent: "space-between",
        margin: "0px 0px 15px 0px",
    },
    image: {
        width: "40px",
        height: "40px",
        borderRadius: theme.palette.borderRadius
    },
    itemName: {
        fontSize: "14px",
        fontFamily: `${Bold}`,
        color: theme.typography.color.primary
    },
    subtext: {
        fontSize: "12px",
        fontFamily: `${Regular}`,
        color: theme.typography.color.secondary
    },
    addbtn: {

        width: "100%",
        textTransform: "capitalize",
        color:theme.palette.primary.main,
        background: "transparent",
        border:`1px solid ${theme.palette.primary.main}`,
        "&:hover":{
            background: "transparent",
            border:`1px solid ${theme.palette.primary.main}`
        }
    },
    dividertext: {
        marginBottom: "15px"
    },
    checkIcon: {
        marginTop: "12px"
    },
    avatar: {
        borderRadius: "4px",
        backgroundColor: "#F4F4F4"
    }
}))