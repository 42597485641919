import { Box, Button, Grid, Typography } from "@mui/material";
import React , {useState} from "react";
import { withNamespaces } from "react-i18next";
import { useLocation } from "react-router-dom";
import { DialogDrawer, TextBox } from "../../../components";
import { config } from "../../../config";
import { NetworkCall } from "../../../networkcall";
import { NetWorkCallMethods } from "../../../utils";
import { SlotCard } from "../components";
import { useStyles } from "../styles";
import ReAllocateCard from "./pause&reallocate"

const SlotSelection = ({ t, setStep = () => false, updateState = () => false, data = {}, stepData = {}, disable = false }) => {

    const classes = useStyles();
    const [slots, setSlots] = React.useState([]);
    const { state } = useLocation()
    const [showReallocateDialog, setShowReallocateDialog] = useState(false)
    const [location, setLocation] = React.useState({
        lat: "",
        lng: ""
    })


    //initial load
    React.useEffect(() => {
        getCurrentLocation()

        // eslint-disable-next-line
    }, [])
    const getCurrentLocation = () => {
        let location = null;
        if (window.navigator && window.navigator.geolocation) {
            location = window.navigator.geolocation
        }
        if (location) {
            location.getCurrentPosition(function (position) {
                setLocation({ lat: position.coords.latitude, lng: position.coords.longitude })
            })
        }
    }
    const getParkingSlots = (search = "") => {
        const payload = {
            unit_id: state?.unit_id,
            agreement_id: state?.agreement_id,
            search: search
        }
        NetworkCall(
            `${config.api_url}/parking_unit/get_parking_units`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((res) => {
            setSlots(res?.data?.data)
        }).catch((err) => {
            console.log(err)
        })
    }

    React.useEffect(() => {
        getParkingSlots()
        // eslint-disable-next-line
    }, [])



    //on click card 
    const onClick = (val) => {
        setStep(3, stepData)
    }


    return (
        <Box>

            <Box className={classes.root}>
                <Box p={1}>
                    <TextBox
                        color="white"
                        label={null}
                        onChange={(e) => getParkingSlots(e.target.value)}
                        placeholder={t("search")}

                    />
                    <Box height="10px" />
                    <Grid container spacing={1}>
                        {
                            slots?.length > 0 ?
                                slots?.map((val) => {
                                    return (
                                        <Grid item xs={3} sm={2} md={2} lg={2}>
                                            <SlotCard value={data?.slot} onClick={(value) => updateState("slot", value)} data={val} title={t("available")} selectedTitle={t("selected")} />
                                        </Grid>
                                    )
                                })
                                :
                                <Grid item xs={12}>
                                    <Typography textAlign="center" className={classes.title}>{t("contact_manager")}</Typography>
                                </Grid>

                        }

                    </Grid>

                </Box>


            </Box>

            {/* <Box className={classes.btnRoot}>
                <Button
                    fullWidth
                    className={classes.btn}
                    variant="contained"
                    disabled={!data?.slot?.id}
                    onClick={onClick}
                    sx={{ backgroundColor: "#B20606" }}
                >
                    {t("preview")}
                </Button>

            </Box> */}
            {disable ?
                <Box className={classes.btnRoot}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Button
                                fullWidth
                                className={classes.btn}
                                sx={{ backgroundColor: "#B20606" }}
                                variant="contained"
                                onClick={()=>setStep(3,stepData)}
                            >
                                {t("next")}
                            </Button>
                        </Grid>

                    </Grid>

                </Box>
                :
                <Grid className={classes.btnRoot} container spacing={1}>
                    <Grid item xs={6}>
                        <Button onClick={() => setShowReallocateDialog(true)} fullWidth className={classes.closebtn}>{t("pause/reallocate")}</Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            fullWidth
                            className={classes.startbtn}
                            variant="contained"
                            disabled={!data?.slot?.id}
                            onClick={onClick}
                            sx={{ backgroundColor: "#B20606" }}
                        >
                            {t("next")}
                        </Button>
                    </Grid>
                </Grid>
            }
              {/* {Pause/Reallocate} */}
              <DialogDrawer
                header="Pause/Reallocate"
                component={<ReAllocateCard location={location} setStep={setStep} state={state} setShowReallocateDialog={setShowReallocateDialog} />}
                open={showReallocateDialog}
                padding={0}
                onClose={() => setShowReallocateDialog(false)}
            />
        </Box>
    );
};

export default withNamespaces("inspections")(SlotSelection);