import { makeStyles } from "@mui/styles";

export const DetailsCardStyles = makeStyles((theme) => ({
    mainsection: {
        backgroundColor: "#FFFFFF",
        boxShadow: "0px 3px 30px #4250682E",
        borderRadius: theme.palette.borderRadius,
    },
    headerTitle: {
        fontSize: "10px",
        fontFamily:"NunitoSans-Bold",
        color: theme.typography.color.Tertiary,
        textTransform:"uppercase",
        padding:"15px 15px 0px 15px"
    },
    refId: {
        fontSize: "14px",
        fontFamily:"NunitoSans-Bold",
        color: theme.typography.color.primary,
        textTransform:"capitalize"
    },
    propertyName: {
        fontSize: "12px",
        fontFamily: "NunitoSans-Regular",
        color: theme.typography.color.secondary,
        overflow:"hidden",
        textOverflow:"ellipsis",
        whiteSpace:"noWrap",
        width:"200px",
        marginTop:"3px"
    },
    section2:{
        backgroundColor: "#EDE4FE80",
        borderRadius: theme.palette.borderRadius,
    },
    address: {
        fontSize: "12px",
        fontFamily: "NunitoSans-SemiBold",
        color: theme.typography.color.secondary
    },
    maptext:{
        fontSize:"12px",
        fontFamily:"NunitoSans-Bold",
        color:theme.palette.primary.main,
        textAlign:"center",
        marginTop:"10px"

    },
    phoneIcon:{
        width:"35px",
        height:"35px",
        border:"1px solid #E4E8EE",
        borderRadius:theme.palette.borderRadius,
        textAlign:"center",
        paddingTop:"9px",
        marginTop:"5px"
    },
    statusbox:{
        backgroundColor:"#FFE6C9",
        padding:"5px",
        height:"25px",
        borderRadius:theme.palette.borderRadius
    },
    status:{
        fontSize:"8px",
        color:"#E29336",
        fontFamily:"NunitoSans-Bold",
        textAlign:"right",
        textTransform:"capitalize"
    },
    name:{
        fontSize: "12px",
        fontFamily: "NunitoSans-Regular",
        color: theme.typography.color.secondary,
        marginLeft:"5px",
    },
    avatarImg:{
        width:"40px",
        height:"40px",
        borderRadius:theme.palette.borderRadius
    }
}))