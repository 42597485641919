import { Box } from "@mui/material";
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import React from "react";
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 4,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: "#FFFFFF"
    },
    // [`& .${linearProgressClasses.bar}`]: {
    //     backgroundColor: theme.palette.mode === 'light' ? '#091B29' : '#308fe8',
    // },
}));
export const Stepper = ({ stepCount = 1, totalCount = "", title = "", progressValue = "30" }) => {
    // const classes = stepperStyles()
    return (
        <>
            {/* <Box className={`${classes.stepperHeader}`}>
                <Box className={`${classes.stepperCount}`}>
                    <Typography className={`${classes.number}`}>{stepCount}</Typography>
                </Box>
                <Box className={`${classes.stepperSubheader}`}>
                    <Typography className={`${classes.stepdetails}`}>Step {stepCount} / {totalCount}</Typography>
                    <Typography className={`${classes.title}`}>{title}</Typography>

                </Box>
            </Box> */}
            <Box>
                <BorderLinearProgress variant="determinate" value={progressValue} color="error" />
            </Box>
        </>
    )
}