import { Box, Button, TextField } from "@mui/material";
import { withNamespaces } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { GeneralCard } from "../generalCard";
import { useStyles } from "./styles";

const Step1 = ({ list = [], changeInspactionItems = () => false, addInspectionItem = () => false,
    changeStep = () => false,
    fetchMoreData = () => false
}) => {
    const classes = useStyles()
    return (
        <Box p={1}>
            <TextField
                size="small"
                fullWidth
                placeholder="Search Inspection Items"
                className={classes.textBox}
            />
            <Box className={classes.itemRoot}>
                <InfiniteScroll
                    dataLength={list?.length ?? ""}
                    next={fetchMoreData}
                    hasMore={true}
                    height={`calc(100vh - 290px)`}
                >
                    {list.map((val) => {
                        return (
                            <GeneralCard
                                list={val}
                                buttonText="Add"
                                handleChange={() => changeInspactionItems(val?.id, true)}
                                increaseQty={() => addInspectionItem(val?.id, true)}
                                decreaseQty={() => addInspectionItem(val?.id, false)}
                                component={"increment"}
                            />

                        )

                    })}
                </InfiniteScroll>
            </Box>
            <Box className={classes.btnRoot} marginTop="12px">
                <Button onClick={changeStep} className={classes.btn} fullWidth variant="contained">
                    Continue
                </Button>
            </Box>

        </Box>
    )
}
export default withNamespaces("inspections")(Step1);