import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from 'react';
import Datepicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './datepicker.css';
const useStyles = makeStyles((theme) => ({
      Label: {
            color: theme.typography.color.Tertiary,
            fontSize: "12px",
            paddingBottom: "8px"
      },
      text: {},

      textbox: {
            height: (props) => (props.multiline ? "unset" : 50),
            width: '100%',
            borderRadius: "10px",
            border: '1.5px solid #E2E2E2',
            cursor: 'pointer',
            [`& fieldset`]: {
                  borderRadius: "10px",
                  height: (props) => (props.multiline ? "unset" : 50),
                  border: "1px solid #CED3DD",
                  "& :hover": {
                        border: "1px solid #5078E1",
                  },
            },
            "& .MuiOutlinedInput-input": {
                  padding: (props) => (props.multiline ? "0px" : "11px 14px"),
            },
            ":-webkit-autofill": {
                  WebkitBoxShadow: "none !important",
                  backgroundColor: "red !important",
            },
            '&:focusVisible': {
                  border: '1.5px solid #E2E2E2',
            },
            padding: '24px'
      },
      calander: {
            position: 'absolute',
            top: "55%",
            right: '15px',

      }
}));

export const DatePickerNew = (props) => {
      const classes = useStyles(props);
      const getLabel = (props) => {
            return (
                  <div style={{ display: "flex" }}>
                        {
                              <Typography
                                    variant="body1"
                                    color="textsecondary"
                                    className={classes.Label}
                                    align="left"
                              >
                                    {props?.label}
                              </Typography>
                        }
                        {props?.isRequired && (
                              <Typography color="error" variant="caption">
                                    &nbsp;*
                              </Typography>
                        )}
                  </div>
            );
      };
      return (
            <>
                  <div style={{ position: 'relative' }}>
                        {getLabel(props)}
                        <Datepicker
                              selected={props?.value}
                              onChange={date => props?.onChange(date)}
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              className={classes.textbox}
                              placeholderText={props?.placeholder}
                              minDate={props?.minDate ?? false}
                              maxDate={props?.maxDate ?? false}
                              onKeyDown={(e) => {
                                    e.preventDefault();
                              }}
                        />
                        <div className={classes.calander}>
                              <img src={'/images/icons8-calendar (5).svg'} alt="" />
                        </div>
                  </div>
                  {props.isError && (
                        <Typography variant={"caption"} color={"error"}>
                              {props.errorMessage}
                        </Typography>
                  )}
            </>
      )
}