import { Box, Button, Typography } from "@mui/material";
import { useStyles } from "./styles";

export const ConditionBtn = ({ options = [], value = {}, label = null, isError = false, errorMessage = null, onChange = () => false, isReadOnly = false, data = [] }) => {
    const classes = useStyles();
    const getColor = (value) => {
        return {
            color: data?.filter((x) => { return x.id === value })?.[0].color
        }
    }

    return (
        <Box>
            {
                label && <Typography className={classes.Label}>{label}</Typography>
            }

            <Box display="flex" flexWrap="wrap" alignItems="center" mb={1}>
                {
                    options?.map((val) => {
                        return (
                            <Button 
                                variant={value === val?.id ? "contained" : "outlined"}
                                onClick={() => {
                                    if (!isReadOnly) {
                                        onChange(val)
                                    }
                                }}
                                color="error"
                                sx={{
                                    textTransform: "capitalize",
                                    backgroundColor: value === val?.id ? getColor(val?.id)?.color : "auto",
                                    "&:hover": {
                                        backgroundColor: value === val?.id ? getColor(val?.id)?.color : "auto",
                                    },
                                    border:value === val?.id ? "auto" : "1px solid #E4E8EE",
                                    marginLeft: "4px",
                                    boxShadow: "none",
                                    color: value === val?.id ? "auto" : "#091B29",
                                    marginBottom:options?.length>3?"8px":0
                                }}
                            >{val?.name}</Button>
                        )
                    })
                }

            </Box>
            {isError && (
                <Typography variant={"caption"} color={"error"}>
                    {errorMessage}
                </Typography>
            )}
        </Box>
    )
}