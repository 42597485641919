import { Box, Skeleton } from "@mui/material";
import { LoderSimmer } from "./card";

export const ParkingUnit = () => {
    return (
        <Box bgcolor="#F2F5FA">
            <Box padding="0px 12px" >
                <Skeleton height={120} />
            </Box>
            <Box bgcolor="white">
                <Skeleton height={50} sx={{ margin: "0px 12px" }} />
                <LoderSimmer count={5} />
            </Box>
        </Box>
    )
}