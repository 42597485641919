import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { withNamespaces } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import SignaturePad from 'react-signature-canvas';
import { DialogDrawer } from "../../../components";
import { Routes } from "../../../router/routes";
import ReAllocateCard from "./pause&reallocate"
import { useStyles } from "../styles";

const Signature = ({
    next = () => false,
    details = {},
    disable,
    t,
    type = "",
    setStep = () => false,
    updateState = () => false
}) => {
    const classes = useStyles();
    const { state } = useLocation()
    const sigCanvas = React.useRef({});
    const history = useHistory();
    const [showReallocateDialog, setShowReallocateDialog] = useState(false)
    const [location, setLocation] = React.useState({
        lat: "",
        lng: ""
    })



    //initial load
    React.useEffect(() => {
        getCurrentLocation()

        // eslint-disable-next-line
    }, [])
    const getCurrentLocation = () => {
        let location = null;
        if (window.navigator && window.navigator.geolocation) {
            location = window.navigator.geolocation
        }
        if (location) {
            location.getCurrentPosition(function (position) {
                setLocation({ lat: position.coords.latitude, lng: position.coords.longitude })
            })
        }
    }

    const onSubmit = () => {
        if (disable) {
            if (state?.status === "Completed") {
                setStep(8)
            }
            else {
                history.push(Routes.inspections)
            }
        } else {
            if (sigCanvas.current)
                updateState("url", sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"))
            setStep(8)
            // next(sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"))

        }

    }

    const handleClick = () => {
        sigCanvas.current.clear();
    }



    return (
        <Box>
            <Box p={2} className={classes.root}>
                <Typography className={classes.label}>{t("signature")}</Typography>
                {
                    details?.url ?
                        <Box>
                            <img className={classes.image} src={details?.url} alt="" />
                        </Box>
                        :
                        <Box position="relative">

                            <SignaturePad
                                canvasProps={{ className: classes.signRoot }}
                                ref={sigCanvas}
                                backgroundColor="white"
                                penColor="black"

                            />
                            <center>
                                <Box display="flex" justifyContent="center">
                                    <Button onClick={handleClick} className={classes.clearBtn}>Clear</Button>
                                </Box>
                            </center>
                        </Box>

                }

            </Box>

            <Grid className={classes.btnRoot} container spacing={1}>
                {!disable &&
                    <Grid item xs={6}>
                        <Button onClick={() => setShowReallocateDialog(true)} fullWidth className={classes.closebtn}>{t("pause/reallocate")}</Button>
                    </Grid>
                }
                <Grid item xs={disable ? 12 : 6}>
                    {/* <Button onClick={onSubmit} fullWidth variant="contained" className={classes.startbtn}>{t("complete")}</Button>
                         */}
                    <Button onClick={onSubmit} fullWidth variant="contained" className={disable ? classes.btn : classes.startbtn} sx={{ backgroundColor: "#B20606" }}>
                        {disable ? (state.status === "Completed" ? t("next") : `${t("done")}`) : t("complete")}
                    </Button>
                </Grid>
            </Grid>

            {/* {Pause/Reallocate} */}
            <DialogDrawer
                header="Pause/Reallocate"
                component={<ReAllocateCard location={location} setStep={setStep} state={state} setShowReallocateDialog={setShowReallocateDialog} />}
                open={showReallocateDialog}
                padding={0}
                onClose={() => setShowReallocateDialog(false)}
            />
        </Box>
    )
}
export default withNamespaces("inspections")(Signature);