import { Box, Button, Stack, Typography } from "@mui/material";
import React from "react";
import { withNamespaces } from "react-i18next";
import { UploadComponent } from "../assetUpload";
import { TextBox } from "../textbox";
import { useStyles } from "./styles";

const UtilityForm = ({
    t,
    onUpdate = () => false,
    disable = false,
    details = {}
}) => {
    const classes = useStyles();
    const [data, setData] = React.useState({
        present_meter_readings: details?.present_meter_readings,
        assets: details?.assets,
        id: details?.id
    });


    const updateState = (key, value) => {
        setData({ ...data, [key]: value });
    };

    //on submit
    const onSubmit = () => {
        onUpdate(data)
    }

    return (
        <Box>
            <Box height="450px" overflow="auto">
                <Box p={1.5}>
                    <Stack direction="row" justifyContent="space-between" className={classes.detailRoot}>
                        <Typography className={classes.detailTitle}>{details?.utility_name}</Typography>
                        <Typography className={classes.rating}>{details?.previous_meter_readings}</Typography>
                    </Stack>
                </Box>
                <Box height="10px" sx={{ borderTop: "4px solid #F5F7FA" }} />
                <Box p={1.5}>
                    <TextBox
                        label={t("present_reading")}
                        placeholder={`${t("enter")} ${t("present_reading")}`}
                        value={data?.present_meter_readings}
                        onChange={(e) => updateState("present_meter_readings", e.target.value)}
                        type="number"
                    />
                    <Box height="12px" />
                    <UploadComponent
                        setAssets={(value) => updateState("assets", value)}
                        assets={data?.assets ?? []}
                        xs={4}
                        disable={disable}
                        logo_title="Upload Images"
                    />
                </Box>

            </Box>
            <Box sx={{ borderTop: "1px solid #E4E8EE", paddingTop: "12px" }} p={1.5}>
                <Button className={classes.btn} disabled={disable} onClick={onSubmit} fullWidth variant="contained">Update</Button>
            </Box>
        </Box>
    )
}
export default withNamespaces("inspections")(UtilityForm);