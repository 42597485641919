import { Avatar, Box, Stack, Typography } from "@mui/material";
import { Parking } from "../../assets";
import { useStyles } from "./styles";
export const ParkingDetailCard = ({ data, bg = true }) => {
    const classes = useStyles();
    return (
        <Box className={bg ? classes.root : null}>
            <Stack direction="row" alignItems="center" spacing={1}>
                <Avatar className={classes.avatar}>
                    <Parking />
                </Avatar>
                <Box>
                    <Typography className={classes.title}>{data?.title}</Typography>
                    <Typography className={classes.subTitle}>{data?.subTitle}</Typography>

                </Box>
            </Stack>

        </Box>
    )
}