import * as React from "react"

export const ResumeIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
        <g id="Group_107152" data-name="Group 107152" transform="translate(-28 -273)">
            <rect id="Rectangle_55704" data-name="Rectangle 55704" width="32" height="32" rx="4" transform="translate(28 273)" fill="#f1f7ff" />
            <path id="icons8-pause-button" d="M11.92,4a8,8,0,1,0,8,8A8,8,0,0,0,11.92,4ZM9.952,8.8a.8.8,0,0,1,.8.8v4.8a.8.8,0,1,1-1.6,0V9.6A.8.8,0,0,1,9.952,8.8Zm4,0a.8.8,0,0,1,.8.8v4.8a.8.8,0,1,1-1.6,0V9.6A.8.8,0,0,1,13.952,8.8Z" transform="translate(32.08 277)" fill="#78b1fe" />
        </g>
    </svg>

)