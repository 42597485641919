import { Box, IconButton, Stack, Typography } from "@mui/material";
import React from "react";
import { withNamespaces } from "react-i18next";
import { Search } from "../../assets";
import { TrackCard } from "../../components";
import { trackerData } from "../../utils/inspections";
import { useStyles } from "./styles";
const Track = ({ t }) => {

  const classes = useStyles();


  return (
    <Box>
      <Box className={classes.root}>

        {/*title */}
        <Stack p={1} alignItems="center" direction="row" justifyContent="space-between">
          <Typography className={classes.heading}>{t("tracking")} (04)</Typography>
          <IconButton size="small">
            <Search />
          </IconButton>
        </Stack>
        {/*list */}
        {
          trackerData?.map((val) => <TrackCard value={val} />)
        }

      </Box>
    </Box>
  );
};

export default withNamespaces("track")(Track);