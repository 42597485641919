import makeStyles from "@mui/styles/makeStyles";
import { Bold, SemiBold } from "../../utils";

export const useStyles = makeStyles((theme) => ({

    btn: {
        padding: "8px",
        boxShadow: "none",
        textTransform: "capitalize",
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
        }


    },
    detailTitle: {
        fontSize: "14px",
        fontFamily: Bold
    },
    detailRoot: {
        backgroundColor: "#F5F7FA",
        borderRadius: "10px",
        padding: "12px",
        marginBottom: "10px"
    },
    rating: {
        fontSize: "14px",
        fontFamily: SemiBold,
        color: "#4E5A6B"
    }

}))