import { Box, Button, Container, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import jwt_decode from "jwt-decode";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { TextBox } from "../../components";
import { PasswordInfo } from "../../components/passwordInfo";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { Routes } from "../../router/routes";
import { AlertProps, Message, NetWorkCallMethods } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";

const useStyles = makeStyles((theme) => ({
  backgroundImage: {
    backgroundColor: "#7EA1FF",
    height: "213px",
    backgroundImage: `url("/images/bgNew.png")`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "100%"
  },
  content: {
    width: "inherit",
  },
  text: {
    fontSize: "24px",
    fontFamily: "NunitoSans-ExtraBold",
    color: theme.typography.color.primary,
  },
  btn: {
    borderRadius: "12px",
    boxShadow: "0px 6px 10px #00000014",
    padding: "16px",
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      color: "#FFFFFF",
      backgroundColor: theme.palette.primary.main,
    },
  },
  buttonTextStyle: {
    fontSize: "14px",
    color: "#FFFFFF",
    fontFamily: "NunitoSans-Bold",
  },
  img: {
    height: "8%",
    width: "28%",
  },
}));

const InitialState = {
  email: "",
  enterPassword: "",
  confirmPassword: "",
  error: {
    email: "",
    enterPassword: "",
    confirmPassword: "",
  },
};

export const ResetPassword = () => {
  const classes = useStyles();
  const history = useHistory();
  const size = useWindowDimensions();
  const [account, setAccount] = React.useState({ ...InitialState });
  const alert = React.useContext(AlertContext);
  const search = useLocation().search;
  const resetPasswordAuthToken = new URLSearchParams(search).get("token");
  const [decoded, setDecoded] = React.useState(null);

  React.useEffect(() => {
    if (resetPasswordAuthToken !== null && resetPasswordAuthToken !== "") {
      setDecoded(jwt_decode(resetPasswordAuthToken));
    }
    // eslint-disable-next-line
  }, []);
  const updateState = (key, value) => {
    let error = account.error;
    error[key] = "";
    setAccount({ ...account, [key]: value, error });
  };
  const isIamValideToCreateAccount = () => {
    let isValid = true;
    let error = account.error;

    const is_password_strong_regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/;
    const is_password_strong = is_password_strong_regex.test(account.enterPassword)

    //Checking Email
    // if (account.email.length === 0) {
    //     isValid = false;
    //     error.email = Message.English.requiredMessage('Email')
    // }

    //Checking enterPassword
    if (account.enterPassword.length === 0) {
      isValid = false;
      error.enterPassword = Message.English.requiredMessage("Password");
    } else if (!Boolean(is_password_strong)) {
      isValid = false;
      error.enterPassword = "Password is not stong";
    }

    //Checking confirmPassword
    if (account.confirmPassword.length === 0) {
      isValid = false;
      error.confirmPassword =
        Message.English.requiredMessage("Confirm Password");
    }
    if (account.confirmPassword.length > 0) {
      if (
        account.enterPassword.length > 0 &&
        account.enterPassword !== account.confirmPassword
      ) {
        isValid = false;
        error.confirmPassword = "Password does not Match";
      }
    }
    if (
      account.email &&
      account.enterPassword !== "" &&
      account.confirmPassword !== ""
    ) {
      if (account.enterPassword === account.confirmPassword) {
        isValid = true;
      }
    }

    setAccount({ ...account, error });
    return isValid;
  };

  const onSendAccountBtnClicked = () => {
    if (isIamValideToCreateAccount()) {
      const payload = {
        tenantId: "8e9c54d2-45ef-4fac-bb37-6bc7d35bb199",
        password: account.confirmPassword,
      };

      NetworkCall(
        `${config.api_url}/users/resetpassword/${resetPasswordAuthToken}`,
        NetWorkCallMethods.post,
        payload,
        null,
        false,
        false
      )
        .then((response) => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: "Password updated successfully",
          });
          localStorage.clear();
          history.push(Routes.login);
        })
        .catch((err) => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: "Some thing went wrong",
          });
        });
    } else {
      return false;
    }
  };

  return (
    <>
      <Container maxWidth="sm" style={{ padding: 0 }}>
        <Grid container>
          <Grid
            item
            className={classes.content}
            style={{ height: size.height > 734 ? size.height - 94 : 640 }}
          >
            <Grid item xs={12} className={classes.backgroundImage} />
            <Grid style={{ padding: "0px 16px 0px 16px" }}>
              <Box height={"24px"} />
              <Grid
                container
                xs={12}
                direction="row"
                justifyContent={"space-between"}
                alignContent={"center"}
              >
                <img
                  src="/images/logoOne.svg"
                  alt="logo"
                  className={classes.img}
                />
                <img
                  src="/images/Propgoto logo-01.svg"
                  alt="logo"
                  className={classes.img}
                />
              </Grid>
              <Box height={"16px"} />
              <Grid item xs={12}>
                <Typography className={classes.text}>
                  Reset your Password Here
                </Typography>
              </Grid>
              <Box height={"24px"} />
              <Grid item xs={12}>
                <TextBox
                  label={Message.English.createAccount.email.label}
                  placeholder={Message.English.createAccount.email.placeholder}
                  isError={account.error.email.length > 0}
                  errorMessage={account.error.email}
                  value={decoded && decoded.email_id ? decoded.email_id : ""}
                  isReadonly
                  isRequired
                />
              </Grid>
              <Box height={"32px"} />
              <Grid item xs={12}>
                <TextBox
                  value={account.enterPassword}
                  type="password"
                  onChange={(e) => updateState("enterPassword", e.target.value)}
                  label={Message.English.createAccount.enterPassword.label}
                  placeholder={
                    Message.English.createAccount.enterPassword.placeholder
                  }
                  isError={account.error.enterPassword.length > 0}
                  errorMessage={account.error.enterPassword}
                  isRequired
                />
                <PasswordInfo />
              </Grid>
              <Box height={"24px"} />
              <Grid item xs={12}>
                <TextBox
                  value={account.confirmPassword}
                  type="password"
                  onChange={(e) =>
                    updateState("confirmPassword", e.target.value)
                  }
                  label={Message.English.createAccount.confirmPassword.label}
                  placeholder={
                    Message.English.createAccount.confirmPassword.placeholder
                  }
                  isError={account.error.confirmPassword.length > 0}
                  errorMessage={account.error.confirmPassword}
                  isRequired
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ padding: "16px 16px 25px 16px" }}>
            <Button
              fullWidth
              variant="contained"
              className={classes.btn}
              onClick={onSendAccountBtnClicked}
            >
              <Typography className={classes.buttonTextStyle}>
                Reset password
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
