import dotenv from "dotenv";
dotenv.config();

let config = {};

config.api_url = `${process.env.REACT_APP_BACKEND_API}`;

config.graphql_url = `${process.env.REACT_APP_GRAPHQL_API}`;
config.graphql_web_socket_url = `${process.env.REACT_APP_GRAPHQL_WEB_SOCKET_PROTOCAL}${process.env.REACT_APP_GRAPHQL_WEB_SOCKET_HOST}${process.env.REACT_APP_GRAPHQL_WEB_SOCKET_PORT}${process.env.REACT_APP_GRAPHQL_WEB_SOCKET_BASE_URL}`;
config.socket =
  process.env.REACT_APP_SOCKET ||
  "wss://graphql-dev.propertyautomate.com/graphql/";
config.slug = `${process.env.REACT_APP_SLUG}`;
config.tenantid = `${process.env.REACT_APP_TENANT_ID}`;
config.auth_api_url = `${process.env.REACT_APP_AUTH_API}`;
config.googleMapApiKey = `${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`;
config.app_x_build = `${process.env.REACT_APP_X_BUILD}`;

export { config };
