import AddIcon from '@mui/icons-material/Add';
import { Avatar, Box, Button, Fab, Grid, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { withNamespaces } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Item } from '../../assets';
import CheckIcon from "../../assets/checkIcon";
import { DialogDrawer } from '../dialogDrawer';
import MoveInForm from '../moveInForm';
import MoveOutForm from '../moveOutForm';
import { config } from '../../config';
import { AlertContext } from "../../contexts";
import { NetworkCall } from '../../networkcall';
import { Routes } from "../../router/routes";
import { AlertProps, LocalStorageKeys, NetWorkCallMethods } from '../../utils';
import { validateArrayContaines, validateTwoArrayContaines } from '../../utils/inspections';
import ItemCart from "../itemCart";
import ReAllocateCard from "./pause&reallocate";
import { useStyles } from "./styles";
import { useApolloClient } from '@apollo/client';
import { RemoveInspectionItem } from '../../graphql/mutations';

const Step1 = ({ step = 1, previous = () => false, next = () => false, disable = false, t, setStep = () => false, state = {} }) => {
    const classes = useStyles();
    const alert = React.useContext(AlertContext);
    const history = useHistory();
    const [showReallocateDialog, setShowReallocateDialog] = useState(false)
    const [open, setOpen] = React.useState({
        bool: false,
        data: {},
        isDelete: false

    });
    const [addItem, setAddItem] = React.useState(false);
    const [list, setList] = React.useState([]);
    const [location, setLocation] = React.useState({
        lat: "",
        lng: ""
    })
    const client = JSON.parse(localStorage.getItem(LocalStorageKeys.client))
    const gqlclient = useApolloClient()

    //get item list
    const getList = () => {
        const payload = {
            agreement_inspection_id: state?.id,
            agreement_id: state?.agreement_id,
            type_in: state?.type,
        }
        NetworkCall(
            `${config.api_url}/move-in-out/get_agreement_inspection_item`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((res) => {
            setList(res?.data?.data)
        }).catch((err) => {
            console.log(err)
        })
    }

    const getCurrentLocation = () => {
        let location = null;
        if (window.navigator && window.navigator.geolocation) {
            location = window.navigator.geolocation
        }
        if (location) {
            location.getCurrentPosition(function (position) {
                setLocation({ lat: position.coords.latitude, lng: position.coords.longitude })
            })
        }
    }

    //initial load
    React.useEffect(() => {
        getList()
        getCurrentLocation()

        // eslint-disable-next-line
    }, [])
    //on close drawer
    const closeDrawer = () => {
        setOpen({
            bool: false,
            data: {}
        })
    }
    //on click items
    const onClick = (item) => {
        if (item?.list?.length > 1) {
            history.push({
                pathname: Routes.addMoveInOut,
                state: {
                    agreement_inspection_id: state?.id,
                    items: item?.list,
                    inspection_item: item?.list?.[0]?.inspection_item_id,
                    disable: disable,
                    type: state?.type,
                    agreement_id: state?.agreement_id,
                    title: item?.title,
                    unit_id:state?.unit_id
                }
            })
        } else {

            setOpen({
                bool: true,
                data: {
                    ...item?.list?.[0],
                    activity_feedback: item?.list?.[0]?.activity_feedback,
                }
            })
        }

    }
    //on update activity
    const updateActivity = (val,options) => {
        const keys = ["assets", "activity_id", "description", "editActivityFeedback", "product_id", "error", "inspection_activity_feedback"]
        const result = Object.keys(val).filter((key) => !keys.includes(key)).reduce((obj, key) => {
            obj[key] = val[key];
            return obj;
        }, {}
        )
        const feedback = Object.keys(result).map((x) => {
            return {
                activity_id: result[x]?.inspection_activity_id,
                feedback_sub_category_id: result[x].id
            }
        })
        if(feedback?.length>0){
            const payload = {
                activity_items: [
                    {
                        id: val?.activity_id,
                        description: val?.description,
                        assets: val?.assets ?? undefined,
                        client: client?.id,
                        activity_feedback_subcategory: feedback
                    }
                ]
            }
            NetworkCall(
                `${config.api_url}/inspection_activity/upsert`,
                NetWorkCallMethods.post,
                payload,
                null,
                true,
                false
            ).then((res) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.success,
                    msg: "Activity Successfully Updated",
                });
                getList()
                closeDrawer()
    
            }).catch((error) => {
                console.log(error)
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: "Some thing went wrong",
                });
            });
        }
        else{
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: options.length>0?`Please Choose FeedBack for Any One (${options?.map((x)=>{return x.title})})`:`Contact manager to add feedback category`,
            });
        }
    }
    const openDeleteModal = () => {
        setOpen({ ...open, isDelete: true })
    }
    const closeDeleteModal = () => {
        setOpen({ ...open, isDelete: false })

    }
    const deleteInspectionItem = (val) => {
        const payload = {
            id: val?.activity_id,
            update: {
                is_active: false,
            }
        }
        gqlclient.mutate({
            mutation: RemoveInspectionItem,
            variables: payload
        }).then((res) => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.success,
                msg: `${val.name} ${t("deleteMsg")}`,
            });
            setOpen({ ...open, bool: false, isDelete: false })
            getList()
        }).catch((err) => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("SomethingWentwrong"),
            });
        })
    }
    return (
        <Box>
            <Box p={2} className={classes.formRoot}>
                <Grid container spacing={1}>
                    {
                        list?.filter(i => i?.title !== "null")?.map((item) => {
                            return (
                                <Grid item xs={12}>
                                    <Box display="flex" alignItems="center" onClick={() => onClick(item)} className={classes.btn}>
                                        <Box>
                                            {
                                                item?.list?.[0]?.url ?
                                                    <Avatar sx={{ borderRadius: "4px" }} variant="square" src={item?.list?.[0]?.url} />
                                                    :
                                                    <Avatar sx={{ backgroundColor: "#F6F6F6", borderRadius: "4px" }} variant="square">
                                                        <Item />
                                                    </Avatar>

                                            }

                                        </Box>
                                        <Box marginLeft="12px" flexGrow={1}>
                                            <Typography className={classes.itemHeading}> {item?.title} {item?.list?.length > 1 && `( ${item?.list?.length} )`}</Typography>
                                        </Box>
                                        <Box>
                                            {validateArrayContaines(item?.list) ? <CheckIcon /> : false}

                                        </Box>
                                    </Box>
                                </Grid>
                            )
                        })
                    }

                </Grid>
                {/*fab icon */}
                {
                    !disable && (state?.type !=="move-out" && state?.type !== "site_visit") &&
                    <Fab onClick={() => setAddItem(true)} color="error" className={classes.fab}>
                        <AddIcon />
                    </Fab>
                }

            </Box>
            <Grid className={classes.btnRoot} container spacing={1}>
                <Grid item xs={6}>
                    <Button onClick={() => disable === true ? previous : setShowReallocateDialog(true)} fullWidth className={classes.btn1} disabled={disable}>{disable === true ? t("previous") : t("pause/reallocate")}</Button>
                </Grid>
                <Grid item xs={6}>
                    <Button onClick={next} fullWidth variant="contained" disabled={!validateTwoArrayContaines(list?.filter(i => i?.title !== "null"))} className={classes.next}>{t("next")}</Button>
                </Grid>
            </Grid>
            {/*add details  */}
            <DialogDrawer
                header={open?.data?.name}
                open={open?.bool}
                onClose={closeDrawer}
                onDelete={openDeleteModal}
                padding={0}
                type={state?.type}
                component={
                    (state?.type !=="move-out") ?
                        <MoveInForm
                            serial_number={open?.data?.product_id}
                            activity_id={open?.data?.activity_id}
                            datas={open?.data}
                            onUpdate={updateActivity}
                            disable={disable}
                            type={state?.type}
                        />
                        :
                        <MoveOutForm
                            serial_number={open?.data?.product_id}
                            activity_id={open?.data?.activity_id}
                            onUpdate={updateActivity}
                            disable={disable}
                            in_activity={open?.data?.in_activity}
                            type={state?.type}
                        />
                }
            />

            {/*add item  */}
            <DialogDrawer
                header="Add Inspection Item"
                component={<ItemCart
                    agreement_inspection_id={state?.id}
                    onClose={() => setAddItem(false)}
                    reload={getList}
                    type={state?.type}
                    propertyId={state?.property_id}
                    unitId={state?.unit_id}
                />}
                open={addItem}
                padding={0}
                onClose={() => setAddItem(false)}
            />
               {/* delete inspection item */}
               <DialogDrawer
                header={"Delete Item"}
                open={open?.isDelete}
                onClose={closeDeleteModal}
                padding={0}
                component={
                    <center>
                        <Box mt={3} height="150px" alignItems={"center"}>
                            <Typography className={classes.deletetext}>Are you sure you want to delete this item?</Typography>
                            <Stack m={3} direction ="row" spacing={1}>
                                <Button variant={"contained"} fullWidth className={classes.next} onClick={()=>deleteInspectionItem({activity_id:open?.data?.activity_id,name:open?.data?.name})}>Yes</Button>
                                <Button variant={"contained"} fullWidth className={classes.btn1} onClick={closeDeleteModal}>No</Button>
                            </Stack>
                        </Box>
                    </center>
                }
            />
            {/* {Pause/Reallocate} */}
            <DialogDrawer
                header="Pause/Reallocate"
                component={<ReAllocateCard location={location} setStep={setStep} state={state} setShowReallocateDialog={setShowReallocateDialog} />}
                open={showReallocateDialog}
                padding={0}
                onClose={() => setShowReallocateDialog(false)}
            />


        </Box>
    )
}
export default withNamespaces("inspections")(Step1);