import makeStyles from "@mui/styles/makeStyles";
import { Bold } from "../../../utils";

export const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.dashboard.primary,
        height: `calc(100vh - 122px)`,
        overflow: "auto"
    },
    textBox: {

        [`& fieldset`]: {
            borderRadius: "4px",

            border: "1px solid #E4E8EE",
            "& :hover": {
                border: "1px solid #E4E8EE",
            },
        },
        "& .MuiOutlinedInput-input": {
            backgroundColor: "#F5F7FA",
        },
    },
    btn: {
        boxShadow: "none",
        padding: "8px",
        textTransform: "capitalize",
        backgroundColor:theme.palette.primary.main,
        color:theme.palette.primary.contrastText,
        "&:hover":{
            backgroundColor:theme.palette.primary.main,
            color:theme.palette.primary.contrastText,
        }
    },
    btnRoot: {
        padding: "12px",
        // borderTop: "1px solid #E4E8EE",
        backgroundColor: "white"
    },
    itemRoot: {
        height: "300px",
        overflow: "auto"
    },
    itemHead: {
        fontFamily: Bold,
        fontSize: "12px",
        color: "#98A0AC"
    },
    delete: {
        fontFamily: Bold,
        fontSize: "12px",
        color: "#FF4B4B",
        cursor: "pointer"
    },
    btnOutlined: {
        boxShadow: "none",
        padding: "8px",
        textTransform: "capitalize",
        border:`1px solid ${theme.palette.primary.main}`,
        color:theme.palette.primary.main,
        backgroundColor:theme.palette.primary.contrastText,
        "&:hover":{
            border:`1px solid ${theme.palette.primary.main}`,
            color:theme.palette.primary.main,
            backgroundColor:theme.palette.primary.contrastText,
        }
    },

}));