import makeStyles from "@mui/styles/makeStyles";
import { ExtraBold, Regular } from "../../utils";

export const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "#F2F5FA",
        // height: `calc(100vh - 80px)`,
        // overflow: "auto"
    },
    title: {
        fontFamily: `${ExtraBold}`,
        fontSize: "18px",
        color: theme.typography.color.primary
    },
    subTitle: {
        fontFamily: `${Regular}`,
        fontSize: "14px",
        color: theme.typography.color.primary
    },
    heading: {
        fontFamily: `${ExtraBold}`,
        fontSize: "14px",
        color: theme.typography.color.primary
    },
    calenderRoot: {
        backgroundColor: theme.palette.background.paper,
        padding: "12px"
    },
    btn: {
        textTransform: "capitalize"
    },
    progressBar: {
        backgroundColor: "#E4E8EE",
        "& .MuiLinearProgress-bar1": {
            backgroundColor: "black"
        }
    },
}));