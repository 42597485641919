import {
  Backdrop, Box, Button, CircularProgress, Grid, Typography
} from "@mui/material";
import React from "react";
import { withNamespaces } from "react-i18next";
import { useHistory } from "react-router-dom";
import "../../App.css";
import { TextBox, TitleBar } from "../../components";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { UPDATEADDRESS } from "../../graphql/mutations";
import { NetworkCall } from "../../networkcall";
import {
  AlertProps, NetWorkCallMethods
} from "../../utils";
import { initialAddressState, returnAddressEditPayload } from "../../utils/profile";
import { useStyles } from "./style";

const ProfileAddAddress = ({ t }) => {
  const classes = useStyles();
  const history = useHistory();
  const alert = React.useContext(AlertContext);
  const [initialData, setInitialData] = React.useState({ ...initialAddressState });
  const [loading, setLoading] = React.useState(true);
  const goBack = () => {
    history.goBack()
  };
  //update state
  const updateState = (key, value) => {

    setInitialData({ ...initialData, [key]: value });
  };
  //get details
  const getDetails = () => {
    NetworkCall(
      `${config.api_url}/user-info`,
      NetWorkCallMethods.get,
      null,
      null,
      true,
      false
    ).then((res) => {
      const result = returnAddressEditPayload(res?.data?.data?.user_profiles?.[0]);
      setInitialData(result)
      setLoading(false)
    })
  }
  //initial load
  React.useEffect(() => {
    getDetails()
  }, [])
  const saveAddress = () => {
    const updateAddressPayload = {
      query: UPDATEADDRESS,
      variables: {
        contactID: initialData?.contactID,
        updatedAddress: {
          street_1: initialData?.addressLineOne,
          street_2: initialData?.addressLineTwo,
          street_3: initialData?.addressLineThree,
          //district: initialData?.district.value,
          city: initialData?.city,
          state: initialData?.state,
          zipcode: initialData?.zipCode,
          country: initialData?.country,
          // latitude: initialData?.latitude?.toString(),
          // longitude: initialData?.longitude?.toString(),
        },
      },
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      updateAddressPayload,
      null,
      true,
      false
    )
      .then((response) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: "Address Updated Successfully.",
        });
        goBack();
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Some thing Went wrong.",
        });
      });
  };

  return (
    <Box className={classes.root}>
      {loading ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <Box>

          <TitleBar
            arrow
            text={t("Address")} goBack={goBack} />
          <Box p={2} className={classes.addressFieldsContainerStyle}>
            <Typography
              style={{
                fontFamily:"NunitoSans-Bold",
                fontSize: "16px",
                color: "#091B29",
              }}
            >
              {t("Address")}
            </Typography>
            <Box height="16px" />
            <TextBox
              label={t("AddressLine1")}
              value={initialData?.addressLineOne}
              onChange={(e) => updateState("addressLineOne", e.target.value)}
            />
            <Box height="16px" />
            <TextBox
              label={t("AddressLine2")}
              value={initialData?.addressLineTwo}
              onChange={(e) => updateState("addressLineTwo", e.target.value)}
            />
            <Box height="16px" />
            <TextBox
              label={t("AddressLine3")}
              value={initialData?.addressLineThree}
              onChange={(e) => updateState("addressLineThree", e.target.value)}
            />
            <Box height="16px" />
            <Grid container >
              <Grid xs={6} sx={{ paddingRight: "8px" }}>
                <TextBox
                  label={t("City")}
                  value={initialData?.city}
                  onChange={(e) => updateState("city", e.target.value)}
                />
              </Grid>
              <Grid xs={6}>
                <TextBox
                  label={t("State")}
                  value={initialData?.state}
                  onChange={(e) => updateState("state", e.target.value)}
                />
              </Grid>
            </Grid>
            <Box height="16px" />
            <Grid container >
              <Grid xs={6} sx={{ paddingRight: "8px" }}>
                <TextBox
                  label={t("Country")}
                  value={initialData?.country}
                  onChange={(e) => updateState("country", e.target.value)}                />
              </Grid>
              <Grid xs={6} >
                <TextBox
                  label={t("Pincode")}
                  fullWidth
                  value={initialData?.zipCode}
                  onChange={(e) => updateState("zipCode", e.target.value)}
                />
              </Grid>
            </Grid>
            <Box height="16px" />

          </Box>
          <Box className={classes.addAddressButtonContainerStyle}>
            <Button
              onClick={saveAddress}
              variant="contained"
              fullWidth
              className={classes.iconButtonStyle}
            >
              <Typography className={classes.addAddressButtonTextStyle}>
                {"Add Address"}
              </Typography>
            </Button>
          </Box>

        </Box>
      )}
    </Box>
  );
};

export default withNamespaces("addAddress")(ProfileAddAddress)