import { makeStyles } from "@mui/styles";
import { Bold, SemiBold } from "../../utils";

export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 12,
    boxShadow: "0px 3px 30px #5C86CB2E",
    border: `1px solid ${theme.palette.border.secondary}`,
  },
  headerRoot: {
    backgroundColor: "#F6F9FF",
    borderBottom: `1px solid ${theme.palette.border.secondary}`,
    borderRadius:"12px 12px 0px 0px"
  },
  title: {
    fontSize: 16,
    fontFamily: `${Bold}`,
  },
  tabRoot: {
    borderBottom: `1px solid ${theme.palette.border.secondary}`,
    "& .MuiTab-root": {
      minWidth: "auto",
      textTransform: "capitalize",
    },
    "& .MuiTabs-indicator": {
      backgroundColor: `${theme.palette?.primary?.main} !important`,
    },
    "& .Mui-selected": {
      color: `${theme.palette?.primary?.main} !important`,
    },
  },
  value: {
    fontSize: 12,
    fontFamily: `${SemiBold}`,
  },
  valueAmount: {
    fontSize: 12,
    fontFamily: `${Bold}`,
  },
  toggleSwitch: {
    borderRadius: "18px",
    backgroundColor: "white"
  },
  activeType: {
    backgroundColor: "#B20606",
    color: "white",
    borderRadius: "18px",
    padding: "10px",
    fontSize: "12px",
    fontFamily: `${SemiBold}`,
    cursor:"pointer"


  },
  defaultType:{
    color: theme.typography.color.secondary,
    borderRadius: "18px",
    padding: "5px",
    marginLeft:"5px",
    marginRight:"5px",
    fontSize: "12px",
    fontFamily: `${SemiBold}`,
    cursor:"pointer"

  }
}));
