export const Routes = {
  dashboard: "/dashboard",
  welcomePage: "/welcome",
  setupAccount: "/setupaccount",
  login: "/login",
  otpPage: "/otppage",
  resetpassword: "/resetpassword",
  calendar: "/calendar",
  companyselect: "/companyselect",
  moveIn: "/moveIn",
  moveOut: "/moveOut",
  profilePage: "/profilePage",
  profileEdit: "/profileEdit",
  profileAddAddress: "/profileAddAddress",
  inspections: "/inspections",
  track: "/track",
  addMoveInOut: "/addMoveInOut",
  parkingDetails: "/parking_details",
  notification: "/notification",
  announcement_details: "/announcement_details",
  serviceInspection: "/serviceInspection",
  utilities: "/utilities",
  utilityList: "/utilityList",
  amenityPass:"/amenityPass",
  unitHandover:"/unitHandover",
  siteVisit:"/siteVisit",
  unitReadiness:"/unitReadiness",
  generalInspection:"/generalInspection"
};
