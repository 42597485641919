import makeStyles from "@mui/styles/makeStyles";
import { SemiBold } from "../../utils";

export const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.dashboard.primary,
        //height: `calc(100vh - 130px)`,
        overflow: "auto",
        boxShadow: "0px 3px 30px #5C86CB2E"
    },
    heading: {
        fontFamily: `${SemiBold}`,
        fontSize: "16px",
        color: theme.typography.color.primary
    },
    listRoot: {
        boxShadow: "0px 3px 30px #5C86CB2E"
    }
}));