import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { Box, IconButton } from "@mui/material";
import React from "react";
import { useStyles } from "./styles";


export const AssetCard = ({
    x = {},
    onDelete = () => false,
    disable = false,
    onClick = () => false
}) => {
    const classes = useStyles();
    return (
        <Box marginTop="12px" sx={{ cursor: "pointer" }}>

            <Box position={"relative"}>
                {
                    !disable &&
                    <IconButton onClick={onDelete} sx={{ position: "absolute", top: 0, right: 0 }}>
                        <CancelRoundedIcon sx={{ color: "#071741" }} />
                    </IconButton>
                }

                <img src={x?.url} onClick={onClick} className={`${classes.selectedImg}`} alt="" />

            </Box>
        </Box>
    )
}