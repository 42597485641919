import { Avatar, Box, Button, Divider, Typography } from "@mui/material";
import React from "react";
import { Item } from "../../assets";
import CheckIcon from "../../assets/checkIcon";
import { ItemListStyles } from "./styles";
export const ItemList = ({ list = [], buttonName = "", check = false, handleSubmit = () => false, onClick = () => false, disabled = false, type = "" }) => {
    const classes = ItemListStyles()
    return (

        <Box className={`${classes.ItemCard}`}>
            {type !== "move-out" &&
                <Button disabled={disabled} variant="outlined" className={`${classes.addbtn}`} onClick={handleSubmit}>{buttonName}</Button>
            }
            <Box height="20px" />
            {list?.map((x) => {
                return (
                    <>
                        <Box className={`${classes.cardlist}`} onClick={() => onClick(x)}>
                            <Box display={"flex"}>
                                <Avatar className={classes.avatar}>
                                    <Item />
                                </Avatar>
                                <Box mr={2} ml={2}>
                                    <Typography className={`${classes.itemName}`}>{x?.name}</Typography>
                                    <Typography className={`${classes.subtext}`}>{x?.product_id}</Typography>
                                </Box>
                            </Box>
                            {x?.activity_feedback?.length>0?
                                <Box className={`${classes.checkIcon}`}>
                                    <CheckIcon />
                                </Box>
                                : ""
                            }
                        </Box>
                        <Divider className={`${classes.dividertext}`} />
                    </>
                )
            })}
        </Box>

    )
}