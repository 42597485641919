import React from "react"

export const CompleteInspectionIcon = () =>(
    <svg id="Group_106584" data-name="Group 106584" xmlns="http://www.w3.org/2000/svg" width="109.518" height="81.614" viewBox="0 0 109.518 81.614">
  <defs>
    <clipPath id="clip-path">
      <rect id="Rectangle_54926" data-name="Rectangle 54926" width="109.518" height="81.614" fill="none"/>
    </clipPath>
    <clipPath id="clip-path-2">
      <rect id="Rectangle_54914" data-name="Rectangle 54914" width="26.144" height="12.751" fill="none"/>
    </clipPath>
  </defs>
  <g id="Group_102947" data-name="Group 102947">
    <rect id="Rectangle_54901" data-name="Rectangle 54901" width="109.518" height="0.055" transform="translate(0 71.722)" fill="#ebebeb"/>
    <rect id="Rectangle_54902" data-name="Rectangle 54902" width="7.255" height="0.055" transform="translate(11.49 73.808)" fill="#ebebeb"/>
    <rect id="Rectangle_54903" data-name="Rectangle 54903" width="11.797" height="0.055" transform="translate(37.486 73.835)" fill="#ebebeb"/>
    <rect id="Rectangle_54904" data-name="Rectangle 54904" width="4.204" height="0.055" transform="translate(23.168 74.939)" fill="#ebebeb"/>
    <rect id="Rectangle_54905" data-name="Rectangle 54905" width="4.213" height="0.055" transform="translate(93.477 74.912)" fill="#ebebeb"/>
    <rect id="Rectangle_54906" data-name="Rectangle 54906" width="6.635" height="0.055" transform="translate(84.892 74.912)" fill="#ebebeb"/>
    <rect id="Rectangle_54907" data-name="Rectangle 54907" width="9.321" height="0.055" transform="translate(71.916 74.549)" fill="#ebebeb"/>
    <g id="Group_102934" data-name="Group 102934">
      <g id="Group_102933" data-name="Group 102933" clip-path="url(#clip-path)">
        <path id="Path_97049" data-name="Path 97049" d="M81.753,61.953h-42.3a1.252,1.252,0,0,1-1.25-1.25V1.25A1.252,1.252,0,0,1,39.457,0h42.3A1.252,1.252,0,0,1,83,1.25V60.7a1.252,1.252,0,0,1-1.25,1.25M39.457.055a1.2,1.2,0,0,0-1.2,1.2V60.7a1.2,1.2,0,0,0,1.2,1.2h42.3a1.2,1.2,0,0,0,1.2-1.2V1.25a1.2,1.2,0,0,0-1.2-1.2Z" transform="translate(-29.838)" fill="#ebebeb"/>
        <path id="Path_97050" data-name="Path 97050" d="M298.05,61.953h-42.3a1.251,1.251,0,0,1-1.25-1.25V1.25A1.252,1.252,0,0,1,255.755,0h42.3a1.252,1.252,0,0,1,1.25,1.25V60.7a1.252,1.252,0,0,1-1.25,1.25m-42.3-61.9a1.2,1.2,0,0,0-1.2,1.2V60.7a1.2,1.2,0,0,0,1.2,1.2h42.3a1.2,1.2,0,0,0,1.2-1.2V1.25a1.2,1.2,0,0,0-1.2-1.2Z" transform="translate(-198.76)" fill="#ebebeb"/>
        <rect id="Rectangle_54908" data-name="Rectangle 54908" width="25.43" height="30.363" transform="translate(66.539 7.9)" fill="#e6e6e6"/>
        <rect id="Rectangle_54909" data-name="Rectangle 54909" width="25.43" height="30.363" transform="translate(67.174 7.9)" fill="#f5f5f5"/>
        <rect id="Rectangle_54910" data-name="Rectangle 54910" width="23.15" height="27.803" transform="translate(68.313 9.18)" fill="#fff"/>
        <rect id="Rectangle_54911" data-name="Rectangle 54911" width="0.48" height="27.803" transform="translate(90.984 9.18)" fill="#e6e6e6"/>
        <rect id="Rectangle_54912" data-name="Rectangle 54912" width="24.487" height="1.718" transform="translate(67.645 30.396)" fill="#f5f5f5"/>
        <path id="Path_97051" data-name="Path 97051" d="M326.034,26.395H297.485a.175.175,0,0,0,0,.351h28.549a.175.175,0,1,0,0-.351" transform="translate(-232.189 -20.614)" fill="#e6e6e6"/>
        <path id="Path_97052" data-name="Path 97052" d="M427,24.068a.877.877,0,1,1-.877-.877.877.877,0,0,1,.877.877" transform="translate(-332.103 -18.111)" fill="#e6e6e6"/>
        <path id="Path_97053" data-name="Path 97053" d="M304.019,28.179h0a.237.237,0,0,1-.237-.237v-.509a.237.237,0,1,1,.474,0v.509a.238.238,0,0,1-.237.237" transform="translate(-237.243 -21.24)" fill="#e6e6e6"/>
        <path id="Path_97054" data-name="Path 97054" d="M415.527,28.179h0a.237.237,0,0,1-.237-.237v-.509a.237.237,0,1,1,.473,0v.509a.237.237,0,0,1-.237.237" transform="translate(-324.327 -21.24)" fill="#e6e6e6"/>
        <g id="Group_102932" data-name="Group 102932" transform="translate(67.645 5.957)" opacity="0.5">
          <g id="Group_102931" data-name="Group 102931">
            <g id="Group_102930" data-name="Group 102930" clip-path="url(#clip-path-2)">
              <rect id="Rectangle_54913" data-name="Rectangle 54913" width="26.144" height="12.751" fill="#e0e0e0"/>
            </g>
          </g>
        </g>
        <path id="Path_97055" data-name="Path 97055" d="M315.493,24.068a.877.877,0,1,1-.877-.877.877.877,0,0,1,.877.877" transform="translate(-245.019 -18.111)" fill="#e6e6e6"/>
        <path id="Path_97056" data-name="Path 97056" d="M56.636,150.866H47.359l.822-10.857h7.634Z" transform="translate(-36.986 -109.342)" fill="#ebebeb"/>
        <path id="Path_97057" data-name="Path 97057" d="M73.528,150.866h-1.6L71.6,140.009h1.472Z" transform="translate(-55.913 -109.342)" fill="#f5f5f5"/>
        <rect id="Rectangle_54915" data-name="Rectangle 54915" width="0.928" height="30.198" transform="translate(14.548 41.524)" fill="#f0f0f0"/>
        <path id="Path_97058" data-name="Path 97058" d="M65.434,213.4h-.7a.62.62,0,0,1-.619-.618V210.5a.62.62,0,0,1,.619-.618h.7a.62.62,0,0,1,.618.618v2.28a.62.62,0,0,1-.618.618" transform="translate(-50.072 -163.912)" fill="#f0f0f0"/>
        <path id="Path_97059" data-name="Path 97059" d="M65.434,239.121h-.7a.62.62,0,0,1-.619-.618v-2.279a.62.62,0,0,1,.619-.618h.7a.62.62,0,0,1,.618.618V238.5a.62.62,0,0,1-.618.618" transform="translate(-50.072 -183.999)" fill="#f0f0f0"/>
        <path id="Path_97060" data-name="Path 97060" d="M64.769,325.214H61.831a.626.626,0,0,1,.626-.626h1.686a.626.626,0,0,1,.626.626" transform="translate(-48.288 -253.492)" fill="#f0f0f0"/>
        <rect id="Rectangle_54916" data-name="Rectangle 54916" width="0.928" height="0.567" transform="translate(14.548 41.524)" fill="#e0e0e0"/>
        <rect id="Rectangle_54917" data-name="Rectangle 54917" width="25.636" height="25.705" transform="translate(21.097 6.81)" fill="#f0f0f0"/>
        <rect id="Rectangle_54918" data-name="Rectangle 54918" width="0.443" height="25.705" transform="translate(20.654 6.81)" fill="#e0e0e0"/>
        <rect id="Rectangle_54919" data-name="Rectangle 54919" width="21.109" height="22.458" transform="translate(23.36 8.434)" fill="#fafafa"/>
        <path id="Path_97061" data-name="Path 97061" d="M132.664,73.729H135.1a4.66,4.66,0,0,0,4.66-4.66V65.884a4.66,4.66,0,0,0-4.66-4.66h-2.433a4.66,4.66,0,0,0-4.66,4.66v3.185a4.66,4.66,0,0,0,4.66,4.66" transform="translate(-99.967 -47.814)" fill="#f5f5f5"/>
        <rect id="Rectangle_54920" data-name="Rectangle 54920" width="9.758" height="29.56" transform="translate(79.503 42.162)" fill="#e6e6e6"/>
        <path id="Path_97062" data-name="Path 97062" d="M367.963,312.9h4.763v-4.081h-9.758Z" transform="translate(-283.466 -241.175)" fill="#fafafa"/>
        <rect id="Rectangle_54921" data-name="Rectangle 54921" width="9.758" height="29.56" transform="translate(40.588 42.162)" fill="#e6e6e6"/>
        <rect id="Rectangle_54922" data-name="Rectangle 54922" width="43.072" height="28.155" transform="translate(46.189 42.162)" fill="#fafafa"/>
        <path id="Path_97063" data-name="Path 97063" d="M215.638,312.9h-4.764v-4.081h9.758Z" transform="translate(-164.686 -241.175)" fill="#fafafa"/>
        <rect id="Rectangle_54923" data-name="Rectangle 54923" width="35.681" height="7.046" transform="translate(49.884 52.654)" fill="#f0f0f0"/>
        <rect id="Rectangle_54924" data-name="Rectangle 54924" width="35.681" height="7.046" transform="translate(49.884 61.206)" fill="#f0f0f0"/>
        <path id="Path_97064" data-name="Path 97064" d="M280.366,238.968H261.681a1.363,1.363,0,0,1-1.363-1.363H281.73a1.363,1.363,0,0,1-1.364,1.363" transform="translate(-203.299 -185.561)" fill="#fafafa"/>
        <rect id="Rectangle_54925" data-name="Rectangle 54925" width="35.681" height="7.046" transform="translate(49.884 44.102)" fill="#f0f0f0"/>
        <path id="Path_97065" data-name="Path 97065" d="M280.366,199.925H261.681a1.363,1.363,0,0,1-1.363-1.363H281.73a1.364,1.364,0,0,1-1.364,1.363" transform="translate(-203.299 -155.07)" fill="#fafafa"/>
        <path id="Path_97066" data-name="Path 97066" d="M280.366,278.012H261.681a1.363,1.363,0,0,1-1.363-1.363H281.73a1.364,1.364,0,0,1-1.364,1.363" transform="translate(-203.299 -216.053)" fill="#fafafa"/>
      </g>
    </g>
    <g id="Group_102936" data-name="Group 102936">
      <g id="Group_102935" data-name="Group 102935" clip-path="url(#clip-path)">
        <path id="Path_97067" data-name="Path 97067" d="M141.048,352.443c0,1.37-19.014,2.48-42.468,2.48s-42.468-1.11-42.468-2.48,19.014-2.48,42.468-2.48,42.468,1.11,42.468,2.48" transform="translate(-43.821 -273.309)" fill="#f5f5f5"/>
      </g>
    </g>
    <g id="Group_102944" data-name="Group 102944" transform="translate(25.858 15.789)">
      <g id="Group_102948" data-name="Group 102948">
        <path id="Path_97068" data-name="Path 97068" d="M122.439,114.985h32.688a3.864,3.864,0,0,1-3.807-3.6l-.577-9.744H118.055l.576,9.744a3.863,3.863,0,0,0,3.807,3.594" transform="translate(-118.055 -95.171)" fill="#5ac782"/>
        <path id="Path_97070" data-name="Path 97070" d="M173.178,72.083H144.084a3.349,3.349,0,0,0-3.381,3.594L141.45,88.3a3.35,3.35,0,0,1-3.382,3.6h32.688a3.349,3.349,0,0,0,3.382-3.594l.081,1.366-.828-13.99a3.35,3.35,0,0,1,3.382-3.6Z" transform="translate(-133.684 -72.083)" fill="#9afabd"/>
        <path id="Path_97071" data-name="Path 97071" d="M198.224,72.084H165.536a3.864,3.864,0,0,1,3.807,3.6l2.809,47.455H204.84l-2.809-47.455a3.863,3.863,0,0,0-3.807-3.594" transform="translate(-155.136 -72.083)" fill="#5ac782"/>
        <path id="Path_97073" data-name="Path 97073" d="M223.014,124.129H204.92l-1.071-18.091h18.094ZM206.925,122h13.833l-.82-13.836H206.105Z" transform="translate(-182.144 -89.557)" fill="#189446"/>
        <path id="Path_97076" data-name="Path 97076" d="M217.853,120.649a1.774,1.774,0,0,1-1.217-.484l-4.444-4.2a1.772,1.772,0,1,1,2.433-2.578l3.153,2.976,7.089-7.535a1.773,1.773,0,1,1,2.582,2.429l-8.3,8.828a1.774,1.774,0,0,1-1.239.557h-.052" transform="translate(-184.84 -90.331)" fill="#fff"/>
      </g>
    </g>
  </g>
</svg>

)