import { Box, Grid } from "@mui/material";
import React from "react";
import ImageViewer from "react-simple-image-viewer";
import { ResourceMasterComponentStyles } from "./styles";

export const AssetView = ({ asset }) => {
    const classes = ResourceMasterComponentStyles();
    const [currentImage, setCurrentImage] = React.useState(0);
    const [isViewerOpen, setIsViewerOpen] = React.useState(false);
    const openImageViewer = React.useCallback((index) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
    }, []);
    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };
    return (
        <>
            <Box sx={{ cursor: "pointer" }} >
                <Grid container spacing={1} onClick={() => openImageViewer(0)}>
                    <Grid item xs={12}>
                        <img className={classes.mainImg} src={asset?.[0]?.url} alt="" />
                    </Grid>
                    {
                        asset?.[1]?.url &&
                        <Grid item xs={3}>
                            <img className={classes.subImg} src={asset?.[1]?.url} alt="" />
                        </Grid>
                    }
                    {
                        asset?.[2]?.url &&
                        <Grid item xs={3}>
                            <img className={classes.subImg} src={asset?.[2]?.url} alt="" />
                        </Grid>
                    }
                    {
                        asset?.[3]?.url &&
                        <Grid item xs={3}>
                            <img className={classes.subImg} src={asset?.[3]?.url} alt="" />
                        </Grid>
                    }
                    {
                        asset?.[4]?.url &&
                        <Grid item xs={3}>
                            <img className={classes.subImg} src={asset?.[4]?.url} alt="" />
                        </Grid>
                    }
                </Grid>
                {isViewerOpen && (
                    <ImageViewer
                        src={asset?.map((val) => val?.url)}
                        currentIndex={currentImage}
                        onClose={closeImageViewer}
                        disableScroll={false}
                        backgroundStyle={{
                            backgroundColor: "rgba(0,0,0,0.9)",
                        }}
                        closeOnClickOutside={true}
                    />
                )}
            </Box >

        </>

    )
}