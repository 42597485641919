import { Box } from "@mui/material"
import React from "react"
import  ServiceInspection from "./serviceInspection"

const ServiceInspectionParent = () =>{
    return(
        <Box>
            <ServiceInspection/>
        </Box>
    )
}
export default ServiceInspectionParent