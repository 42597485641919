import { Grid } from "@mui/material"
import React from "react"
import { CustomSelect } from "../../../components"
import { getYearList } from "../../../utils"

export const Header = ({
    onChangeMonth = () => false,
    selected = {},
    days = [],
    setType = () => false,
    calenderType = [],
    type = {},
    onChangeYear=()=>false,
    activeYear={}
}) => {

    const [years,setYears]=React.useState([])
   
    React.useEffect(()=>{
        const yearlist=getYearList();
        setYears(yearlist)

    },[])
    
    return (
        <Grid container spacing={1} justifyContent="space-between">
             <Grid item xs={12} md={3} lg={3}>
                <CustomSelect
                    type="date"
                    placeholder="Select Year"
                    options={years}
                    value={{
                        value:activeYear,
                        label:activeYear
                    }}
                    onChange={(val) => onChangeYear(val?.value)}
                />

            </Grid>
            <Grid item xs={6} md={5} lg={5}>
                <CustomSelect
                    type="date"
                    placeholder="Select Month"
                    options={days}
                    value={selected}
                    onChange={(val) => onChangeMonth(val)}
                />

            </Grid>
            <Grid item xs={6} md={3} lg={4}>
                <CustomSelect
                    type="date"
                    placeholder="Type"
                    options={calenderType}
                    value={type}
                    onChange={(val) => setType(val)}
                />

            </Grid>
        </Grid>
    )
}