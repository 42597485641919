import makeStyles from "@mui/styles/makeStyles";
import { Bold, ExtraBold, Regular, SemiBold } from "../../utils";

export const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.dashboard.primary,
        //height: `calc(100vh - 130px)`,
        overflow: "auto"
    },
    title: {
        fontFamily: `${ExtraBold}`,
        fontSize: "18px",
        color: theme.typography.color.primary
    },
    subTitle: {
        fontFamily: `${Regular}`,
        fontSize: "14px",
        color: theme.typography.color.primary
    },
    heading: {
        fontFamily: `${ExtraBold}`,
        fontSize: "14px",
        color: theme.typography.color.primary
    },
    calenderRoot: {
        backgroundColor: theme.palette.background.paper,
        padding: "12px"
    },
    btn: {
        textTransform: "capitalize",
        color: theme.typography.color.primary
    },
    avatar: {
        height: 54,
        width: 54,
        border: "2px solid #B20606",
        borderRadius: "50%"
    },
    titles: {
        backgroundColor: "#B20606",
        color: "white",
        fontFamily: Bold,
        padding: "2px 4px",
        borderRadius: "4px",
        position: "absolute",
        bottom: "-10px",
        fontSize: "12px",
        left: "-6px"

    },
    iconDiv: {
        backgroundColor: "white",
        borderRadius: "50%",
        width: "48px",
        height: "48px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        boxShadow:"0px 3px 15px rgba(67, 84, 114, 0.04)"
    },
    imgstyle: {
        textAlign: "center",
        width: "25px",
        height: "25px"
    },
    subtext:{
        fontSize:"12px",
        color:theme.typography.color.primary,
        fontFamily: `${SemiBold}`,
        textAlign:"center"
    },
    scanImg:{
        width:"100%",
        objectFit:"cover"
    }
}));