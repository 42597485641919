import * as React from "react"

export const Canceled = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={25.6} height={32} {...props}>
        <g data-name="Group 103565" fill="#98a0ac">
            <path d="M16.452 20.17a.835.835 0 0 1-1.181 1.181l-2.468-2.471-2.471 2.471a.835.835 0 1 1-1.181-1.18l2.468-2.469-2.471-2.471a.835.835 0 0 1 1.181-1.181l2.474 2.468 2.471-2.471a.835.835 0 1 1 1.181 1.181l-2.475 2.474Z" />
            <path d="M10 0a3.608 3.608 0 0 0-3.559 3.2H3.6A3.6 3.6 0 0 0 0 6.8v21.6A3.6 3.6 0 0 0 3.6 32H22a3.6 3.6 0 0 0 3.6-3.6V6.8A3.6 3.6 0 0 0 22 3.2h-2.841A3.608 3.608 0 0 0 15.6 0Zm0 2.4h5.6a1.2 1.2 0 1 1 0 2.4H10a1.2 1.2 0 1 1 0-2.4Zm2.8 8a7.2 7.2 0 1 1-7.2 7.2 7.2 7.2 0 0 1 7.2-7.2Z" />
        </g>
    </svg>
)

