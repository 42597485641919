import { Box, Button, Grid } from "@mui/material";
import React, { useState } from "react";
import { withNamespaces } from "react-i18next";
import { DialogDrawer } from "../dialogDrawer";
import { TextBox } from "../textbox";
import ReAllocateCard from "./pause&reallocate";
import { useStyles } from "./styles";
const Step2 = ({
    step = 1,
    previous = () => false,
    next = () => false,
    setStep = () => false,
    details = {},
    updateState = () => false,
    disable = false,
    t = () => false,
    state={},
    isdescription=false
}) => {
    const classes = useStyles()
    const [showReallocateDialog, setShowReallocateDialog] = useState(false)
    const [location, setLocation] = React.useState({
        lat: "",
        lng: ""
    })
  
     //initial load
     React.useEffect(() => {
        getCurrentLocation()

        // eslint-disable-next-line
    }, [])
    const getCurrentLocation = () => {
        let location = null;
        if (window.navigator && window.navigator.geolocation) {
            location = window.navigator.geolocation
        }
        if (location) {
            location.getCurrentPosition(function (position) {
                setLocation({ lat: position.coords.latitude, lng: position.coords.longitude })
            })
        }
    }

    return (
        <Box >
            <Box p={2} className={classes.formRoot}>
                <TextBox
                    label="Inspector Notes"
                    multiline
                    rows={5}
                    placeholder="Enter Notes"
                    color="white"
                    value={details?.description}
                    isReadonly={disable}
                    onChange={(e) => updateState("description", e.target.value)}
                />
            </Box>
            <Grid className={classes.btnRoot} container spacing={1}>
                <Grid item xs={6}>
                <Button onClick={() =>disable?previous():setShowReallocateDialog(true)} fullWidth className={classes.btn1}>{disable===true?t("previous"):t("pause/reallocate")}</Button>
                    
                </Grid>
                <Grid item xs={6}>
                    <Button disabled={isdescription} onClick={next} fullWidth variant="contained" className={classes.next}>{t("preview&signature")}</Button>
                </Grid>
            </Grid>
            {/* {Pause/Reallocate} */}
            <DialogDrawer
                header="Pause/Reallocate"
                component={<ReAllocateCard location={location} setStep={setStep} state={state} setShowReallocateDialog={setShowReallocateDialog}/>}
                open={showReallocateDialog}
                padding={0}
                onClose={() => setShowReallocateDialog(false)}
            />
           
        </Box>
    )
}
export default withNamespaces("inspections")(Step2);