import { Container } from "@mui/material";
import React from "react";
import ProfileEdit from "./profileEdit";

class ProfileEditParent extends React.Component {
  render() {
    return (
      <Container maxWidth="sm" sx={{ padding: "0px" }}>
        <ProfileEdit />
      </Container>
    );
  }
}

export default ProfileEditParent;
