import { Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import * as React from "react";
import { SemiBold } from "../../utils";
const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
  },
  containers: {

    padding: "12px",
    //height: "62px",
    boxShadow: "0px -1px 6px #00000021",
  },
  tabStyle: {
    cursor: "pointer",
  },
  selectedLabelTextStyle: {
    fontSize: "11px",
    color: theme.palette.primary.main,
    fontFamily: `${SemiBold}`
  },
  unselectedLabelTextStyle: {
    fontSize: "11px",
    color: theme.typography.color.Tertiary,
    fontFamily: `${SemiBold}`
  },
}));
export const BottomNavbar = ({ list = [] }) => {
  const classes = useStyles();

  return (
    <Grid
      container
      justifyContent={"space-around"}
      alignItems={"center"}
      className={classes.containers}
    >
      {
        list?.map((val) => {
          return (
            <Grid item xs={2} className={classes.tabStyle} onClick={val.onClick}>
              <center>
                {val?.icon}
                <Typography className={val?.select ? classes.selectedLabelTextStyle : classes.unselectedLabelTextStyle}>
                  {val?.label}
                </Typography>
              </center>
            </Grid>
          )
        })
      }


    </Grid>
  );
};
