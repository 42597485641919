import { useApolloClient } from "@apollo/client";
import { Box, Button, Divider, Grid, Skeleton, Typography } from "@mui/material";
import React,{useState} from "react";
import { withNamespaces } from "react-i18next";
import { useLocation } from "react-router-dom";
import { DialogDrawer, ParkingDetailCard } from "../../../components";
import { GET_PARKING_SLOT } from "../../../graphql/queries";
import ReAllocateCard from "./pause&reallocate"
import { useStyles } from "../styles";

const Preview = ({ t, setStep = () => false, data = {}, detail = {}, disable = false }) => {

    const classes = useStyles();
    const { state } = useLocation()
    const client = useApolloClient();
    const [details, setDetails] = React.useState({
        data: {},
        loading: true
    })
    const [showReallocateDialog, setShowReallocateDialog] = useState(false)
    const [location, setLocation] = React.useState({
        lat: "",
        lng: ""
    })


    //initial load
    React.useEffect(() => {
        getCurrentLocation()

        // eslint-disable-next-line
    }, [])
    const getCurrentLocation = () => {
        let location = null;
        if (window.navigator && window.navigator.geolocation) {
            location = window.navigator.geolocation
        }
        if (location) {
            location.getCurrentPosition(function (position) {
                setLocation({ lat: position.coords.latitude, lng: position.coords.longitude })
            })
        }
    }


    //get lead
    const getDetails = () => {
        client.query({
            query: GET_PARKING_SLOT,
            fetchPolicy: 'network-only',
            variables: {
                id: state?.agreement_id,
                unit_id: state?.unit_id
            }
        }).then((res) => {
            const result = res?.data?.agreement[0]?.agreement_units?.map((val) => {
                return {
                    agreement_unit: val?.id,
                    logo: val?.unit?.logo,
                    title: val?.unit?.unit_no,
                    name: val?.agreement_unit_users?.[0]?.contact?.first_name,
                    mobile: `${val?.agreement_unit_users?.[0]?.contact?.mobile_no_country_code ?? ""} ${val?.agreement_unit_users?.[0]?.contact?.mobile_no ?? ""}`,
                    type: val?.unit?.unit_type?.name,
                    id: val?.unit?.id,
                    parking: {
                        parking_no: val?.unit?.parking_slot[0]?.parkingByID?.parking_no ?? detail?.slot?.slot_no,
                        location: val?.unit?.parking_slot[0]?.parkingByID?.location ?? "",
                    }
                }
            })

            setDetails({
                data: result?.[0],
                loading: false
            })
        }).catch((err) => {
            console.log(err)
        })
    }

    const datas = [
        {
            label: "UNIT ID",
            value: details?.data?.title
        },
        {
            label: "RESIDENT NAME",
            value: details?.data?.name
        },
        {
            label: "RESIDENT MOBILE NUMBER",
            value: details?.data?.mobile
        },
        {
            label: "UNIT TYPE",
            value: details?.data?.type
        }
    ]

    React.useEffect(() => {
        if (state?.is_parking_component) {
            getDetails()
        } else {
            setDetails({
                data: data,
                loading: false
            })
        }
        // eslint-disable-next-line
    }, [])

    return (
        <Box>
            {
                details?.loading ?
                    <Box p={1} marginTop="-60px">
                        <Skeleton height={300} />
                    </Box>
                    :
                    <Box>
                        <Box className={classes.root}>
                            <Box className={classes.card}>
                                <Box p={1}>
                                    <ParkingDetailCard
                                        data={{
                                            title: details?.data?.parking?.parking_no,
                                            subTitle: details?.data?.parking?.location,
                                        }}
                                        bg={false}
                                    />
                                </Box>
                                <Divider className={classes.divider} />
                                <Grid container spacing={2}>
                                    {
                                        datas?.map((val) => {
                                            return (
                                                <Grid item xs={6}>
                                                    <Typography className={classes.label}>{val?.label}</Typography>
                                                    <Typography className={classes.value}>{val?.value}</Typography>
                                                </Grid>
                                            )
                                        })
                                    }
                                </Grid>
                            </Box>
                        </Box>
                        {/* <Box className={classes.btnRoot}>
                            <Grid container spacing={1}>
                                {
                                    !state?.is_parking_component &&
                                    <Grid item xs={6}>
                                        <Button
                                            fullWidth
                                            className={classes.btn}
                                            variant="outlined"
                                            sx={{ border: "1px solid #E4E8EE", color: "#091B29" }}
                                            onClick={() => setStep(2, details?.data)}
                                        >
                                            {t("cancel")}
                                        </Button>
                                    </Grid>
                                }


                                <Grid item xs={state?.is_parking_component ? 12 : 6}>
                                    <Button
                                        fullWidth
                                        className={classes.btn}
                                        sx={{ backgroundColor: "#B20606" }}
                                        variant="contained"
                                        onClick={() => setStep(state?.type === "move-out" ? 6 : disable ? 5 : 4, details?.data)}
                                    >
                                        {t("proceed")}
                                    </Button>
                                </Grid>
                            </Grid>

                        </Box> */}
                        {disable ?
                            <Box className={classes.btnRoot}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Button
                                            fullWidth
                                            className={classes.btn}
                                            sx={{ backgroundColor: "#B20606" }}
                                            variant="contained"
                                            onClick={()=>setStep(4, details?.data)}
                                        >
                                            {t("okay")}
                                        </Button>
                                    </Grid>

                                </Grid>

                            </Box>
                            :
                            <Grid className={classes.btnRoot} container spacing={1}>
                                <Grid item xs={6}>
                                    <Button onClick={() => setShowReallocateDialog(true)} fullWidth className={classes.closebtn}>{t("pause/reallocate")}</Button>
                                </Grid>
                                <Grid item xs={6}>
                                    <Button
                                        fullWidth
                                        className={classes.startbtn}
                                        sx={{ backgroundColor: "#B20606" }}
                                        variant="contained"
                                        onClick={() => setStep(disable ? 5 : 4, details?.data)}
                                    >
                                        {t("next")}
                                    </Button>
                                </Grid>
                            </Grid>
                        }
                    </Box>
            }
            {/* {Pause/Reallocate} */}
            <DialogDrawer
                header="Pause/Reallocate"
                component={<ReAllocateCard location={location} setStep={setStep} state={state} setShowReallocateDialog={setShowReallocateDialog} />}
                open={showReallocateDialog}
                padding={0}
                onClose={() => setShowReallocateDialog(false)}
            />
        </Box>
    );
};

export default withNamespaces("inspections")(Preview);