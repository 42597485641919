import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Avatar, Box, Button, IconButton, Stack, Typography } from "@mui/material";
import jwt_decode from "jwt-decode";
import moment from 'moment';
import React from "react";
import { withNamespaces } from "react-i18next";
import ScrollContainer from "react-indiana-drag-scroll";
import { useHistory } from "react-router-dom";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { CalendarCard, DashboardCard, DashboardChart, StatusChart } from "../../components";
import { DashboardSimmer } from '../../components/simmers';
import { config } from "../../config";
import { NetworkCall } from "../../networkcall";
import { Routes } from "../../router/routes";
import { InspectionList, LocalStorageKeys, NetWorkCallMethods } from '../../utils';
import { useStyles } from "./styles";
const Dashboard = ({ t }) => {

  const classes = useStyles();
  const history = useHistory();
  const hour = new Date().getHours();
  const decoded = jwt_decode(localStorage.getItem(LocalStorageKeys.authToken));
  const [data, setData] = React.useState({
    data: [],
    loading: true
  })
  //get dashboard data
  const getDashboardData = () => {
    NetworkCall(
      `${config.api_url}/inspector/dashboard`,
      NetWorkCallMethods.post,
      {},
      null,
      true,
      false
    ).then((res) => {

      setData({
        data: res?.data?.data,
        loading: false
      })

    }).catch((error) => {
      console.log(error)
    });
  }
  //initial load
  React.useEffect(() => {
    getDashboardData()
    // eslint-disable-next-line
  }, [])

  const goCalender = () => {
    history.push(Routes.calendar)
  }

  const onClick = (val) => {
    history.push({
      pathname: Routes.moveInMoveOut,
      state: {
        id: val?.id,
        type: val?.request_type,
        agreement_id: val?.agreement_id,
        request_type: val?.request_type,
        resource_id: val?.resource_id,
        unit_id: val?.unit_id,
        status: val?.status,
        reference_id: val?.reference_id,
        is_parking_component: val?.is_parking_component,
        is_parking_unit: val?.is_parking_unit,
      },
    })
  }
  // const goToAmenityPass = () =>{
  //   history.push({
  //     pathname:Routes.amenityPass
  //   })
  // }
  const goToRoutes = (title) => {
    const type = title === "MoveIn" ? { value: 1, label: "move-in" } : title === "MoveOut" ? { value: 2, label: "move-out" }:title === "Service" ? { value: 3, label: "service" }  : title === "HandOver" ? { value: 4, label: "unit_handover" } : title === "Survey" ? { value: 5, label: "site_visit" }:title === "Readiness" ? { value: 6, label: "unit_readiness" }:title==="General"?{ value: 7, label: "general" } :""
    if (title) {
      history.push({
        pathname: Routes?.inspections,
        state: {
          type: type
        }
      })
    }
  }
  return (
    <Box>
      {
        data?.loading ?
          <DashboardSimmer />
          :
          <Box className={classes.root}>
            <Box p={2}>
              <Box display="flex" alignItems="center">
                <Box flexGrow={1}>
                  {/*name and message */}
                  <Typography className={classes.title}>{t("hi")}, {decoded?.username ?? ""}!</Typography>
                  <Typography className={classes.subTitle}>
                    {(hour < 12
                      ? t("good_morning")
                      : hour >= 12 && hour < 18
                        ? t("good_afternoon")
                        : t("good_evening")) +
                      "," + t("welcome_back") + "!"}
                  </Typography>
                </Box>
                <Box position="relative">
                  <Avatar className={classes.avatar} src={data?.data?.profile?.image_url} />
                  <Typography className={classes.titles}>{t("inspector")}</Typography>
                </Box>
              </Box>
              {/*details Card */}
              <DashboardCard data={data?.data} />
              {/*scan Amenity Pass*/}
              <Box margin="12px 0px" sx={{cursor:"pointer"}}>
                <img src="/images/scanAmenityPass.svg" className={classes.scanImg} alt="scanAmenity" />
              </Box>
              <Box display="flex" alignItems="center">
                <ScrollContainer className="scroll-container">
                  <Stack direction={"row"} spacing={5}>
                    {InspectionList?.map((x) => {
                      return (
                        <div onClick={() => goToRoutes(x.title)}>
                          <IconButton className={classes.iconDiv}>
                            <img src={x.icon} className={classes.imgstyle} alt="icon" />
                          </IconButton>
                          <Box mt={1}>
                            <Typography className={classes.subtext}>{x.title}</Typography>
                          </Box>
                        </div>
                      )
                    })}
                  </Stack>
                </ScrollContainer>
              </Box>
              {/*chart data */}
              <DashboardChart />
            </Box>
            {/*calender */}
            <Box className={classes.calenderRoot}>
              <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Typography className={classes.heading}>{t("calendar")}</Typography>
                <Button onClick={goCalender} endIcon={<KeyboardArrowRightIcon />} className={classes.btn}>{t("view")} {t("calendar")}</Button>
              </Stack>
              {/*calender slides */}
              {
                data?.data?.request_list?.length > 0 &&
                <Box display="flex" alignItems="center" marginBottom="12px">
                  <ScrollContainer className="scroll-container">
                    <Box display="flex">
                      {
                        data?.data?.request_list?.map((val) => {
                          return (
                            <CalendarCard
                              onClick={() => onClick(val)}
                              val={{
                                ...val,
                                date: moment(val?.execution).format("DD"),
                                day: moment(val?.execution).format("ddd")
                              }}
                            />
                          )
                        })
                      }
                    </Box>
                  </ScrollContainer>

                </Box>
              }


            </Box>

            {/*status chart */}
            <Box p={2} marginTop="-10px">
              <StatusChart />
            </Box>

          </Box>
      }

    </Box>
  );
};

export default withNamespaces("dashboard")(Dashboard);