import { makeStyles } from "@mui/styles";

export const TrackCardCardStyles = makeStyles((theme) => ({
 
    headerTitle: {
        fontSize: "10px",
        fontFamily:"NunitoSans-Bold",
        color: theme.typography.color.Tertiary,
        textTransform:"uppercase"
    },
    calendaricon: {
        backgroundColor: "#F1F7FF",
        borderRadius: theme.palette.borderRadius,
        width:"32px",
        height:"32px",
        textAlign:"center"
    },
    userIcon: {
        backgroundColor: "#F1F7FF",
        borderRadius: theme.palette.borderRadius,
        width:"32px",
        height:"32px",
        textAlign:"center",
        paddingTop:"7px"
    },
    date: {
        fontSize: "14px",
        fontFamily:"NunitoSans-Bold",
        color: theme.typography.color.primary
    },
    type: {
        fontSize: "12px",
        fontFamily: "NunitoSans-Regular",
        color: theme.typography.color.secondary
    },
    dashedborder: {
        borderColor: theme.palette.border.secondary,
        borderLeftStyle: "dashed",
        borderLeftWidth: "2px",
        height: "25px",
        marginLeft: "15px",
        marginBottom:"2px"
  },
  noborder: {
       border:"none"
  },
//   trackList:{
//     height:"200px",
//     overflow:"scroll"
//   }
}))