import { Avatar, Box, Button, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { withNamespaces } from "react-i18next";
import { Item } from "../../../assets";
import CheckIcon from "../../../assets/checkIcon";
import { DialogDrawer, LoadingSection, UtilityForm } from "../../../components";
import { config } from "../../../config";
import { AlertContext } from "../../../contexts";
import { NetworkCall } from "../../../networkcall";
import { AlertProps, LocalStorageKeys, NetWorkCallMethods } from "../../../utils";
import ReAllocateCard from "./pause&reallocate";
import { useStyles } from "./styles";


const Utilities = ({
    previous = () => false,
    next = () => false,
    disable,
    t,
    setStep = () => false,
    state = {}
}) => {
    const classes = useStyles();
    const [showReallocateDialog, setShowReallocateDialog] = useState(false)
    const [utilities, setUtilities] = React.useState([]);
    const alert = React.useContext(AlertContext)
    const [loading, setLoading] = React.useState(true)
    const [open, setOpen] = React.useState({
        bool: false,
        data: {}
    })
    const [location, setLocation] = React.useState({
        lat: "",
        lng: ""
    })
    const client = JSON.parse(localStorage.getItem(LocalStorageKeys.client))
    const handleChange = (item = {}) => {
        setOpen({
            bool: !open?.bool,
            data: item
        })
    }


    const getUnitUtilities = () => {
        const payload = {
            // unit_id: "5465f398-8767-4b10-a35f-7a05f66707fa",
            // inspection_id: "52ee95f1-1e7e-4c88-9477-34751b6d026f"
            unit_id: state?.unit_id,
            inspection_id: state?.id
        }
        NetworkCall(
            `${config.api_url}/move-in-out/get_unit_utlities`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((res) => {
            if (res?.data?.data?.data?.length > 0) {
                setUtilities(res?.data?.data?.data)
                setLoading(false)
            }
            else {
                setStep(3)
                setLoading(false)

            }
        })
    }

    React.useEffect(() => {

        getCurrentLocation();
        getUnitUtilities()
        // eslint-disable-next-line
    }, [])

    const updateData = (details) => {
        const payload = {
            activity_items: [{
                id: open?.data?.activity?.id ?? undefined,
                is_utility: true,
                present_reading: parseInt(details?.no),
                unit_utility_id: open?.data?.id,
                assets: details?.assets ?? undefined,
                client: client?.id,
                agreement_inspection: state?.id,
            }]
        }
        console.log(payload)
        NetworkCall(
            `${config.api_url}/inspection_activity/upsert`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((res) => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.success,
                msg: "Activity Successfully Updated",
            });
            getUnitUtilities()
            setOpen({
                bool: false,
            })
        }).catch((error) => {
            console.log(error)
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: "Some thing went wrong",
            });
        });
    }

    const getCurrentLocation = () => {
        let location = null;
        if (window.navigator && window.navigator.geolocation) {
            location = window.navigator.geolocation
        }
        if (location) {
            location.getCurrentPosition(function (position) {
                setLocation({ lat: position.coords.latitude, lng: position.coords.longitude })
            })
        }
    }

    return (
        <Box>
            {loading ?
                <Box p={2} className={classes.formRoot}>
                    <LoadingSection top="10vh" message={t("checkingutilities")} />
                </Box>
                :
                <>
                    <Box p={2} className={classes.formRoot}>
                        <Typography className={classes.utilityTitle}>{t("utility_group")}</Typography>

                        <Box>
                            {
                                utilities?.map((item) => {
                                    return (
                                        <Grid item xs={12} onClick={() => handleChange(item)} marginTop="10px">
                                            <Box display="flex" alignItems="center" className={classes.btn}>
                                                <Box>
                                                    <Avatar sx={{ backgroundColor: "#F6F6F6", borderRadius: "4px" }} variant="square">
                                                        <Item />
                                                    </Avatar>
                                                </Box>
                                                <Box marginLeft="12px" flexGrow={1}>
                                                    <Typography className={classes.itemHeading}>{item?.utility_name}</Typography>
                                                </Box>
                                                <Box>
                                                    {item?.activity?.present_reading ? <CheckIcon /> : false}

                                                </Box>
                                            </Box>
                                        </Grid>
                                    )
                                })
                            }
                        </Box>

                    </Box>
                    <Grid className={classes.btnRoot} container spacing={1}>
                        <Grid item xs={6}>
                            <Button onClick={() => disable === true ? previous() : setShowReallocateDialog(true)} fullWidth className={classes.btn1}>{disable === true ? t("previous") : t("pause/reallocate")}</Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button fullWidth onClick={next} variant="contained" className={classes.next}>
                                {t("next")}
                            </Button>
                        </Grid>
                    </Grid>
                    {/* {Pause/Reallocate} */}
                    <DialogDrawer
                        header="Pause/Reallocate"
                        component={<ReAllocateCard location={location} setStep={setStep} state={state} setShowReallocateDialog={setShowReallocateDialog} />}
                        open={showReallocateDialog}
                        padding={0}
                        onClose={() => setShowReallocateDialog(false)}
                    />

                    {/*utility drawer */}
                    <DialogDrawer
                        header={open?.data?.utility_name}
                        component={<UtilityForm activity_id={open?.data?.activity?.id ?? null} details={open?.data} onClose={handleChange} onUpdate={updateData} />}
                        open={open?.bool}
                        padding={0}
                        onClose={handleChange}
                    />
                </>
            }
        </Box>

    )
}
export default withNamespaces("inspections")(Utilities);