import { useApolloClient } from "@apollo/client";
import { Box } from "@mui/material";
import React from "react";
import { withNamespaces } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { LoadingSection, Stepper, TitleBar } from "../../components";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { updateAgreementInspections } from "../../graphql/mutations";
import { getAgreementInspection } from "../../graphql/queries";
import { NetworkCall } from "../../networkcall";
import { Routes } from "../../router/routes";
import { AlertProps, NetWorkCallMethods } from "../../utils";
import { CommenceWork, CompleteInspection, Description, Details, FinalPreview, Preview, Signature, SlotSelection, VehicleDetails } from "./sections";

const ParkingDetails = ({ t }) => {


  const history = useHistory();
  const { state } = useLocation();
  const client = useApolloClient();

  const alert = React.useContext(AlertContext);
  const [step, setStep] = React.useState({
    val: 1,
    data: {},
    loading: true
  });
  const [disable, setDisable] = React.useState(false)
  const [data, setData] = React.useState({
    description: "",
    signature: null,
    slot: {},
    agreement_unit_id: {},
    url: ""
  })
  //next 
  const next = (value, datas) => {
    setStep({
      ...step,
      val: value,
      data: datas
    })
  }
  //update state
  const updateState = (key, value) => {
    setData({ ...data, [key]: value });
  };
  //const move in
  const onSubmit = (url) => {
    if (url) {
      const payload = {
        id: state?.id,
        update: {
          signature: url,
          status: "Yet To Verify",
          description: data?.description
        }
      }
      client.mutate({
        mutation: updateAgreementInspections,
        variables: payload
      }).then((res) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: t("move_in_successfully"),
        });
        // history.push(Routes.inspections)
        // setStep({
        //   val: 8
        // })
      }).catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("SomethingWentwrong"),
        });
      })
    }
  }
  const components = {
    1: <CommenceWork setStep={next} detail={data} stepdata={step?.data} state={state} />,
    2: state?.is_parking_component ? <SlotSelection setStep={next} stepData={step?.data} disable={disable} updateState={updateState} data={data} /> : <Details agreement_unit_id={data} updateState={updateState} setStep={next} disable={disable} />,
    3: <Preview setStep={next} detail={data} data={step?.data} disable={disable} />,
    4: <VehicleDetails detail={data} datas={step?.data} setStep={next} disable={disable} />,
    5: <Description datas={step?.data} setStep={next} updateState={updateState} data={data} disable={disable} />,
    6: <FinalPreview setStep={next} details={data} data={step?.data} disable={disable} setDetails={setData} />,
    7: <Signature details={data} disable={disable} setStep={next} updateState={updateState} />,
    8: <CompleteInspection state={state} next={onSubmit} url={data?.url} details={data} setDetails={setData} />
  }
  const goBack = () => {
    history.push(Routes.inspections)
  }
  const getInspectionDetails = () => {
    const payload = {
      query: getAgreementInspection,
      variables: {
        id: state?.id,
        agreement_id: state?.agreement_id,
      },
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        const result = res?.data?.data?.agreement_inspection?.[0];
        setData({
          description: result?.description,
          url: result?.signature,
          pdf_url: result?.asset_url,
          property_unit_details: state?.property_unit_details
        })

        // const is_parking = state?.type === "move-in" ?
        //   (state?.is_parking_component && result?.status !== "Assigned") ? true : false
        //   :
        //   state?.is_parking_component ? true : false


        if (result?.status === "Assigned") {
          setStep({
            val: 1,
            data: {},
            loading: false
          })
        }
        else {
          setStep({
            val: 6
          })
        }

        setDisable(result?.status !== "Assigned" ? true : false)
      })
      .catch((error) => {
        console.log(error)
      });
  }
  React.useEffect(() => {
    getInspectionDetails()
    if (disable) {
      setStep({ val: 6 })
    }


    // eslint-disable-next-line 
  }, [])
  const getHeading = () => {
    switch (step?.val) {
      case 1:
        return state?.reference_id
      case 2:
        return state?.is_parking_component ? t("choose_slot") : t("parking_details")
      case 3:
        return t("Parking Details")
      case 4:
        return t("vehicle_details")
      case 5:
        return "Inspector Notes"
      case 6:
        return "Preview"
      case 7:
        return "Signature"
      case 8:
        return `${state?.status === "Completed" ? "Inspection details" : "Complete Inspection"}`
      default:
        return t("parking_details")
    }
  }

  return (
    <Box>

      {
        step?.loading ?
          <LoadingSection message={t("parking_loading")} />

          :
          <Box>
            <TitleBar goBack={goBack} arrow={true} text={getHeading()} />
            {/* <LinearProgress className={classes.progressBar} variant="determinate" value={(state?.status === "Yet To Verify")?100:step?.val * 12.5} /> */}
            <Stepper
              totalCount="2"
              // title={steps?.[0]?.text}/
              progressValue={(state?.status === "Yet To Verify" || state?.status === "Rejected") ? 100 : step?.val * 12.5}
              stepCount={step}
            />
            <Box>
              {components[step?.val]}
            </Box>
          </Box>

      }

    </Box>
  );
};

export default withNamespaces("inspections")(ParkingDetails);