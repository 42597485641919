import { useApolloClient } from "@apollo/client";
import { Box, Container } from "@mui/material";
import React from "react";
import { withNamespaces } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { LoadingSection, Stepper, TitleBar } from "../../components";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { updateAgreementInspections } from "../../graphql/mutations";
import { getAgreementInspection } from "../../graphql/queries";
import { NetworkCall } from "../../networkcall";
import { Routes } from "../../router/routes";
import { AlertProps, NetWorkCallMethods } from "../../utils";
import { returnmoveInOutStepper } from "../../utils/inspections";
import { CompleteInspection, Preview, Signature, Step0, Step1, Step2, Utilities } from "../../components/steps";
import { useStyles } from "./styles";

const UnitReadiness = ({ t }) => {

  const classes = useStyles();
  const { state } = useLocation();
  const client = useApolloClient();
  const steps = returnmoveInOutStepper(t, state?.type === "move-in" ? "Move In" : "Move Out");
  const [step, setStep] = React.useState(0);
  const history = useHistory();
  const alert = React.useContext(AlertContext);
  const [details, setDetails] = React.useState({
    description: "",
    url: null,
    disable: false,
    loading: true
  })

  const getInspectionDetails = () => {
    const payload = {
      query: getAgreementInspection,
      variables: {
        id: state?.id,
        agreement_id: state?.agreement_id,
      },
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        const result = res?.data?.data?.agreement_inspection?.[0];

        setDetails({
          description: result?.description,
          url: result?.signature,
          pdf_url: result?.asset_url,
          disable: result?.status !== "Assigned" ? true : false,
          loading: false,
          property_unit_details: state?.property_unit_details
        })
      })
      .catch((error) => {
        console.log(error)
      });
  }
  React.useEffect(() => {
    getInspectionDetails()
    setStep(state?.status === "Assigned" ? 0 : 4)
    // eslint-disable-next-line
  }, [])

  const next = (url) => {
    if (step === 6) {
      if (url) {
        const payload = {
          id: state?.id,
          update: {
            signature: url,
            status: "Yet To Verify",
            description: details?.description
          }
        }
        client.mutate({
          mutation: updateAgreementInspections,
          variables: payload
        }).then((res) => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: t("UnitReadinessInspectionCompleted_successfully"),
          });
        }).catch((err) => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("SomethingWentwrong"),
          });
        })
      } else {
        // history.push(Routes.inspections)
        setStep(step + 1);

      }
    } else {
      setStep(step + 1);
    }

  }
  const previous = () => {
    setStep(step - 1)
  }
  const onPreviewClick = () =>{
    details?.disable === true ? (state?.status === "Completed" ? setStep(6) : history.push(Routes?.inspections)) : next()
  }
  //update state
  const updateState = (key, value) => {
    setDetails({ ...details, [key]: value });
  };
  const components = {
    0: <Step0 step={step} next={next} state={state} />,
    1: <Step1 disable={details?.disable} step={step} previous={previous} next={next} setStep={setStep} state={state} />,
    2: <Utilities disable={details?.disable} updateState={updateState} details={details} state={state} step={step} setStep={setStep} previous={previous} next={next} />,
    3: <Step2 disable={details?.disable} updateState={updateState} details={details} state={state} step={step} setStep={setStep} previous={previous} next={next} />,
    4: <Preview next={next} disable={details?.disable} step={step} state={state} setStep={setStep} previous={previous} details={details} setDetails={setDetails} onPreviewClick={onPreviewClick}/>,
    5: <Signature type={"Unit HandOver"} disable={details?.disable} state={state} updateState={updateState} details={details} step={step} previous={previous} next={next} setStep={setStep} />,
    6: <CompleteInspection disable={details?.disable} step={step} next={next} state={state} url={details?.url} details={details} />,

  }

  const goBack = () => {
    history.push(Routes.inspections)
  }
  const getHeading = () => {
    switch (step) {
      case 0:
        return state?.reference_id
      case 1:
        return t("inspectionItem")
      case 2:
        return t("utilities")
      case 3:
        return t("inspectorNotes")
      case 4:
        return t("preview")
      case 5:
        return t("signature")
      case 6:
        return `${state?.status === "Completed"?t("inspectionDetails"):t("completeInspection")}`
      default:
        return state?.type
    }
  }
  return (
    <Container maxWidth="sm" sx={{ padding: 0 }}>
      {/* <TitleBar goBack={goBack} arrow={true} text={state?.type === "move-in" ? t("move_in") : t("move_out")} /> */}
      <TitleBar goBack={goBack} arrow={true} text={getHeading()} />

      <Stepper
        totalCount="2"
        title={steps?.[0]?.text}
        progressValue={(state?.status === "Yet To Verify"|| state?.status === "Rejected") ? 100 : step * 16.66}
        stepCount={step}
      />


      {
        details?.loading ?
          <LoadingSection top="10vh" message={t("load_inspection_detail")} />
          :
          <Box className={classes.root}>
            {components[step]}
          </Box>
      }

    </Container>
  );
};

export default withNamespaces("inspections")(UnitReadiness);