import * as React from "react"

export const YetToUpload = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={25.6} height={32} {...props}>
    <g data-name="Group 103564" fill="#e29336">
      <path d="M10 0a3.608 3.608 0 0 0-3.559 3.2H3.6A3.6 3.6 0 0 0 0 6.8v21.6A3.6 3.6 0 0 0 3.6 32H22a3.6 3.6 0 0 0 3.6-3.6V6.8A3.6 3.6 0 0 0 22 3.2h-2.841A3.608 3.608 0 0 0 15.6 0Zm0 2.4h5.6a1.2 1.2 0 1 1 0 2.4H10a1.2 1.2 0 1 1 0-2.4Zm2.8 8a7.2 7.2 0 1 1-7.2 7.2 7.2 7.2 0 0 1 7.2-7.2Z" />
      <path
        d="M11.729 13.333a.889.889 0 0 0-.889.889v4.444a.889.889 0 0 0 .889.889h3.556a.889.889 0 0 0 0-1.778H12.62v-3.555a.889.889 0 0 0-.891-.889Z"
        stroke="rgba(0,0,0,0)"
      />
    </g>
  </svg>
)
