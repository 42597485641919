import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.dashboard.primary,

    },
    toolbar: {
        display: "flex",
        justifyContent: "space-between"
    },
    current_date: {
        backgroundColor: "#000",
        color: "#fff",
        padding: 4,
        borderRadius: "50%",
        textAlign: "center"
    },
    header: {
        padding: "12px",
        backgroundColor: theme.palette.background.paper,
        boxShadow: "0px -1px 6px #00000021",
        border: theme.palette.border.secondary,
    },
    calenderRoot: {
        height: `calc(100vh - 200px)`,
        overflow: "overlay",
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": {
            display: "none",
        },
    }
}));
