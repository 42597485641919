import { Box, Stack, Typography, Avatar, Divider } from "@mui/material"
import React from "react"
import { ApartmentIcon } from "../../assets/appartmentIcon"
import { PhoneIcon } from "../../assets/phoneIcon"
import { DetailsCardStyles } from "./style"

export const DetailsCard = ({ title = "", data = {}, t, onClick = () => false, type = "move-in" }) => {
    const classes = DetailsCardStyles()
    return (
        <Box className={classes.mainsection}>
            <Typography className={classes.headerTitle}>{title}</Typography>
            <Box display={"flex"} justifyContent="space-between" p={1.5}>
                <Box display={"flex"}>
                    <Avatar className={classes.avatarImg} src={data?.property_unit_details?.logo} variant="square" />
                    <Box ml={1} mt={-0.5}>
                        <Typography className={classes.refId}>{data?.property_unit_details?.unit_no}</Typography>
                        <Typography className={classes.propertyName}>{data?.property_unit_details?.unit_name.concat(",", data?.property_unit_details?.property_name)}</Typography>
                        <Box display="flex" mt={1}>
                            <ApartmentIcon />
                            <Typography className={classes.name}>{data?.property_unit_details?.type}</Typography>
                        </Box>
                    </Box>
                </Box>
                <Box className={classes.statusbox}>
                    <Typography className={classes.status}>{data?.inspection_resource?.current_state}</Typography>
                </Box>
            </Box>
            {data?.agreement_unit_user_details&&
                <>
                    <Divider />
                    <Box display={"flex"} justifyContent="space-between" p={1.5}>
                        <Stack direction={"row"} spacing={1} flexWrap="wrap">
                            <Box><Avatar variant="circular" /></Box>
                            <Box>
                                <Typography className={classes.refId}>{data?.agreement_unit_user_details?.first_name}</Typography>
                                <Typography className={classes.propertyName}>
                                    {data?.agreement_unit_user_details?.mobile_no_country_code}&nbsp;
                                    {data?.agreement_unit_user_details?.mobile_no}
                                </Typography>
                            </Box>
                        </Stack>
                        <Box onClick={() => onClick(data?.agreement_unit_user_details?.mobile_no_country_code, data?.agreement_unit_user_details?.mobile_no)}>
                            <Typography className={classes.phoneIcon}><PhoneIcon /></Typography>
                        </Box>
                    </Box>
                </>
            }
        </Box>
    )
}