import makeStyles from "@mui/styles/makeStyles";
import { Bold, SemiBold } from "../../utils";

export const useStyles = makeStyles((theme) => ({
    root: {
        padding: "12px",
        backgroundColor: theme.palette.background.paper,
        borderBottom: `1px solid ${theme.palette.border.secondary}`,
        "&:last-child": {
            borderBottom: "none",
        }
    },
    title: {
        fontFamily: `${Bold}`,
        fontSize: "14px"
    },
    status: {
        fontFamily: `${Bold}`,
        fontSize: "8px",
        padding: "2px 4px",
        borderRadius: "4px",
        display: "flex",
        alignItems: "center"
    },
    sub: {
        fontSize: "12px",
        color: theme.typography.color.secondary
    },
    name: {
        fontFamily: `${SemiBold}`,
        fontSize: "12px",
        marginTop: "8px"
    },
    avatar: {
        borderRadius: "4px",
        height: "80px",
        width: "80px"
    },

}))