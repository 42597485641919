import { Grid } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import React from "react";

export const LoderSimmer = (props) => {
    const count = props?.count;
    return (
        <div>
            {props?.card ? (
                <>
                    {Array.apply(null, Array(count)).map((value, index) => (
                        <Grid key={index} item style={{ padding: "12px" }}>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={12}>
                                    <Skeleton
                                        variant="rectangular"
                                        height={props?.size}
                                        style={{ borderRadius: "12px" }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    ))}
                </>
            ) : (
                <>
                    {Array.apply(null, Array(count)).map((value, index) => (
                        <Grid key={index} item style={{ padding: "12px" }}>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={3} sm={2}>
                                    <Skeleton
                                        variant="rectangular"
                                        height={70}
                                        style={{ borderRadius: "12px" }}
                                    />
                                </Grid>
                                <Grid item xs={9} sm={10}>
                                    {" "}
                                    <Skeleton variant="text" />
                                    <Skeleton variant="text" />
                                </Grid>
                            </Grid>
                        </Grid>
                    ))}
                </>
            )}
        </div>
    );
};
