import { Box, Stack } from "@mui/material";
import React from "react";
import { LoadingSection } from "../../components";
import { config } from "../../config";
import { NetworkCall } from "../../networkcall";
import { NetWorkCallMethods } from "../../utils";
import { betweenDays, returnYearMonth } from "../../utils/calendar";
import { calenderType } from "../../utils/inspections";
import { DailyCalender } from "./components/DayCalender";
import { Header } from "./components/header";
import { MontlyCalendar } from "./components/monthlyCalender";
import { useStyles } from "./style";
export const CustomCalendar = () => {
    const classes = useStyles()
    const month = new Date().getMonth()
    const dayss = returnYearMonth();
    const [selected, setSelected] = React.useState(dayss?.[month]);
    const [between, setBetween] = React.useState(betweenDays(dayss?.[month]?.start, dayss?.[month]?.end))
    const [type, setType] = React.useState(calenderType?.[0]);
    const [list, setList] = React.useState([]);
    const [days,setDays]=React.useState(dayss);
    const [activeYear,setActiveYear]=React.useState(new Date().getFullYear());
    const [loading,setLoading]=React.useState(true)

    //get calender  data
    const getCalendarData = (start_date, end_date) => {
        setLoading(true)
        const payload = {
            start_date,
            end_date
        }
        NetworkCall(
            `${config.api_url}/move-in-out/get_all`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((res) => {
            setList(res?.data?.data)
            setLoading(false)
        }).catch((err) => {
            console.log(err)
            setLoading(false)
        })
    }

    //onchange month
    const onChangeMonth = (val) => {
        setSelected(val)
        const result = betweenDays(val?.start, val?.end)
        setBetween(result)
        getCalendarData(val?.start, val?.end)
    }

    //initial load
    React.useEffect(() => {
        getCalendarData(dayss?.[month]?.start, dayss?.[month]?.end)
        // eslint-disable-next-line
    }, [])

    //onclick single date
    const onSingleDate = (val) => {
        //const date = moment(val).format("LL");
        getCalendarData(val, val);
        setType(calenderType?.[1])
    }

    //onChange type
    const onChangeType = (val) => {
        setType(val)
        if (val?.value === "Month") {
            getCalendarData(days?.[month]?.start, days?.[month]?.end)
        }

    }

    //onChangeYear
    const onChangeYear=(val)=>{
        const choosenYear = returnYearMonth(val);
        getCalendarData(choosenYear?.[month]?.start, choosenYear?.[month]?.end)
        setDays(choosenYear)
        setActiveYear(val)
        
    }

    return (
        <Stack direction={"column"}>
            <Box className={classes.header} marginBottom="2px">
                <Header
                    calenderType={calenderType}
                    type={type}
                    setType={(val) => onChangeType(val)}
                    days={days}
                    selected={selected}
                    onChangeMonth={(val) => onChangeMonth(val)}
                    activeYear={activeYear}
                    onChangeYear={(val)=>onChangeYear(val)}
                />
            </Box>
            <Box className={classes.calenderRoot}>
                {
                    loading?
                    <LoadingSection/>
                    :
                    <Box>
                {
                    type?.value === "Day" ?
                        <DailyCalender
                            state={between} events={list} />
                        :
                        <MontlyCalendar state={between} onSingleDate={onSingleDate} events={list} />
                }
            </Box>
           }
            </Box>

        </Stack>
    )
}