import makeStyles from "@mui/styles/makeStyles";
import { Bold, SemiBold } from "../../utils";

export const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.dashboard.primary,
        height: `calc(100vh - 60px)`,
        overflow: "overlay",
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": {
            display: "none",
        },
    },
    title: {
        fontSize: "10px",
        color: theme.typography.color.Tertiary,
        fontFamily: `${SemiBold}`,
        textTransform: "uppercase"
    },
    amenityPassBlock: {
        backgroundColor: 'white',
        borderRadius: theme.palette.borderRadius,
        padding: "10px"
    },
    slotInfo: {
        backgroundColor: "#FF5C0014",
        padding: "3px",
        fontSize: "10px",
        color: "#FF5C00",
        fontFamily: `${Bold}`,
        borderRadius: theme.palette.borderRadius
    },
    horizontalLine: {
        width: "50px"
    },
    timedifference: {
        border: "1px solid #E4E8EE",
        borderRadius: "13px",
        padding: "4px",
        fontSize: "12px",
        fontFamily: `${Bold}`,
        color: theme.typography.color.Tertiary
    },
    timeText: {
        fontSize: "16px",
        color: theme.typography.color.secondary,
        fontFamily: `${Bold}`,
    },
    propertyData: {
        backgroundColor: "#F1F7FF",
        padding: "8px",
        borderRadius: theme.palette.borderRadius
    },
    propertyName: {
        fontSize: "12px",
        color: "#78B1FE",
        fontFamily: `${Bold}`,
    },
    detailBox: {
        backgroundColor: "#F5F7FA",
        borderRadius: "8px",
        // padding:"12px"
    },
    amenityName: {
        fontSize: "14px",
        color: theme.typography.color.primary,
        fontFamily: `${Bold}`,
    },
    amenitydesc: {
        fontSize: "12px",
        color: theme.typography.color.secondary,
        fontFamily: `${SemiBold}`,
    },
    amenitysubName: {
        fontSize: "14px",
        color: theme.typography.color.primary,
        fontFamily: `${SemiBold}`,
    },
    checkIn: {
        backgroundColor: "#EEF9EE",
        padding: "6px",
        borderRadius: theme.palette.borderRadius
    },
    checkout: {
        backgroundColor: "#FF5C0014",
        padding: "6px",
        borderRadius: theme.palette.borderRadius
    },
    swipecheckIn: {
        backgroundColor: "#5AC782",
        padding: "6px",
        borderRadius: theme.palette.borderRadius
    },

    swipeTo: {
        fontSize: "12px",
        color: theme.typography.color.Tertiary,
        fontFamily: `${SemiBold}`,
    },
    checkInswipeTo: {
        fontSize: "12px",
        color: "white",
        fontFamily: `${SemiBold}`,
    },
    squareBox: {
        backgroundColor: "#3BAF66",
        borderRadius: theme.palette.borderRadius,
        width: "32px",
        height: "32px"
    },
    checkoutsquareBox: {
        backgroundColor: "#FF5C00",
        borderRadius: theme.palette.borderRadius,
        width: "32px",
        height: "32px"
    },
    checkinsquareBox: {
        backgroundColor: "#3BAF66",
        borderRadius: theme.palette.borderRadius,
        width: "32px",
        height: "32px"
    }
}))