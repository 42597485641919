import { Box, Stack, Typography, Grid, Button, Divider } from "@mui/material"
import moment from "moment"
import React from "react"
import { PlannerIcon } from "../../assets/planner"
import { UserClockIcon } from "../../assets/userClockIcon"
import { RemoveZUTCDateTime } from "../../utils"
import { WorkFlowCardStyles } from "./style"

export const WorkFlowCard = ({ t = "", data = {}, setStep = () => false, next = () => false, setIsShowDialog = () => false, stepdata = {}, startWork = () => false }) => {
    const classes = WorkFlowCardStyles()
    // const gotoNextPage = () =>{
    //     setStep(4,stepdata)
    //     next()
    // }

    return (
        <Box p={2}>
            <Box className={classes.mainsection}>
                {moment(new Date()).format("YY MM DD HH:mm") <= moment(RemoveZUTCDateTime(data?.inspection_details?.execution)).format("YY MM DD HH:mm") &&
                    <Box p={1.5} className={classes.notifyBox}>
                        <Stack direction={"row"} spacing={2}>
                            {/* <video className={classes.videobox} loop autoPlay>
                                <source src="/images/notifyBell.mp4" type="video/mp4" />
                            </video> */}
                            <img src="/images/notifyGIF.gif" className={classes.notifygif} alt="bellIcon"/>
                            <Box>
                                <Typography className={classes.title}>{t("earlierWorkCommencing")}</Typography>
                                <Typography className={classes.description}>{t("YouAreCommencingTheWorkEarlierThanTheSchedule")}</Typography>
                            </Box>
                        </Stack>
                    </Box>
                }
                <Box mt={1}>
                    <Typography className={classes.headerTitle}>{t("workCommence")}</Typography>
                    <Stack direction={"row"} spacing={2} mt={1}>
                        <Box className={classes.usericon}><UserClockIcon /></Box>
                        <Box>
                            <Typography className={classes.date}>{moment(new Date()).format("DD MMM YY HH:mm a")}</Typography>
                            <Typography className={classes.type}>{data?.property_unit_details?.unit_name.concat(",", data?.property_unit_details?.property_name)}</Typography>
                        </Box>
                    </Stack>
                </Box>
                <Box mt={2} mb={2}>
                    <Divider />
                </Box>
                <Box mt={1}>
                    <Typography className={classes.headerTitle}>{t("workScheduled")}</Typography>
                    <Stack direction={"row"} spacing={2} mt={1}>
                        <Box className={classes.calendaricon}><PlannerIcon /></Box>
                        <Box>
                            <Typography className={classes.date}>{moment(RemoveZUTCDateTime(data?.inspection_details?.execution)).format("DD MMM YY HH:mm a")}</Typography>
                            <Typography className={classes.type}>{data?.property_unit_details?.unit_name.concat(",", data?.property_unit_details?.property_name)}</Typography>
                        </Box>
                    </Stack>
                </Box>
            </Box>
            <Grid container spacing={1} mt={4}>
                <Grid item xs={6}>
                    <Button className={classes.closebtn} fullWidth onClick={() => setIsShowDialog(false)}>{t("close")}</Button>
                </Grid>
                <Grid item xs={6}>
                    <Button className={classes.startbtn} fullWidth onClick={() => startWork()}>{t("start")}</Button>
                </Grid>
            </Grid>
        </Box>
    )
}