import { useApolloClient } from "@apollo/client";
import { ChevronRight } from "@mui/icons-material";
import { Avatar, Box, Button, Divider, Grid, Stack, Typography } from "@mui/material";
import moment from "moment";
import React, { useContext, useState } from "react";
import { withNamespaces } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Calander, InspectionProperty } from "../../../assets";
import { TimeIcon } from "../../../assets/clockTimeIcon";
import { CompletedIcon } from "../../../assets/completedIcon";
import { CompleteInspectionIcon } from "../../../assets/completeInspectionIcon";
import { FileIcon } from "../../../assets/fileIcon";
import { SandClockIcon } from "../../../assets/sandClockIcon";
import { StarIcon } from "../../../assets/staricon";
import { UpdateTimeIcon } from "../../../assets/updateTimeIcon";
import { DialogDrawer, TextBox, WorkingHourTrackCard, DocumentViewer } from "../../../components";
import { config } from "../../../config";
import { AlertContext } from "../../../contexts";
import { UpdateTotalHours } from "../../../graphql/mutations";
import { NetworkCall } from "../../../networkcall";
import { Routes } from "../../../router/routes";
import { AlertProps, checkManualHours, NetWorkCallMethods } from "../../../utils";
import { useStyles } from "./styles";
import { saveAs } from "file-saver";

const CompleteInspection = ({ t, state = {}, url = "", next = () => false, details = {} }) => {

    const classes = useStyles()
    const value = details?.property_unit_details
    const [showDialog, setShowDialog] = useState(false)
    const client = useApolloClient()
    const [updateType, setUpdateType] = useState("")
    const [completeInspection, setCompleteInspection] = useState(false)
    const [hours, setHours] = useState("")
    const [data, setData] = useState([])
    const alert = useContext(AlertContext);
    const [openPdf, setOpenPdf] = React.useState(false)
    const [showButton, setShowButton] = React.useState({
        otherbtn: true
    })
    const history = useHistory()
    const [location, setLocation] = React.useState({
        lat: "",
        lng: ""
    })
    const updateHoursdata = (type) => {
        if (type === "actual") {
            onCloseInspection()
        }
        else {
            setShowDialog(true)

        }
        setUpdateType(type)
    }
    const getInspectionPreviewDetails = async () => {
        const payload = {
            "agreement_inspection_id": state?.id,
            "unit_id": state?.unit_id,
            "agreement_id": state?.agreement_id,
            "resource_id": state?.resource_id,
        }
        await NetworkCall(
            `${config.api_url}/move-in-out/preview_inspection`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((res) => {
            setData([res?.data?.data?.result])
        }).catch((error) => {
            console.log(error)
        });

    }
    React.useEffect(() => {
        getInspectionPreviewDetails()
        getCurrentLocation()
        // eslint-disable-next-line
    }, [])
    const getCurrentLocation = () => {
        let location = null;
        if (window.navigator && window.navigator.geolocation) {
            location = window.navigator.geolocation
        }
        if (location) {
            location.getCurrentPosition(function (position) {
                setLocation({ lat: position.coords.latitude, lng: position.coords.longitude })
            })
        }
    }
    const onCloseInspection = (actualhours) => {
        const payload = {
            id: data[0]?.inspection_resource_id,
            update: {
                actual_hours: updateType === "manual" ? Number(hours) : data[0]?.actual_hours,
              
            }
        }
        client.mutate({
            mutation: UpdateTotalHours,
            variables: payload
        }).then((res) => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.success,
                msg: t("updatedSuccessfully"),
            });
            if (updateType !== "actual") {
                setShowDialog(false)
                setCompleteInspection(true)
            }
            else {
                setShowDialog(true)
                setCompleteInspection(true)
            }
            next(url)
            getInspectionPreviewDetails()
            resumeWork()
        }).catch((err) => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("SomethingWentwrong"),
            });
        })
    }
    const onCompleteInspection = (total_hours, assigned_hours) => {
        let result = checkManualHours(assigned_hours, total_hours, hours)
        if (result) {
            onCloseInspection()
        }
        else {
            const message = hours > total_hours ? t("manualHoursWarningMsg") : t("manualHoursLessWarningMsg")
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.warning,
                msg: message,
            });
        }
    }
    const updateOtherHour = (total_hours, assigned_hours) => {
        let result = checkManualHours(assigned_hours, total_hours, hours)
        if (result) {
            const payload = {
                id: data[0]?.inspection_resource_id,
                update: {
                    other_hours: Number(hours),
                }
            }
            client.mutate({
                mutation: UpdateTotalHours,
                variables: payload
            }).then((res) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.success,
                    msg: t("updatedSuccessfully"),
                });
                if (updateType !== "actual") {
                    setShowDialog(false)
                }
                else {
                    setShowDialog(true)
                    setCompleteInspection(true)
                }
                getInspectionPreviewDetails()
                setShowButton({ otherbtn: false })
            }).catch((err) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("SomethingWentwrong"),
                });
            })
        }
        else {
            const message = hours > total_hours ? t("manualHoursWarningMsg") : t("manualHoursLessWarningMsg")
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.warning,
                msg: message,
            });
        }
    }
    const resumeWork = async () => {
        const payload = {
            "agreement_inspection_id": state?.id,
            "resource_id": state?.resource_id,
            "end_lat": location?.lat,
            "end_long": location?.lng
        }
        await NetworkCall(
            `${config.api_url}/move-in-out/resume_work`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((res) => {

        }).catch((error) => {
            console.log(error)
        });
    }
    const downloadPdf = () => {
        if (details?.pdf_url) {
            saveAs(`${details?.pdf_url}`, `${state?.reference_id}.pdf`)
        }
    }
    const updateHoursCard = () => {
        return (
            <Box className={classes.updatehourCard}>
                {updateType !== "actual" ?
                    <>
                        <Stack direction={"row"} spacing={2}>
                            <Box mt={2}><UpdateTimeIcon /></Box>
                            <Box>
                                <Typography className={classes.title}>{updateType === "other" ? t("updateOtherHour") : t("updateActualHour")}</Typography>
                                <Typography className={classes.description}>{updateType === "other" ? t("updateOtherHourDescription") : t("updateHourDescription")}</Typography>
                            </Box>
                        </Stack>
                        <Box mt={2}>
                            <TextBox label="" onChange={(e) => { setHours(e?.target?.value) }} placeholder="Enter hours" />
                        </Box>
                    </>
                    :
                    <Box>
                        <center>
                            {/* <video className={classes.videobox} loop autoPlay>
                                <source src="/images/reward.mp4" type="video/mp4" />
                            </video> */}
                            <img src="/images/rewardGIF.gif" alt="rewardGif" className={classes.rewardGIF} />
                            <Box mt={2}>
                                <Typography className={classes.title}>{t("wonderful")}</Typography>
                            </Box>
                            <Box mt={1}>
                                <Typography className={classes.description}>You completed this task  by {data?.[0]?.inspection_resource_detals?.actual_hours > 0 ? `${data?.[0]?.inspection_resource_detals?.actual_hours} hours` : "less than one hour"}</Typography>
                            </Box>
                            {data[0]?.rewards &&
                                <Box mt={1} className={classes.starCard}>
                                    <Stack direction={"row"} spacing={1} flexWrap={"wrap"}>
                                        <Box><StarIcon /></Box>
                                        <Box>3.5 Stars you earned</Box>
                                    </Stack>
                                </Box>
                            }
                        </center>
                    </Box>
                }
                <Grid container spacing={1} mt={4}>
                    <Grid item xs={12}>
                    <Button onClick={() => updateType === "manual" ? onCompleteInspection(data[0].actual_hours, data[0].projected_hours) : updateType === "other" ? updateOtherHour(data[0].actual_hours, data[0].projected_hours) : setShowDialog(false)} className={classes.startbtn} fullWidth>{updateType !== "actual" ? t("update") : t("close")}</Button>
                    </Grid>
                </Grid>
            </Box>
        )
    }

    return (

        <Box className={classes.completeInspection}>
            <Box className={classes.root}>
                {state?.status !== "Completed" ?
                    <Box mt={2}>
                        <center>
                            <CompleteInspectionIcon />
                            <Typography className={classes.cmptitle}>{t("completemsg")}</Typography>
                            <Typography className={classes.cmpdescription}>{t("completemsgDescription")}</Typography>
                        </center>
                    </Box>
                    :
                    <Box className={classes.completeDetailsCard} m={2}>
                        <Stack className={classes.container} direction={"row"} alignItems="center">
                            <Avatar className={classes.propertyImage}>
                                <img
                                    src={value?.logo}
                                    alt=""
                                    className={classes.image}
                                />
                                {
                                    (value?.is_parking_component || value?.is_parking_unit) &&
                                    <Typography noWrap className={classes.unit_category}>Parking</Typography>
                                }
                            </Avatar>

                            <Box className={classes.content}>

                                <Stack direction={"row"} justifyContent={"space-between"}>
                                    <Typography className={classes.propertyId}>{value?.reference_id}</Typography>
                                </Stack>
                                <Stack >
                                    <Typography className={classes.propertyName}>
                                        {value?.property_name}
                                    </Typography>
                                    <Typography sx={{ marginTop: "2px" }} className={classes.unitName}>
                                        {value?.unit_no}
                                    </Typography>
                                </Stack>
                                <Stack
                                    direction={"row"}
                                    spacing={2}
                                    marginTop="6px"
                                    divider={<Divider orientation="vertical" flexItem />}
                                >
                                    <Typography sx={{ display: "flex", alignItems: "center" }}>
                                        <Calander />
                                        <span className={classes.dateAndType}> {moment(value?.execution).format("DD-MM-YYYY")} </span>
                                    </Typography>
                                    <Typography sx={{ display: "flex", alignItems: "center" }}>
                                        <InspectionProperty />
                                        <span className={classes.dateAndType}> {value?.unit_type ?? value?.type} </span>
                                    </Typography>
                                </Stack>

                            </Box>
                        </Stack>
                        <Box mt={2} mb={2}>
                            <Divider />
                        </Box>
                        <Box display={"flex"} justifyContent={"space-between"}>
                            <Box>
                                <Typography className={classes.completeTitle}>Inspection Completed</Typography>
                                <Typography className={classes.description}>On {moment(data[0]?.actual_end_date).format("DD MMM YY HH:mm a")}</Typography>

                            </Box>
                            <Box><CompletedIcon /></Box>
                        </Box>
                        <Box mt={2} display={"flex"} justifyContent={"space-between"} className={classes.insBox} onClick={() => setOpenPdf(true)}>
                            <Stack direction={"row"} spacing={1}>
                                <FileIcon />
                                <Typography className={classes.instext}>Inspection Report</Typography>
                            </Stack>
                            <ChevronRight className={classes.arrowIcon} />
                        </Box>
                    </Box>
                }
                <Box m={2} className={classes.completeDetailsCard}>
                    <Stack direction={"row"} spacing={2}>
                        <Box mt={1.5}><TimeIcon /></Box>
                        <Box>
                            <Typography className={classes.title}>{t("taskprojectedhour")}</Typography>
                            <Typography className={classes.description}>{t("projectedhoursDescription")}</Typography>
                        </Box>
                    </Stack>
                    <Box mt={1} className={classes.projectedCard}>
                        <Box>
                            <Typography className={classes.title}>{t("projectedhours")}</Typography>
                            <Typography className={classes.description}>{moment(data[0]?.projected_start_date).format("DD MMM YY HH:mm")}</Typography>
                        </Box>
                        <Box mt={1}><Typography className={classes.title}>{data[0]?.projected_hours} hr</Typography></Box>
                    </Box>
                    <Box mt={2}>
                        <Divider />
                    </Box>
                    <Box mt={2}>
                        <WorkingHourTrackCard title={t("actualWorkingHour")} data={data} t={t} actualhourData={true} />
                    </Box>
                    {data[0]?.inspection_resource_detals?.other_hours > 0 &&
                        <Box mt={2} className={classes.otherHoursCard}>
                            <Typography className={classes.otherhourtext}>{`Other Work For ${data[0]?.inspection_resource_detals?.other_hours}`}</Typography>
                        </Box>
                    }

                    <Box mt={data[0]?.inspection_resource_detals?.other_hours > 0?-2:2} className={data[0]?.inspection_resource_detals?.other_hours > 0 ? classes.sandClockCardother : classes.sandClockCard}>
                        <Stack direction="row" spacing={1} flexWrap={"wrap"}>
                            <Box><SandClockIcon /></Box>
                            <Box className={classes.actualhourtext}>
                                {t("youractualworkinghour")}
                            </Box>
                            
                        </Stack>
                        {/* <Box><Typography className={classes.actualhourtext}>{data[0]?.actual_hours} hr</Typography></Box> */}
                        {data[0]?.inspection_resource_detals &&
                            <Box><Typography className={classes.actualhourtext}>
                                {data[0]?.inspection_resource_detals?.actual_hours !== null ? (data[0].inspection_resource_detals?.actual_hours > 0 ? data[0].inspection_resource_detals?.actual_hours : "< 1") : (data[0]?.actual_hours > 0 ? data[0]?.actual_hours : "< 1")} hr</Typography></Box>
                        }
                    </Box>

                    {state?.status !== "Completed" && completeInspection === false &&
                        <Box mt={3}>
                            <Grid container spacing={2}>
                                {showButton?.otherbtn ?
                                    <>
                                        <Grid item xs={12}>
                                            {/* <Button className={classes.submitbtn} fullWidth onClick={() => updateHoursdata("actual")}>Submit as {data[0]?.actual_hours>0?data[0]?.actual_hours:"less than 1 hr"} hr</Button>
                                     */}
                                            <Button className={classes.submitbtn} fullWidth onClick={() => updateHoursdata("actual")}>Submit as {data[0]?.actual_hours > 0 ? data[0]?.actual_hours : "< 1"} hr</Button>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Button className={classes.manualbtn} fullWidth onClick={() => updateHoursdata("manual", data)}>{t("submitmanualhrs")}</Button>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Button className={classes.manualbtn} fullWidth onClick={() => updateHoursdata("other", data)}>{t("submitotherhrs")}</Button>
                                        </Grid>
                                    </>
                                    :
                                    <>
                                        <Grid item xs={6}>
                                            <Button className={classes.manualbtn} fullWidth onClick={() => updateHoursdata("manual", data)}>{t("submitmanualhrs")}</Button>
                                        </Grid>
                                        <Grid item xs={6}>
                                            {/* <Button className={classes.submitbtn} fullWidth onClick={() => updateHoursdata("actual")}>Submit as {data[0]?.actual_hours>0?data[0]?.actual_hours:"less than 1 hr"} hr</Button>
                                     */}
                                            <Button className={classes.submitbtn} fullWidth onClick={() => updateHoursdata("actual")}>Submit as {data[0]?.actual_hours > 0 ? data[0]?.actual_hours : "< 1"} hr</Button>
                                        </Grid>
                                    </>
                                }
                            </Grid>
                        </Box>
                    }
                </Box>
            </Box>
            <Grid className={classes.btnRoot} container spacing={1}>
                <Grid item xs={12}>
                    <Button className={classes.homebtn} onClick={() => history.push(Routes?.inspections)} fullWidth>{t("backtohome")}</Button>
                </Grid>
                {/* */}
            </Grid>

            <DialogDrawer
                header={updateType === "other" ? t("updateOtherHour") : t("updateActualHour")}
                component={updateHoursCard()}
                open={showDialog}
                padding={0}
                onClose={() => setShowDialog(false)}
            />

            <DialogDrawer
                header={'Pdf Preview'}
                component={<Box p={2} >
                    <Box className={classes.pdfViewer}>
                        <center>
                            <DocumentViewer url={details?.pdf_url} />
                        </center>
                    </Box>
                    <Button fullWidth onClick={downloadPdf} className={classes.manualbtn}>Download</Button>
                </Box>}
                open={openPdf}
                padding={0}
                onClose={() => setOpenPdf(false)}
            />
        </Box>
    )
}
export default withNamespaces("inspections")(CompleteInspection);