import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { withNamespaces } from "react-i18next";
import { useHistory } from "react-router-dom";
import SignaturePad from 'react-signature-canvas';
import { DialogDrawer } from "../../../components";
import { Routes } from "../../../router/routes";
import ReAllocateCard from "./pause&reallocate";
import { useStyles } from "./styles";

const Signature = ({
    step = 1,
    previous = () => false,
    next = () => false,
    details = {},
    updateState = () => false,
    disable,
    t,
    type = "",
    setStep=()=>false,
    state={}
}) => {
    const classes = useStyles();
    const [showReallocateDialog, setShowReallocateDialog] = useState(false)
    const sigCanvas = React.useRef({});
    const history = useHistory();
    const [location, setLocation] = React.useState({
        lat: "",
        lng: ""
    })
    const onSubmit = () => {
        if (disable) {
            if(state?.status==="Completed"){
                setStep(5)
            }
            else{
                history.push(Routes.inspections)
            }
           
        } else {
            if (sigCanvas.current)
                // next(sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"))
                updateState("url",sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"))
                next()

        }

    }

    const handleClick = () => {
        sigCanvas.current.clear();
    }
    React.useEffect(() => {

        getCurrentLocation()
        // eslint-disable-next-line
    }, [])

    const getCurrentLocation = () => {
        let location = null;
        if (window.navigator && window.navigator.geolocation) {
            location = window.navigator.geolocation
        }
        if (location) {
            location.getCurrentPosition(function (position) {
                setLocation({ lat: position.coords.latitude, lng: position.coords.longitude })
            })
        }
    }
  
    return (
        <Box>
            <Box p={2} className={classes.formRoot}>
                <Typography className={classes.label}>{t("signature")}</Typography>
                {
                    details?.url ?
                        <Box>
                            <img className={classes.image} src={details?.url} alt="" />
                        </Box>
                        :
                        <Box position="relative">
                            <SignaturePad
                                canvasProps={{ className: classes.signRoot }}
                                ref={sigCanvas}
                                backgroundColor="white"
                                penColor="black"

                            />
                            <center>
                                <Box display="flex" justifyContent="center">
                                    <Button onClick={handleClick} className={classes.clearBtn}>{t("clear")}</Button>
                                </Box>
                            </center>
                        </Box>

                }

            </Box>
            <Grid className={classes.btnRoot} container spacing={1}>
                <Grid item xs={6}>
                <Button onClick={() =>disable===true?previous():setShowReallocateDialog(true)} fullWidth className={classes.btn1}>{disable===true?t("previous"):t("pause/reallocate")}</Button> 
                </Grid>
                <Grid item xs={6}>
                    <Button onClick={onSubmit} fullWidth variant="contained" className={classes.next}>
                        {disable ?(state?.status==="Completed"?`${t("next")}`:t("done"))  : t("complete")}
                    </Button>
                </Grid>
            </Grid>
            {/* {Pause/Reallocate} */}
            <DialogDrawer
                header="Pause/Reallocate"
                component={<ReAllocateCard location={location} setStep={setStep} state={state} setShowReallocateDialog={setShowReallocateDialog}/>}
                open={showReallocateDialog}
                padding={0}
                onClose={() => setShowReallocateDialog(false)}
            />
        </Box>
    )
}
export default withNamespaces("inspections")(Signature);