import { Avatar, Box, Button, Divider, Grid, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { withNamespaces } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { Call } from "../../../assets";
import { ApartmentIcon } from "../../../assets/appartmentIcon";
import { DialogDrawer, ParkingDetailCard, WorkingHourTrackCard } from "../../../components";
import { config } from "../../../config";
import { NetworkCall } from "../../../networkcall";
import { NetWorkCallMethods } from "../../../utils";
import ReAllocateCard from "./pause&reallocate";
import { LoderSimmer } from "../../../components/simmers/card";
import { useStyles } from "../styles";
import ShowMoreText from "react-show-more-text";
import { Routes } from "../../../router/routes";

const FinalPreview = ({ t, setStep = () => false, disable = false ,details={},setDetails=()=>false}) => {

    const classes = useStyles();
    const history=useHistory()
    const { state } = useLocation();
    const [previewdata, setPreviewData] = React.useState([])
    const [showReallocateDialog, setShowReallocateDialog] = useState(false)
    const [loading, setLoading] = React.useState(true)
    const [location, setLocation] = React.useState({
        lat: "",
        lng: ""
    })

    const getInspectionPreviewDetails = async () => {
        const payload = {
            "agreement_inspection_id": state?.id,
            "unit_id": state?.unit_id,
            "agreement_id": state?.agreement_id,
            "resource_id": state?.resource_id,
            "type": state?.request_type,
            "is_parking_component": state?.is_parking_component,
            "is_parking_unit": state?.is_parking_unit

        }
        await NetworkCall(
            `${config.api_url}/move-in-out/get_preview_details`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((res) => {
            setPreviewData(res?.data?.data)
            setDetails({...details,property_unit_details:res?.data?.data?.property_unit_details})
            setLoading(false)
        }).catch((error) => {
            console.log(error)
            setLoading(false)
        });

    }
    React.useEffect(() => {
        getInspectionPreviewDetails()
        getCurrentLocation()
        // eslint-disable-next-line
    }, [])

    const getCurrentLocation = () => {
        let location = null;
        if (window.navigator && window.navigator.geolocation) {
            location = window.navigator.geolocation
        }
        if (location) {
            location.getCurrentPosition(function (position) {
                setLocation({ lat: position.coords.latitude, lng: position.coords.longitude })
            })
        }
    }


    return (
        <Box>
            {loading ?
                 <LoderSimmer count={10} />
                :
                <>
                    <Box className={classes.root}>
                        <Box className={classes.card}>
                            <Box p={0}>
                                <ParkingDetailCard
                                    data={{
                                        title: previewdata?.parking_details?.parking_no,
                                        subTitle: previewdata?.parking_details?.location,
                                    }}
                                    bg={false}
                                />
                            </Box>
                            <Divider className={classes.divider} />
                            <Box mt={2}>
                                <Typography className={classes.headerTitle}>TAGGED UNIT AND TENANT DETAILS</Typography>
                            </Box>
                            <Box mt={2}>
                                <Stack direction={"row"} spacing={1} flexWrap="wrap">
                                    <Box mt={0.5}><Avatar variant="square" src={previewdata?.property_unit_details?.logo} className={classes.avatarImg} /></Box>
                                    <Box>
                                        <Typography className={classes.title}>{previewdata?.property_unit_details?.unit_no}</Typography>
                                        <Box display="flex" mt={0.5}>
                                            <Typography className={classes.value}><Call />&nbsp;{previewdata?.parking_details?.mobile_no}&nbsp;</Typography>
                                            <Typography className={classes.value}>&nbsp;&nbsp;<ApartmentIcon />&nbsp;{previewdata?.property_unit_details?.type}</Typography>
                                        </Box>

                                    </Box>
                                </Stack>
                            </Box>
                            <Divider className={classes.divider} />
                            <Box mt={2}>
                                <Stack direction={"row"} spacing={1} flexWrap="wrap">
                                    <Avatar />
                                    <Box>
                                        <Typography className={classes.title}>{previewdata?.parking_details?.first_name}</Typography>
                                        <Typography className={classes.description}>{previewdata?.parking_details?.mobile_no}&nbsp;</Typography>
                                    </Box>
                                </Stack>
                            </Box>
                            <Divider className={classes.divider} />
                            <Box mt={2}>
                                <Stack direction={"row"} spacing={1} flexWrap="wrap">
                                    <Avatar variant="square" src={previewdata?.parking_details?.vehicle_image} className={classes.vehicleImage} />
                                    <Box>
                                        <Typography className={classes.title}>{previewdata?.parking_details?.number_plate}</Typography>
                                        <ShowMoreText
                                                                        lines={2}
                                                                        more="Show More"
                                                                        less="Show Less"
                                                                        className={classes.description}
                                                                        anchorClass={classes.seeMoreLessTextStyle}
                                                                        expanded={false}
                                                                        truncatedEndingComponent={"... "}
                                                                    >
                                        <Typography className={classes.description}>{previewdata?.parking_details?.vehicle_type}&nbsp;</Typography>
                                   </ShowMoreText>
                                    </Box>
                                </Stack>
                            </Box>
                        </Box>
                        <Box mt={2} className={classes.card} mb={5}>
                            <WorkingHourTrackCard title={t("workinghourTrack")} data={previewdata?.track} t={t} />
                        </Box>
                    </Box>
                    {disable ?
                        <Box className={classes.btnRoot}>
                            <Button
                                fullWidth
                                className={classes.btn}
                                variant="contained"
                                onClick={() => state?.status==="Completed"?setStep(8):history.push(Routes?.inspections)}
                                sx={{ backgroundColor: "#B20606" }}
                            >
                                {state?.status==="Completed"?t("next"):t("done")}
                            </Button>

                        </Box>
                        :

                        <Grid className={classes.btnRoot} container spacing={1}>
                            <Grid item xs={6}>
                                <Button onClick={() => setShowReallocateDialog(true)} fullWidth className={classes.closebtn}>{t("pause/reallocate")}</Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button onClick={() => setStep(7)} fullWidth variant="contained" className={classes.startbtn}>{t("getSignature")}</Button>
                            </Grid>
                        </Grid>
                    }
                    {/* {Pause/Reallocate} */}
                    <DialogDrawer
                        header="Pause/Reallocate"
                        component={<ReAllocateCard location={location} setStep={setStep} state={state} setShowReallocateDialog={setShowReallocateDialog} />}
                        open={showReallocateDialog}
                        padding={0}
                        onClose={() => setShowReallocateDialog(false)}
                    />
                </>
            }
        </Box>



    );
};

export default withNamespaces("inspections")(FinalPreview);