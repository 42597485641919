import { Avatar, Box, Container, Divider, Stack, Typography } from "@mui/material"
import React from "react"
import { useHistory } from "react-router-dom"
import { AmenityIcon } from "../../assets/amenityIcon"
import { TitleBar } from "../../components"
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useStyles } from "./style"
const AmenityPass = () => {
    const history = useHistory()
    const classes = useStyles()
    const [type, setType] = React.useState("")
    const goBack = () => {
        history.goBack()
    }
    return (
        <Container maxWidth="sm" sx={{ padding: 0 }}>
            <TitleBar goBack={goBack} arrow={true} text={"Amenity Pass"} />
            <Box className={classes.root}>
                <Box className={classes.amenityPassBlock} m={2}>
                    <Box display="flex" justifyContent={"space-between"}>
                        <Typography className={classes.title}>Slot Details</Typography>
                        <Typography className={classes.slotInfo}>On 22 Dec 22</Typography>
                    </Box>
                    <Box mt={2}>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Typography className={classes.timeText}>8.00 PM</Typography>
                            <Stack direction="row" alignItems={"center"}>
                                <hr className={classes.horizontalLine} />
                                <Typography className={classes.timedifference}>01h 30 m</Typography>
                                <hr className={classes.horizontalLine} />
                            </Stack>
                            <Typography className={classes.timeText}>9.30 PM</Typography>
                        </Stack>
                    </Box>
                    <center>
                        <Box mt={2} className={classes.propertyData}>
                            <Stack direction={"row"} alignItems="center" spacing={1}>
                                <AmenityIcon />
                                <Typography className={classes.propertyName}>Rukon Al Salim Real Estate Dubai</Typography>
                            </Stack>
                        </Box>
                    </center>
                    <Box mt={2} className={classes.detailBox}>
                        <Box display="flex" justifyContent={"space-between"} p={1}>
                            <Stack direction="row" spacing={1} alignItems="center">
                                <Avatar variant="square" />
                                <Box>
                                    <Typography className={classes.amenityName}>Amenity Name Here</Typography>
                                    <Typography className={classes.amenitydesc}>Any Specific info about the amenity</Typography>
                                </Box>
                            </Stack>
                            <Typography className={classes.amenityName}>$2.00</Typography>
                        </Box>
                        <Box mt={1} mb={1}><Divider /></Box>
                        <Box p={1}>
                            <Box display="flex" justifyContent={"space-between"}>
                                <Typography className={classes.amenitydesc}>Court Id</Typography>
                                <Typography className={classes.amenitysubName}>AH 123</Typography>
                            </Box>
                            <Box display="flex" justifyContent={"space-between"} mt={1}>
                                <Typography className={classes.amenitydesc}>Booked On</Typography>
                                <Typography className={classes.amenitysubName}>22 dec22</Typography>
                            </Box>
                            <Box display="flex" justifyContent={"space-between"} mt={1}>
                                <Typography className={classes.amenitydesc}>Payment Status</Typography>
                                <Typography className={classes.amenitysubName}>Paid</Typography>
                            </Box>
                        </Box>
                        <Box mt={1} mb={1}><Divider /></Box>
                        <Box p={1}>
                            <Box display="flex" justifyContent={"space-between"}>
                                <Typography className={classes.amenitydesc}>Booking Unit</Typography>
                                <Typography className={classes.amenitysubName}>AH 123</Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box mt={1} p={1}>
                        <Typography className={classes.title}>Booked By</Typography>
                        <Stack direction={"row"} spacing={1} mt={1}>
                            <Avatar />
                            <Box>
                                <Typography className={classes.amenityName}>Max</Typography>
                                <Typography className={classes.amenitydesc}>+91 9876543210</Typography>
                            </Box>
                        </Stack>
                    </Box>
                    <Divider />
                    <Box mt={1}>
                        <Typography className={classes.title}>Checked In</Typography>
                        <Box mt={1}>
                            <Typography className={classes.amenitydesc}>By Mary Griffin, 28 Jan, 11:48</Typography>
                        </Box>
                    </Box>
                    {type === "Check-In" ?
                        <Box mt={1.5} textAlign="center" className={classes.swipecheckIn} display="flex" justifyContent={"space-between"} alignItems="center" onClick={() => setType("Check-Out")}>
                            <Box></Box>
                            <Typography className={classes.checkInswipeTo}>Checked In</Typography>
                            <Box className={classes.checkinsquareBox} alignItems="center"><KeyboardArrowRightIcon sx={{ color: "white", marginTop: "3px" }} /></Box>

                        </Box>
                        : type === "Check-Out" ?
                            <Box mt={1.5} textAlign="center" className={classes.checkout} display="flex" justifyContent={"space-between"} alignItems="center" onClick={() => setType("CheckedOut")}>
                                <Box className={classes.checkoutsquareBox} alignItems="center"><KeyboardArrowRightIcon sx={{ color: "white", marginTop: "3px" }} /></Box>
                                <Typography className={classes.swipeTo}>Swipe To Check Out</Typography>
                                <Box></Box>

                            </Box>
                            : type === "CheckedOut" ?
                                <Box mt={1.5} textAlign="center" className={classes.checkIn} display="flex" justifyContent={"center"} alignItems="center" onClick={() => setType("CheckedOut")}>
                                    <Typography className={classes.swipeTo}>Checked Out</Typography>
                                </Box>
                                :
                                <Box mt={1.5} textAlign="center" className={classes.checkIn} display="flex" justifyContent={"space-between"} alignItems="center" onClick={() => setType("Check-In")}>
                                    <Box className={classes.squareBox} alignItems="center"><KeyboardArrowRightIcon sx={{ color: "white", marginTop: "3px" }} /></Box>
                                    <Typography className={classes.swipeTo}>Swipe To Check In</Typography>
                                    <Box></Box>
                                </Box>
                    }
                </Box>
            </Box>
        </Container>
    )
}
export default AmenityPass