import { Box, Stack, Typography } from '@mui/material';
import React from "react";
import { RequestType } from '../../utils';
import { useStyles } from "./style";

export const CalendarCard = ({ val = {}, hideDate = false, width = null, onClick = () => false }) => {
    const classes = useStyles()

    return (
        <Box
            onClick={onClick}
            className={classes.root}
            sx={{ backgroundColor: val?.request_type === "move-in" ? "#B2060614" : "#EDE4FE", minWidth: width ?? "auto" }}>
            <Stack direction={"row"} alignItems="center" spacing={1} p={1.5}>
                {
                    hideDate ? "" :
                        <Box className={classes.dateCard}>
                            <center>
                                <Typography className={classes.date}> {val?.date} </Typography>
                                <Typography className={classes.day}>{val?.day}</Typography>
                            </center>
                        </Box>
                }

                <Box>
                    <Stack direction="row" spacing={1}>
                    <Typography noWrap className={classes.status} sx={{ backgroundColor: val?.request_type === "move-in" ? "#B20606" : "#834EE8" }}>&nbsp;{RequestType(val?.request_type)}&nbsp; </Typography>
                        <Typography noWrap className={classes.reference_id}> {val?.reference_id} </Typography>

                    </Stack>
                    <Typography noWrap className={classes.propertyName}> {val?.property_name} </Typography>
                </Box>
                <Box width="30px" />
            </Stack>
        </Box>

    )
}