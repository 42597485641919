import { makeStyles } from "@mui/styles";

export const ActionCardStyles = makeStyles((theme) => ({
    mainsection: {
        backgroundColor: "#FFFFFF",
        boxShadow: "0px 3px 30px #4250682E",
        borderRadius: theme.palette.borderRadius,
        padding: "15px"
    },
    headerTitle: {
        fontSize: "10px",
        fontFamily:"NunitoSans-Bold",
        color: theme.typography.color.Tertiary,
        textTransform: "uppercase"
    },
    calendaricon: {
        borderRadius: theme.palette.borderRadius,
        width: "40px",
        height: "40px",
        paddingTop: "8px",
        textAlign: "center"
    },
    timeicon: {
        borderRadius: theme.palette.borderRadius,
        width: "60px",
        height: "50px",
        paddingTop: "14px",
        textAlign: "center"
    },
    date: {
        fontSize: "14px",
        fontFamily:"NunitoSans-Bold",
        color: theme.typography.color.primary
    },
    type: {
        fontSize: "12px",
        fontFamily: "NunitoSans-Regular",
        color: theme.typography.color.secondary
    },
    btnBox: {
        border: "1px solid #E4E8EE",
        borderRadius: theme.palette.borderRadius,
        height: "30px",
        width: "60px",
        padding: "5px",
        marginTop: "12px"

    },
    btnName: {
        fontSize: "12px",
        fontFamily:"NunitoSans-Bold",
        color: theme.palette.primary.main,
        textAlign: "center",
    },
    updatebtn: {
        boxShadow: "none",
        padding: "8px",
        textTransform: "capitalize",
        border: "1px solid #E4E8EE",
        backgroundColor: theme.palette.primary.main,
        color: "#FFFFFF",
        fontSize: "14px",
        fontFamily:"NunitoSans-Bold",

    },
    delayBox: {
        border: "1px solid #E4E8EE",
        borderRadius: theme.palette.borderRadius,
    },
    hoursbtn: {
        border: "1px solid #E4E8EE",
        borderRadius: theme.palette.borderRadius,
        textAlign: "center",
        padding: "8px",
        fontSize: "12px",
        color: theme.typography.color.primary,
        width: "60px"
    },
    selectedhoursbtn: {
        border: "1px solid #E4E8EE",
        borderRadius: theme.palette.borderRadius,
        backgroundColor: theme.palette.primary.main,
        textAlign: "center",
        padding: "8px",
        fontSize: "12px",
        color: "#FFFFFF",
        width: "60px"
    },
    checkicon: {
        marginRight: "25px"
    }
}))