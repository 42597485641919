import { FilledCheckIcon } from "../assets/filledCheckIcon"
import { PauseIcon } from "../assets/pauseIcon"
import { ResumeIcon } from "../assets/resumeIcon"
import { UserClockIcon } from "../assets/userClockIcon"

export const InspectionCardData = [
    {
        image: "https://images.unsplash.com/photo-1600585154340-be6161a56a0c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8cHJvcGVydHl8ZW58MHx8MHx8&w=1000&q=80",
        property_id: "PID74432",
        property_name: "Casagrand 3245",
        status: "Move In Update Pending",
        date: "12-02-2022",
        type: "Apartments"
    },
    {
        image: "https://images.unsplash.com/photo-1600585154340-be6161a56a0c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8cHJvcGVydHl8ZW58MHx8MHx8&w=1000&q=80",
        property_id: "PID74432",
        property_name: "Casagrand 3245",
        status: "Completed",
        date: "12-02-2022",
        type: "Apartments"
    }
]

export const calendarListCardData = [
    {
        property_name: "Casagrand 3245",
        units: "06",
        borderColor: "#9681FF",
        images: [
            "https://mui.com/static/images/avatar/1.jpg",
            "https://mui.com/static/images/avatar/1.jpg",
            "https://mui.com/static/images/avatar/1.jpg"
        ]
    },
    {
        property_name: "Casagrand 3245",
        units: "06",
        borderColor: "#FF85A9",
        images: [
            "https://mui.com/static/images/avatar/1.jpg",
            "https://mui.com/static/images/avatar/1.jpg",
            "https://mui.com/static/images/avatar/1.jpg"
        ]
    }
]

export const CalendarCardData = [
    {
        date: "03",
        day: "Mon",
        status: "Move In",
        property_name: "Casagrande 5450647",
        background_color: "#E4EDFF"
    },
    {
        date: "04",
        day: "Mon",
        status: "Move In",
        property_name: "Casagrande 5450647",
        background_color: "#EDE4FE"
    },
    {
        date: "05",
        day: "Mon",
        status: "Move In",
        property_name: "Casagrande 5450647",
        background_color: "#E4EDFF"
    },
    {
        date: "06",
        day: "Mon",
        status: "Move In",
        property_name: "Casagrande 5450647",
        background_color: "#EDE4FE"
    }
]
export const ReallocateCardList = [
    {
        icon: "/images/pauseIcon.png",
        title: "Pause & Continue",
        description: "Pause the work now & continue afterwards"
    },
    // {
    //     icon:"/images/reallocateIcon.png",
    //     title:"Pause and Reallocate",
    //     description:"Pause the work now & Reallocate to someone"
    // },
]

export const TrackCardData = [
    {
        icon: <UserClockIcon />,
        date: "12 Oct 22, 08:30am",
        track_status: "Checked"
    },
    {
        icon: <PauseIcon />,
        date: "12 Oct 22, 12:30am",
        track_status: "Paused"
    },
    {
        icon: <ResumeIcon />,
        date: "12 Oct 22, 02:30am",
        track_status: "Resumed"
    },
]
export const ActualWorkingData = [
    {
        icon: <UserClockIcon />,
        date: "12 Oct 22, 08:30am",
        track_status: "Checked"
    },
    {
        icon: <FilledCheckIcon />,
        date: "12 Oct 22, 12:30am",
        track_status: "Checked Out"
    },
]
export const ActionList = [
    {
        title: "Notify the arrival",
        icon: "/images/alaram.png",
        description: "Arrival notification will sent to tenant",
        btnName: "Send",
        color: "#FFFAEC"

    },
    {
        title: "Running late",
        icon: "/images/time.png",
        description: "Delay in arrival Intimation",
        btnName: "Update",
        color: "#EEF9EE"

    },
    {
        title: "Can't take up",
        icon: "/images/fail.png",
        description: "The work can't to commence",
        btnName: "Update",
        color: "#FFECEC"
    }
]
export const ServiceActionList = [
    {
        title: "Running late",
        icon: "/images/time.png",
        description: "Delay in arrival Intimation",
        btnName: "Update",
        color: "#EEF9EE"

    },
    {
        title: "Can't take up",
        icon: "/images/fail.png",
        description: "The work can't to commence",
        btnName: "Update",
        color: "#FFECEC"
    }
]

export const InspectionList = [
    {
        title: "MoveIn",
        icon: "/images/moveInIcon.svg"
    },
    {
        title: "MoveOut",
        icon: "/images/moveOutIcon.svg"
    },
    {
        title: "Survey",
        icon: "/images/siteSurveyIcon.svg"
    },
    {
        title: "Readiness",
        icon: "/images/unitReadinessIcon.svg"
    },
    {
        title: "HandOver",
        icon: "/images/unitHandoverIcon.svg"
    },
    {
        title: "Service",
        icon: "/images/serviceInspectionIcon.svg"
    },
    {
        title: "General",
        icon: "/images/generalInspectionIcon.svg"
    },
]
