import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Avatar, Box, Button, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { withNamespaces } from "react-i18next";
import { useHistory } from "react-router-dom";
import ContactIcon from "../../assets/contactIcon";
import LogOutIcon from "../../assets/logOutIcon";
import MapIcon from "../../assets/mapIcon";
import MessageIcon from "../../assets/messageIcon";
import { ProfileSimmaer } from "../../components/simmers";
import { config } from "../../config";
import { NetworkCall } from "../../networkcall";
import { Routes } from "../../router/routes";
import { NetWorkCallMethods ,LocalStorageKeys,AlertProps} from "../../utils";
import { profileImageStyles } from "./style";
import {FirebaseContext} from "../../contexts"

const ProfilePage = ({ t }) => {
  const classes = profileImageStyles();
  const history = useHistory();
  const firebaseContext = React.useContext(FirebaseContext);
  const [data, setDate] = React.useState({
    user: {},
    role: {},
    loading: true
  })

  //get profile details
  const getProfileDetails = () => {
    NetworkCall(
      `${config.api_url}/user-info`,
      NetWorkCallMethods.get,
      null, null, true,
      false).then((response) => {
        setDate({
          user: response?.data?.data?.user_profiles?.[0],
          role: response?.data?.data?.role?.[0],
          loading: false
        })
      }).catch((error) => {
        console.log(error)
      });
  }
  //initial load
  React.useEffect(() => {
    getProfileDetails()
  }, [])

 // logout
 const getLogoutdeviceToken = () => {
  const params = {
    token: localStorage.getItem(LocalStorageKeys?.deviceToken),
    app_build: config.app_x_build,
  };
  NetworkCall(
    `${config.api_url}/logout`,
    NetWorkCallMethods.post,
    params,
    null,
    true,
    false
  )
    .then((response) => {
      localStorage.clear();
      firebaseContext.deleteToken();
      history.push(Routes.login);
    })
    .catch((error) => {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: "Some Thing Went Wrong.",
      });
    });
};
  return (
    <Box>
      {
        data?.loading ?
          <ProfileSimmaer />
          :
          <Box>
            <Stack className={classes.imageText} direction="row" spacing={2}>
              {/* Profile Image Section */}

              <div className={classes.imageSet}>
                <Avatar variant="circular" className={classes.avatarImg1}>
                  <Avatar variant="circular" className={classes.avatarImg2}>
                    {
                      data?.user?.image_url &&
                      <img
                        className={classes.profilePicture}
                        src={data?.user?.image_url}
                        alt="profile_image"
                      />
                    }

                  </Avatar>
                </Avatar>
                <Button variant="contained" className={classes.editButton}>
                  <Typography
                    className={classes.editText}
                    style={{
                      fontSize: "12px",
                      color: "#FFFFFF",
                    }}
                  >
                    <div onClick={() => history.push(Routes.profileEdit)}>
                      {t("edit")}
                    </div>
                  </Typography>
                </Button>
              </div>

              {/* Person Details Section */}

              <div className={classes.textSet}>
                <div>
                  <Typography className={classes.name}>
                    {data?.user?.first_name ?? ""}&nbsp;{data?.user?.last_name ?? ""}
                  </Typography>
                </div>
                <Grid className={classes.personRole}>
                  <Box className={classes.box1}>
                    <Typography className={classes.type}>Inspector</Typography>
                  </Box>
                  {
                    data?.role?.client_role_no &&
                    <Box className={classes.box2}>
                      <Typography className={classes.role}>{data?.role?.client_role_no}</Typography>
                    </Box>
                  }

                </Grid>
              </div>
            </Stack>
            {/* Card Section */}
            <Grid container className={classes.background}>
              <Grid item xs={12}>
                <Grid container className={classes.top} spacing={2}>

                  <Grid item xs={6}>
                    <Box className={classes.cardBox1}>
                      <div className={classes.icon1}>
                        <ContactIcon />
                      </div>
                      <div className={classes.contactNum}>
                        <Typography>{data?.user?.mobile_no_country_code ?? ""}&nbsp;
                          {data?.user?.mobile_no ?? ""}</Typography>
                        <Typography> {data?.user?.alternative_mobile_no_country_code ?? ""}&nbsp;
                          {data?.user?.alternative_mobile_no ?? ""}</Typography>
                      </div>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box className={classes.cardBox1}>
                      <div className={classes.icon1}>
                        <MessageIcon />
                      </div>
                      <div className={classes.contactMail}>
                        <Typography>{data?.user?.email_id ?? ""}</Typography>
                        <Typography>{data?.user?.alternative_email_id ?? ""}</Typography>
                      </div>
                    </Box>
                  </Grid>

                </Grid>
                <Grid container className={classes.bottom}>
                  <Grid item xs={12}>
                    <Box className={classes.bigCardBox}>
                      <div className={classes.mapIcon}>
                        <MapIcon />
                      </div>
                      <div className={classes.address}>
                        <Typography className={classes.address1}>
                          {data?.user?.contact?.street_1 ? `${data?.user?.contact?.street_1}, ` : ""}{data?.user?.contact?.street_2 ? `${data?.user?.contact?.street_2}, ` : ""}
                          {data?.user?.contact?.street_3 ? `${data?.user?.contact?.street_3}, ` : ""}
                          {data?.user?.contact?.district ? `${data?.user?.contact?.district}, ` : ""}
                          {data?.user?.contact?.state ? `${data?.user?.contact?.state}, ` : ""}
                          {data?.user?.contact?.country ? `${data?.user?.contact?.country}, ` : ""}
                          {data?.user?.contact?.zipcode ? `- ${data?.user?.contact?.zipcode}` : ""}
                        </Typography>

                      </div>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container className={classes.lastBottom} onClick={getLogoutdeviceToken}>
                  <Grid item xs={12}>
                    <Box className={classes.logOut}>
                      <div className={classes.logOutSection}>
                        <div className={classes.logOutSvg}>
                          <div className={classes.logOutIcon}>
                            <LogOutIcon />
                          </div>
                          <Typography className={classes.logOutText}>
                            {t("log_out")}
                          </Typography>
                        </div>
                        <div className={classes.rightArrow}>
                          <ChevronRightIcon />
                        </div>
                      </div>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>

            </Grid>
          </Box>
      }


    </Box>
  );
};
export default withNamespaces("profile")(ProfilePage);