import React from "react";

export const UpdateTimeIcon = ()=>(
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
  <g id="icons8-story-time" transform="translate(-4 -4)">
    <path id="Path_99151" data-name="Path 99151" d="M36,20A16,16,0,1,1,20,4,16.047,16.047,0,0,1,36,20Z" fill="#7e57c2"/>
    <path id="Path_99152" data-name="Path 99152" d="M33.6,20.8A12.8,12.8,0,1,1,20.8,8,12.838,12.838,0,0,1,33.6,20.8Z" transform="translate(-0.8 -0.8)" fill="#eee"/>
    <path id="Path_99153" data-name="Path 99153" d="M36.6,20.8v.8H23V8h.8A12.838,12.838,0,0,1,36.6,20.8Z" transform="translate(-3.8 -0.8)" fill="#ffccbc"/>
    <path id="Path_99154" data-name="Path 99154" d="M20,36.2h1.6V33H20ZM8,24.2h3.2V22.6H8v1.6Zm15.2-.8A2.4,2.4,0,1,1,20.8,21a2.357,2.357,0,0,1,2.4,2.4" transform="translate(-0.8 -3.4)" fill="#455a64"/>
    <path id="Path_99155" data-name="Path 99155" d="M23,11h1.6v8.8H23Zm11.2,9.6v1.6H25.4V20.6Z" transform="translate(-3.8 -1.4)" fill="#455a64"/>
    <path id="Path_99156" data-name="Path 99156" d="M24.6,23.8a.8.8,0,1,1-.8-.8.756.756,0,0,1,.8.8" transform="translate(-3.8 -3.8)" fill="#eee"/>
    <path id="Path_99157" data-name="Path 99157" d="M29.74,6.24l.16.64a1.275,1.275,0,0,0,1.04,1.04l.64.16c.32.08.32.56,0,.56l-.64.16A1.275,1.275,0,0,0,29.9,9.84l-.16.64c-.08.32-.56.32-.56,0l-.16-.64A1.275,1.275,0,0,0,27.98,8.8l-.64-.16c-.32-.08-.32-.56,0-.56l.64-.16a1.275,1.275,0,0,0,1.04-1.04l.16-.64C29.18,5.92,29.66,5.92,29.74,6.24Z" transform="translate(-4.62 -0.4)" fill="#ff8a65"/>
    <path id="Path_99158" data-name="Path 99158" d="M30.735,17.155l.08.48a.946.946,0,0,0,.72.72l.48.08c.24.08.24.32,0,.4l-.48.08a.946.946,0,0,0-.72.72l-.08.48c-.08.24-.32.24-.4,0l-.08-.48a.946.946,0,0,0-.72-.72l-.48-.08c-.24-.08-.24-.32,0-.4l.48-.08a.946.946,0,0,0,.72-.72l.08-.48C30.415,16.915,30.735,16.915,30.735,17.155Z" transform="translate(-4.975 -2.595)" fill="#ff7043"/>
    <path id="Path_99159" data-name="Path 99159" d="M40.735,12.155l.08.48a.946.946,0,0,0,.72.72l.48.08c.24.08.24.32,0,.4l-.48.08a.946.946,0,0,0-.72.72l-.08.48c-.08.24-.32.24-.4,0l-.08-.48a.946.946,0,0,0-.72-.72l-.48-.08c-.24-.08-.24-.32,0-.4l.48-.08a.946.946,0,0,0,.72-.72l.08-.48C40.415,11.915,40.735,11.915,40.735,12.155Z" transform="translate(-6.975 -1.595)" fill="#ff8a65"/>
  </g>
</svg>

)