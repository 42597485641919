import { useApolloClient } from "@apollo/client";
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { withNamespaces } from "react-i18next";
import { useHistory } from "react-router-dom";
import LocationIcon from "../../assets/locationIcon";
import { LogoUpload, MobileNumberInputComponent, TextBox, TitleBar } from "../../components";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { UPDATE_PROFILE } from "../../graphql/mutations";
import { NetworkCall } from "../../networkcall";
import { Routes } from "../../router/routes";
import { AlertProps, NetWorkCallMethods } from "../../utils";
import { initialState, returnEditPayload } from "../../utils/profile";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { profileIEditStyles } from "./style";

const ProfileEdit = ({ t }) => {
  const classes = profileIEditStyles();
  const history = useHistory();
  const client = useApolloClient();
  const alert = React.useContext(AlertContext);
  const size = useWindowDimensions();
  const [data, setData] = React.useState({ ...initialState });

  const getDetails = () => {
    NetworkCall(
      `${config.api_url}/user-info`,
      NetWorkCallMethods.get,
      null,
      null,
      true,
      false
    ).then((res) => {
      const result = returnEditPayload(res?.data?.data?.user_profiles?.[0]);
      console.log(result)
      setData(result)
    })
  }
  //initial load
  React.useEffect(() => {
    getDetails()
  }, [])
  //update state
  const updateState = (key, value) => {
    let error = data.error;
    error[key] = "";
    setData({ ...data, [key]: value, error });
  };
  //validate form
  const isFormValid = () => {
    let isValid = true;
    let error = data.error;
    if (data.primaryContact.length === 0) {
      isValid = false;
      error.primaryContact = t("primary_contact_required");
    }

    setData({ ...data, error });
    return isValid;
  };
  //on submit
  const onSubmit = () => {
    const payload = {
      userId: data?.userProfileID,
      update: {
        mobile_no: data?.primaryContact?.mobile,
        mobile_no_country_code: data?.primaryContact?.mobile_code,
        alternative_mobile_no: data?.alternativeMobile?.mobile,
        alternative_mobile_no_country_code: data?.alternativeMobile?.mobile_code,
        image_url: data?.profileImage?.src,
      }
    }
    if (isFormValid()) {

      client.mutate({
        mutation: UPDATE_PROFILE,
        variables: payload
      }).then((res) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: t("ProfileUpdatedSuccessfully"),
        });
        history.push(Routes.profilePage)
      }).catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("SomethingWentwrong"),
        });
      })
    }
  }
  //go back
  const goBack = () => {
    history.push(Routes.profilePage)
  }

  return (
    <Grid
      className={classes.screen}
      style={{ height: size?.height }}
      item
      xs={12}
    >
      <TitleBar
        className={classes.titleText}
        arrow
        text={t("ProfileEdit")}
        goBack={goBack}
      />
      <Box className={classes.background} >

        <Box p={2}>
          <LogoUpload
            handleChange={(val) => updateState("profileImage", val)}
            data={data?.profileImage}
          />
          <Box height="10px" />
          <TextBox
            label={t("name")}
            disabled
            value={data?.firstName}
            onChange={(val) => updateState("firstName", val)}
          />
          <Box height="10px" />
          <MobileNumberInputComponent
            label={t("PrimaryContact")}
            placeholder=""
            value={data?.primaryContact}
            handleChange={(val) => updateState("primaryContact", val)}
            isRequired
            isError={data?.error?.primaryContact?.length > 0}
            errMessage={data?.error?.primaryContact}
          />
          <Box height="10px" />
          <MobileNumberInputComponent
            label={t("AlternativeContact")}
            placeholder=""
            value={data?.alternativeMobile}
            handleChange={(val) => updateState("alternativeMobile", val)}
          />
          <Box height="10px" />
          <TextBox
            className={classes.emailBox}
            label={t("EmailAddress")}
            value={data?.primaryEmailID}
            color={true}
          />
        </Box>
        <div className={classes.InputBoxesAdd}>
          <Grid className={classes.addressInputBox} container direction={"row"}>
            <Typography className={classes.givenAddress}> {t("Address")}</Typography>
          </Grid>
          <Stack className={classes.locationAdd} direction="column">
            <div className={classes.editBtn}>
              <Typography
                onClick={() => history.push(Routes.profileAddAddress)}
                className={classes.editAddress}
              >
                {t("edit")}
              </Typography>
            </div>
            <Stack
              spacing={2}
              direction="row"
              className={classes.addressInputBoxSecond}
            >
              <div>
                <LocationIcon />
              </div>
              <div className={classes.innerAddress}>
                <Typography className={classes.Address1}>
                  {t("Address")}
                </Typography>
                <Typography className={classes.Address2}>
                  {data?.address}
                </Typography>
              </div>
            </Stack>
          </Stack>
        </div>
      </Box>
      <Grid xs={12} className={classes.saveNUpdateButtonContainerStyle}>
        <Button
          variant="contained"
          fullWidth
          className={classes.iconButtonStyle}
          onClick={onSubmit}
        >
          <Typography className={classes.saveNUpdateButtonTextStyle}>
            {"Save & Update"}
          </Typography>
        </Button>
      </Grid>
    </Grid>
  );
};
export default withNamespaces("profile")(ProfileEdit);