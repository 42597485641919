
import gql from "graphql-tag";
// export const yourMutation = gql``;
export const UPDATE_PROFILE = gql`mutation($userId: String, $update: user_profilesInput) {
    user_profiles(
    update: $update
    where: { id: { eq: $userId } }

    ) {
        id
        user_id
        alternative_mobile_no
        email_id
        first_name
        last_name
        mobile_no
    }
}
`;


export const updateDeviceToken = `
mutation($insert:[user_device_tokenOutput!]) {
 user_device_token(
    insert: $insert

  ) {
   id
  }
}`;

export const LOG_OUT_TOKEN = `
mutation($id:String,$token:String,$data:user_device_tokenInput) {
user_device_token(
    where: { user_profile_id: { eq: $id },token:{eq:$token} }
    update: $data

  ) {
    id
  }
}

`;

export const UPDATEADDRESS = `
mutation($contactID: ID, $updatedAddress: contactInput) {
  contact(
    where: { id: { eq: $contactID } }
    update: $updatedAddress

  ) {
    id
    street_1
    street_2
    street_3
    district
    city
    state
    zipcode
    country
    latitude
    longitude
  }
}
`;

export const updateAgreementInspections = gql`
mutation {
  agreement_inspection(update: $update, where: { id: { eq: $id } }) {
    id
  }
}

`
export const RemoveInspectionItem = gql`
mutation {
  inspection_activity(update: $update, where: { id: { eq: $id } }) {
    id
  }
}

`
export const UPDATE_PARKING_SLOT_MASTER = gql`
mutation {
  parking_slot(
    where: { agreement_id: $agreement_id, parking_id: $parking_id }
    update: $payload
  ) {
    id
  }
}
`
export const UPDATE_PARKING_SLOT_MASTER_BY_UNIT = gql`
mutation {
  parking_slot(
    where: { agreement_id: $agreement_id, agreement_unit_id: $agreement_unit_id }
    update: $payload
  ) {
    id
  }
}
`
export const UNREADNOTIFICATION = `
mutation($id: String, $updatedData: notification_logInput) {
  notification_log(
    where: { notification_id: { eq: $id } }
    update: $updatedData

  ) {
    id
    is_read
  }
}`;
export const PARKING_SLOT_MASTER = gql`
query {
  parking_slot(
    where: { agreement_id: $agreement_id, parking_id: $parking_id }
  ) {
    id
    number_plate
    vehicle_type
    vehicle_image
  }
}
`
export const PARKING_SLOT_MASTER_BY_UNIT = gql`
query {
  parking_slot(
    where: { agreement_id: $agreement_id, agreement_unit_id: $agreement_unit_id }
  ) {
    id
    number_plate
    vehicle_type
    vehicle_image
  }
}
`
export const UpdateTotalHours = gql`
mutation {
  inspection_resources(
  update: $update
  where: { id: { eq: $id } }
  ) {
      id
  }
}
`
export const getAgreementInspectionDetails = gql`
query{
  agreement_inspection( where: { id: $agreement_inspection_id})
    {
      id
      scope_of_work
    }
  }
`