import { Container, Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useHistory } from "react-router-dom";
import { Dashboard, Inspections, Profile } from "../assets";
import { UtilityIcon } from "../assets/utilityIcon";
import { BottomNavbar, TitleBar, TopNavBars } from "../components";
import { Routes } from "../router/routes";
import useWindowDimensions from "../utils/useWindowDimensions";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: "100%",
    overflow: "hidden",
  },

  screenPadding: {
    overflow: "overlay",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    backgroundColor: "#F2F5FA"
  }
}));

const withBottombar = (Component, params) => (props) => {
  const classes = useStyles({ props });
  const history = useHistory();
  const size = useWindowDimensions();

  const list = [
    {
      label: "Dashboard",
      select: window.location.pathname === Routes.dashboard,
      icon: <Dashboard
        color={window.location.pathname === Routes.dashboard ? "#B20606" : "none"}
        colors={window.location.pathname === Routes.dashboard ? "#B20606" : "#98a0ac"}
      />,
      onClick: () => history.push(Routes.dashboard),
    },
    {
      label: "Inspection",
      select: window.location.pathname === Routes.inspections,
      icon: <Inspections
        select={window.location.pathname === Routes.inspections}
      />,
      onClick: () => history.push(Routes.inspections),
    },
    {
      label: "Utilities",
      select: window.location.pathname === Routes.utilities,
      icon: <UtilityIcon color={window.location.pathname === Routes.utilities ? "#B20606" : "#98a0ac"} />,
      onClick: () => history.push(Routes.utilities),
    },
    {
      label: "Profile",
      select: window.location.pathname === Routes.profilePage,
      icon: <Profile
        select={window.location.pathname === Routes.profilePage}
      />,
      onClick: () => history.push(Routes.profilePage),
    },
  ]

  const goBack = () => {
    history.goBack()
  }

  return (
    <div className={classes.root}>
      {/* Your nav bars here */}
      <Container maxWidth="sm" sx={{ padding: 0 }}>
        <Grid
          className={classes.screen}
          style={{ height: size?.height - 10 }}
          item
          xs={12}
        >
          <Grid>
            {
              params?.titleBar ?
                <TitleBar goBack={goBack} arrow={params?.goBack} text={params?.title} />
                :
                <TopNavBars note={params?.note} handleClicknote={() => history.push(Routes.notification)} />
            }

          </Grid>
          <Grid className={classes.screenPadding} style={{ height: size?.height - (params?.titleBar ? 125 : 132), overflow: "auto" }}>
            <Component {...props}>{props.children}</Component>
          </Grid>
          <Grid>
            <BottomNavbar list={list} />
          </Grid>
        </Grid>
      </Container>


    </div>
  );
};

export default withBottombar;
