import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import { config } from "../config";
import { NetworkCall } from "../networkcall";
import { Routes } from '../router/routes';
import { NetWorkCallMethods } from "../utils";
export const mapResult = (map) => {
      let updateData = {
            latitude: map?.lat,
            longitude: map?.lng
      };
      if (map) {
            let keys = ['doorNo', 'addressLineOne', 'addressLineTwo', 'landmark', 'area', 'district', 'city', 'state', 'country', 'pincode', 'zipcode'];
            let findAddress = map?.result?.address_components;
            keys?.map(_ => {
                  switch (_) {
                        case 'doorNo':
                              updateData[_] = findAddress?.find(_ => _?.types?.some(type => ["premise"]?.includes(type)))?.long_name ?? ''
                              break;
                        case 'addressLineOne':
                              updateData[_] = findAddress?.find(_ => _?.types?.some(type => ["establishment", "street_number"]?.includes(type)))?.long_name ?? ''
                              break;
                        case 'addressLineTwo':
                              updateData[_] = findAddress?.find(_ => _?.types?.some(type => ["neighborhood", "route",]?.includes(type)))?.long_name ?? ''
                              break;
                        case 'landmark':
                              updateData[_] = findAddress?.find(_ => _?.types?.includes('landmark'))?.long_name ?? ''
                              break;
                        case 'area':
                              updateData[_] = findAddress?.find(_ => _?.types?.includes("political", "locality"))?.long_name ?? ''
                              break;
                        case 'district':
                              updateData[_] = findAddress?.find(_ => _?.types?.includes('administrative_area_level_2'))?.long_name ?? ''
                              break;
                        case 'city':
                              updateData[_] = findAddress?.find(_ => _?.types?.includes('administrative_area_level_2'))?.long_name ?? ''
                              break;
                        case 'state':
                              updateData[_] = findAddress?.find(_ => _?.types?.includes('administrative_area_level_1'))?.long_name ?? ''
                              break;
                        case 'country':
                              updateData[_] = findAddress?.find(_ => _?.types?.includes('country'))?.long_name ?? ''
                              break;
                        case 'pincode':
                              updateData[_] = findAddress?.find(_ => _?.types?.includes('postal_code'))?.long_name ?? ''
                              break;
                        case 'zipcode':
                              updateData[_] = findAddress?.find(_ => _?.types?.includes('postal_code'))?.long_name ?? ''
                              break;
                        default:
                              return null
                  }
                  return _
            })
      }

      return updateData
}

// sigle images
export const singleImageUpload = async (data) => {
      const formData = new FormData();
      formData.append("files", data);
      formData.append("tenantId", `${config.tenantid}`);

      let image = await NetworkCall(
            `${config.api_url}/file-upload`,
            NetWorkCallMethods.post,
            formData,
            null,
            true,
            false
      )

      return image.data.fileUrls[0].url

}

// multiple images
export const multiImageUpload = async (data, { companyId }) => {
      const formData = new FormData();
      formData.append("files", data);
      formData.append("tenantId", `${config.tenantid}`);
      formData.append("companyId", `${companyId}`);

      let image = await NetworkCall(
            `${config.api_url}/file-upload`,
            NetWorkCallMethods.post,
            formData,
            null,
            true,
            false
      )
      return image.data.fileUrls[0].url

};
// access companies
export const companyDetails = JSON.parse(localStorage.getItem('accessType'))


/**
 * Function to get ENUM dynamically
 * @param enumTypes `[String]`
 * @returns `Object`
 */
export const enumSelect = async (enumTypes) => {
      let result;

      const payload = { enumName: enumTypes, };

      await NetworkCall(
            `${config.api_url}/enum`,
            NetWorkCallMethods.post,
            payload, null, true, false
      ).then((res) => {
            result = res?.data?.data;
      }).catch((err) => {
            console.log(err)
      })

      return result;
};


export const enumCatagory = {
      notifications_content_type: 'notifications_content_type',
      security_status_enum_type: 'security_status_enum_type',
      security_request_type: 'security_request_type',
      delivery_status_type: 'delivery_status_type',
      general_maintenance_type: 'general_maintenance_type',
      swift_type: 'swift_type',
      cleanliness_type: "cleanliness_type",
      condition_type: "condition_type",
      agreement_inspection_status_type_new:"agreement_inspection_status_type_new"
}

// get types
export const keyMapSource = ({ enumType }) => {
      return enumType.flatMap((item, index) => {
            return {
                  [`type${index + 1}`]: item?.value
            }
      }).reduce((old, item) => (
            { ...old, ...item }
      ), {})

}
// IOS TOGGLE

export const IOSSwitch = styled((props) => (
      <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
      width: 42,
      height: 26,
      padding: 0,
      "& .MuiSwitch-switchBase": {
            padding: 0,
            margin: 2,
            transitionDuration: "300ms",
            "&.Mui-checked": {
                  transform: "translateX(16px)",
                  color: "#fff",
                  "& + .MuiSwitch-track": {
                        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
                        opacity: 1,
                        border: 0,
                  },
                  "&.Mui-disabled + .MuiSwitch-track": {
                        opacity: 0.5,
                  },
            },
            "&.Mui-focusVisible .MuiSwitch-thumb": {
                  color: "#33cf4d",
                  border: "6px solid #fff",
            },
            "&.Mui-disabled .MuiSwitch-thumb": {
                  color:
                        theme.palette.mode === "light"
                              ? theme.palette.grey[100]
                              : theme.palette.grey[600],
            },
            "&.Mui-disabled + .MuiSwitch-track": {
                  opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
            },
      },
      "& .MuiSwitch-thumb": {
            boxSizing: "border-box",
            width: 22,
            height: 22,
      },
      "& .MuiSwitch-track": {
            borderRadius: 26 / 2,
            backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
            opacity: 1,
            transition: theme.transitions.create(["background-color"], {
                  duration: 500,
            }),
      },
}));


export const checkManualHours = (assignedHr,actualHr,Manualhour) =>{
      if(actualHr<=assignedHr && Manualhour >0){
            if(actualHr===0  && Manualhour <=1){
               return true
            }
            else if(actualHr===1  && Manualhour <=1){
                  return true
            }
            else if(actualHr>1&&Manualhour<=actualHr){
                  return true
            }
            else {
                  return false
            }
        }
        else if(actualHr>assignedHr&&Manualhour>0){
            if(Manualhour>assignedHr&&Manualhour<=actualHr){
                  return true
            }
            else{
               return false
            }
        }
        else {
            return false
        }
}

export const RemoveZUTCDateTime = (val) =>{
      return val?.replace('Z','')
}

export const switchRoutes = (val) => {
      switch (val) {
        case "move-in":
          return Routes?.moveIn
        case "move-out":
          return Routes?.moveOut
        case "unit_handover":
          return Routes?.unitHandover
        case "service":
          return Routes?.serviceInspection
        case "site_visit":
          return Routes?.siteVisit
        case "unit_readiness":
          return Routes?.unitReadiness
        case "general":
          return Routes?.generalInspection
        default:
          return Routes?.inspections
      }
    }
    export  const RequestType = (val) => {
      switch (val) {
          case "move-in":
              return "Move In"
          case "move-out":
              return "Move Out"
          case "service":
              return "Service"
          case "unit_readiness":
              return "Unit Readiness"
          case "unit_handover":
              return "Unit Handover"
          case "site_visit":
              return "Site Visit"
          case "general":
              return "General"
          default:
              return val
      }
  }
