import makeStyles from "@mui/styles/makeStyles";
import { SemiBold } from "../../utils";

export const useStyles = makeStyles((theme) => ({
    Label: {
        color: theme.typography.color.Tertiary,
        fontSize: "12px",
        paddingBottom: "8px",
        fontFamily: SemiBold
    },

}));