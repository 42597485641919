import { Box } from "@mui/material";
import React from "react";
import { TextBox } from "../textbox";
export const FillComponent = ({ lists = [], setList = () => false }) => {

    //const classes = useStyles();

    const updateState = (key, value, index) => {
        let allProperty = [...lists];
        allProperty[index] = {
            ...allProperty[index],
            [key]: value,
        };
        setList(allProperty)
    }


    return (
        <Box>
            {
                lists?.map((val, index) => {
                    return (
                        <Box marginTop="10px">
                            <TextBox
                                label={`${val?.name}(Serial No/Product ID)`}
                                value={val?.serial_number}
                                onChange={(e) => updateState("serial_number", e.target.value, index)}
                                placeholder="Enter Serial Number" />
                        </Box>

                    )
                })
            }

        </Box>
    )
}