import * as React from "react"

export const Call = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={17.024}
        height={15.487}
        {...props}
    >
        <path
            data-name="icons8-call (1)"
            d="m13.27 13.369-.586.532a2.282 2.282 0 0 1-2.207.458Q7.664 13.5 5.224 9.505T3.398 2.79a2.114 2.114 0 0 1 1.54-1.577l.784-.209a1.625 1.625 0 0 1 1.858.864l.668 1.377a1.424 1.424 0 0 1-.318 1.7l-1.038.92a.535.535 0 0 0-.177.49 6.135 6.135 0 0 0 .734 1.992 5.714 5.714 0 0 0 1.33 1.6.53.53 0 0 0 .486.092l1.483-.442a1.622 1.622 0 0 1 1.74.588l.928 1.229a1.444 1.444 0 0 1-.146 1.955Z"
            fill="#4e5a6b"
        />
    </svg>
)

