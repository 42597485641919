import { Box, IconButton, Typography } from "@mui/material";
import { AvailableSlot } from "../../../assets";
import { TickIcons } from "../../../assets/tickIcon";
import { useStyles } from "./styles";

export const SlotCard = ({ data = {}, title = "", selectedTitle = "", onClick = () => false, value = {} }) => {
    const classes = useStyles();
    console.log(value)
    return (
        <Box
            //sx={{ background: 'linear-gradient(180deg, white, #FFE6E6) !important' }}
            className={value?.id === data?.id ? classes.slotselectedCard : classes.slotCard}>
            <center>
                <Typography noWrap className={classes.slot_no}>
                    {data?.slot_no}
                </Typography>
                {
                    value?.id === data?.id ?
                        <Box>
                            <Typography className={classes.selectedTitle}>{selectedTitle}</Typography>

                            <IconButton size="small" >
                                <TickIcons />
                            </IconButton>
                        </Box>
                        :
                        <Box>
                            <Typography className={classes.availableTitle}>{title}</Typography>
                            <IconButton size="small" onClick={() => onClick(data)}>
                                <AvailableSlot />
                            </IconButton>
                        </Box>

                }


            </center>


        </Box>

    )
}