import * as React from "react";

const MessageIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={28} height={28} {...props}>
    <g data-name="Group 4325" transform="translate(-1977.811 16211.189)">
      <circle
        data-name="Ellipse 39546"
        cx={14}
        cy={14}
        r={14}
        transform="translate(1977.811 -16211.189)"
        fill="#6a68cc"
      />
      <path
        data-name="icons8-mail (5)"
        d="M1987.686-16202.189a1.878 1.878 0 0 0-1.869 1.719l6.244 3.371 6.244-3.372a1.878 1.878 0 0 0-1.869-1.718Zm-1.875 2.778v5.347a1.876 1.876 0 0 0 1.875 1.875h8.75a1.876 1.876 0 0 0 1.875-1.875v-5.347l-6.028 3.259a.468.468 0 0 1-.444 0Z"
        fill="#fff"
      />
    </g>
  </svg>
);

export default MessageIcon;
