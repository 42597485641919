import * as React from "react"

export const TickIcons = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
        <path
            data-name="icons8-ok 1"
            d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm4.281 8.281-5 5a.751.751 0 0 1-1.061 0l-2.5-2.5A.75.75 0 0 1 6.78 9.72l1.969 1.969 4.47-4.47a.75.75 0 0 1 1.062 1.061Z"
            fill="#b20606"
        />
    </svg>
)

