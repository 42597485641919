import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Avatar, Box, Stack, Typography } from "@mui/material";
import { Call, UnitType } from "../../assets";
import { useStyles } from "./styles";

export const ParkingUnitCard = ({ data, selected = null, onClick = () => false }) => {
    const classes = useStyles();
    return (
        <Box className={classes.root}
            sx={{ border: `1px solid ${data?.id === selected ? "#B20606" : "#CED3DD"}` }}
            onClick={() => onClick(data)}
        >
            {
                data?.id === selected &&
                <CheckCircleIcon className={classes.tick} />
            }

            <Box display="flex" alignItems="center">
                <Box>
                    <Avatar className={classes.propertyImage} src={data?.logo} />
                </Box>
                <Box marginLeft="8px">
                    <Typography className={classes.title}>{data?.title}</Typography>
                    <Typography className={classes.name}>{data?.name}</Typography>
                    <Box display="flex" alignItems="center" justifyContent="space-between" marginTop="4px">
                        <Box flexGrow={1}>
                            <Stack direction="row" alignItems="center" spacing={0.5}>
                                <Call />
                                <Typography className={classes.subTitle}>{data?.mobile}</Typography>
                            </Stack>
                        </Box>
                        <Box marginLeft="12px">
                            <Stack direction="row" alignItems="center" spacing={1}>
                                <UnitType />
                                <Typography className={classes.subTitle}>{data?.type}</Typography>
                            </Stack>
                        </Box>

                    </Box>
                </Box>
            </Box>

        </Box>
    )
}