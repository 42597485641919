import makeStyles from "@mui/styles/makeStyles";
import { Bold } from "../../utils";

export const useStyles = makeStyles((theme) => ({
      backgroundImage: {
            backgroundColor: "#7EA1FF",
            height: "213px",
            backgroundImage: `url("/images/bgNew.png")`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "100%"
      },
      content: {
            width: "inherit",
      },
      text: {
            fontSize: "24px",
            fontFamily: Bold,
            color: theme.typography.color.primary,
      },
      btn: {
            borderRadius: "4px",
            boxShadow: "0px 6px 10px #00000014",
            padding: "12px",
            backgroundColor: theme.palette.primary.main,
            "&:hover": {
                  color: "#FFFFFF",
                  backgroundColor: theme.palette.primary.main,
            },
      },
      buttonTextStyle: {
            fontSize: "14px",
            color: "#FFFFFF",
            fontFamily: Bold,
      },
      img: {
            height: "8%",
            width: "24%",
      },
      contentBottom: {
            padding: "12px",
      },

      companyName: {
            color: theme.typography.color.secondary,
            fontFamily: Bold,
            fontSize: "14px",
            marginLeft: '12px'
      },
      companySelect: {
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer'
      },
      comapnyImage: {
            width: 50,
            height: 50,
            borderRadius: 0
      },
      selectCompany: {
            padding: '14px',
            boxShadow: '0px 3px 30px #5C86CB2E',
            margin: '8px 0px',
            borderRadius: 12,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            cursor: 'pointer'
      },
      selectCompanyBorder: {
            padding: '14px',
            boxShadow: '0px 3px 30px #5C86CB2E',
            margin: '8px 0px',
            borderRadius: 12,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            border: `1px solid ${theme.palette.primary.main}`,
            cursor: 'pointer'
      },
      goBackStyle: {
            textAlign: 'center',
            textDecoration: 'underline',
            cursor: 'pointer',
            color: theme.palette.primary.main,
            fontSize: '14px',
            fontFamily: Bold,
            marginTop: '14px'
      },
      selectMain: {
            padding: '16px'
      },
      paddingCompany: {
            padding: "16px 16px 25px 16px"
      }
}));