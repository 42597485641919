import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import makeStyles from "@mui/styles/makeStyles";
import * as React from "react";

const useStyles = makeStyles((theme) => ({
  dialog: {
    width: "100%",
    padding: "0 !important",
    "& .MuiPaper-root": {
      width: "100% !important",
    },
    "& .MuiDialogContent-root": {
      padding: "0 !important",
      borderRadius: "16px"
    },
    "& .MuiDialog-paper": {
      borderRadius: "16px",
      padding: "0 !important",
    },
  },
}));
export const AlertDialog = (props) => {
  const classes = useStyles();

  return (
    <div >
      <Dialog
        open={props?.open}
        onClose={props?.toggleDrawer}
        aria-labelledby="alert-dialog-title"
        className={classes.dialog}
        aria-describedby="alert-dialog-description"
      >
        <DialogContent style={{ padding: "0 !important" }}>
          {props?.component}
        </DialogContent>
      </Dialog>
    </div>
  );
}
