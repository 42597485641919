import { Box, Tab, Tabs, Typography } from "@mui/material"
import React, { useState } from "react"
import { TabStyles } from "./styles"
export const CustomTabs = ({ list = [], handleChange = () => false,activeTab="" }) => {
    const classes = TabStyles()
    const [value, setValue] = useState(activeTab!==""?activeTab:1)

    const handleChangeTab = (value) => {
        setValue(value?.value)
        handleChange(value?.type, value)
    }
    return (
        <Box className={`${classes.header}`}>
            <Tabs
                value={value}
                indicatorColor="none"
                className={classes.tabroot}
                variant="scrollable"
                visibleScrollbar={false}
                scrollButtons={false}
            >
                {list?.map((x) => {
                    return (
                        <Tab
                            onClick={() => handleChangeTab(x)}
                            className={x?.value === value ? classes.tabItemSelect : classes.tabItem}
                            label={<Typography className={x?.value === value ? classes.selectedlabel : classes.label}>{x?.title}</Typography>}
                            value={x?.value}
                        />
                    )
                })}

            </Tabs>
        </Box>
    )
}