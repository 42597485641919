import { Box, Button, Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from 'react';
import { CustomSelect, TextBox, ToggleButtonComponent } from "../index";
import { ConditionBtn } from "../toggleButton/conditionBtn";

export const useStyles = makeStyles((theme) => ({
    Label: {
        color: theme.typography.color.tertiary,
        fontFamily: "NunitoSans-SemiBold",
        fontSize: "14px",
        marginBottom: "5px",
    },
    next: {
        boxShadow: "none",
        padding: "8px",
        textTransform: "capitalize",
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
        }
    },
}));

export const FormGenerator = (props) => {
    const {
        components = [],
    } = props;


    const classes = useStyles()
    const switchComponents = (val) => {

        if (val?.isActive) {

            switch (val.component) {
                case "text":
                    return (
                        <Grid
                            item
                            xs={val?.size?.xs}
                            sm={val?.size?.sm}
                            md={val?.size?.md}
                            lg={val?.size?.lg}
                        >
                            <TextBox
                                isrequired={val?.isRequired}
                                label={val?.label}
                                placeholder={val?.placeholder}
                                value={val.value}
                                onChange={val?.onChange}
                                isError={val?.error?.length > 0}
                                errorMessage={val?.error}
                                multiline={val?.multiline}
                                isReadonly={val?.isReadonly}
                                type={val?.type}
                                startAdornment={val?.startAdornment}
                                endAdornment={val?.endAdornment}
                            />
                        </Grid>
                    );
                case "button":
                    return (
                        <Grid
                            item
                            xs={val?.size?.xs}
                            sm={val?.size?.sm}
                            md={val?.size?.md}
                            lg={val?.size?.lg}
                        >
                            <Button
                                sx={{
                                    padding: "8px",
                                    boxShadow: "none",
                                    marginTop: val?.top ?? "4px",
                                }}
                                className={classes.next}
                                onClick={val?.onClick}
                                variant={val?.variant ?? "contained"}
                                fullWidth
                                disabled={val?.disable}
                                color={'error'}
                            >
                                {val?.label}
                            </Button>
                        </Grid>
                    );
                case "select":
                    return (
                        <Grid
                            item
                            xs={val?.size?.xs}
                            sm={val?.size?.sm}
                            md={val?.size?.md}
                            lg={val?.size?.lg}
                        >
                            <CustomSelect
                                menuPlacement={val?.menuPlacement}
                                parentID={val?.id}
                                isRequired={val?.isRequired}
                                label={val?.label}
                                placeholder={val?.placeholder}
                                value={val.value}
                                zIndex={val?.zIndex}
                                onChange={val?.onChange}
                                error={val?.error?.length > 0}
                                errorText={val?.error}
                                isReadOnly={val?.isReadonly}
                                isPaginate={val?.isPaginate}
                                loadOptions={val?.loadOptions}
                                loading={val?.loading}
                                debounceTimeout={800}
                                options={val?.options ?? []}
                                key={val?.key}
                            />
                        </Grid>
                    );
                case "toggle":
                    return (
                        <Grid
                            item
                            xs={val?.size?.xs}
                            sm={val?.size?.sm}
                            md={val?.size?.md}
                            lg={val?.size?.lg}
                        >
                            <ToggleButtonComponent
                                options={val?.options}
                                label={val?.label}
                                value={val.value}
                                zIndex={val?.zIndex}
                                onChange={val?.onChange}
                                error={val?.error?.length > 0}
                                errorText={val?.error}
                            />
                        </Grid>
                    )
                case "condition_toggle":
                    return (
                        <Grid
                            item
                            xs={val?.size?.xs}
                            sm={val?.size?.sm}
                            md={val?.size?.md}
                            lg={val?.size?.lg}
                        >
                            <ConditionBtn
                                options={val?.options}
                                label={val?.label}
                                value={val.value}
                                onChange={val?.onChange}
                                isError={val?.error?.length > 0}
                                errorMessage={val?.error}
                                isReadOnly={val?.isReadonly}
                                data={val?.data}
                            />
                        </Grid>
                    )

                default:
                    return null;
            }
        }
    }

    return (
        <Box>
            {
                <Grid container spacing={2}>
                    {
                        components?.length && components?.map(_ => (
                            <>
                                {switchComponents(_)}

                            </>
                        ))
                    }
                </Grid>
            }
        </Box>
    )
}