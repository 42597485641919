import { Box } from "@mui/material";
import React from "react";
import { withNamespaces } from "react-i18next";
import { Stepper } from "../../../components";
import { config } from '../../../config';
import { AlertContext } from "../../../contexts";
import { NetworkCall } from '../../../networkcall';
import { AlertProps, LocalStorageKeys, NetWorkCallMethods } from '../../../utils';
import Step1 from "./step1";
import Step2 from "./step2";
import Step3 from "./step3";


const ItemCart = ({ onClose = () => false, agreement_inspection_id = null, reload = () => false,type="",propertyId="",unitId=""}) => {
    const [step, setStep] = React.useState(1);
    const [list, setList] = React.useState([])
    const alert = React.useContext(AlertContext);
    const company = JSON.parse(localStorage.getItem(LocalStorageKeys.accessType))
    const [offset, setOffset] = React.useState(0)

    //get details
    const getDetails = (offsets) => {
        const payload = {
            company_ids: company?.map((val) => val?.company_id),
            offset: offsets,
            limit: 10,
            "is_inventory": [false],
            "active": [true],
            "type":["Inspection"]
        }
        NetworkCall(
            `${config.api_url}/inspection_items/getAll`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((res) => {
            setList(res?.data?.data)
        }).catch((error) => {
            console.log(error)

        });
    }

    React.useState(() => {
        getDetails(0)
    }, [])

    const steps = {
        1: "Add Inspection Item",
        2: "Preview"
    }
    //onclick inspection item
    const changeInspactionItems = (id, bool) => {
        const edited = list.map((val) =>
            val.id === id
                ? {
                    ...val,
                    isActive: bool,
                    open: bool ? true : false,
                    count: bool ? 1 : 0
                }
                : val
        );
        setList(edited)
    }
    //add inspectionitem
    const addInspectionItem = (id, type) => {

        const edited = list.map((val) => {
            let count = type ? val?.count + 1 : val?.count - 1;

            let selected = val.id === id
                ? {
                    ...val,
                    count: count > 0 ? count : 0,
                    isActive: count > 0 ? true : false,
                    open: count > 0 ? true : false,
                }
                : val

            return selected;
        });

        setList(edited)
    }
    //on delete
    const onDeleteAll = () => {
        const edited = list.map((val) => {
            return {
                ...val,
                isActive: false,
                open: false,
                count: 0
            }
        }
        );
        setList(edited)
        setStep(1)
    }
    //onsubmit
    const onSubmit = (data) => {
        const payload = {
            agreement_inspection_id: agreement_inspection_id,
            items: data?.map((val) => {
                return {
                    id: val?.id,
                    inventory_id: val?.inventory_id,
                    serial_number: val?.serial_number
                }
            }),
            property_id: propertyId,
            unit_id: unitId,
            type: type
        }
        NetworkCall(
            `${config.api_url}/move-in-out/add_inspection_item_mapping`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((res) => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.success,
                msg: "Activity Successfully Updated",
            });
            reload()
            onClose()

        }).catch((error) => {
            console.log(error)
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: "Some thing went wrong",
            });
        });
    }
    // on step 2
    const onContinue = (data) => {
        if (step === 1) {
            if (list?.filter(i => i?.is_active)?.length > 0) {
                setStep(2)
            }
        }
        else if (step === 2) {
            data.some((x) => x.item === "Inspection") ? onSubmit(data) : setStep(3)
        } else if (step === 3) {
            onSubmit(data)
        }
    }
    //on previous
    const onPrevious = () => {
        setStep(step - 1)
    }
    //fetch more
    const fetchMoreData = () => {
        setOffset(offset + 10);
        //getDetails(offset + 10)

    }
    const components = {
        1: <Step1
            list={list}
            changeInspactionItems={changeInspactionItems}
            addInspectionItem={addInspectionItem}
            changeStep={onContinue}
            fetchMoreData={fetchMoreData}

        />,
        2: <Step2 list={list}
            changePrevious={() => setStep(1)}
            onDeleteAll={onDeleteAll}
            changeStep={onContinue}
            onPrevious={onPrevious}
            changeInspactionItems={changeInspactionItems}
            addInspectionItem={addInspectionItem} />,
        3: <Step3 list={list}
            changePrevious={() => setStep(1)}
            onDeleteAll={onDeleteAll}
            changeStep={onContinue}
            onPrevious={onPrevious}
            changeInspactionItems={changeInspactionItems} addInspectionItem={addInspectionItem} />
    }
    return (
        <Box>
            <Stepper totalCount={2} title={steps[step]} progressValue={list.some((x)=>x.item==="Inspection")?step*50:step*33.33} stepCount={step} />
            {components[step]}
        </Box>
    )
}
export default withNamespaces("inspections")(ItemCart);