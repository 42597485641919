//import NotificationsIcon from '@mui/icons-material/Notifications';
import { Badge, Box, Grid, IconButton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { TranslateIcon } from "../../assets";
import NotificationIcon from "../../assets/notificationIcon";
const useStyles = makeStyles((theme) => ({
  grow: {},
  appBar: {
    background: theme.palette.primary.contrastText,
    padding: "8px 20px",
    boxShadow: "0px 2px 6px #00000014",
    minHeight:"62px"
  },
  navbarimg: {
    height: "36px",
    marginTop: "4px"
  },
}));

export const TopNavBars = (props) => {
  const classes = useStyles(props);
  const clientLogo = localStorage.getItem("clientLogo")
  const logo = JSON.parse(localStorage.getItem("accessType")) ? JSON.parse(localStorage.getItem("accessType")).filter((val) => val.name === "Inspector").find((item) => item?.company_logo !== null) : null
  return (
    <Grid container className={classes.appBar} alignItems={"center"}>
      <Grid item xs={12}>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>
            {
              (logo?.company_logo || clientLogo) &&
              <img src={logo?.company_logo ?? clientLogo} alt="logo" className={classes.navbarimg} />
            }

          </Box>
          <Box>

            {/* {props?.note && ( */}
              <Box>
                <IconButton
                  size="small"

                >
                  <TranslateIcon />

                </IconButton>

                <IconButton
                  size="small"

                  onClick={props.handleClicknote}
                >
                  {props?.new ? (
                    <Badge color="error" variant="dot">
                      <NotificationIcon />
                    </Badge>
                  ) : (
                    <NotificationIcon />
                  )}
                </IconButton>
              </Box>
            {/* )} */}

          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};
