import { Container } from "@mui/material";
import React from "react";
import ProfileAddAddress from "./profileAddAddress";
class ProfileAddAddressParent extends React.Component {
  render() {
    return (
      <Container maxWidth="sm" sx={{ padding: "0px" }}>
        <ProfileAddAddress />
      </Container>
    );
  }
}

export default ProfileAddAddressParent;
