import React from "react";

export const SandClockIcon = () =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
  <g id="Group_107165" data-name="Group 107165" transform="translate(20056 -2033)">
    <rect id="Rectangle_55706" data-name="Rectangle 55706" width="16" height="16" transform="translate(-20056 2033)" fill="none"/>
    <path id="icons8-hourglass" d="M8.722,6a.722.722,0,1,0,0,1.445v.361a5.009,5.009,0,0,0,3.25,4.65v.089a5.008,5.008,0,0,0-3.25,4.65v.361a.722.722,0,1,0,0,1.445H18.833a.722.722,0,1,0,0-1.444v-.361a5.009,5.009,0,0,0-3.25-4.65v-.089a5.009,5.009,0,0,0,3.25-4.65V7.444a.722.722,0,1,0,0-1.445H8.722Zm1.444,1.444h7.222v.361A3.6,3.6,0,0,1,14.68,11.3a.722.722,0,0,0-.542.7V13a.722.722,0,0,0,.542.7,3.6,3.6,0,0,1,2.708,3.492v.361h-1.06a2.7,2.7,0,0,0-5.1,0h-1.06v-.361A3.6,3.6,0,0,1,12.875,13.7a.722.722,0,0,0,.542-.7V12a.722.722,0,0,0-.542-.7,3.6,3.6,0,0,1-2.708-3.492Zm2.167,2.167a1.444,1.444,0,1,0,2.889,0Z" transform="translate(-20061.777 2028.5)" fill="#ff9340"/>
  </g>
</svg>

)