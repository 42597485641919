import makeStyles from "@mui/styles/makeStyles";
import { Bold, SemiBold } from "../../utils";

export const useStyles = makeStyles((theme) => ({
    root: {
        //backgroundColor: theme.palette.dashboard.primary,

    },
    scroll: {
        overflow: "overlay",
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": {
            display: "none",
        },
    },
    heading: {
        fontFamily: `${SemiBold}`,
        fontSize: "16px",
        color: theme.typography.color.primary,
        marginLeft: "8px"
    },
    tabSection: {
        backgroundColor: "white",
        borderBottom: "1px solid #E4E8EE",
        boxShadow: "0px -1px 6px #00000021"
    },
    propertyblock: {
        backgroundColor: "white",
        padding: "16px",
        borderRadius: "12px",
        boxShadow: "0px 3px 30px #5C86CB2E",
    },
    propertyMainblock: {
        backgroundColor: "white",
        padding: "12px",
        borderRadius: "12px",
        boxShadow: "0px 3px 30px #5C86CB2E",
    },
    propertyName: {
        fontSize: "14px",
        color: theme.typography.color.primary,
        fontFamily: `${Bold}`,
    },
    meterid: {
        fontSize: "12px",
        color: theme.typography.color.primary,
        fontFamily: `${SemiBold}`,
    },
    utilitybg: {
        borderRadius: "8px",
        backgroundColor: "#B2060614",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "50px",
        height: "50px"
    },
    readingdate: {
        fontSize: "12px",
        color: theme.typography.color.secondary
    },
    issueName: {
        borderRadius: theme.palette.borderRadius,
        backgroundColor: "#78B1FE1A",
        color: "#78B1FE",
        padding: "5px",
        height: "20px",
        display: "flex",
        alignItems: "center"
    },
    issuetitle: {
        fontSize: "10px",
        fontFamily: `${Bold}`,
    },
    searchdropdown: {
        backgroundColor: "white",
        border: "1px solid #E4E8EE",
        padding: "10px",
        borderRadius: theme.palette.borderRadius
    },
    unitName: {
        color: "#071741",
        fontSize: "14px",
        fontFamily: `${SemiBold}`,
    },
    stepBlock: {
        backgroundColor: theme.palette.dashboard.primary,
        height: "100vh",
    },
    nodata:{
        fontSize:"14px",
        color:theme.typography.color.tertiary,
        fontFamily: `${SemiBold}`,
        textAlign:"center"
    },
    logoImg:{
        borderRadius:theme.palette.borderRadius
    }
}));