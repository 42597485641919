import { Box, Button, Grid } from "@mui/material"
import React from "react"
import { withNamespaces } from "react-i18next"
import { ActionCard, AddressCard, DetailsCard, DialogDrawer, WorkFlowCard, WorkingHourTrackCard } from "../../../components"
import { config } from "../../../config"
import { AlertContext } from "../../../contexts"
import { NetworkCall } from "../../../networkcall"
import { ActionList, AlertProps, NetWorkCallMethods } from "../../../utils"
import { LoderSimmer } from "../../../components/simmers/card";
import { useStyles } from "./styles"
const Step0 = ({ t, next = () => false, state = {} }) => {
    const classes = useStyles();
    const [isShowDialog, setIsShowDialog] = React.useState(false)
    const [data, setData] = React.useState([])
    const [loading, setLoading] = React.useState(true)
    const [list, setList] = React.useState(ActionList)
    const [location, setLocation] = React.useState({
        lat: "",
        lng: ""
    })
    const alert = React.useContext(AlertContext);
    const getInspectionUnitDetails = async () => {
        const payload = {
            "inspection_id": state?.id,
            "unit_id": state?.unit_id,
            "agreement_id": state?.agreement_id,
            "resource_id": state?.resource_id
        }
        await NetworkCall(
            `${config.api_url}/move-in-out/get_inspection_unit_details`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((res) => {
            setLoading(false)
            setData(res?.data?.data)
        }).catch((error) => {
            setLoading(false)
            console.log(error)
        });

    }
    const sendNotification = async () => {
        const payload = {
            "unit_id": state?.unit_id,
            "agreement_id": state?.agreement_id,
        }
        await NetworkCall(
            `${config.api_url}/move-in-out/notify_arrival`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((res) => {
            const msg = res?.data?.data
            alert.setSnack({
                open: true,
                horizontal: AlertProps.horizontal.center,
                vertical: AlertProps.vertical.top,
                msg: msg,
                severity: AlertProps.severity.success,
            });
        }).catch((error) => {
            console.log(error)
        });
    }
    //initial load
    React.useEffect(() => {
        getInspectionUnitDetails()
        getCurrentLocation()
        // eslint-disable-next-line
    }, [])
    const updateInspectionAction = async (actionName, delayTime, remarks) => {
        const payload = {
            "agreement_inspection_id": state?.id,
            "resource_id": state?.resource_id,
            "type": actionName === "Running late" ? "delayed" : "rejected",
            "delayed_hours": delayTime ?? undefined,
            "description": remarks ?? undefined
        }
        await NetworkCall(
            `${config.api_url}/move-in-out/inspection_action`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((res) => {
            const msg = res?.data?.data
            alert.setSnack({
                open: true,
                horizontal: AlertProps.horizontal.center,
                vertical: AlertProps.vertical.top,
                msg: msg,
                severity: AlertProps.severity.success,
            });
            getInspectionUnitDetails()
        }).catch((error) => {
            console.log(error)
        });
    }
    const showMap = (lat, lng) => {
        window.open(`https://maps.google.com/?q=${lat},${lng}`);
    }
    const enableCall = (countrycode, mobileNo) => {
        window.location = `tel:${countrycode}${mobileNo}`
    }
    const getCurrentLocation = () => {
        let location = null;
        if (window.navigator && window.navigator.geolocation) {
            location = window.navigator.geolocation
        }
        if (location) {
            location.getCurrentPosition(function (position) {
                setLocation({ lat: position.coords.latitude, lng: position.coords.longitude })
            })
        }
    }
    const startWork = async () => {
        const payload = {
            "agreement_inspection_id": state?.id,
            "resource_id": state?.resource_id,
            "start_lat": location?.lat,
            "start_long": location?.lng
        }
        await NetworkCall(
            `${config.api_url}/move-in-out/work_now`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((res) => {
            const msg = res?.data?.data
            alert.setSnack({
                open: true,
                horizontal: AlertProps.horizontal.center,
                vertical: AlertProps.vertical.top,
                msg: msg,
                severity: AlertProps.severity.success,
            });
            next()
        }).catch((error) => {
            console.log(error)
        });
    }
    const actionUpdate = (actionName, type, delayTime, remarks) => {
        if (type === "Send") {
            sendNotification()
        }
        else {
            updateInspectionAction(actionName, delayTime, remarks)

        }
    }
    return (

        <Box>
            {loading ?
                <LoderSimmer count={10} />
                :
                <>
                    <Box p={2} className={classes.root}>
                        <AddressCard title={t("inspectionDateAndLocation")} data={data} t={t} onClick={showMap} />
                        <Box mt={2}>
                            <DetailsCard title={t("unitAndTenantDetails")} data={data} onClick={enableCall} />
                        </Box>
                        <Box mt={2}>
                            <ActionCard title={t("actions")} data={list} t={t} setData={setList} onClick={actionUpdate} actiondetails={data?.action} />
                        </Box>
                        {data?.track?.length > 0 &&
                            <Box mt={2} className={classes.mainsection}>
                                <WorkingHourTrackCard title={t("workinghourTrack")} data={data?.track} t={t} />
                            </Box>
                        }
                    </Box>

                    <Grid className={classes.btnRoot} container spacing={1}>
                        {data?.track?.length > 0 && data?.track?.[data?.track?.length - 1]?.end_date_time?.length !== null ?
                            <Grid item xs={12}>
                                <Button onClick={() => (data?.track?.length > 0 && data?.track?.[data?.track?.length - 1]?.end_date_time !== null) ? startWork() : next()} className={classes.commencebtn} fullWidth>
                                    {data?.track?.[data?.track?.length - 1]?.end_date_time?.length > 0 ? t("resumeWorkNow") : t("next")}
                                </Button>
                            </Grid>
                            :
                            <Grid item xs={12}>
                                <Button onClick={() => setIsShowDialog(true)} className={classes.commencebtn} fullWidth>
                                    {t("commenceWorkNow")}
                                </Button>
                            </Grid>
                        }
                    </Grid>
                    <DialogDrawer
                        header={t("commenceWorkNow")}
                        component={<WorkFlowCard t={t} data={data} next={next} setIsShowDialog={setIsShowDialog} startWork={startWork} />}
                        open={isShowDialog}
                        padding={0}
                        onClose={() => setIsShowDialog(false)}
                    />
                </>
            }
        </Box>
    )
}
export default withNamespaces("inspections")(Step0);
