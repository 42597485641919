import { Box, Button } from "@mui/material";
import React from "react";
import { withNamespaces } from "react-i18next";
import { config } from "../../config";
import { GET_ACTIVITY_BY_ID } from "../../graphql/queries";
import { NetworkCall } from "../../networkcall";
import { NetWorkCallMethods } from "../../utils";
import { initialState, returnActivityEdit } from "../../utils/inspections";
import { UploadComponent } from "../assetUpload";
import { FormGenerator } from "../formGenerator";
import { useStyles } from "./styles";

const MoveInForm = ({
    serial_number = "",
    t, onUpdate = () => false, activity_id = "",
    disable = false,
    moveIn = false,
    type = ""
}) => {
    const classes = useStyles();
    const [data, setData] = React.useState({ ...initialState });
    const [options, setOptions] = React.useState([])
    //get details
    const getActivityDetails = () => {

        const payload = {
            query: GET_ACTIVITY_BY_ID,
            variables: {
                id: activity_id
            },
        };
        NetworkCall(
            `${config.graphql_url}`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                getItemConditionValues(response?.data?.data?.inspection_activity[0]?.inspection_item_mappingByID?.inspection_itemByID?.item)
                const result = returnActivityEdit(response?.data?.data?.inspection_activity[0], serial_number, activity_id);
                response?.data?.data?.inspection_activity[0]?.inspection_activity_feedback?.map((x) => {
                   return  result[x?.inspection_feedback_category?.[0]?.name] = { inspection_activity_id: x.id, ...x?.inspection_feedback_sub_category }
                })
                setData({ ...result })
            })
            .catch((error) => {
                console.log(error)
            });

    }
    const getItemConditionValues = (item_type) => {
        const payload = {
          type: item_type,
        }
        NetworkCall(
            `${config.api_url}/inspection_feedback_sub_category/active`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((res) => {
            const keys = Object.keys(res?.data?.data)
            const result = keys?.map((x, i) => {
                if (res.data?.data?.hasOwnProperty(x)) {
                    return {
                        title: x,
                        list: res.data.data[x]
                    }
                }
                else {
                    return []
                }
                //eslint-disable-next-line
            })
            setOptions(result)
        }).catch((err) => {
            console.log(err)
        })
    }

    React.useEffect(() => {


        if (activity_id) {
            getActivityDetails();
        }
        // eslint-disable-next-line
    }, [activity_id])

    const updateState = (key, value) => {
        let error = data.error;
        error[key] = "";
        setData({ ...data, [key]: value, error });
    };
    const updateActivityFeedBack = (title, value) => {
        setData({
            ...data,
            [title]: value
        })
    }
    //validate form
    const isFormValid = () => {
        let isValid = true;
        let error = data.error;
        if (data.description.length === 0) {
            isValid = false;
            error.description = `${t("description")} ${t("is_required")}`;
        }
        setData({ ...data, error });
        return isValid;
    };
    const form = [
        {
            size: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12
            },
            isActive: true,
            component: "text",
            label: t("product_id"),
            placeholder: `${t("enter")} ${t("product_id")}`,
            value: serial_number,
            onChange: (value) => updateState("product_id", value.target.value),
            error: data?.error?.product_id,
            isRequired: true,
            isReadonly: true
        },
    ]
    const form2 = (val) => [
        {
            size: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12
            },
            isActive: true,
            component: "condition_toggle",
            label: val?.title,
            placeholder: `${t("select")} ${t("feedback")}`,
            value: data?.[val?.title]?.id,
            onChange: (value) => updateActivityFeedBack(`${val?.title}`, value),
            // error: data?.[val?.title].length >0 ?"":`${val?.title} is Requried`,
            isRequired: true,
            options: val.list,
            isReadonly: disable,
            data: val.list

        },
    ]
    const form3 = [
        {
            size: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12
            },
            isActive: true,
            component: "text",
            label: t("description"),
            placeholder: `${t("enter")} ${t("description")}`,
            value: data?.description,
            onChange: (value) => updateState("description", value.target.value),
            error: data?.error?.description,
            isRequired: true,
            multiline: true,
            isReadonly: disable
        },
    ]
    //on submit
    const onSubmit = () => {
        if (isFormValid()) {
            onUpdate(data,options)
        } else {
            return false
        }
    }
    return (
        <Box p={1.5}>
            <Box height="550px" overflow="auto">
                <FormGenerator components={form} />
                <Box mt={1}>
                    {options?.map((val) => {
                        return <FormGenerator components={form2(val)} />
                    })}
                </Box>
                <Box mt={1}>
                    <FormGenerator components={form3} />
                </Box>
                <Box height="12px" />
                <UploadComponent
                    setAssets={(value) => updateState("assets", value)}
                    assets={data?.assets}
                    xs={4}
                    disable={disable}
                />


            </Box>
            <Box sx={{ borderTop: "1px solid #E4E8EE", paddingTop: "12px" }}>
                <Button className={classes.btn} disabled={disable} onClick={onSubmit} fullWidth variant="contained">Update</Button>
            </Box>
        </Box>
    )
}
export default withNamespaces("inspections")(MoveInForm);