import { Box, Grid, Typography } from "@mui/material";
import styled from '@mui/material/styles/styled';
import React from "react";
import ImageViewer from "react-simple-image-viewer";
import { AssetCard } from "./assetCard";
import { UploadBtn } from "./uploadBtn";


const CustomTypography = styled(Typography, {
    shouldForwardProp: prop => prop !== "fontFamily" && prop !== "fontSize" && prop !== "color"
})(({ fontFamily, fontSize, color }) => ({
    fontFamily,
    fontSize,
    color
}))

export const UploadComponent = ({
    logo_title,
    isrequired,
    isError,
    errorMessage,
    handleChange = () => false,
    value = [],
    assets = [],
    setAssets = () => false,
    xs = 2,
    disable = false
}) => {
    const [currentImage, setCurrentImage] = React.useState(0);
    const [isViewerOpen, setIsViewerOpen] = React.useState(false);
    const openImageViewer = React.useCallback((index) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
    }, []);
    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };
    //upload image
    const handleUpload = (value) => {
        setAssets([
            ...assets,
            value
        ])
    }
    //delete asset
    const onDelete = (i) => {
        const edited = assets.map((val, index) =>
            index === i
                ? {
                    ...val,
                    is_active: false
                }
                : val
        );

        setAssets(edited)
    }

    ///initial load

    return (
        <Grid container spacing={1} alignItems="flex-end">

            <Grid item xs={12}>
                {logo_title &&
                    <CustomTypography
                        fontFamily={"NunitoSans-Bold"}
                        fontSize={12}
                        margin="12px 0px 12px 0px"
                        color={"#98A0AC"}
                    >
                        {logo_title}
                        {isrequired && (
                            <Typography variant="caption" style={{ color: "red", marginLeft: 4 }}>
                                *
                            </Typography>
                        )}
                    </CustomTypography>
                }

                {
                    !disable &&
                    < UploadBtn
                        handleChange={handleUpload}

                    />
                }

            </Grid>

            {
                assets?.filter(i => i?.is_active)?.map((val, index) => {
                    return (
                        <Grid item xs={xs} >
                            <Box marginTop="20px">
                                <AssetCard
                                    disable={disable}
                                    onClick={() => openImageViewer(index)}
                                    onDelete={() => onDelete(index)}
                                    x={val} />
                            </Box>

                        </Grid >
                    )
                })
            }
            {isViewerOpen && (
                <ImageViewer
                    src={assets?.filter(i => i?.is_active)?.map((val) => val?.url)}
                    currentIndex={currentImage}
                    onClose={closeImageViewer}
                    disableScroll={false}
                    backgroundStyle={{
                        backgroundColor: "rgba(0,0,0,0.9)",
                    }}
                    closeOnClickOutside={true}
                />
            )}

            <Grid item xs={12}>
                {isError && (
                    <Typography variant={"caption"} color={"error"}>
                        {errorMessage}
                    </Typography>
                )}
            </Grid>

        </Grid >
    )
}