import { Box } from "@mui/material"
import React from "react"
import { useHistory, useLocation } from "react-router-dom"
import { LoadingSection, TitleBar } from "../../../components"
import { config } from "../../../config"
import { AlertContext } from "../../../contexts"
import { NetworkCall } from "../../../networkcall"
import { AlertProps, NetWorkCallMethods } from "../../../utils"
import { useStyles } from "../style"
import { Step1 } from "./step1"
import { Step2 } from "./step2"

const UtilityList = () => {
    const history = useHistory()
    const classes = useStyles()
    const { state } = useLocation()
    const [utilityReading, setUtilityReading] = React.useState([])
    const [open, setOpen] = React.useState({
        bool: false,
        data: {}
    })
    const alert = React.useContext(AlertContext)
    const [loading, setLoading] = React.useState(false)
    const [step, setStep] = React.useState(1)
    const goBack = () => {
        history.goBack()
    }
    const getUtilityReading = (utilityId = "") => {
        const payload = {
            "utlities_id": utilityId
        }
        NetworkCall(
            `${config.api_url}/unit_meter_reading/reading`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((res) => {
            setLoading(false)
            setUtilityReading(res?.data?.data)
        }).catch((error) => {
            console.log(error)
        });
    }
    const onSubmitData = (data) => {
        const payload = {
            data: {
                "present_meter_readings": data?.present_meter_readings,
                "assets": data?.assets,
                "id": data?.id
            }
        }
        NetworkCall(
            `${config.api_url}/unit_meter_reading/upsert`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((res) => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.success,
                msg: res?.data?.data,
            });
            setOpen({ ...open, bool: false })
            getUtilityReading(open?.data?.utility_id)
        }).catch((error) => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: "Something Went Wrong",
            });
        });
    }
    const next = (val) => {
        setStep(step + 1)
        if (val) {
            const utility_details = {
                utility_id: val?.id,
                utility_name: val?.utility_name
            }
            setOpen({ ...open, data: utility_details })
            getUtilityReading(val?.id)
        }
    }

    const prev = () => {
        setStep(step - 1)
    }
    const components = {
        1: <Step1 next={next} prev={prev} />,
        2: <Step2 next={next} prev={prev} utilityReading={utilityReading} onSubmit={onSubmitData} open={open} setOpen={setOpen} />
    }
    return (

        <Box className={classes.stepBlock}>
            <TitleBar goBack={goBack} arrow={true} text={step === 1 ? state?.property_name : open?.data?.utility_name} />
            {loading ?
                <LoadingSection top="10vh" message={"Loading Utility Reading"} />
                :
                <> {components[step]}</>
            }
        </Box>
    )
}
export default UtilityList