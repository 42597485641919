import makeStyles from "@mui/styles/makeStyles/makeStyles";
import { Bold, SemiBold } from "../../utils";

export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#fff",
    cursor: "pointer",
    borderRadius: "4px",
    boxShadow: "0px 3px 30px #5C86CB2E",
    padding: "4px"

  },
  container: {
    //borderBottom: `1px solid ${theme.palette.border.secondary}`,
    // margin: "0px 4px",
  },
  propertyImage: {
    height: 75,
    width: 75,
    marginTop: 4,
    borderRadius: 4,
    position: "relative"
  },
  content: {
    width: "100%",
    marginLeft: "12px",

  },
  status_success: {
    fontSize: 10,
    padding: 4,
    borderRadius: 4,
    textTransform: "capitalize",
    fontFamily: `${Bold}`,
  },
  status_pending: {
    fontSize: 8,
    color: theme.palette.warning.main,
    backgroundColor: theme.palette.warning.light,
    padding: 4,
    borderRadius: 4,
    fontFamily: `${Bold}`,
  },
  propertyId: {
    fontSize: 14,
    fontFamily: `${Bold}`,
    color: theme.typography.color.primary,
    textOverflow: "ellipsis",
    overflow: "hidden",
    width: "155px"
  },
  propertyName: {
    fontSize: 12,
    fontFamily: `${SemiBold}`,
    color: theme.typography.color.primary,
    textAlign: "left"

  },
  unitName: {
    fontSize: 10,
    fontFamily: `${SemiBold}`,
    color: theme.typography.color.primary,
    marginTop: "4px"
  },
  dateAndType: {
    fontSize: 12,
    color: theme.typography.color.Tertiary,
    paddingLeft: 8
  },
  dot: {
    height: "6px",
    width: "6px",
    borderRadius: "50%",
    backgroundColor: "#CED3DD",
    margin: "6px",
  },

  unit_category: {
    position: "absolute",
    backgroundColor: "#071741",
    color: "white",
    fontFamily: SemiBold,
    fontSize: "10px",
    bottom: "0px",
    padding: "4px",
    width: "100%",
    textAlign: "center"
  }
}));
