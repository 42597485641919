import { eachDayOfInterval, eachMonthOfInterval, lastDayOfMonth } from "date-fns"
import moment from "moment"

const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]
const get_calendar_days = (daysArray) => {
    return daysArray.map((day, index) => ({ label: day, order: index + 1 }))
}
export const calendar_days = get_calendar_days(days)

export const Events = [
    {
        event: [
            {
                title: "Casagrand",
                background_color: "#8FAEFF",
                unit_count: "06",
                images: [
                    "https://mui.com/static/images/avatar/2.jpg",
                    "https://mui.com/static/images/avatar/1.jpg",
                    "https://mui.com/static/images/avatar/3.jpg"
                ]
            },
            {
                title: "Rubix Application",
                background_color: "#9681FF",
                unit_count: "06",
                images: [
                    "https://mui.com/static/images/avatar/2.jpg",
                    "https://mui.com/static/images/avatar/1.jpg",
                    "https://mui.com/static/images/avatar/3.jpg"
                ]
            },
            {
                title: "Vinayag",
                background_color: "#FF9340",
                unit_count: "06",
                images: [
                    "https://mui.com/static/images/avatar/2.jpg",
                    "https://mui.com/static/images/avatar/1.jpg",
                    "https://mui.com/static/images/avatar/3.jpg"
                ]
            }
        ],
        date: "Tue Aug 1 2022 11:20:05 GMT+0530 (India Standard Time)"
    },
    {
        event: [
            {
                title: "Casagrand",
                background_color: "#8FAEFF",
                unit_count: "06",
                images: [
                    "https://mui.com/static/images/avatar/2.jpg",
                    "https://mui.com/static/images/avatar/1.jpg",
                    "https://mui.com/static/images/avatar/3.jpg"
                ]
            },
            {
                title: "Rubix Application",
                background_color: "#9681FF",
                unit_count: "06",
                images: [
                    "https://mui.com/static/images/avatar/2.jpg",
                    "https://mui.com/static/images/avatar/1.jpg",
                    "https://mui.com/static/images/avatar/3.jpg"
                ]
            },
            {
                title: "Vinayag",
                background_color: "#FF9340",
                unit_count: "06",
                images: [
                    "https://mui.com/static/images/avatar/2.jpg",
                    "https://mui.com/static/images/avatar/1.jpg",
                    "https://mui.com/static/images/avatar/3.jpg"
                ]
            }
        ],
        date: "Tue Aug 2 2022 11:20:05 GMT+0530 (India Standard Time)"
    },
    {
        event: [
            {
                title: "Casagrand",
                background_color: "#8FAEFF",
                unit_count: "06",
                images: [
                    "https://mui.com/static/images/avatar/2.jpg",
                    "https://mui.com/static/images/avatar/1.jpg",
                    "https://mui.com/static/images/avatar/3.jpg"
                ]
            },
            {
                title: "Rubix Application",
                background_color: "#9681FF",
                unit_count: "06",
                images: [
                    "https://mui.com/static/images/avatar/2.jpg",
                    "https://mui.com/static/images/avatar/1.jpg",
                    "https://mui.com/static/images/avatar/3.jpg"
                ]
            },
            {
                title: "Vinayag",
                background_color: "#FF9340",
                unit_count: "06",
                images: [
                    "https://mui.com/static/images/avatar/2.jpg",
                    "https://mui.com/static/images/avatar/1.jpg",
                    "https://mui.com/static/images/avatar/3.jpg"
                ]
            }
        ],
        date: "Tue Aug 3 2022 11:20:05 GMT+0530 (India Standard Time)"
    }
]

export const Months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']



export const returnYearMonth = (year=null) => {

    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

    const start_year = year ? year:new Date().getFullYear()
    const start_month = new Date(start_year, 0, 1).getMonth()
    const start_date = 1


    const end_year = start_year
    const end_month = 11
    const end_date = 31


    let dates = eachMonthOfInterval({
        start: new Date(start_year, start_month, start_date),
        end: new Date(end_year, end_month, end_date)
    }).map(item => {
        let end = lastDayOfMonth(item)
        return {
            label: months[new Date(item).getMonth()],
            value: new Date(item).getMonth(),
            start: item,
            end
        }
    })

    return dates
}

export const betweenDays = (start, end) => eachDayOfInterval({
    start: new Date(start),
    end: new Date(end)
})

export const filterByReference = (arr1, arr2) => {

    const result = arr1.filter(o1 => arr2.some(o2 => moment(o1).format("DD MMM YYYY") === moment(o2.execution).format("DD MMM YYYY")));
    const hours = result?.map((val) => val)
    return [...new Set(hours)]
}

export const returnYearMonthGraph = () => {

    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

    const start_year = new Date().getFullYear()
    const start_month = new Date(start_year, 0, 1).getMonth()
    const start_date = 1


    const end_year = start_year
    const end_month = 11
    const end_date = 31


    let dates = eachMonthOfInterval({
        start: new Date(start_year, start_month, start_date),
        end: new Date(end_year, end_month, end_date)
    }).map((item, index) => {
        let end = lastDayOfMonth(item)
        return {
            label: months[new Date(item).getMonth()],
            value: new Date(item).getMonth(),
            start: item,
            end,
            order: index + 1
        }
    })

    return dates
}


export const getYearList = (startYear) =>{
    var currentYear = new Date().getFullYear()+20, years = [];
    startYear = startYear || 1980;  
    while ( startYear <= currentYear ) {
        years.push(startYear++);
    }   

    return years?.map((val)=>{
        return{
            value:val,
            label:val
        }
    });
}
 
