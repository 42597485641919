import { useApolloClient } from "@apollo/client";
import { Box, Button, Container, Stack, Typography } from "@mui/material";
import React from "react";
import { withNamespaces } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { DialogDrawer, ItemList, MoveInForm, MoveOutForm, TitleBar } from "../../components";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { RemoveInspectionItem } from "../../graphql/mutations";
import { NetworkCall } from "../../networkcall";
import { AlertProps, LocalStorageKeys, NetWorkCallMethods } from "../../utils";
import { validateArrayContaines } from "../../utils/inspections";
import { AddForm } from "./components";
import { useStyles } from "./styles";


const AddMoveInOut = ({ t }) => {

  const classes = useStyles();
  const history = useHistory();
  const { state } = useLocation()
  const [add, setAdd] = React.useState(false);
  const [list, setList] = React.useState([]);
  const alert = React.useContext(AlertContext);
  const client = JSON.parse(localStorage.getItem(LocalStorageKeys.client))
  const gqlclient = useApolloClient()
  const [update, setUpdate] = React.useState({
    bool: false,
    data: {},
    isDelete: false
  });
  //onclick
  const onClick = (val) => {
    setUpdate({
      bool: !update?.bool,
      data: val ?? {}
    })

  }
  //get Items
  const getItems = () => {
    const payload = {
      agreement_inspection_id: state?.agreement_inspection_id,
      inspection_item: state?.inspection_item,
      item: true,
      agreement_id: state?.agreement_id,
      type: state?.type,
    }
    if (state?.type === "service" || state?.type === "general" ||state?.type==="unit_readiness") {
      NetworkCall(
        `${config.api_url}/general_inspection/get_inspection_items`,
        NetWorkCallMethods.post,
        payload,
        null,
        true,
        false
      ).then((res) => {
        const result = res.data.data?.[0]?.list?.map((x) => {
          return {
            ...x,
            activity_feedback: x?.activity_feedback?.map((val) => {
              return {
                feedback_sub_category_id: val?.sub_category_id,
                activity_id: val?.inspection_activity_id
              }
            })
          }
        })
        setList(result)

      }).catch((error) => {
        console.log(error)

      });
    }
    else {
      NetworkCall(
        `${config.api_url}/move-in-out/get_item_by_activity`,
        NetWorkCallMethods.post,
        payload,
        null,
        true,
        false
      ).then((res) => {
        const result = res.data.data?.map((x) => {
          return {
            ...x,
            activity_feedback: x?.activity_feedback?.map((val) => {
              return {
                feedback_sub_category_id: val?.sub_category_id,
                activity_id: val?.inspection_activity_id
              }
            })
          }
        })
        setList(result)

      }).catch((error) => {
        console.log(error)

      });
    }
  }
  //initial load
  React.useEffect(() => {
    getItems()

    // eslint-disable-next-line
  }, [])
  //goback
  const goBack = () => {
    history.goBack()
  }
  //on update
  const onUpdate = (data,options) => {
    const keys = ["assets", "activity_id", "description", "editActivityFeedback", "product_id", "error", "inspection_activity_feedback"]
    const result = Object.keys(data).filter((key) => !keys.includes(key)).reduce((obj, key) => {
      obj[key] = data[key];
      return obj;
    }, {}
    )

    const feedback = Object.keys(result).map((x) => {
      return {
        activity_id: result[x]?.inspection_activity_id,
        feedback_sub_category_id: result[x].id
      }
    })
    if(feedback?.length>0){
      const edited = list.map((val) =>
      val?.activity_id === data?.activity_id
        ? {
          ...val,
          activity_feedback: feedback,
          assets: data?.assets,
          description: data?.description
        }
        : { ...val }
    );
    setList(edited)
    onClick()
    }
    else{
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: `${options?.map((x)=>{return x.title})} is Required`,
    });
    }
  }

  //on submit
  const onSubmit = () => {
    const activity_items = list?.map((val) => {
      return {
        id: val?.activity_id,
        activity_feedback_subcategory: val?.activity_feedback,
        description: val?.description,
        assets: val?.assets ?? undefined,
        client: client?.id
      }
    })
    const payload = {
      activity_items: activity_items
    }
    NetworkCall(
      `${config.api_url}/inspection_activity/upsert`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    ).then((res) => {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.success,
        msg: "Activity Successfully Updated",
      });
      history.goBack()

    }).catch((error) => {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: "Some thing went wrong",
      });
    });
  }
  const openDeleteModal = () => {
    setUpdate({ ...update, isDelete: true })
  }
  const closeDeleteModal = () => {
    setUpdate({ ...update, isDelete: false })

  }
  const deleteInspectionItem = (val) => {
    const payload = {
      id: val?.activity_id,
      update: {
        is_active: false
      }
    }
    gqlclient.mutate({
      mutation: RemoveInspectionItem,
      variables: payload
    }).then((res) => {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.success,
        msg: t("deleteMsg"),
      });
      setUpdate({ ...update, bool: false, isDelete: false })
      getItems()
    }).catch((err) => {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: t("SomethingWentwrong"),
      });
    })
  }
  return (
    <Container maxWidth="sm" sx={{ padding: "0px" }}>
      <TitleBar goBack={goBack} text={`${state?.title}`} arrow={true} />
      <Box className={classes.root}>
        <Box p={1}>
          <Typography className={classes.title}>{state?.title}</Typography>
        </Box>
        {/*ietm list */}
        <ItemList disabled={state?.disable} onClick={onClick} handleSubmit={() => setAdd(true)} list={list} buttonName="Add New Item" type={state?.type} />

      </Box>
      <Box className={classes.btnRoot}>
        <Button className={classes.btn}
          disabled={state?.disable || !validateArrayContaines(list)}
          onClick={onSubmit} fullWidth variant="contained">Update</Button>
      </Box>
      {/*add form */}

      <DialogDrawer
        header="Add New Item"
        component={<AddForm
          reload={getItems}
          inspection_item={list?.[0]?.inspection_item_id}
          inventory_id={list?.[0]?.inventory_id}
          onClose={() => setAdd(false)}
          agreement_inspection_id={list?.[0]?.id} />}
        open={add}
        onClose={() => setAdd(false)}
      />




      {/* delete inspection item */}
      <DialogDrawer
        header={"Delete Item"}
        open={update?.isDelete}
        onClose={closeDeleteModal}
        padding={0}
        component={
          <center>
            <Box mt={3} height="150px" alignItems={"center"}>
              <Typography className={classes.deletetext}>Are you sure? You want to delete this item</Typography>
              <Stack m={3} direction="row" spacing={1}>
                <Button variant={"contained"} fullWidth className={classes.next} onClick={() => deleteInspectionItem({ activity_id: update?.data?.activity_id })}>Yes</Button>
                <Button variant={"contained"} fullWidth className={classes.btn1} onClick={closeDeleteModal}>No</Button>
              </Stack>
            </Box>
          </center>
        }
      />
      {/*update form */}
      <DialogDrawer
        header={update?.data?.name}
        padding={0}
        type={state?.type}
        onDelete={openDeleteModal}
        component={
          (state?.type === "move-in" || state?.type === "service") ?
            <MoveInForm
              disable={state?.disable}
              datas={update?.data}
              serial_number={update?.data?.product_id}
              activity_id={update?.data?.activity_id}
              onUpdate={onUpdate}
              type={state?.type}
            />
            :
            <MoveOutForm
              disable={state?.disable}
              datas={update?.data}
              serial_number={update?.data?.product_id}
              activity_id={update?.data?.activity_id}
              onUpdate={onUpdate}
              in_activity={update?.data?.in_activity}
              type={state?.type}
            />
        }


        open={update?.bool}
        onClose={() => setUpdate(false)}
      />


    </Container>
  );
};

export default withNamespaces("inspections")(AddMoveInOut);