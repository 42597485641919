import { ChevronRight } from "@mui/icons-material";
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { useContext } from "react";
import { withNamespaces } from "react-i18next";
import { useHistory } from "react-router-dom";
import { DialogDrawer } from "../../../components";
import { config } from "../../../config";
import { AlertContext } from "../../../contexts";
import { NetworkCall } from "../../../networkcall";
import { Routes } from "../../../router/routes";
import { AlertProps, NetWorkCallMethods, ReallocateCardList } from "../../../utils";
import { useStyles } from "./styles";


const ReallocateCard = ({ t, location = {}, setStep = () => false, state = {}, setShowReallocateDialog = () => false }) => {
    const classes = useStyles()
    const [isReallocate, setIsReallocate] = useState(false)
    const alert = useContext(AlertContext)
    const history = useHistory()
    const handleWork = (val) => {
        if (val?.title === "Pause & Continue") {
            resumeWork()
        }
        else {
            setShowReallocateDialog(false)
            setIsReallocate(true)
        }
    }

    const ReallocateConfirmCard = () => {
        return (
            <Box p={2}>
                <center>
                    <img src="/images/reallocateGroupIcon.png" alt="icon" />
                    <Box mt={1} p={2}>
                        <Typography className={classes.title}>{t("confirmmsg")}</Typography>
                        <Typography className={classes.description}>{t("confirmdescription")}</Typography>
                    </Box>
                </center>
                <Grid container spacing={1} mt={4}>
                    <Grid item xs={6}>
                        <Button className={classes.closebtn} fullWidth onClick={() => setIsReallocate(false)}>{t("No")}</Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button className={classes.startbtn} fullWidth >{t("Yes")}</Button>
                    </Grid>
                </Grid>
            </Box>
        )
    }
    const resumeWork = async () => {
        const payload = {
            "agreement_inspection_id": state?.id,
            "resource_id": state?.resource_id,
            "end_lat": location?.lat,
            "end_long": location?.lng
        }
        await NetworkCall(
            `${config.api_url}/move-in-out/resume_work`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((res) => {
            alert.setSnack({
                open: true,
                horizontal: AlertProps.horizontal.center,
                vertical: AlertProps.vertical.top,
                msg: "Work Paused Sccessfully",
                severity: AlertProps.severity.success,
            });
            //    setStep(0)
            history.push(Routes?.inspections)
        }).catch((error) => {
            console.log(error)
        });
    }
    return (
        <Box p={2}>
            {ReallocateCardList.map((x) => {
                return (
                    <Box className={classes.reallocatecard} onClick={() => handleWork(x)}>
                        <Stack direction="row" spacing={2}>
                            <Box mt={1}><img src={x.icon} alt="icon" /></Box>
                            <Box>
                                <Typography className={classes.title}>{x.title}</Typography>
                                <Typography className={classes.description}>{x.description}</Typography>
                            </Box>

                        </Stack>
                        <Box mt={1.3}><ChevronRight /></Box>
                    </Box>
                )
            })}
            {/* confirm reallocate */}
            <DialogDrawer
                header="Pause/Reallocate"
                component={ReallocateConfirmCard()}
                open={isReallocate}
                padding={0}
                onClose={() => setIsReallocate(false)}
            />
        </Box>
    )
}
export default withNamespaces("inspections")(ReallocateCard);