import { Box, Button, Grid, Stack, Typography } from "@mui/material"
import React, { useState } from "react"
import { BigCheckIcon } from "../../assets/bigCheckIcon"
import { DialogDrawer } from "../dialogDrawer"
import { TextBox } from "../textbox"
import { ActionCardStyles } from "./style"

export const ActionCard = ({ title = "", data = [], t, setData = () => false, onClick = () => false, actiondetails = {},type="move-in" }) => {
    const classes = ActionCardStyles()
    const [isShowDialog, setIsShowDialog] = useState(false)
    const [actionType, setActiontype] = useState("")
    const [delayTime, setDelayTime] = useState(undefined)
    const [remarks, setRemarks] = useState(undefined)
    const hours = [1, 2, 3, 4, 5]
    const ActionTypeCard = (data = {}, t) => {
        const onSubmit = (value) => {
            setIsShowDialog(false)
            onClick(data?.title, data?.btnName, delayTime, remarks)
        }
        return (
            <Box p={2}>
                {data.title === "Can't take up" ?
                    <Box>
                        <Stack direction={"row"} spacing={2}>
                            <Box className={classes.calendaricon} style={{ backgroundColor: data.color }}><img src={data?.icon} alt="icon" /></Box>
                            <Box>
                                <Typography className={classes.date}>{t("Sorry, Unable to Commence work")}</Typography>
                                <Typography className={classes.type}>{t("Due to emergency situation, couldn't commence work now.")}</Typography>
                            </Box>
                        </Stack>
                        <Box mt={1}>
                            <Typography className={classes.date}>{t("remarks")}</Typography>
                            <TextBox
                                label={""}
                                multiline={true}
                                onChange={(e) => { return (setRemarks(e?.target?.value), setDelayTime(undefined)) }}
                            />
                        </Box>
                    </Box>
                    :
                    <Box>
                        <Stack direction={"row"} spacing={2}>
                            <Box className={classes.timeicon} style={{ backgroundColor: data.color }} mt={2}><img src={data?.icon} alt="icon" /></Box>
                            <Box>
                                <Typography className={classes.date}>{t("runningLate")}</Typography>
                                <Typography className={classes.type}>{t("Delay in arrival Intimation will be send to tenant for their information")}</Typography>
                            </Box>
                        </Stack>
                        <Box mt={2} className={classes.delayBox} p={1.5}>
                            <Box>
                                <Typography className={classes.date}>{t("Delay arrival by")}</Typography>
                                <Typography className={classes.type}>{t("Select the delay hour below and update")}</Typography>
                            </Box>
                            <Stack direction={"row"} spacing={2} mt={1.5}>
                                {hours.map((hour, i) => {
                                    return (
                                        <Box className={delayTime === hour ? classes.selectedhoursbtn : classes.hoursbtn} onClick={() => setDelayTime(hour)}>{hour}&nbsp;Hr</Box>
                                    )
                                })}
                            </Stack>
                        </Box>
                    </Box>
                }
                <Grid className={classes.btnRoot} container spacing={1} mt={4}>
                    <Grid item xs={12}>
                        <Button onClick={() => onSubmit(data)} className={classes.updatebtn} fullWidth>{t("update")}</Button>
                    </Grid>
                </Grid>
            </Box>
        )
    }
    const openDialog = (val) => {
        if (val.title !== "Notify the arrival") {
            setIsShowDialog(true)
            setActiontype(val)
        }
        else {
            onClick(val?.title, val?.btnName)
        }
    }
    return (
        <Box className={classes.mainsection}>
            <Typography className={classes.headerTitle}>{title}</Typography>
            {data.map((x) =>
                <Box display={"flex"} justifyContent="space-between" mt={1}>
                    <Stack direction={"row"} spacing={2} mt={1}>
                        <Box className={classes.calendaricon} style={{ backgroundColor: x.color }}><img src={x?.icon} alt="icon" /></Box>
                        <Box>
                            <Typography className={classes.date}>{x?.title}</Typography>
                            <Typography className={classes.type}>{x?.description}</Typography>
                        </Box>
                    </Stack>
                    {(x.title === "Running late" && actiondetails?.is_running_late) ? <Box mt={2} className={classes.checkicon}><BigCheckIcon sx={{ width: "50px", height: "50px" }} /></Box> :
                        (x.title === "Can't take up" && actiondetails?.is_take_up) ? <Box mt={1} className={classes.checkicon}><BigCheckIcon sx={{ width: "50px", height: "50px" }}/></Box> :
                            <Box className={classes.btnBox} onClick={() => openDialog(x)}>
                                <Typography className={classes.btnName}>{x.btnName}</Typography>
                            </Box>
                    }
                </Box>
            )}
            <DialogDrawer
                header={actionType.title === "Running late" ? "Running late" : "Can't take up"}
                component={ActionTypeCard(actionType, t)}
                open={isShowDialog}
                padding={0}
                onClose={() => setIsShowDialog(false)}
            />
        </Box>
    )
}