import React from "react";
import { withBottombar } from "../../HOCs";
import ProfilePage from "./profilePage";

class ProfilePageParent extends React.Component {
  render() {
    return <ProfilePage />;
  }
}
const props = {
  titleBar: true,
  title: "Profile"
}
export default withBottombar(ProfilePageParent, props);
