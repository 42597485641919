import * as React from "react"

export const AvailableSlot = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
        <g data-name="Group 105366" transform="translate(-328.326 -360.326)">
            <rect
                data-name="Rectangle 55547"
                width={20}
                height={20}
                rx={4}
                transform="translate(328.326 360.326)"
                fill="#d2fae0"
            />
            <g
                data-name="Group 3750"
                fill="none"
                stroke="#5ac782"
                strokeLinecap="round"
                strokeWidth={1.5}
            >
                <path data-name="Path 948" d="M338.327 366.024v8.605" />
                <path data-name="Path 949" d="M342.628 370.326h-8.605" />
            </g>
        </g>
    </svg>
)

