import { useApolloClient } from "@apollo/client";
import { Box, Button, Typography,Grid } from "@mui/material";
import React , {useState} from "react";
import { withNamespaces } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { DialogDrawer, ParkingDetailCard, ParkingUnitCard } from "../../../components";
import { ParkingUnit } from "../../../components/simmers";
import { GET_AGREEMENT_PARKING_UNIT } from "../../../graphql/queries";
import { Routes } from "../../../router/routes";
import ReAllocateCard from "./pause&reallocate"
import { useStyles } from "../styles";


const Details = ({ t, setStep = () => false, updateState = () => false,disable=false }) => {

    const classes = useStyles();
    const client = useApolloClient();
    const { state } = useLocation();
    const history = useHistory()
    const [list, setList] = React.useState({
        data: [],
        selected: {},
        loading: true
    })
    const [showReallocateDialog, setShowReallocateDialog] = useState(false)
    const [location, setLocation] = React.useState({
        lat: "",
        lng: ""
    })

    const getCurrentLocation = () => {
        let location = null;
        if (window.navigator && window.navigator.geolocation) {
            location = window.navigator.geolocation
        }
        if (location) {
            location.getCurrentPosition(function (position) {
                setLocation({ lat: position.coords.latitude, lng: position.coords.longitude })
            })
        }
    }
    //get lead
    const getList = () => {
        client.query({
            query: GET_AGREEMENT_PARKING_UNIT,
            fetchPolicy: 'network-only',
            variables: {
                id: state?.agreement_id,
                unit_id: state?.unit_id
            }
        }).then((res) => {
            const result = res?.data?.agreement[0]?.agreement_units?.map((val) => {
                return {
                    agreement_unit: val?.id,
                    logo: val?.unit?.logo,
                    title: val?.unit?.unit_no,
                    name: val?.agreement_unit_users?.[0]?.contact?.first_name,
                    mobile: `${val?.agreement_unit_users?.[0]?.contact?.mobile_no_country_code ?? ""} ${val?.agreement_unit_users?.[0]?.contact?.mobile_no ?? ""}`,
                    type: val?.unit?.unit_type?.name,
                    id: val?.unit?.id,
                    parking: val?.unit?.parking_masterByID?.[0]
                }
            })

            setList({
                data: result,
                selected: result?.[0],
                loading: false,
                parking: res?.data?.unit?.[0]?.parking_masterByID?.[0]
            })

        }).catch((err) => {
            console.log(err)
        })
    }

    React.useEffect(() => {
        if (state?.agreement_id) {
            getList()
        }
        else {
            history.push(Routes.inspections)
        }
        getCurrentLocation()

        // eslint-disable-next-line
    }, [])

    //on click card 
    const onClick = (val) => {
        setList({
            ...list,
            selected: val ?? list?.selected
        })
        updateState("agreement_unit_id", val)
    }

    return (
        <Box>
            {
                list?.loading ?
                    <ParkingUnit />
                    :
                    <Box>
                        <Box className={classes.root}>
                            <Box p={2}>
                                {
                                    list?.parking?.parking_no &&
                                    <ParkingDetailCard
                                        data={{
                                            title: list?.parking?.parking_no,
                                            subTitle: list?.parking?.location,
                                        }}
                                    />
                                }

                            </Box>
                            <Box className={classes.list}>
                                <Typography className={classes.title}>{t("parkingUnit")} ({list?.data?.length})</Typography>
                                {
                                    list?.data?.map((val) => {
                                        return <ParkingUnitCard onClick={onClick} selected={list?.selected?.id} data={val} />
                                    })
                                }
                            </Box>
                        </Box>
                        {disable ?
                            <Box className={classes.btnRoot}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Button
                                            fullWidth
                                            className={classes.btn}
                                            sx={{ backgroundColor: "#B20606" }}
                                            variant="contained"
                                            onClick={() => setStep(3, { ...list?.selected, parking: list?.parking })}
                                        >
                                            {t("next")}
                                        </Button>
                                    </Grid>

                                </Grid>

                            </Box>
                            :
                            <Grid className={classes.btnRoot} container spacing={1}>
                                <Grid item xs={6}>
                                    <Button onClick={() => setShowReallocateDialog(true)} fullWidth className={classes.closebtn}>{t("pause/reallocate")}</Button>
                                </Grid>
                                <Grid item xs={6}>
                                    <Button
                                        fullWidth
                                        className={classes.startbtn}
                                        sx={{ backgroundColor: "#B20606" }}
                                        variant="contained"
                                        onClick={() => setStep(3, { ...list?.selected, parking: list?.parking })}
                                    >
                                        {t("next")}
                                    </Button>
                                </Grid>
                            </Grid>
                        }
                    </Box>
            }
            {/* {Pause/Reallocate} */}
            <DialogDrawer
                header="Pause/Reallocate"
                component={<ReAllocateCard location={location} setStep={setStep} state={state} setShowReallocateDialog={setShowReallocateDialog} />}
                open={showReallocateDialog}
                padding={0}
                onClose={() => setShowReallocateDialog(false)}
            />
        </Box>
    );
};

export default withNamespaces("inspections")(Details);