import makeStyles from "@mui/styles/makeStyles";
import { Bold, SemiBold } from "../../utils";

export const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.dashboard.primary,
        height: `calc(100vh - 122px)`,
        overflow: "auto",
        position: "relative"
    },
    title: {
        fontFamily: `${Bold}`,
        fontSize: "16px",
        color: theme.typography.color.primary,

    },
    btn: {
        boxShadow: "none",
        padding: "8px",
        textTransform: "capitalize",
        backgroundColor:theme.palette.primary.main,
        color:theme.palette.primary.contrastText,
        "&:hover":{
            backgroundColor:theme.palette.primary.main,
            color:theme.palette.primary.contrastText,
        }
    },
    btnRoot: {
        padding: "12px",
        boxShadow: "0px -1px 6px #00000021"
    },
    deletetext:{
        fontSize: "16px",
        fontFamily: SemiBold,
        color: theme.typography.color.secondary,   
    },
    next: {
        boxShadow: "none",
        padding: "8px",
        textTransform: "capitalize",
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
        }
    },
    btn1: {
        boxShadow: "none",
        padding: "8px",
        textTransform: "capitalize",
        border: "1px solid #E4E8EE",
        backgroundColor: theme.palette.background.paper,
        color: theme.typography.color.primary,
    },
}));