import makeStyles from "@mui/styles/makeStyles/makeStyles";
import { Bold, SemiBold } from "../../../utils";

export const useStyles = makeStyles((theme) => ({
    // calendar dates styles
    calDates: {

        marginLeft: "2px"
    },
    date_not_curr_month: {
        color: theme.typography.color.Tertiary,
        border: "1px solid #CFDAEB",
        height: 150,
        // backgroundColor:"#e6e6e6",
        backgroundColor: theme.palette.dashboard.primary
    },
    date_curr_month: {
        color: "black",
        border: "1px solid #CFDAEB",
        height: 150,
        backgroundColor: theme.palette.dashboard.primary
    },
    hidden_other_month_dates: {
        visibility: "hidden"
    },
    current_date: {
        color: "#fff",
        backgroundColor: "#000",
        padding: 4,
        borderRadius: "50%",
    },
    eventlabel: {
        float: "right",
        borderTopLeftRadius: 4,
        borderBottomLeftRadius: 4,
        alignItems: "center",
        marginTop: 2,
        width: "95%",
        overflow: "hidden",
        marginRight: "-0.5px",
        color: "#fff"
    },
    eventtxt: {
        fontSize: 10,
        whiteSpace: "pre",
        marginRight: "-5px",
        textAlign: "left"
    },
    eventlabelicon: {
        fontSize: 10,
        whiteSpace: "pre",
    },
    // calendar days style

    caldays: {
        borderRight: "1px solid #CFDAEB",
        textAlign: "center",
        padding: 8,
        backgroundColor: theme.palette.background.paper
    },
    highlight_day: {
        color: theme.palette.primary.main
    },
    current_dates: {
        fontFamily: `${Bold}`,
        fontSize: "20px"
    },
    current_datesType: {
        fontFamily: `${SemiBold}`,
        fontSize: "12px",
        color: theme.typography.color.Tertiary
    },
    date_not_curr_daily: {
        color: theme.typography.color.Tertiary,
        border: "1px solid #CFDAEB",

        backgroundColor: theme.palette.dashboard.primary
    },
    date_curr_daily: {
        color: "black",
        border: "1px solid #CFDAEB",
        backgroundColor: theme.palette.dashboard.primary
    },
    //new

    dateRoot: {
        border: `0.1px solid #E1E8F3`,
        minHeight: "120px",
        //display: "flex",
        justifyContent: "center",
        //overflow: "hidden"
    },
    // day: {
    //     fontFamily: SemiBold,
    //     fontSize: "14px",
    //     marginTop: "12px"
    // },
    toDay: {
        backgroundColor: theme.typography.color.primary,
        borderRadius: "50%",
        color: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "10px",
        fontSize: "14px",
        fontFamily: SemiBold,
        height: 32,
        width: 32
    },
    propertyName: {
        fontFamily: SemiBold,
        fontSize: "8px",
        color: "white",

    },
    tagRoot: {
        borderTopLeftRadius: "4px",
        borderBottomLeftRadius: "4px",
        cursor: "pointer",
        [theme.breakpoints.down('md')]: {
            marginLeft: "10px",
        },
        [theme.breakpoints.up('md')]: {
            marginLeft: "10px",
        },
    },
    dailyCardRoot: {
        marginTop: "12px"
    },
    dateCard: {
        padding: "8px",
        backgroundColor: theme.palette.background.paper,
        borderRadius: "12px"
    },
    date: {
        fontSize: 20,
        fontWeight: "bold",
        marginTop: "8px",
        textAlign: "center"
    },
    day: {
        fontSize: 12,
        fontFamily: `${Bold}`,
        color: theme.typography.color.Tertiary,
        marginBottom: "8px",
        marginTop: "20px",
        textAlign: "center"
    },
}))