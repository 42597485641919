import { Box, Grid } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import useWindowDimensions from "../../utils/useWindowDimensions";
const useStyles = makeStyles((theme) => ({
    second: {
        backgroundColor: "white",
        borderTopRightRadius: "15px",
        borderTopLeftRadius: "15px",
        padding: "8px",
    },
}));
export const ProfileSimmaer = (props) => {
    const size = useWindowDimensions();
    const classes = useStyles();
    return (
        <div style={{ height: size.height }}>

            <Grid item style={{ padding: "8px" }}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={3.6} sm={2}>
                        <Box height="10px" />
                        <Skeleton variant="circular" height={120} />
                        <Box height="10px" />
                    </Grid>
                    <Grid item xs={7} sm={8}></Grid>
                </Grid>
            </Grid>
            <div className={classes.second}>
                <br />
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={6}>
                        <Skeleton
                            variant="rectangular"
                            height={100}
                            style={{ borderRadius: "8px" }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Skeleton
                            variant="rectangular"
                            height={100}
                            style={{ borderRadius: "8px" }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <br />
                        <Skeleton
                            variant="rectangular"
                            height={100}
                            style={{ borderRadius: "8px" }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <br />
                        <Skeleton
                            variant="rectangular"
                            height={50}
                            style={{ borderRadius: "8px" }}
                        />
                        <br />
                        <Skeleton
                            variant="rectangular"
                            height={50}
                            style={{ borderRadius: "8px" }}
                        />
                        <br />
                        <Skeleton
                            variant="rectangular"
                            height={50}
                            style={{ borderRadius: "8px" }}
                        />
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};
