import { Box, Grid, Typography } from "@mui/material";
import moment from "moment";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { CalendarCard } from "../../../components";
import { filterByReference, switchRoutes } from "../../../utils";
import { useStyles } from "./style";
export const DailyCalender = ({ state = [], events = [], }) => {

    const classes = useStyles();
    const history = useHistory();
    const [list, setList] = React.useState([]);

    useEffect(() => {
        const result = filterByReference(state, events);
        setList(result)
    }, [state, events]);

    const onClick = (val) => {
        history.push({
            pathname: switchRoutes(val?.request_type),
            state: {
                id: val?.id,
                type: val?.request_type,
                agreement_id: val?.agreement_id,
                request_type:val?.request_type,
                resource_id:val?.resource_id,
                unit_id:val?.unit_id,
                status:val?.status,
                reference_id:val?.reference_id,
                is_parking_component: val?.is_parking_component,
                is_parking_unit: val?.is_parking_unit,
                maintenance_id: val?.maintenance_id,
                property_unit_details: val,

            },
        })
    }
    return (
        <Box container className={classes.calDates} p={1}>
            {
                events?.length > 0 ?
                    <Box>
                        {
                            list?.map((val) => {

                                return (
                                    <Box marginTop="12px">
                                        <Grid container spacing={1} justifyContent="flex-end">
                                            <Grid item xs={3}>
                                                <Box className={classes.dateCard}>
                                                    <Typography className={classes.date}> {moment(val).format("DD")} </Typography>
                                                    <Typography className={classes.day}>{moment(val).format("ddd")}</Typography>
                                                </Box >
                                            </Grid>
                                            {events?.filter((t) => moment(val).format("DD MMM YYYY") === moment(t?.execution).format("DD MMM YYYY")).map((item) => {

                                                return (
                                                    <Grid item xs={9} >
                                                        <CalendarCard
                                                            hideDate={true}
                                                            val={{
                                                                property_name: item?.property_name,
                                                                date: moment(item?.date).format("DD"),
                                                                day: moment(item?.date).format("ddd"),
                                                                status: item?.status,
                                                                background_color: "#E4EDFF",
                                                                request_type: item?.request_type,
                                                                reference_id: item?.reference_id
                                                            }}
                                                            onClick={() => onClick(item)}
                                                        />

                                                    </Grid>
                                                )
                                            })
                                            }
                                        </Grid>

                                    </Box>


                                )
                            })
                        }
                    </Box>
                    :
                    <Typography textAlign={"center"} className={classes.day}>No Data Found</Typography>

            }

        </Box>
    )
}