import makeStyles from "@mui/styles/makeStyles";
import { SemiBold } from "../../../utils";

export const useStyles = makeStyles((theme) => ({
    card: {
        backgroundColor: "#FFFFFF",
        boxShadow: "0px 3px 6px #00000029",
        borderRadius: "10px",
        padding: "12px",
        cursor: "pointer",
        minHeight: "110px"

    },
    cardValue: {
        textAlign: "center",
        fontSize: "14px",
        fontFamily: SemiBold
    },
    label: {
        color: theme.typography.color.Tertiary,
        fontSize: "12px",
        paddingBottom: "8px",
        fontFamily: SemiBold
    },
    slotCard: {
        border: "1px solid #CED3DD",
        minHeight: "120px",
        padding: "8px",
        borderRadius: "4px",
        backgroundColor: "white"
    },
    slot_no: {
        border: "1px solid #CED3DD",
        //display: "inline",
        textAlign: "center",
        borderRadius: "4px",
        padding: "2px 4px",
        color: theme.typography.color.secondary,
        fontSize: "10px",
        fontFamily: SemiBold,
    },
    availableTitle: {
        color: "#5AC782",
        fontSize: "12px",
        textAlign: "center",
        fontFamily: SemiBold,
        marginTop: "24px"
    },
    selectedTitle: {
        color: "#B20606",
        fontSize: "12px",
        textAlign: "center",
        fontFamily: SemiBold,
        marginTop: "24px"
    },
    slotselectedCard: {
        border: "1px solid  #B20606",
        minHeight: "120px",
        padding: "8px",
        borderRadius: "4px",
        //backgroundColor: "white",
        background: 'linear-gradient(180deg, white, #FFE6E6)',

    }

}));