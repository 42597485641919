import { Box, Button, Grid } from "@mui/material";
import React from "react";
import { withNamespaces } from "react-i18next";
import { FillComponent } from "../../../components";
import { generateItems } from "../../../utils/inspections";
import { useStyles } from "./styles";

const Step3 = ({
    list = [],
    changeStep = () => false,
    onPrevious = () => false
}) => {
    const classes = useStyles();
    const [lists, setList] = React.useState([]);
    React.useEffect(() => {
        let inspection_mapping_params = list
            .flatMap((item) => {
                const { count, ...rest } = item;
                let newItems = generateItems(count, rest);
                return newItems;
            })
            .filter(Boolean);
        setList(inspection_mapping_params)
        // eslint-disable-next-line
    }, [])

    //on submit
    const onSumbit = () => {
        let result = lists?.filter(i => i?.isActive).map((val) => {
            return {
                id: val?.id,
                inventory_id: val?.inventory_id,
                serial_number: val?.serial_number
            }
        })
        changeStep(result)
    }

    return (
        <Box >

            <Box p={1} className={classes.itemRoot}>
                <FillComponent lists={lists} setList={setList} />
            </Box>
            <Grid className={classes.btnRoot} container spacing={2}>
                <Grid item xs={6}>
                    <Button onClick={onPrevious} className={classes.btnOutlined} fullWidth variant="outlined">Previous</Button>
                </Grid>
                <Grid item xs={6}>
                    <Button onClick={onSumbit} className={classes.btn} fullWidth variant="contained">Add</Button>
                </Grid>
            </Grid>

        </Box>
    )
}
export default withNamespaces("inspections")(Step3);