import makeStyles from "@mui/styles/makeStyles";
import { Bold, SemiBold } from "../../utils";

export const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        padding: "12px",
        boxShadow: "0px 3px 30px #5C86CB2E",
        borderRadius: "4px"
    },
    avatar: {
        borderRadius: "4px",
        backgroundColor: "#F5F7FA"
    },
    title: {
        fontFamily: Bold,
        fontSize: "14px"
    },
    subTitle: {
        fontFamily: SemiBold,
        fontSize: "12px",
        color: theme.typography.color.secondary,
        marginTop: "4px"
    }

}))