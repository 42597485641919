import * as React from "react";

export const CalendarWithTimeIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <g id="Group_107170" data-name="Group 107170" transform="translate(-39 -82)">
            <rect id="Rectangle_55709" data-name="Rectangle 55709" width="24" height="24" transform="translate(39 82)" fill="none" />
            <path id="icons8-event-accepted-tentatively" d="M8.925,6A2.928,2.928,0,0,0,6,8.925V9.6H22.2V8.925A2.928,2.928,0,0,0,19.275,6ZM6,10.95v8.325A2.928,2.928,0,0,0,8.925,22.2h5.2a5.817,5.817,0,0,1-.925-3.15c0-.118.011-.233.018-.35l-.571.427a.579.579,0,0,1-.9-.635l.522-1.669-1.541-1.169a.579.579,0,0,1,.35-1.04h1.9l.573-1.666a.578.578,0,0,1,1.094,0l.573,1.666h.022a5.83,5.83,0,0,1,6.958-.489V10.95ZM19.05,14.1A4.95,4.95,0,1,0,24,19.05,4.95,4.95,0,0,0,19.05,14.1Zm-.45,1.8a.45.45,0,0,1,.45.45v2.7H21.3a.45.45,0,0,1,0,.9H18.6a.45.45,0,0,1-.45-.45V16.35A.45.45,0,0,1,18.6,15.9Z" transform="translate(36 79)" fill="#896db3" />
        </g>
    </svg>

)