import * as React from "react"

export const Parking = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={26.19}
        height={26.19}
        {...props}
    >
        <defs>
            <clipPath id="a">
                <path
                    data-name="Rectangle 50308"
                    fill="#4e5a6b"
                    stroke="#707070"
                    d="M0 0h26.19v26.19H0z"
                />
            </clipPath>
        </defs>
        <g data-name="Mask Group 6342" clipPath="url(#a)">
            <path
                data-name="Rectangle 50307"
                fill="#78b1fe"
                d="M6.548 16.369h13.095v4.365H6.548z"
            />
            <path
                d="M13.096 0a3.613 3.613 0 0 0-2.239.773l-8.2 6.463a5.176 5.176 0 0 0-1.972 4.063v12.478a2.43 2.43 0 0 0 2.414 2.414h20a2.43 2.43 0 0 0 2.414-2.414V11.3a5.176 5.176 0 0 0-1.972-4.063l-8.2-6.463A3.613 3.613 0 0 0 13.096 0Zm0 2.064a1.548 1.548 0 0 1 .959.335l8.2 6.463a3.1 3.1 0 0 1 1.183 2.437v12.478a.329.329 0 0 1-.345.345h-3.104a.69.69 0 0 0 .69-.69v-5.863a3.067 3.067 0 0 0-.7-1.933q-.007-.039-.016-.077l-.659-2.836a3.112 3.112 0 0 0-3.022-2.4H9.91a3.114 3.114 0 0 0-3.021 2.4l-.661 2.834-.014.074a3.068 3.068 0 0 0-.7 1.936v5.863a.69.69 0 0 0 .69.69h-3.1a.329.329 0 0 1-.345-.345V11.3a3.1 3.1 0 0 1 1.174-2.437l8.2-6.463a1.548 1.548 0 0 1 .963-.336ZM9.91 12.397h6.372a1.023 1.023 0 0 1 1.006.8l.3 1.272h-8.98l.3-1.272a1.024 1.024 0 0 1 1.002-.8Zm-1.3 4.138h8.969a1.019 1.019 0 0 1 1.035 1.035v3.1H7.578v-3.1a1.019 1.019 0 0 1 1.035-1.035Zm1.379 1.379a1.035 1.035 0 1 0 1.035 1.035 1.035 1.035 0 0 0-1.035-1.035Zm6.207 0a1.035 1.035 0 1 0 1.035 1.035 1.035 1.035 0 0 0-1.031-1.035Zm-7.932 4.828h9.656v.69a.69.69 0 0 0 .69.69H7.578a.69.69 0 0 0 .69-.69Z"
                fill="#4e5a6b"
            />
        </g>
    </svg>
)

