import { Box, Stack, Typography } from "@mui/material"
import moment from "moment"
import React from "react"
import { BigCheckIcon } from "../../assets/bigCheckIcon"
import { ResumeIcon } from "../../assets/resumeIcon"
import { UserClockIcon } from "../../assets/userClockIcon"
import { TrackCardCardStyles } from "./style"

export const WorkingHourTrackCard = ({ title = "", data = [], t, actualhourData = false }) => {
    const classes = TrackCardCardStyles()
    return (
        <Box className={classes.mainsection}>
            <Typography className={classes.headerTitle}>{title}</Typography>
            <Box mt={2} className={classes.trackList}>
                {data.map((x, i) => {
                    return (
                        <>
                            {(x?.pause_date_time?.length > 0 || x?.actual_start_date?.length > 0) &&
                                <>
                                    <Stack direction={"row"} spacing={2}>
                                        <Box className={classes.userIcon}><UserClockIcon /></Box>

                                        <Box>

                                            {x?.pause_date_time ?
                                                <Typography className={classes.date}>{moment(x?.pause_date_time).format("DD MMM YY HH:mm a")}</Typography>
                                                :
                                                <Typography className={classes.date}>{moment(x?.actual_start_date).format("DD MMM YY HH:mm a")}</Typography>
                                            }


                                            <Typography className={classes.type}>Start</Typography>
                                        </Box>


                                    </Stack>
                                    <Box>
                                        {data.length - 1 !== i  ?
                                            <Box className={classes.dashedborder}></Box>
                                            :
                                            x?.end_date_time?.length > 0 || x?.actual_end_date?.length > 0?
                                            <Box className={classes.dashedborder} />
                                            :
                                            <Box className={classes.noborder} />

                                        }
                                    </Box>
                                </>
                            }
                            {/* {data.map((x, i) => {
                                if (x?.end_date_time?.length > 0 || x?.actual_end_date?.length > 0) {
                                    return (
                                        <Box className={classes.dashedborder}></Box>
                                    )
                                }

                            })
                            } */}
                            {(x?.end_date_time?.length > 0 || x?.actual_end_date?.length > 0) &&
                                <>
                                    <Stack direction={"row"} spacing={2}>
                                        <Box className={classes.calendaricon}>{actualhourData !== true ? <ResumeIcon /> : <BigCheckIcon />}</Box>

                                        <Box>
                                            {x?.end_date_time ?
                                                <Typography className={classes.date}>{moment(x?.end_date_time).format("DD MMM YY HH:mm a")}</Typography>
                                                :
                                                <Typography className={classes.date}>{moment(x?.actual_end_date).format("DD MMM YY HH:mm a")}</Typography>
                                            }
                                            <Typography className={classes.type}>End</Typography>
                                        </Box>


                                    </Stack>
                                    <Box>
                                        {data.length - 1 !== i ?
                                            <Box className={classes.dashedborder}></Box>
                                            :
                                            <Box className={classes.noborder} />
                                        }
                                    </Box>
                                </>
                            }

                        </>
                    )
                })}
            </Box>
        </Box>
    )
}