import * as React from "react";

const LogOutIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={14} height={14} {...props}>
    <path
      d="M7 0a7 7 0 1 0 5.487 11.34.525.525 0 1 0-.823-.652 5.95 5.95 0 1 1 0-7.376.525.525 0 0 0 .823-.652A6.988 6.988 0 0 0 7 0Zm4.37 4.37a.525.525 0 0 0-.366.9l1.2 1.2-7.131-.006a.525.525 0 1 0 0 1.05l7.134.006-1.2 1.2a.525.525 0 1 0 .742.742l2.1-2.1a.525.525 0 0 0 0-.742l-2.1-2.1a.525.525 0 0 0-.377-.159Z"
      fill="#6a68cc"
    />
  </svg>
);

export default LogOutIcon;
