import { useApolloClient } from "@apollo/client"
import { Box, Button, Container, Grid, Typography } from "@mui/material"
import React, { useState } from "react"
import { withNamespaces } from "react-i18next"
import { DialogDrawer } from "../../components"
import { LoderSimmer } from "../../components/simmers/card"
import { getAgreementInspectionDetails } from "../../graphql/mutations"
import ReAllocateCard from "./pause&reallocate"
import { useStyles } from "./styles"
import ReactQuill from "react-quill";
const ScopeOfWork = ({ t, next = () => false, state = {}, setStep = () => false, previous = () => false, disable = false, details = {}, setDetails = () => false }) => {
    const classes = useStyles()
    const client = useApolloClient()
    const [loading, setLoading] = useState(true)
    const [showReallocateDialog, setShowReallocateDialog] = useState(false)
    const [data, setData] = useState({})
    const [location, setLocation] = React.useState({
        lat: "",
        lng: ""
    })


    React.useEffect(() => {
        getCurrentLocation()
        getAgreementInspectionData()
        // eslint-disable-next-line
    }, [])
    const getAgreementInspectionData = () => {
        client.query({
            query: getAgreementInspectionDetails,
            fetchPolicy: 'network-only',
            variables: {
                "agreement_inspection_id": state?.id
            }
        }).then((response) => {
            setLoading(false)
            setData({ ScopeOfWork:response?.data?.agreement_inspection?.[0]?.scope_of_work })
        }).catch((err) => {
            setLoading(false)
            console.log(err)
        })
    }

    const getCurrentLocation = () => {
        let location = null;
        if (window.navigator && window.navigator.geolocation) {
            location = window.navigator.geolocation
        }
        if (location) {
            location.getCurrentPosition(function (position) {
                setLocation({ lat: position.coords.latitude, lng: position.coords.longitude })
            })
        }
    }

    return (
        <Container maxWidth="sm" sx={{ padding: 0 }}>
            {loading ?
                <LoderSimmer count={10} />
                :
                <Box>
                    <Box className={classes.root}>
                        <Box p={2}>
                            <Typography className={classes.headerTitle}>Scope Of Work</Typography>
                            <Box className={classes.quillblock}>
                                <ReactQuill
                                    theme="bubble"
                                    readOnly
                                    value={data.ScopeOfWork??"No Data Found"}
                                ></ReactQuill>
                            </Box>
                        </Box>
                    </Box>
                    <Grid className={classes.btnRoot} container spacing={1}>
                        {disable !== true &&
                            <Grid item xs={6}>
                                <Button onClick={() => disable === true ? previous() : setShowReallocateDialog(true)} fullWidth className={classes.btn1}>{disable === true ? t("previous") : t("pause/reallocate")}</Button>
                            </Grid>
                        }
                        <Grid item xs={disable === true ? 12 : 6}>
                            <Button onClick={() => next()} fullWidth variant="contained" className={classes.next}>{t("next")}</Button>
                        </Grid>
                    </Grid>
                    {/* {Pause/Reallocate} */}
                    <DialogDrawer
                        header="Pause/Reallocate"
                        component={<ReAllocateCard location={location} setStep={setStep} state={state} setShowReallocateDialog={setShowReallocateDialog} />}
                        open={showReallocateDialog}
                        padding={0}
                        onClose={() => setShowReallocateDialog(false)}
                    />

                </Box>
            }
        </Container>
    )
}
export default withNamespaces("inspections")(ScopeOfWork)