import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.background.tertiary1,
  },
  screen: {
    borderRadius: "16px",
    border: "1px solid #F5F7FA",
    background: theme.palette.background.tertiary1,
  },
  screenScrollEffect: {
    overflow: "overlay",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  iconButtonStyle: {
    height: "44px",
    padding: "12px 16px 12px 16px",
    borderRadius: "8px",
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.main,
    boxShadow: "0px 2px 6px #00000021",
    "&:hover":{
      backgroundColor:theme.palette.primary.main,
      color:theme.palette.primary.contrastText,
  }
  },
  addAddressButtonTextStyle: {
    fontSize: "14px",
    color: "#FFFFFF",
    fontWeight: "bold",
  },
  addAddressButtonContainerStyle: {
    padding: "16px",
    background: "white",
    border: "1px solid #E4E8EE",
    boxShadow: "0px -7px 20px #0717410D",
    // position: "fixed",
    width: "-webkit-fill-available",
  },

  addressFieldsContainerStyle: {
    backgroundColor: theme.palette.dashboard.primary,
    height: `calc(100vh - 133px)`,
    boxShadow: "0px -7px 20px #0717410D",
    overflow: "auto"
  },

}));
