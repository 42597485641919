import makeStyles from "@mui/styles/makeStyles";
import { Bold, ExtraBold } from "../../utils";

export const profileIEditStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: theme.palette.dashboard.primary,
    height: `calc(100vh - 125px)`,
    overflow: "auto"
  },

  profilePicturePadding: {
    display: "flex",
    justifyContent: "center",
    padding: "24px 16px 24px 16px",
  },

  avatarImg: {
    height: "128px",
    width: "128px",
    border: "2px solid" + theme.palette.border.tertiary,
  },

  InputBoxes: {
    padding: "0 15px 15px 15px",
    "& p": {
      color: theme.typography.color.Tertiary,
      fontSize: "12px",
      paddingBottom: "4px",
    },
    "& input": {
      fontWeight: 700,
    },
    "& fieldset": {
      border: 0,
    },
  },

  InputBoxes1: {
    padding: "0 15px 15px 15px",
    "& p": {
      color: theme.typography.color.Tertiary,
      fontSize: "12px",
      paddingBottom: "4px",
    },
    "& input": {
      fontWeight: 700,
    },
  },

  InputBoxes2: {
    padding: "0 15px 15px 15px",
    "& p": {
      color: theme.typography.color.Tertiary,
      fontSize: "12px",
      paddingBottom: "4px",
    },
    "& input": {
      fontWeight: 700,
    },
  },

  InputBoxes3: {
    padding: "0 15px 15px 15px",
    "& p": {
      color: theme.typography.color.Tertiary,
      fontSize: "12px",
      paddingBottom: "4px",
    },
    "& input": {
      fontWeight: 700,
    },
  },

  addressInputBox: {
    justifyContent: "space-between",
    alignItems: "center",
  },

  InputBoxesAdd: {
    padding: "0 15px 55px 15px",
  },

  givenAddress: {
    fontSize: "16px",
    fontFamily: Bold,
  },

  addAddress: {
    fontFamily: Bold,
    fontSize: "12px",
    color: theme.typography.color.main + "!important",
    cursor: "pointer",
    fontWeight: 700,
  },

  Address1: {
    fontSize: "12px",
    fontFamily: ExtraBold,
    flex: 1,
  },

  editAddress: {
    fontFamily: ExtraBold,
    fontSize: "12px",
    color: theme.typography.color.main + "!important",
    cursor: "pointer",
    fontWeight: 700,
  },

  editBtn: {
    textAlign: "right",
  },

  locationAdd: {
    padding: "15px",
    border: "1px solid" + theme.palette.border.secondary,
    borderRadius: "8px",
    background: theme.palette.background.tertiary1,
    marginTop: "10px",
  },

  Address2: {
    fontSize: "12px",
    color: theme.typography.color.Tertiary,
    marginTop: "5px",
  },

  addressInputBoxSecond: {
    alignItems: "center",
  },

  saveNUpdateButtonTextStyle: {
    fontSize: "14px",
    color: "#FFFFFF",
    fontWeight: "bold",
  },

  saveNUpdateButtonContainerStyle: {
    padding: "16px",
    background: "white",
    border: "1px solid #E4E8EE",
    boxShadow: "0px -7px 20px #0717410D",
    // position: "fixed",
    width: "-webkit-fill-available",
  },
  iconButtonStyle:{
    backgroundColor:theme.palette.primary.main,
    color:theme.palette.primary.contrastText,
    "&:hover":{
        backgroundColor:theme.palette.primary.main,
        color:theme.palette.primary.contrastText,
    }
  }
}));
