import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab } from "@mui/material";
import React from "react";
import { withNamespaces } from "react-i18next";
import MoveInForm from "../moveInForm";
import Details from "./details";


const MoveOutForm = ({
    serial_number = "",
    onUpdate = () => false,
    activity_id = "",
    disable = false,
    in_activity = "",
    type="",
    t
}) => {

    const [value, setValue] = React.useState(in_activity ? '1' : '2');
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    return (
        <Box>
            <TabContext value={value} >
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                        {
                            in_activity && <Tab sx={{ textTransform: "capitalize" }} color='#071741' label={`${t("move_in")}`} value="1" />
                        }
                        <Tab sx={{ textTransform: "capitalize" }} label={`${t("move_out")}`} value="2" />
                    </TabList>
                </Box>
                {
                    in_activity &&
                    <TabPanel sx={{ padding: 0 }} value="1">
                        <Details in_activity={in_activity} serial_number={serial_number} />
                    </TabPanel>
                }

                <TabPanel sx={{ padding: 0 }} value="2">
                    <MoveInForm
                        disable={disable}
                        activity_id={activity_id}
                        serial_number={serial_number}
                        onUpdate={onUpdate}
                        type={type}
                    />
                </TabPanel>
            </TabContext>
        </Box>
    )
}
export default withNamespaces("inspections")(MoveOutForm);