import makeStyles from "@mui/styles/makeStyles";
import { Bold, SemiBold } from "../../utils";

export const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        padding: "12px",
        borderRadius: "4px",
        marginTop: "8px",
        position: "relative"
    },
    propertyImage: {
        height: 65,
        width: 65,
        borderRadius: 4,
    },
    title: {
        fontFamily: Bold,
        fontSize: "14px"
    },
    name: {
        fontFamily: SemiBold,
        fontSize: "12px",
        marginTop: "4px"
    },
    subTitle: {
        fontFamily: SemiBold,
        fontSize: "12px",
        color: theme.typography.color.secondary,

    },
    tick: {
        color: "#B20606",
        position: "absolute",
        right: 10,
        top: 10
    }

}))