import React from "react"

export const UtilityIcon = (props) =>(
    <svg xmlns="http://www.w3.org/2000/svg"  width="24" height="24" viewBox="0 0 24 24">
  <defs>
    <clipPath id="clip-path">
      <rect id="Rectangle_55761" data-name="Rectangle 55761" width="24" height="24" transform="translate(82.809 183.809)" fill={props?.color??"#98a0ac"}/>
    </clipPath>
  </defs>
  <g id="Group_7068" data-name="Group 7068" transform="translate(-27.84 0.16)">
    <g id="Group_18" data-name="Group 18" transform="translate(27.84 -0.16)">
      <rect id="Rectangle_31" data-name="Rectangle 31" width="24" height="24" transform="translate(0 0)" fill="none"/>
      <g id="Group_108221" data-name="Group 108221" transform="translate(-82.809 -183.809)" clip-path="url(#clip-path)">
        <g id="Group_108220" data-name="Group 108220" transform="translate(85.457 183.807)">
          <g id="icons8-street-light" transform="translate(0 0)">
            <path id="Union_24" data-name="Union 24" d="M7.429,24V21.946a2.129,2.129,0,0,1-.98-.8H5.714a.571.571,0,0,1,0-1.143h.234a2.271,2.271,0,0,1-2.23-1.791l-1.891-8.5H1.744A1.141,1.141,0,0,1,.661,8.933L.03,7.04a.574.574,0,0,1,.542-.755H2.286c4.571-.572,6-4,6-5.429A.857.857,0,0,1,10,.857c0,1.429,1.428,4.857,6,5.429h1.714a.574.574,0,0,1,.542.755l-.631,1.893a1.143,1.143,0,0,1-1.088.781h-.082l-1.887,8.5A2.274,2.274,0,0,1,12.341,20h.231a.571.571,0,1,1,0,1.143h-.73a2.126,2.126,0,0,1-.984.8V24Zm2.286-5.141h2.626a1.141,1.141,0,0,0,1.117-.9l1.834-8.251H9.714Zm-4.883-.9a1.141,1.141,0,0,0,1.117.9H8.571V9.714H3Z" fill={props?.color??"#98a0ac"}/>
          </g>
        </g>
      </g>
    </g>
  </g>
</svg>

)